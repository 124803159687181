import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import TemplateFlowEditTable from "./flowEditTable";
import { saveTemplateFlow, getTemplateFlow } from '../../../services/manage/flow/manageFlowService';
import { NavLink } from 'react-router-dom';
import PermissionComponent from '../common/permissonComponent';

// import dlv from 'dlv'
import FlowOptions from "./options/flowOptions";

const defaultPermission = [{role: {name: 'Create Flow', value: 'create'}, mode: {name: 'All Staffs', value: 'all'}, person: []}]

const TemplateFlowEdit = ({history, match}) => {

  const [id,] = useState(match.params.id)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const [tasks, setTasks] = useState([])
  const [options, setOptions] = useState()
  const [permission, setPermission] = useState(defaultPermission)

  console.log('tasks', tasks)

  useEffect(() => {
    const loadData = async() => {
      try {
        const {data: result } = await getTemplateFlow(id);

        const { data } = result.data

        setName(data.name)
        setDescription(data.description)
        setTasks(data.tasks)
        setOptions(data.options)
        setPermission(data.permission || defaultPermission)

      } catch(err) {
        toast.error('Failed to load data')
      }
    }

    if(id && id!=='new') loadData(id)

  }, [id])

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    if(tasks.length===0) return toast.error('Step is empty', toastOptions)

    const doc = { name , description, tasks, permission, options, _id: (id==='new') ? undefined : id  }

    try {
      const { data: result } = await saveTemplateFlow(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/manage/flow"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-10">
        <div className="row align-items-center mb-4">
          <div className="col-auto" style={{width: 300}}>
              <h5 className="font-weight-bold">Flow Name</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
          <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-auto text-muted" style={{width: 300}}>
              <h5 className="font-weight-bold">Description</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
          </div>
        </div>
        <TemplateFlowEditTable tasks={tasks} setTasks={setTasks} />
        <div className="row mt-4">
          <div className="col">
            <FlowOptions data={options} setData={setOptions} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <PermissionComponent permission={permission} setPermission={setPermission} />
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-auto text-center">
          <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save Flow</button>
          </div>
          <div className="col-auto">
            <NavLink className="nav-item nav-link p-0" to={`/manage/flow`} >
            <button type="button" className="btn btn-secondary text-white" >Cancel</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateFlowEdit;
