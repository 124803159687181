import React, {useState, useEffect, useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
// import FontAwesome from 'react-fontawesome';
import ConfirmDelete from '../common/confirmDelete';
import { getWorkPlans } from '../../services/plan/planService';
import FilterDateCombo from './../common/filter/filterDateCombo';

import { DomainContext } from './../../context/domainContext';
import PlanDaily from './daily/planDaily';
import PlanWeekly from './weekly/planWeekly';
import PlanMonthly from './monthly/planMonthly';
import socketIOClient from '../../services/app/socketioService';
import { getManagePlanList } from './../../services/manage/plan/managePlanService';
import { templateTaskList } from './../../services/manage/task/taskService';
import { projectList } from './../../services/manage/project/index';
import { staffList } from './../../services/manage/staff/staffService';
import { personList } from './../../services/manage/staff/teamService';

import dlv from 'dlv'
import _ from 'lodash'

moment.updateLocale('en', {week: { dow: 1 }})

const PlanIndex = () => {
    const {domain } = useContext(DomainContext)

    const [filter, setDateFilter] = useState({filter: 'today', date: moment().format('YYYY-MM-DD')})

    useEffect(() => {
        // setDateFilter({filter: 'day', date: '2020-07-25'})
    },[])

    const [autoPlans, setAutoPlans] = useState()
    const [list, setList] = useState([])
    const [plans, setPlans] = useState([])
    const [askDelete, setAskDelete] = useState('')

    const [allTasks, setAllTasks] = useState()
    const [allProjects, setAllProjects] = useState([])
    const [allStaffs, setAllStaffs] = useState()
    const [personGroups, setPersonGroups] = useState()

    useEffect(() => {
        if(!domain) return

        const loadAutoPlan = async() => {
            try {
                const input = { enable: true, mode: 'auto', projectionReq: 'task,project,count,countPerStaff,permission,recurrenceOptions' }
                const {data: result} = await getManagePlanList({input});
    
                if(result.status==='success') {
    
                    setAutoPlans(result.data.list)
        
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        const loadAllTasks = async() => {
            try {
                const input = { enable: true, projectionReq: '_id,name' }
                const {data: result} = await templateTaskList(input);
    
                if(result.status==='success') {
                    setAllTasks(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        const loadAllProjects = async() => {
            try {
                const input = { enable: true, projectionReq: '_id,name,tasks._id' }
                const {data: result} = await projectList(input);
    
                if(result.status==='success') {
                    
                    setAllProjects(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        const loadAllStaffs = async() => {
            try {
                const input = { enable: true, projectionReq: 'userId' }
                const {data: result} = await staffList(input);
    
                if(result.status==='success') {
                    setAllStaffs(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        const loadPersonGroups = async() => {
            try {
                const input = { enable: true, projectionReq: 'list.userId' }
                const {data: result} = await personList(input);
    
                if(result.status==='success') {
                    setPersonGroups(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        loadAutoPlan()
        loadAllTasks()
        loadAllProjects()
        loadAllStaffs()
        loadPersonGroups()
    },[domain])

    useEffect(()=> {

        if(!autoPlans || autoPlans.length===0) return
        if(!allTasks || allTasks.length===0) return
        if(!allStaffs || allStaffs.length===0) return
        if(!personGroups) return

        const allStaffIds = allStaffs.map(x=> x.userId)

        const dateTag = ['day','week','month'].includes(filter.filter) ? filter.filter : 'day'

        var dayBegin = moment(filter.date, 'YYYY-MM-DD').startOf(dateTag).format('YYYY-MM-DD')
        var dayEnd = moment(filter.date, 'YYYY-MM-DD').endOf(dateTag).format('YYYY-MM-DD')

        const docPlans = []
        for (var m = moment(dayBegin); m.isSameOrBefore(dayEnd); m.add(1, 'day')) {

            if(moment(m).hour(23).minute(0).isBefore(moment().add(1, 'day'))) continue;

            const dayOfWeek = m.day()
            const dayMonth = m.date()
            const targetDay = moment(m).format('YYYY-MM-DD')
            const dayText = m.format('ddd')
            
            const docAll = autoPlans.map(plan => {
                try {
                  const { _id, task, project, recurrenceOptions, count, permission, countPerStaff } = plan
                  if(!task || !task._id) return undefined
        
                  const { mode, dayMode, timeMode, selectedDays, timeBegin, timeEnd, dayOfMonth } = recurrenceOptions
        
                  if(mode.value==='weekly' && dayMode==='custom') {
                    if(!selectedDays.map(x=> x.value).includes(dayOfWeek)) return undefined
                  } else if(mode.value==='monthly' && dayOfMonth && dayOfMonth.length>0) {

                    const findEnd = dayOfMonth.find(x=> x==='End') && moment(targetDay).isSame(moment(targetDay).endOf('month'), 'day')

                    const findSpecial = dayOfMonth.filter(x=> x.length>=4).find(item => {
                        const day = item.substring(0, 3)

                        if(day===dayText) {
                            if(item.length===6) {
                                if(item.substring(item.length-3)==='End') {
                                    if(!moment(targetDay).add(1, 'week').isSame(targetDay, 'month')) return true
                                }
                            } else {
                                const num = parseInt(item.substring(item.length-1))
                                if(isNaN(num)) return false
        
                                if(!moment(targetDay).add(-1*(num), 'week').isSame(targetDay, 'month')) {
                                    if(moment(targetDay).add(-1*(num-1), 'week').isSame(targetDay, 'month')) {
                                        return true
                                    }
                                }
                            }
                        }

                        return false
                    })

                    const findNumber = dayOfMonth.filter(x=> x.length<=2).includes(dayMonth.toString())

                    if(!findEnd && !findSpecial && !findNumber) return undefined
                  }
        
                  const findTask = allTasks.find(x=> x._id.toString()===task._id)
                  if(!findTask) return undefined
        
                  const docs = []
                  const doc = { status: 'wait'}
                  doc.name = findTask.name
                  doc.template = { _id: task._id }
        
                  if(project && project._id) {
                    const findProject = allProjects.find(x=> x._id===project._id)
                    if(!findProject) return undefined
                    if(!findProject.tasks.find(x=> x._id===task._id)) return undefined
        
                    doc.project = findProject
                  }
        
                  const dateBegin = (timeMode==='all') ? moment(targetDay).toDate() :  moment(targetDay + ' ' + timeBegin).toDate()
                  const dateEnd = (timeMode==='all') ? moment(targetDay).endOf('day').toDate() : moment(targetDay + ' ' + timeEnd).toDate()
        
                  doc.plan = {_id, begin: dateBegin, end: dateEnd }
                  doc.isAuto = true
                  doc.permission = permission
        
                  const permissionCreate = permission.find(x=> dlv(x, 'role.value')==='create')
                  if(countPerStaff && permissionCreate) {
                    
                    if(dlv(permissionCreate, 'mode.value')==='all') {
                      
                      allStaffs.map(staff => {
                        doc.permission = [{ 
                            role: {name: 'Create Task', value: 'create'}, 
                            mode: {name: 'Custom', value: 'custom'}, 
                            person:  [{type: 'staff', _id: staff.userId}]
                          }]
        
                        docs.push({...doc})
                        return staff
                      })
        
                    } else {
        
                      const groupIds = permissionCreate.person.filter(x=> x.type==='group').map(x=> x._id)
                      const staffIds = permissionCreate.person.filter(x=> x.type==='staff').map(x=> x._id).filter(x=> allStaffIds.includes(x))
        
                      const groupStaffs = personGroups.filter(group => groupIds.includes(group._id.toString()))
                                                .map(group => group.list.map(x=>x)).reduce((acc,x)=> acc.concat(x), [])
    
                      const allIds = _.uniq([...staffIds, ...groupStaffs ])
        
                      allIds.map(ids => {
                        doc.permission = [{ 
                          role: {name: 'Create Task', value: 'create'}, 
                          mode: {name: 'Custom', value: 'custom'}, 
                          person:  [{type: 'staff', _id: ids}]
                        }]
                        docs.push({...doc})

                        return ids
                      })
                    }
        
                  } else {
                    for(var i=0; i<count; i++) {
                      doc.permission = permission
                      docs.push(doc)
                    }
                  }
                  
                  return docs
        
                }catch(err) { 
                  console.log('err.message', err.message)
                  return undefined
                }
            }).filter(x=>x).reduce((acc,x)=> acc.concat(x), [])

           docPlans.push(...docAll) 
        }

        setPlans(docPlans)

    },[filter, autoPlans, allTasks, allProjects, allStaffs, personGroups])

    const getList = async() => {
        try {
            const {data: result} = await getWorkPlans(filter);

            if(result.status==='success') {
                setList(result.data.list)
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(domain) getList()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, domain])

    useEffect(()=> {
        const socketEvent = (message) => {
            const { domainid } = message
            if(!domainid) return
      
            if(domainid===domain._id.toString() ) {
              getList()
            }     
        }

        socketIOClient.on('work-start', socketEvent)
        socketIOClient.on('work-finish', socketEvent)
        return () => { 
            socketIOClient.off('work-start')  
            socketIOClient.off('work-finish')
        }
        // eslint-disable-next-line
      },[])
    
    const confirmDelete = async() => {
        try {
            // await deletePlan(askDelete);

            // setAskDelete('')
            // getList()

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row no-gutters justify-content-end ">
                <div className="col-auto mr-auto mb-3">
                    <h4>Plans</h4>
                </div>
                <div className="col-auto mb-3 d-none">
                    {/* <SearchBox value={search} onChange={value => setSearch(value)} /> */}
                </div>
                <div className="col-auto mb-3">
                    <NavLink className="nav-item nav-link text-dark d-inline" to={`/plan/new`} >
                        <button type="button" className="btn btn-success" >Create Plan</button>
                    </NavLink>
                </div>
                <div className="col-auto ml-2 mb-3">
                    <FilterDateCombo enableFilter={['today', 'day', 'week', 'month']}  notifyDateFilter={filter => setDateFilter(filter)} />
                </div>
            </div>
            {['today', 'day'].includes(filter.filter) && <PlanDaily date={filter.date} data={[...list, ...plans]} />}
            {['week'].includes(filter.filter) && <PlanWeekly date={filter.date} data={[...list, ...plans]} />}
            {['month'].includes(filter.filter) && <PlanMonthly date={filter.date} data={[...list, ...plans]}  />}
        </React.Fragment>
     );
}
 
export default PlanIndex;