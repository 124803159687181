import React from 'react';
import FontAwesome from 'react-fontawesome';
// import { NavLink } from 'react-router-dom';
// import { getWorkHourFromTotalMinutes } from '../../../utils/work';
// import PersonPhoto from '../common/card/personPhoto';
// import dlv from 'dlv'

const PlanWeeklyCard = ({data={}, className='', isFuture}) => {

  const { name, workWait='-', workActive='-', workFinish='-', workOverdue='-', workPause='-', workDelete='-'} = data;

  const total = parseInt(workWait)
  const finish = parseInt(workFinish)

  const percent = (isNaN(finish) || isNaN(finish)) ? 0 : (finish*100/total).toFixed(0)

  return ( 
    <div className={className}>
      <div className="card border-secondary mb-2">
        <div className="card-body p-2">
          <div className="row no-gutters justify-content-end align-items-center">
            <div className="col-auto ml-2 mr-auto">
                <div className="row no-gutters align-items-center">
                  <div className="col-auto mr-3">
                    <FontAwesome name="file-code-o" />
                    <h6 className="ml-1 d-inline font-weight-bold">{name.substring(0,20) + ((name.length>20) ? '...' : '')}</h6>
                  </div>
                </div>
            </div>
            <div className="col-auto text-right">
              <div className="row no-gutters justify-content-center align-items-center" style={{lineHeight: 0.5}}>
                  {!isFuture && <div className='col-auto mr-4 text-center'>
                    <h5>{percent}%</h5>
                  </div>}
                  <div className='col-auto mx-1 text-center'>
                  <h4><span className="badge badge-pill badge-info">{workWait}</span></h4>
                  Plan
                  </div>
                  {!isFuture && <div className='col-auto mx-1 text-center'>
                  <h4><span className="badge badge-pill badge-warning">{workActive}</span></h4>
                  Active
                  </div>}
                  {!isFuture && <div className='col-auto mx-1 text-center'>
                  <h4><span className="badge badge-pill badge-success">{workFinish}</span></h4>
                  Finish
                  </div>}
                  {!isFuture && <div className='col-auto mx-1 text-center'>
                  <h4><span className="badge badge-pill badge-secondary">{workOverdue}</span></h4>
                  Overdue
                  </div>}
                  {!isFuture && <div className='col-auto mx-1 text-center'>
                  <h4><span className="badge badge-pill badge-secondary">{workPause}</span></h4>
                  Pause
                  </div>}
                  {!isFuture && <div className='col-auto mx-1 text-center'>
                  <h4><span className="badge badge-pill badge-secondary">{workDelete}</span></h4>
                  Delete
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
    
}
 
export default PlanWeeklyCard;