import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SearchBox from "../searchBox";
import { toast } from "react-toastify";
import { searchEmployee } from "../../../services/hrms/employee"
import SearchTable from "./searchTable";
import { useState, useEffect } from 'react';
import { useRef } from 'react';
// import { searchUser } from './../../../services/manage/staff/staffService';
// import dlv from 'dlv'

const DialogSearchPersonMain = ({isOpen, onCancel, onConfirm, list=[], allowSearch=true}) => {

  const [search, setSearch] = useState('')
  const [data, setData] = useState(list)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setData(list)
  }, [list])

  const index = 0
  const row = 5
  const _delay_seach = useRef(false)

  useEffect(() => {
    
    const searchPerson = async() => {

      if(search && search.length>2) {
  
        clearTimeout(_delay_seach.current)
        setIsLoading(true)
  
        _delay_seach.current = setTimeout(async () => {

          try {

            const result = await searchEmployee(search, index, row);
            setIsLoading(false)
  
            setData(result)

            // const {data: searchUsers} = await searchUser(search)
            // if(searchUsers.status==='success') {
            //   if(dlv(searchUsers, 'data.list', []).length>0) {
            //     console.log('add search users');
            //     setData(prev=> [...prev, ...dlv(searchUsers, 'data.list', [])])
            //   }
            // }
  
          } catch(err) {
            toast.error("Error : " + err.message)
          }
          
        }, 1000)
   
      } else {
        clearTimeout(_delay_seach.current)
        setData([])
        setIsLoading(false)
      }
    }

    if(allowSearch) searchPerson()

  }, [search, allowSearch])

  const handleSelect = (item) => {
    if( typeof(onConfirm) === 'function') {
      onConfirm(item)
      if(allowSearch) setSearch('')
    }
  }


  return ( 
    <Modal isOpen={isOpen} toggle={onCancel} >
        <ModalHeader color="info">ค้นหาบุคคล
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto mb-3">
                <h6 className="d-inline">ค้นหา</h6>
              </div>
              <div className="col-12 col-md-10 col-lg-8 mb-3">
                <SearchBox value={search} onChange={value => setSearch(value)} />
              </div>
            </div>
            {search && search.length>2 && data.length===0 && !isLoading && <div className="alert alert-secondary mt-4" role="alert">
              Search is not found
            </div>}
            {isLoading && <div className="alert alert-secondary mt-4" role="alert">
              Loading...
            </div>}
            <SearchTable data={data} onConfirm={handleSelect} />
          </React.Fragment>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>
        </ModalFooter>
      </Modal>
   );
}
 
export default DialogSearchPersonMain;