import React, { useState, useEffect } from 'react';
import ButtonGroup from './../../../common/buttonGroup';
import _ from 'lodash'
import dlv from 'dlv'
import { testApi } from '../../../../services/script/scriptService'

import DialogSearchListIndex from '../../../common/dialogSearchList/index';
import FontAwesome from 'react-fontawesome';

function useInput({ name='default', type, valueDefault='' }) {
    const [value, setValue] = useState(valueDefault);
    const input = <input className="form-control" name={name} value={value} onChange={e => setValue(e.target.value)} type={type} />;
    return [value, input, setValue];
}

const ScriptForm = ({index, data, linkFieldList=[], notifySave, notifyCancel }) => {
    const [info, setInfo] = useState('')
    const [error, setError] = useState('')

    const eventList = [
        {name: 'Pre-Start', value: 'pre-start'},
        {name: 'Post-Start', value: 'post-start'},
        {name: 'Pre-Finish', value: 'pre-finish'}, 
        {name: 'Post-Finish', value: 'post-finish'}]
    const [event, setEvent] = useState( eventList.find(x=> x.value===dlv(data, 'event.value', 'pre-start')) )
    const [httpUrl, httpUrlInput] = useInput({ type: "text", valueDefault: dlv(data, 'httpUrl', '') })

    const methodList = [{name: 'GET', value: 'get'}, {name: 'POST', value: 'post'}, {name: 'PUT', value: 'put'}, {name: 'DELETE', value: 'delete'},] 
    const [httpMethod, setHttpMethod] = useState(dlv(data, 'httpMethod', 'get'))
    const [httpInput, setHttpInput] = useState(dlv(data, 'httpInput', []))
    const handleHttpInput = ({index, ...res}) => {

        setHttpInput(prev => {
            return prev.map((p, i) => {
                if(i===index) p = {...p, ...res}

                return p
            })
        })
    }

    useEffect(() => {
        setInfo('')
        setError('')
    }, [event, httpUrl, httpMethod, httpInput])

    const reqList = [{name: "Query", value: 'query'}, {name: "Body", value: 'body'}]
    const [check200, setCheck200] = useState(dlv(data, 'check200', false))
    const [checkStatus, setCheckStatus] = useState(dlv(data, 'checkStatus', false))

    const verifyForm = () => {
        if(!event) return setError('Event is required')
        if(!httpUrl) return setError('URL is required')

        const urlRegex = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')
        if(httpUrl.length>2048 || !urlRegex.test(httpUrl)) return setError("URL is invalid")

        if(httpInput.find(x=> !x.name)) return setError("Input 'name' is missing")
        if(httpInput.find(x=> x.name && !(/^[a-zA-Z0-9]+$/.test(x.name)))) return setError("Some input 'name' is invalid (Only a-z and number)")
        if(httpInput.find(x=> !x.value && !x.linkField)) return setError("Input 'value' is missing")

        const dupText = _(httpInput).groupBy(x=>x.name).pickBy(x=>x.length>1).keys().value();
        if(dupText.length>0) return setError("Some input 'name' are duplicated")

        setError('')

        return true
    }

    const handleTest = async() => {
        const verify = verifyForm()

        if(typeof(verify)==='boolean' && verify) {

            const test = await testApi({url: httpUrl, method: httpMethod, input: httpInput})
            if(test.status) {
                setInfo("Success")
            } else {
                setError(test.message)
            }

        }
    }

    const onSave = () => {

        const verify = verifyForm()

        if(typeof(verify)==='boolean' && verify) {

            if(notifySave instanceof Function) {
                notifySave({item: {event, httpUrl, httpMethod, httpInput, check200, checkStatus}, index})
            }
        }

    }

    const onCancel = () => {
        if (notifyCancel instanceof Function) {
            notifyCancel()
        }
    }

    const headerSpace = 100

    return ( <React.Fragment>
        <div className="card bg-secondary text-white my-3">
            <div className="card-body">
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Event</h6>
                    </div>
                    <div className="col-auto">
                        <ButtonGroup
                            items={eventList}
                            selectedItem={event}
                            onItemSelect={item => setEvent(item)}
                            valueProperty='value'
                            selectedItemClass="btn btn-sm btn-light"
                            defaultItemClass="btn btn-sm btn-dark btn-outline-secondary text-white"
                            />
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">URL</h6>
                    </div>
                    <div className="col">
                        {httpUrlInput}
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Method</h6>
                    </div>
                    <div className="col-auto">
                        <ButtonGroup
                            items={methodList}
                            selectedItem={methodList.find(x=> x.value===httpMethod)}
                            onItemSelect={item => setHttpMethod(item.value)}
                            valueProperty='value'
                            selectedItemClass="btn btn-sm btn-light"
                            defaultItemClass="btn btn-sm btn-dark btn-outline-secondary text-white"
                            />
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Verify</h6>
                    </div>
                    <div className="col-auto p-0">
                    <input className="ml-4" type="checkbox" checked={check200} onChange={e => setCheck200(e.target.checked)} /><span> HTTP/200</span>
                    </div>
                    <div className="col-auto p-0">
                    <input className="ml-4" type="checkbox" checked={checkStatus} onChange={e => setCheckStatus(e.target.checked)} /><span> Status=SUCCESS</span>
                    </div>
                </div>
                <div>
                    <div className="row align-items-center mt-3">
                        <div className="col-auto" style={{width: headerSpace}}>
                            <h6 className="font-weight-bold">Input</h6>
                        </div>
                        <div className="col-auto">
                            <button type="button" className="btn btn-sm btn-light text-dark" onClick={() => 
                                setHttpInput(prev => [...prev, {req: 'query', name: '', value: '', linkField: ''}])
                            }>Add</button>
                        </div>
                    </div>
                    <div className="row align-items-top">
                        <div className="col-auto" style={{width: headerSpace}}>
                        </div>
                        <div className="col">
                            {httpInput.map((x, index) => {
                                const { req, name, value, linkField } = x

                                return (
                                <React.Fragment key={index}>
                                    <div className="row align-items-center justify-content-start mt-2">
                                        <div className="col-auto">
                                            <ButtonGroup
                                                items={reqList}
                                                selectedItem={reqList.find(x=> x.value===req)}
                                                onItemSelect={item => handleHttpInput({index, req: item.value})}
                                                valueProperty='value'
                                                selectedItemClass="btn btn-sm btn-light"
                                                defaultItemClass="btn btn-sm btn-dark btn-outline-secondary text-white"
                                            />
                                        </div>
                                        <div className="col-auto p-0 mr-2">
                                            <div className="input-group input-group-sm p-0">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-sm">Name</span>
                                            </div>
                                            <input type="text" value={name} 
                                                onChange={e => { handleHttpInput({index, name: e.target.value })}} 
                                                className="form-control" aria-label="Small" 
                                                aria-describedby="inputGroup-sizing-sm" style={{width: 100}} />
                                            </div>
                                        </div>
                                        {!linkField && <div className="col-auto mr-2 p-0">
                                            <div className="input-group input-group-sm">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-sm">Value</span>
                                            </div>
                                            <input type="text" value={value} 
                                                onChange={e => { handleHttpInput({index, value: e.target.value })}} 
                                                className="form-control" aria-label="Small" 
                                                aria-describedby="inputGroup-sizing-sm" style={{width: 100}} />
                                            </div>
                                        </div>}
                                        {linkField && <div className="col-auto p-0">Link Field</div>}
                                        <div className="col-auto ml-2 p-0">
                                                <DialogSearchListIndex 
                                                header="Select Link Field" 
                                                buttonAdd="Link Field" 
                                                buttonStyle="btn btn-sm btn-light"
                                                selectData={linkFieldList.find(x=> x.value===linkField)}
                                                selectValue={linkField}
                                                invalidItem={linkField && !linkFieldList.some(x=> x.value===linkField)}
                                                cache={true}
                                                valueProperty="value"
                                                icon="file-code-o"
                                                list={linkFieldList} 
                                                notifySelect={item => {
                                                    handleHttpInput({index, linkField: item ? item.value : '', value: ''})
                                                }} />
                                                

                                        </div>
                                        <div className="col-auto ml-auto text-light">
                                            <FontAwesome name="times-circle" onClick={() => {
                                                setHttpInput(prev => prev.filter((x,i)=> i!==index))
                                            }} />
                                        </div>
                                   </div>
                                </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer p-2">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        {error && <span className="text-warning font-weight-bold ml-3">{error}</span>}
                        {info && <span className="text-light font-weight-bold ml-3">{info}</span>}
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-sm btn-warning font-weight-bold mr-3" onClick={handleTest}>Test</button>
                        <button type="button" className="btn btn-sm btn-light text-dark font-weight-bold mr-3" onClick={onCancel}>Cancel</button>
                        <button type="button" className="btn btn-sm btn-light font-weight-bold" onClick={onSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default ScriptForm;