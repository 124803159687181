import moment from 'moment';

export function getWorkHour (start, end) {

    const diff = moment(end).diff(start, 'minutes');

    if(diff===0) return '0 Minute'

    const days = Math.floor(diff/60/24);
    const day_text = (days>0) ? (days + ((days>1) ? ' Day': ' Day')) : '';
    
    const hours = Math.floor((diff%1440)/60);
    const hour_text = (hours>0) ? (hours + ((hours>1) ? ' Hour': ' Hour')) : '';
    
   const mins = Math.floor(diff%60);
   const min_text = (mins>0) ? (mins + ((mins>1) ? ' Minute': ' Minute')) : '';

    return (((day_text) ? day_text + ' ' : '') + ((hour_text) ? hour_text + ' ' : '') + ((min_text) ? min_text : '')).trimRight();
}

export function getWorkHourFromTotalMinutes (diff) {

    if(diff===0) return '0min'

    const labelMin = (diff>60) ? 'm' : 'min'

    const days = Math.floor(diff/60/24);
    const day_text = (days>0) ? (days + 'd') : '';
    
    const hours = Math.floor((diff%1440)/60);
    const hour_text = (hours>0) ? (hours + ((hours>1) ? 'h': 'h')) : '';
    
   const mins = Math.floor(diff%60);
   const min_text = (mins>0) ? (mins + labelMin) : '';

    return (((day_text) ? day_text + ' ' : '') + ((hour_text) ? hour_text + ' ' : '') + ((min_text) ? min_text : '')).trimRight();
}