import moment from 'moment';

export function formatDateTimeFriendly (date, locale='en', adjustTimeZone=0) {
    const newdate = (date) ? moment(date).add(adjustTimeZone, 'hour') : moment()
    if (locale.toLowerCase()==='th') {
        if(newdate.isSame(moment(), 'day')) {
            return "วันนี้ " + newdate.format("HH:mm")
        } else {
            return newdate.locale(locale.toLowerCase()).format("D MMM") + newdate.add(543, 'year').format(" YY ") + newdate.format("HH:mm")
        }
        
    } else {
        if(newdate.isSame(moment(), 'day')) {
            return "Today " + newdate.format("HH:mm");
        } else {
            return newdate.format("D MMM YY HH:mm");
        }
        
    }
}

export function formatDateTime (date, locale='en', adjustTimeZone=0) {
    const newdate = (date) ? moment(date).add(adjustTimeZone, 'hour') : moment()
    if (locale.toLowerCase()==='th') {
        return newdate.locale(locale.toLowerCase()).format("D MMMM") + newdate.add(543, 'year').format(" YYYY ") + newdate.format("HH:mm")
        
    } else {
        return newdate.format("D MMMM YYYY HH:mm");
    }
  }

