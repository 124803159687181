import React from 'react';
import ProjectCard from './projectCard';

const ProjectListTable = ({className, list, setAskDelete}) => {

    return ( 
        <div className={className}>
            <div className="row">
            {list && list.map( (item, index) => {

                return (
                <div key={index} className="col-12">
                    <ProjectCard data={item} onRemove={setAskDelete} />
                </div>
                )
            })}
            </div>
        </div>
     );
}
 
export default ProjectListTable;