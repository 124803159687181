import React, {useState, useEffect} from "react";
import FlowDetailTable from './table'
import { getFlow } from "../../../services/flow";
import { toast } from "react-toastify";

const FlowDetail = ({history, match, user}) => {
  const [flowId, ] = useState(match.params.id)
  const [flowData, setFlowData] = useState(undefined)
  const [flows, setFlows] = useState('')

  const loadFlowData = async(id) => {
    if(id && id!=='new') {
      try {
        const {data: result } = await getFlow(id);

        setFlowData(result.data.data)
        setFlows(result.data.data.flows)

      } catch(err) {
        toast.error('Failed to load form data')
      }
    }
  }

  useEffect(() => { loadFlowData(flowId)  }, [flowId])

  const { name, description } = (flowData) ? flowData : {}
  

  const notifyUpdate = (data) => {
     loadFlowData(flowId)
  }

    return (
      <React.Fragment>
          <div className="row align-items-center">
            <div className="col-auto">
                <h5 className="font-weight-bold">Flow Name</h5>
            </div>
            <div className="col-auto">
              <h5 className="font-weight-bold text-primary">{name}</h5>
            </div>
          </div>
          {description && <div className="row align-items-center mt-2">
            <div className="col-auto">
                <h5>Description</h5>
            </div>
            <div className="col-auto">
              <h6 className="font-weight-bold">{description}</h6>
            </div>
          </div>}
          {flowData && flows.length>0 && 
          <div className="mt-4"><FlowDetailTable data={flows} notifyUpdate={notifyUpdate} user={user} step={flowData.step} /></div>
          }
          {flows.length===0 && 
          <div className="alert alert-warning" role="alert">
            Flow is empty
          </div>
          }
      </React.Fragment>
    );
  
}

export default FlowDetail;
