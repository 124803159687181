import React from 'react';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import GraphBarBasic from '../../../common/graph/basicBar';
import GraphDonutBasic from './../../../common/graph/basicDonut';
import ReportWorkCard from './../../work/workCard';
import dlv from 'dlv'

const PerStaffHistory = ({report, filter}) => {

    const { workTotal=0, timeTotal=0, jvTotal=0 } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { groupByHour, groupDaily, groupByProject, groupByTask, timeByTask, tasks } = report

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-3">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Finish Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                                Total
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTime}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jvTotal>0 && 
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Job Value</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{jvTotal.toLocaleString()} &#3647;</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Work Hourly' yaxisText='Finish Work' />}
            {groupDaily && <GraphBarBasic className='mb-3' data={groupDaily} header='Work Daily' yaxisText='Finish Work' series={[]}  />}
            {groupByProject && dlv(groupByProject,'data',[]).length>0 && <GraphDonutBasic className="mb-3" input={groupByProject} header="Group By Project" />}
            {groupByTask && <GraphDonutBasic className="mb-3" input={groupByTask} header="Total Work By Task" centerText="Total Work" />}
            {timeByTask && <GraphDonutBasic className="mb-3" input={timeByTask} header="Total Time By Task" centerText="Total Minute" />}
            { tasks && <div className="row">
            { tasks.map( (item, index) => {
                return (
                <div key={index} className="col-12">
                    <ReportWorkCard data={item} showOwner={false} />
                </div>
                )
            })}
            </div>}
        </React.Fragment>
     );
}
 
export default PerStaffHistory;