import React, {useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';

import FontAwesome from 'react-fontawesome';
import DialogLineRoom from '../../common/dialogLineRoom/dialogLineRoom';
import ToggleButton from 'react-toggle-button'
import { lineList, deleteLine, saveLine } from '../../../services/manage/line/lineService';
import { DomainContext } from '../../../context/domainContext';

const StaffManageLine = ({staff}) => {

    const { domain } = useContext(DomainContext)

    const [askManage, setAskManage] = useState({isOpen: false, id: undefined})

    const getList = async(staffId) => {
        try {
            const {data: result} = await lineList({staffId: staffId});

            if(result.status==='success') {
                setList(result.data.list)
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(staff) getList(staff.userId)
    }, [staff])

    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState({isOpen: false, body: undefined, id: undefined})

    const setEnable = async(_id, value, item) => {
        await saveLine({...item, enable: !value })
        getList(staff.userId)
    }



    const confirmSave = () => { 
        setAskManage({isOpen: false})
        getList(staff.userId)

    }

    const confirmDelete = async() => {
        try {
            if(askDelete.id) {
                await deleteLine(askDelete.id);
                setAskDelete({isOpen: false})
                getList(staff.userId)
            } else {
                toast.error('askDelete-id is required')
            }
            
            

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={askDelete.isOpen} body={askDelete.body} onConfirm={confirmDelete} onCancel={()=> setAskDelete({isOpen: false})} />
            <DialogLineRoom isOpen={askManage.isOpen} id={askManage.id} staff={staff} onCancel={() => setAskManage({isOpen: false})} onSave={confirmSave} />
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col"><h5>ห้องทำงาน Line</h5></div>
                <div className="col-auto">
                    <button type="button" className="btn btn-light text-dark font-weight-bold" onClick={() => setAskManage({isOpen: true, id: 'new'})} >ADD</button>
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {list && list.map( (item, index) => {
                const {_id, name, enable, domain: lineDomain } = item;

                const isEditable = domain._id===lineDomain.id

                return (
                <div key={index} className="col-12 text-dark">
                    <div className="card mb-2">
                        <div className="card-body p-0">
                            <div className="row align-items-center justify-content-between">
                            <div className="col-auto ml-3" style={{cursor: 'pointer'}}>
                            <h6 className="d-inline font-weight-bold"><FontAwesome name="comments-o" size="lg" /><span className="ml-2">{name}</span></h6>
                            </div>
                            {isEditable && <div className="col-auto pr-4">
                                <div className="row  align-items-center">
                                    <div className="col-auto p-2">
                                        <ToggleButton value={enable} onToggle={value => setEnable(_id, value, item)} />
                                    </div>
                                    <div className="col-auto p-2">
                                        <FontAwesome name="pencil" size="lg" onClick={() => setAskManage({isOpen: true, id: _id})} />
                                    </div>
                                    <div className="col-auto p-2 mr-2">
                                        <FontAwesome name="trash" size="lg" onClick={()=>setAskDelete({isOpen: true, id: _id, body: name})} />
                                    </div>
                                </div>
                                
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default StaffManageLine;