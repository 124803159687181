import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
// import ToggleButton from 'react-toggle-button'

import { projectList as getProjectList } from '../../../../services/manage/project/index';

import DialogSearchListIndex from '../../../common/dialogSearchList/index';
import FontAwesome from "react-fontawesome";
import queryString from 'query-string'
import { getProjectGroupList, getProjectGroup, saveProjectGroup } from '../../../../services/manage/project/group';

const ProjectGroupManage = ({history, match, location}) => {

  const [id,setId] = useState(match.params.id)
  const [projectList, setProjectList] = useState([])

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [projects, setProjects] = useState([])
  const [groups, setGroups] = useState([])

  useEffect(() => {
    const loadTaskList = async() => {
      try {
        const {data: result} = await getProjectList({projectionReq: 'name'});

        if(result.status==='success') {

            setProjectList(result.data.list)
        } else {
            toast.error(result.message)
        }
      } catch(err) {
          toast.error(err.message)
      }
    }

    const loadGroups = async() => {
      try {
        const {data: result} = await getProjectGroupList({projectionReq: 'projects'});

        if(result.status==='success') {
            setGroups(result.data.list)
        } else {
            toast.error(result.message)
        }
      } catch(err) {
          toast.error(err.message)
      }
    }

    loadTaskList()
    loadGroups()
  }, [])

  
  useEffect(() => {
    const loadForm = async(formId) => {
      if(formId && formId!=='new') {
        try {
          const {data: result } = await getProjectGroup(formId);
  
          if( result.data && result.data.item ) {
  
            const { item } = result.data
            if(!item) toast.error('Project data is not found')
  
            const { name, description, projects } = item
  
            setName(name)
            setDescription(description)
            setProjects(projects)
  
            if(queryString.parse(location.search).clone) {
              setId('new')
              setName('')
              setDescription('')
            }
  
          }
        } catch(err) {
          toast.error('Failed to load form data')
        }
      }
    }

    loadForm(id)
  }, [id,location])

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    // if(!projects || projects.length<1) return toast.error('Project is empty', toastOptions)

    const doc = {
      _id: (id==='new') ? undefined : id, 
      name, description: description.replace(/^\s*$(?:\r\n?|\n)/gm,'') , 
      projects
    }

    try {
      const { data: result } = await saveProjectGroup(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/project"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }
  }

  return (
    <div className="row">
      <div className="col-12 col-xl-10">
        <div className="row align-items-top mb-2">
          <div className="col-auto" style={{width: 180}}>
              <h5 className="font-weight-bold">Group Name</h5>
          </div>
          <div className="col" style={{minWidth: 300}}>
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            <span className="text-info">กำหนดชื่อให้กระชับ ช่วยให้รายงานต่าง ๆ ดูง่ายและสวยงาม</span>
            <p className="text-info">Keep name short to support a neat report</p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-auto text-muted" style={{width: 180}}>
              <h5 className="font-weight-bold">Description</h5>
          </div>
          <div className="col" style={{minWidth: 300}}>
            <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
          </div>
        </div>
        <div className="row align-items-start mb-2">
          <div className="col-auto" style={{width: 180}}>
              <h5 className="font-weight-bold">Projects</h5>
          </div>
        </div>
        {projects.length> 0 && projects.map( (item, index) => {
            const { _id } = item
            const findTask = projectList.find(x=> x._id===_id)

            const invalid = !findTask ? true : false

            return (<div key={_id} className="row">
                <div className="col">
                    <div className="card mb-2">
                        <div className="card-body p-2">
                        <div className="row justify-content-start">
                            <div className="col-auto">
                              <span className="mx-2"><FontAwesome name="file-code-o" size="lg" /></span>
                              <span className="font-weight-bold">{findTask ? findTask.name : 'Name Not Found'}</span>
                              {invalid && <span className="badge badge-danger ml-2"><FontAwesome name="explamation-triangle" /> INVALID (Project not found)</span>}
                            </div>
                            <div className="col-auto ml-auto mr-2">
                              <div className="row no-gutters">
                                <div className="col-auto">
                                  <FontAwesome style={{cursor: 'pointer'}} name="trash" onClick={() => {
                                      setProjects(prev => {
                                          const update = prev.filter(x=> x._id!==_id);
                                          return update
                                      })
                                  } } size="lg" />
                                </div>
                              </div>
                            </div>
                        </div>

                      </div>
                    </div>
                </div>
            </div>)
        })}
        {projects.length===0 && 
        <div className="alert alert-warning" role="alert">
          Project is empty / กดปุ่ม Add เพื่อเพิ่ม project
        </div>
        }
        <div className="row align-items-center justify-content-end mt-3">
          <div className="col-auto">
          <DialogSearchListIndex header="Add Project" buttonAdd="Add Project" 
            icon="file-code-o"
            list={
              projectList.filter(item=> !(projects.map(x=> x._id).includes(item._id)) 
                && !(groups.map(x=> x.projects.map(project=> project._id)).reduce((a,b)=> a.concat(b), []).includes(item._id)) )
            } 
            notifySelect={item => { 
              setProjects(prev => [...prev, {_id: item._id, enable: true}])
                
            } }
            valueProperty="_id"
            buttonStyle="btn btn-primary"
            />
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <div className="col-auto text-center">
          <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Create Project Group</button>
          </div>
          <div className="col-auto">
            <NavLink className="nav-item nav-link p-0" to={`/project`} >
            <button type="button" className="btn btn-secondary text-white" >Cancel</button>
            </NavLink>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default ProjectGroupManage;
