import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import PersonPhoto from '../../../common/card/personPhoto';
// import dlv from 'dlv'
// import moment from 'moment'

const ReportProjectCard = ({data={}, style={workVisible: ['wait', 'active', 'finish', 'pause', 'delete']}}) => {

  const { _id: projectId, name, personList=[] } = data;

  const { staffTotal, taskTotal=0, jvTotal, taskWait, taskActive, taskPause, taskOverdue, taskFinish=0, taskDelete } = data
  const { timeTotal, progress } = data

  const workTime = getWorkHourFromTotalMinutes(timeTotal)
  const statusColor = (taskTotal>0 || taskFinish>0) ? 'dark' : 'muted'

  return ( 
    <React.Fragment>
      <div className="card mb-2 text-dark p-0">
        <div className="card-body p-3">
        <NavLink className="stretched-link" to={`/report/progression/${projectId}`} ></NavLink>
        <div className="row no-gutters align-items-center justify-content-end">
          <div className="col-auto mr-auto">
            <h5 className={`text-${statusColor}`}><FontAwesome name='flag-checkered' /> {name}</h5>
            {personList.length>0 && <React.Fragment>
            <div className="col-auto p-0">
              <div className="row no-gutters align-items-center">
                 {personList.filter((_,index)=> index<10).map((item,index) => {
                    return (<div key={index} className='col-auto text-center'>
                    <PersonPhoto id={item} size={40} className='rounded-circle' />
                    </div>)
                 })}
                 {personList.length>10 && <div className="col-auto">
                    <h4 className="d-inline"><span className="badge badge-pill badge-success">+{personList.length-10}</span></h4>
                  </div>}
              </div>
            </div>
          </React.Fragment>}
          </div>
          {(taskTotal>0 || taskFinish>0 || progress>0) && <div className="col-auto">
            <div className="row no-gutters justify-content-center align-items-center">
                {progress!==undefined && <div className='col-auto mr-3 text-center'>
                <h5><span className="text-primary">
                    {progress}%</span></h5>
                    Progression
                </div>}
                {jvTotal>0 && <div className='col-auto mr-3 text-center'>
                <h5><span className="text-primary">
                    {jvTotal.toLocaleString()}&#3647;</span></h5>
                    Job Value
                </div>}
                <div className='col-auto mr-3 text-center'>
                <h5>
                  <span className="text-primary"><FontAwesome name="clock-o" />{workTime}</span>
                </h5>
                Work Time
                </div>
                <div className='col-auto mx-3 text-center'>
                  <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                  Staff
                </div>

                {style.workVisible.includes('wait') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                Wait
                </div>}
                {style.workVisible.includes('active') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                Active
                </div>}
                {style.workVisible.includes('finish') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-success">{taskFinish}</span></h4>
                Finish
                </div>}
                {style.workVisible.includes('pause') && taskPause!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                Pause
                </div>}
                {style.workVisible.includes('overdue') && taskOverdue!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskOverdue}</span></h4>
                Overdue
                </div>}
                {style.workVisible.includes('delete') && taskDelete!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                Delete
                </div>}
            </div>
          </div>}
          {!taskTotal && !taskFinish && !progress && <h5 className="text-secondary mr-4">ไม่มีข้อมูลปฏิบัติงาน / No Task</h5>}
        </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default ReportProjectCard;