import React, { useState, useRef, useEffect, useContext } from "react";
import auth from "../../services/app/authService";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "react-sidebar";
import SideNavBar from "../menu/sideBar";
import NavBar from './../menu/navBar';
import { PermissionContext } from '../../context/permissionContext';
import PermissionDeny from "./permissionDeny";

const mql = window.matchMedia(`(min-width: 992px)`);

function useEventListener(eventName, handler, element = window){
  // Create a ref that stores handler
  const savedHandler = useRef();
  
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      
      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);
      
      // Add event listener
      element.addEventListener(eventName, eventListener);
      
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
};

const ProtectedRoute = ({ path, component: Component, render, requestPermission, ...rest }) => {

  const { permission } = useContext(PermissionContext)

  const [sidebarDocked, setSidebarDocked] = useState(mql.matches)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const user = auth.getCurrentUser()

  const toggleSideBar = () => {  setSidebarOpen(!sidebarOpen)  }
  const onSetSidebarOpen = (open) => { setSidebarOpen(open)  }

  const mediaQueryChanged = () => {
    setSidebarDocked(mql.matches)
    setSidebarOpen(false)
  }

  useEventListener('change', mediaQueryChanged, mql)

  return (
    <React.Fragment>
      <NavBar user={user} onSidebarToggle={toggleSideBar} />
      <Sidebar
        sidebar={<SideNavBar onSidebarToggle={toggleSideBar} user={user} />}
        open={sidebarOpen && (user!==undefined)}
        docked={sidebarDocked && (user!==undefined)}
        onSetOpen={onSetSidebarOpen} 
        styles={{root: {top: 0}, sidebar: {overflow: 'hidden'}}}
        contentId='rootcontent'
        >
        <main role="main" className="container-fluid" style={{padding: "43px", marginTop: "25px"}}>
          <Route {...rest} render={props => {
              if (!user) return (<Redirect to={{ pathname: "/login", state: { from: props.location } }} /> );
              if(requestPermission) {
                if(permission && requestPermission.find(req => permission.find(x=> x===req))) {
                  return Component ? <Component {...props} /> : render(props);
                } else {
                  return <PermissionDeny />
                }
                
              } else {
                return Component ? <Component {...props} /> : render(props);
              }
              
            }} />
        </main>
      </Sidebar>
    </React.Fragment>
    
  );
};

export default ProtectedRoute;
