import React, {useState, useEffect} from 'react';
import { captureDailyStaff } from '../../../../services/capture/captureService'
import { toast } from 'react-toastify';
import moment from 'moment'
import PersonCardMini from '../../../common/card/personCardMini';
import CaptureDailyTaskCard from './taskCard';
import { getWorkHourFromTotalMinutes } from './../../../../utils/work';
import queryString from 'query-string'
import FontAwesome from 'react-fontawesome';
import CaptureDailyStaffTaskGroup from './taskGroup';

const CaptureReportDailyStaff = ({match, location}) => {

    const id = match.params.id
    const date = queryString.parse(location.search).date || moment().format('YYYY-MM-DD')
    const [ staff, setStaff ] = useState({})
    const { label, groupByTask, tasks, domain } = staff

    const { taskTotal=0, taskWait='-', taskActive='-', taskPause='-', taskFinish='-', taskDelete='-', timeTotal=0 } = staff.summary || {}
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    

    useEffect(() => {
        const loadReport = async() => {
            if(!id) return
    
            try {
                const { data: result } = await captureDailyStaff({id, date});
                setStaff(result.data.staff)
    
            } catch(err) {
                toast.error('Failed to load work data')
            }
        }

        loadReport()

    }, [id, date])


    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!staff) return (<div className="alert alert-warning" role="alert">staff is not found</div>);

    return (
        <React.Fragment>
        <nav className="navbar navbar-dark fixed-top flex-md-nowrap p-0 shadow">
            <span className="navbar-brand ml-2">
                JAWIS {domain ? <span className="text-warning" > {domain.name || ''}</span> : ''}
            </span>
            <span className="navbar-brand mr-2"><FontAwesome name="pie-chart" /> Auto Report</span>
        </nav>
        <main role="main" className="container-fluid" style={{padding: "33px", marginTop: "30px"}}>
            <h4 className="mb-3">รายงานประจำวัน  <span className="text-primary">{label}</span></h4>
            <PersonCardMini data={staff} styles={{photoSize:178, showInfo:['name', 'company', 'sim', 'position', 'department'], nameSize: 22, infoSize: 16, showBorder: false, marginLeft: 20}} />
        
            <div className="row mt-4">
                <div className="col-12 col-md-7 text-center mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h4>ใบงาน</h4>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-center align-items-center">
                                <div className='col-auto mx-1 text-center p-1'>
                                <h4><span className="badge badge-pill badge-primary">{taskTotal}</span></h4>
                                ทั้งหมด
                                </div>
                                <div className='col-auto mx-1 text-center p-1'>
                                <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                                แผนงาน
                                </div>
                                <div className='col-auto mx-1 text-center p-1'>
                                <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                                ปฏิบัติ
                                </div>
                                <div className='col-auto mx-1 text-center p-1'>
                                <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                                หยุด
                                </div>
                                <div className='col-auto mx-1 text-center p-1'>
                                <h4><span className="badge badge-pill badge-success">{taskFinish}</span></h4>
                                เสร็จสิ้น
                                </div>
                                <div className='col-auto mx-1 text-center p-1'>
                                <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                                ยกเลิก
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-5 text-center mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h4>ระยะเวลาทำงาน</h4>
                        </div>
                        <div className="card-body" style={{minHeight: 103}}>
                            <h4 className="card-title text-success mt-3">{workTime || <span className="text-muted">ไม่มีข้อมูล</span>}</h4>
                        </div>
                    </div>
                </div>
            </div>
            {groupByTask && <CaptureDailyStaffTaskGroup input={groupByTask} /> }
            { tasks && <div className="row mt-3">
            { tasks.map( (item, index) => {
                return (
                <div key={index} className="col-12">
                    <CaptureDailyTaskCard data={item} showOwner={false} />
                </div>
                )
            })}
            </div>}
        </main>
    </React.Fragment> );
        
         
}
 
export default CaptureReportDailyStaff;