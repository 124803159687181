import React from 'react';
import { useState, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import DialogSearchListIndex from '../../../common/dialogSearchList';
import dlv from 'dlv'
import ButtonGroup from '../../../common/buttonGroup';
import { toast } from 'react-toastify';
import { staffList } from '../../../../services/manage/staff/staffService';
import DialogSearchPerson from '../../../common/dialogSearchPerson/index';
// import PersonPhoto from '../../../common/card/personPhoto';
import BadgeStaffCard from '../../../common/badge/badgeStaffCard';
import { personList } from '../../../../services/manage/staff/teamService';
import BadgePersonGroup from '../../../common/badge/badgePersonGroup';

const TaskPermission = ({className='', permission=[], setPermission=()=>{}}) => {

    const [show, setShow] = useState(false)

    const roleList = [{name: 'Start/Create Task', value: 'create'} ]
    const modeList = [{name: 'All Staffs', value: 'all'}, {name: 'Custom', value: 'custom'}]

    const [staffs, setStaffs] = useState([])
    const [personGroup, setPersonGroup] = useState([])

    useEffect(() => {

        const loadStaff = async() => {
            try {
                const { data: result } = await staffList({enable: true})
                if(result.status==='success') {
                    setStaffs(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch (error) {
                toast.error('Load Staff Failed')
            }
        }

        const loadPersonGroup = async() => {
            try {
                const { data: result } = await personList({enable: true})
                if(result.status==='success') {
                    setPersonGroup(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch (error) {
                toast.error('Load Group Failed')
            }
        }

        loadStaff()
        loadPersonGroup()

    },[])
    
    const handlePermissionChange = ({index, ...res}) => {

        setPermission(prev => {
            return prev.map((p, i) => {
                if(i===index) p = {...p, ...res}

                return p
            })
        })
    }

    return ( 
        <div className={className}>
            <div className="card">
                <div className="card-header font-weight-bold" onClick={() => setShow(prev => !prev)} style={{cursor: 'pointer'}}>
                    <span>Permission</span>
                </div>
                
                <Collapse in={show}>
                    <div>
                        {permission.length===0 && 
                        <div className="card-body">
                            Default Permission : All Denied
                        </div>
                        }
                        <ul className="list-group list-group-flush">
                        {permission.map((item, index)=> {
                            const { role, mode, person } = item

                            return (
                            <li key={index} className="list-group-item">
                                <div className="row no-gutters align-items-center">
                                    <div className="col-auto">{role.name}</div>
                                    <div className="col-auto">
                                        <ButtonGroup
                                            className="ml-4"
                                            items={modeList}
                                            selectedItem={mode}
                                            onItemSelect={item => handlePermissionChange({index, mode: item })}
                                            valueProperty='value'
                                            selectedItemClass="btn btn-sm btn-dark"
                                            defaultItemClass="btn btn-sm btn-light btn-outline-dark"
                                            />
                                    </div>
                                    {mode.value==='custom' && 
                                    <div className="col-auto ml-2">
                                        <DialogSearchPerson list={staffs} allowSearch={false} notifySelect={item => {
                                            setPermission(prev => {
                                                return prev.map((p, i) => {
                                                    if(i===index) {

                                                        if(!p.person.find(x=> x.type==='staff' && x._id===item.userId)) {
                                                            p.person.push({type: 'staff', _id: item.userId })
                                                        } else {
                                                            toast.info("Staff is exist")
                                                        }
                                                    }
                                    
                                                    return p
                                                })
                                            })

                                            
                                        }}  buttonClass="btn btn-sm btn-light btn-outline-dark" cache={false} buttonText="Add Staff" />
                                    </div> }
                                    {mode.value==='custom' && 
                                    <div className="col-auto ml-2">
                                        <DialogSearchListIndex header="Select Person Group" buttonAdd="Add Person Group" 
                                            icon="users"
                                            list={personGroup.filter(x=> !(person.map(x=> x._id).includes(x._id)) )} 
                                            notifySelect={item => { 
                                                setPermission(prev => {
                                                    return prev.map((p, i) => {
                                                        if(i===index) {
    
                                                            if(!p.person.find(x=> x.type==='group' && x._id===item._id)) {
                                                                p.person.push({type: 'group', _id: item._id })
                                                            } else {
                                                                toast.info("Group is exist")
                                                            }
                                                        }
                                        
                                                        return p
                                                    })
                                                })
                                            } }
                                            valueProperty="_id"
                                            buttonStyle="btn btn-sm btn-light btn-outline-dark"
                                            />
                                            
                                    </div>}
                                </div>
                                {mode.value==='custom' && person.length>0 && 
                                <div className="row no-gutters align-items-center mt-2">
                                    {person.map( x=> {
                                        if(x.type==='staff') {
                                            return (
                                                <div key={x._id} className="col-auto mb-2 mr-2">
                                                    <BadgeStaffCard userId={x._id} buttonIcon="times-circle-o" buttonClass="text-danger"
                                                        styles={{ photoSize: 70, showInfo: ['name', 'position', 'sim']} } 
                                                        onClick={userId => {
                                                            setPermission(prev => {
                                                                return prev.map((p, i) => {
                                                                    if(i===index) {
                                                                        p.person = p.person.filter(x=> x._id!==userId)
                                                                    }
                                                    
                                                                    return p
                                                                })
                                                            })
                                                        }}  />
                                                </div>
                                                
                                            )
                                        } else {
                                            return (<div key={x._id} className="col-auto mb-2 mr-2">
                                                <BadgePersonGroup userId={x._id} buttonIcon="times-circle-o" buttonClass="text-danger"
                                                styles={{ photoSize: 70, showInfo: ['name', 'position', 'sim']} } 
                                                onClick={userId => {
                                                    setPermission(prev => {
                                                        return prev.map((p, i) => {
                                                            if(i===index) {
                                                                p.person = p.person.filter(x=> x._id!==userId)
                                                            }
                                            
                                                            return p
                                                        })
                                                    })
                                                }}  />
                                            
                                        </div>)
                                        }

                                    })}
                                </div>
                                }
                            </li>)
                        })}
                        </ul>
                        <div className="card-footer p-2 text-right">
                            <DialogSearchListIndex header="Add Permission Role" buttonAdd="Add" 
                            list={roleList.filter(x=> !permission.find(per => dlv(per, 'role.value')===x.value ))} 
                            notifySelect={item => { setPermission(prev => [...prev, {role: item, mode: modeList.find(x=>x), person: []}])} }
                            valueProperty="value"
                            buttonStyle="btn btn-sm btn-dark"
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
     );
}
 
export default TaskPermission;