import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SearchBox from "../../searchBox";
import SearchTable from "./searchTable";
import DialogCreateZoomRoom from './../createZoomRoom';
import { getZoomRoomList } from '../../../../services/manage/zoom/zoomRoomService';

const DialogSearchList = ({header, isOpen, selectList=[], onCancel, onConfirm, textProperty='name', autoSelect=true}) => {

  const [zoomRoomList, setZoomRoomList] = useState([])
  const loadZoomRoom = async() => {
      try {
          const {data: result} = await getZoomRoomList({enable: true});

          if(result.status==='success') {
              setZoomRoomList(result.data.list)
          }
      } catch(err) {
      }
  }
  useEffect(() => {
      
      loadZoomRoom();
  },[])

  const [search, setSearch] = useState('')
  const filterData = zoomRoomList.filter(x => {
    
    if(selectList.find(y=> y._id===x._id)) return false
    if(x[textProperty] && x[textProperty].includes(search)) return true

    return false
  })

  const handleSelect = (id) => {
    if( typeof(onConfirm) === 'function') {
      
      onConfirm(id)
    }
  }

  const handleZoomSave = (item) => {
    if(autoSelect) {
      if( typeof(onConfirm) === 'function') {
        onConfirm(item)
      }
    }
    loadZoomRoom()
  }

  return ( 

    <div>
      <Modal isOpen={isOpen} toggle={onCancel} >
        <ModalHeader color="info">{header}
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto mb-3">
                <h6 className="d-inline">ค้นหา</h6>
              </div>
              <div className="col-12 col-md-9 col-lg-6 mb-3">
                <SearchBox value={search} onChange={search => setSearch(search)} />
              </div>
              <div className="col-auto mb-3">
                <DialogCreateZoomRoom buttonAdd="Create" buttonStyle="btn btn-primary" notifySave={handleZoomSave} />
              </div>
            </div>
            {search && filterData.length===0 && <div className="alert alert-secondary mt-4" role="alert">
              Search is not found
            </div>}
            <SearchTable data={filterData} onConfirm={handleSelect} textProperty={textProperty} />
          </React.Fragment>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>
        </ModalFooter>
      </Modal>
    </div>

   );
}
 
export default DialogSearchList;