import React from 'react';
import FontAwesome from 'react-fontawesome';

const BadgeButton = 
    ({item, itemClass='btn btn-info', 
    buttonText, buttonClass='btn btn-outline-secondary', 
    className="btn-group mr-3 mb-3",
    buttonIcon,
    onItemClick, onButtonClick, disabled='',
    fieldId="_id", fieldName="name" }) => {

    const handleItemClick = () => {
        if(typeof(onItemClick)==='function') {
            onItemClick(item)
        }
    }

    const handleButtonClick = () => {
        if(typeof(onButtonClick)==='function') {
            onButtonClick(item)
        }
    }

    const itemKey = item[fieldId] || Math.random(100)
    const itemText = item[fieldName] || "Unname"

    return ( 
        <React.Fragment>
            <div key={itemKey} className={className} role="group">
                {disabled && <button type="button" className={itemClass} disabled onClick={handleItemClick}>{itemText}</button>}
                {!disabled && <button type="button" className={itemClass} onClick={handleItemClick}>{itemText}</button>}
                {buttonText && <button type="button" className={buttonClass} onClick={handleButtonClick}>{buttonText}</button>}
                {buttonIcon && <button type="button" className={buttonClass} onClick={handleButtonClick}><FontAwesome name={buttonIcon} /></button>}
            </div>
        </React.Fragment>
     );
}
 
export default BadgeButton;