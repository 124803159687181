import React from 'react';
import dlv from 'dlv'
import ButtonGroup from '../../../common/buttonGroup';

const locationTrackOptions = {
    required: true, reportView: 'roadmap', interval: 5000, fastestInterval: 2000, smallestDistance: 5, mode: 'accuracy', minimumAccuracy: 50,
    keepRaw: false, onlyGood: false,
    motionDetect : { enable: true, threshold: 0.3, seconds: 60, skip: 2 }
}

const reportViewList = [{name: 'Road Map', value: 'roadmap'}, {name: 'Satellite', value: 'satellite'}]

const LocationTracking = ({className='', data, setData}) => {

    const headerColor = dlv(data, 'enable', false) ? 'text-primary' : ''

    const handleEnable = (boo) => {
        if(boo) {
            setData({enable: true, trackOptions: locationTrackOptions })
        } else {
            setData({enable: false})
        }
    }

    const handleRequired = (required) => {
        setData(prev => { 
            const update = {...prev}
            update.trackOptions = {...update.trackOptions, required}
            return update
        })
    }


    return ( 
        <div className={className}>
            <div className='card'>
                <div className="card-header font-weight-bold">
                    <span className={headerColor}>GPS Tracking</span>
                <input className="ml-4" type="checkbox" checked={dlv(data, 'enable', false)} 
                    onChange={e => handleEnable(e.target.checked)} /><span> Enable / เปิดใช้งาน</span>
                {dlv(data, 'enable', false) && <div className="d-inline"><input className="ml-4" type="checkbox" checked={dlv(data, 'trackOptions.required', false)} 
                    onChange={e => handleRequired(e.target.checked)} /><span> Required</span></div>}
                {dlv(data, 'enable', false) &&  <div className='d-inline ml-4'><h6 className='d-inline'>Report</h6><ButtonGroup
                    className="ml-2"
                    items={reportViewList}
                    selectedItem={reportViewList.find(x=> x.value===dlv(data, 'trackOptions.reportView', 'roadmap')) }
                    onItemSelect={item => {
                        
                        setData(prev => { 
                            const update = {...prev}
                            update.trackOptions = {...update.trackOptions, reportView: item.value}
                            return update
                        })
                    } }
                    valueProperty='value'
                    selectedItemClass="btn btn-sm btn-dark"
                    defaultItemClass="btn btn-sm btn-light btn-outline-dark"
                    /></div>}
                </div>
            </div>
        </div>
     );
}
 
export default LocationTracking;