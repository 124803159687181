import http from "../httpService";
import jwtDecode from "jwt-decode";
import md5 from 'md5';
// import dlv from 'dlv'

const apiEndpoint = "/session";
const tokenKey = "token";
const domain = "domain"
const permission = "permission"

http.setJwt(getJwt());
http.setDomain(getDomain())

export async function login(account, password) {

  try {

    const data = { account: account, password: md5(password)};
    const { data: result } = await http.post(apiEndpoint + "/authen", data);
    
    if ( result.status === 'success') {

      const jwt = result.data.token;
      localStorage.setItem(tokenKey, jwt);
    
      return "OK";
    } else {
      return result.message;
    }
  } catch(err) {
    return err.message;
  }
  
}

export async function loadPermission() {
  try {
    const user = getCurrentUser()
    if(!user || !user.userId) return undefined
    const { data: result } = await http.get(apiEndpoint + '/permission/' + user.userId)

    if(result.status==='success') {
      return result.data.permission
    } else {
      return undefined
    }
    
  } catch (error) {
    return undefined
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return undefined;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(domain)
}

export function getDomain() {
  const value = localStorage.getItem(domain)
  try {
    return JSON.parse(value)
  } catch(err) {
    return undefined
  }
}

export function setDomain(value) {
  http.setDomain(value)
  localStorage.setItem(domain, JSON.stringify(value))
}

export function getPermission() {
  const value = localStorage.getItem(permission)
  try {
    return JSON.parse(value)
  } catch(err) {
    return undefined
  }
}

export function setPermission(value) {
  localStorage.setItem(permission, JSON.stringify(value))
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  getDomain,
  setDomain,
  getPermission,
  setPermission
};
