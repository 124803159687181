
export function checkAppPermission({permission, input}) {
    if(typeof(input)==='string') {
        return (permission!==undefined) ? permission.includes(input) : false
    } else if(Array.isArray(input)) {
        return (permission!==undefined) ? permission.some(x=> input.includes(x)) : false
    } else {
        console.log('checkAppPermission : invalid input')
        return false
    }
  
}
