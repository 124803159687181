import React from 'react';
// import PhotoDisplay from '../../common/card/viewPhoto';
import PhotoGallery from './../../common/photo/photoGallery';

const ReportWorkPhoto = ({className, photo: data, photoSize=250}) => {
    if(data.length===0) return (<div></div>)

    return ( 
    <div className={className}>
        <h6>รูปภาพเพิ่มเติม</h6>
        <PhotoGallery photos={data} thumbnailSize={photoSize} />
    </div> );
}
 
export default ReportWorkPhoto;