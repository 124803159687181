import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';


import TaskPermission from "./options/permission";
import TaskLineNotify from '../task/taskOptions/lineNotify';
import { saveProject, getProject } from '../../../services/manage/project/index';
import { templateTaskList } from '../../../services/manage/task/taskService';

import { PermissionContext } from "../../../context/permissionContext";
import { useContext } from "react";
import queryString from 'query-string'
import dlv from 'dlv'
import _ from 'lodash'
import ProjectProgression from "./progress";
import ProjectManageTaskList from "./projectManageTask";

const ProjectManage = ({history, match, location}) => {

  const {permission: appPermission } = useContext(PermissionContext)

  const [id,setId] = useState(match.params.id)
  const [taskList, setTaskList] = useState([])

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [tasks, setTasks] = useState([])
  const [overrideTaskPermission, setOverrideTaskPermission] = useState(false)

  const [permission, setPermission] = useState([])
  const [lineNotify, setLineNotify] = useState({event: [], token: []})
  const [publicThis, setPublicThis] = useState({enable: false, sharedReport: false})
  const [topList, setTopList] = useState(0)

  const [progress, setProgress] = useState({ enable: false, autoPercentage: true, enableUnstage: false })

  useEffect(() => {
    const loadTaskList = async() => {
      try {
        const {data: result} = await templateTaskList({enable: true});

        if(result.status==='success') {

            setTaskList(result.data.list)

        } else {
            toast.error(result.message)
        }
    } catch(err) {
        toast.error(err.message)
    }
    }

    loadTaskList()
  }, [])

  useEffect(() => {
    const loadForm = async(formId) => {

      if(formId && formId!=='new') {
        try {
          const {data: result } = await getProject(formId);
  
          if( result.data && result.data.item ) {
  
            const { item } = result.data
            if(!item) toast.error('Project data is not found')
  
            const { name, description, tasks } = item
            const { permission=[], lineNotify={event: [], token: []}, publicThis={enable: false, sharedReport: false}, topList=0 } = item
            const { overrideTaskPermission=false } = item
            const { progress={ enable: false, autoPercentage: true } } = item
  
            setName(name)
            setDescription(description)
            setTasks(tasks)
            setProgress(progress)
            setOverrideTaskPermission(overrideTaskPermission)
  
            setPermission(permission)
            setLineNotify(lineNotify)
            setPublicThis(publicThis)
            setTopList(topList)
  
            if(queryString.parse(location.search).clone) {
              setId('new')
              setName('')
              setDescription('')
            }
  
          }
        } catch(err) {
          toast.error('Failed to load form data')
        }
      }
    }

    loadForm(id)
  }, [id,location])

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    if(!description) return toast.error("Description is required", toastOptions)
    if(!description.replace(/^\s*$(?:\r\n?|\n)/gm,'')) return toast.error("Description is required", toastOptions)
    if(!progress.enable && tasks.length===0) return toast.error('Task is empty', toastOptions)

    const doc = {
      _id: (id==='new') ? undefined : id, 
      name, description: description.replace(/^\s*$(?:\r\n?|\n)/gm,'') , overrideTaskPermission,
      permission, lineNotify, publicThis, topList
    }

    if(progress.enable) {
      const { stages, enableUnstage, unstage } = progress
      if(enableUnstage) {
        const unstageError = dlv(unstage, 'error')
        if(unstageError) return toast.error('Unstage Error : ' + unstageError , toastOptions)

      } else {
        if(!stages || !Array.isArray(stages) || stages.length===0 ) return toast.error('Progress Stage is empty', toastOptions)

        const findError = stages.find(x=> x.error)
        if(findError) return toast.error('Stage Error : ' + findError.error , toastOptions)
      }
      
      const unstageTasks = enableUnstage ? dlv(unstage, 'tasks', []) : []

      doc.progress = progress
      doc.tasks = _.uniqBy( [...stages.map(x=> x.tasks).reduce((acc,x) => acc.concat(x), []), ...unstageTasks] , x=> x._id)
      
    } else {
      doc.tasks = tasks
      doc.progress = { enable: false }
    }

    try {
      const { data: result } = await saveProject(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/project"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }
  }

  return (
    <div className="row mt-3">
      <div className="col-12 col-xl-10">
        <div className="row align-items-top mb-2">
          <div className="col-auto" style={{width: 180}}>
              <h5 className="font-weight-bold">Project Name</h5>
          </div>
          <div className="col" style={{minWidth: 300}}>
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            <span className="text-info">กำหนดชื่อให้กระชับ ช่วยให้รายงานต่าง ๆ ดูง่ายและสวยงาม</span>
            <p className="text-info">Keep name short to support a neat report</p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-auto text-muted" style={{width: 180}}>
              <h5 className="font-weight-bold">Description</h5>
          </div>
          <div className="col" style={{minWidth: 300}}>
            <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
          </div>
        </div>
        <ProjectProgression progress={progress} setProgress={setProgress} taskList={taskList} />
        {progress.enable===false && <ProjectManageTaskList tasks={tasks} setTasks={setTasks} taskList={taskList} />}
        <div className="row align-items-center justify-content-end mt-3">
          <div className="col-auto">
            <input type="checkbox" checked={overrideTaskPermission} onChange={e => setOverrideTaskPermission(e.target.checked)} />
            <span> Override Task Permission (ใช้งาน Task ภายใต้ Project เท่านั้น)</span>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <TaskLineNotify lineNotify={lineNotify} setLineNotify={setLineNotify}  />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <TaskPermission permission={permission} setPermission={setPermission}  />
          </div>
        </div>
        {appPermission && appPermission.includes('admin') && <div className="row mt-4">
          <div className="col">
            <div className="card">
              <div className="card-header font-weight-bold">
                <div className="row no-gutters">
                  <div className="col-auto">Public Project</div>
                  <div className="col-auto">
                    <input className="ml-4" type="checkbox" checked={publicThis.enable} 
                      onChange={e => setPublicThis({enable: e.target.checked, sharedReport: false})} />
                    <span> Enable / เปิดใช้งาน</span>
                  </div>
                  {dlv(publicThis, 'enable') && <div className="col-auto">
                    <input className="ml-4" type="checkbox" checked={publicThis.sharedReport} onChange={e => setPublicThis({enable: true, sharedReport: e.target.checked})} />
                    <span> Shared Report</span>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>}
        {appPermission && appPermission.includes('admin') && <div className="row mt-4">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">Top List</span>
              </div>
              <input type="text"
                  value={topList} onChange={e => setTopList(e.target.value)} style={{maxWidth: 100}}
                  className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
              
            </div>
          </div>
        </div>}
        <div className="row justify-content-center mt-4">
          <div className="col-auto text-center">
          <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save Project</button>
          </div>
          <div className="col-auto">
            <NavLink className="nav-item nav-link p-0" to={`/project`} >
            <button type="button" className="btn btn-secondary text-white" >Cancel</button>
            </NavLink>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default ProjectManage;
