import React from 'react';
import { useState } from 'react';
import ButtonGroup from '../../../common/buttonGroup';
import Flatpickr from 'react-flatpickr';
import moment from 'moment'
import { useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import dlv from 'dlv'

const PlanManual = ({notifyChange}) => {

    const startList = [{name: 'Now', value: 'now'}, {name: 'Select Day', value: 'day'}, {name: 'Custom', value: 'custom'}]
    const endList = [{name: 'Within 1 Hour', value: '1hour'}, {name: 'Day End', value: 'today'}, {name: 'Select Day', value: 'day'}, {name: 'Custom', value: 'custom'}]

    const [begin, setBegin] = useState({...startList.find(x=> x), date: moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DD HH:mm')})
    const [end, setEnd] = useState({...endList.find(x=> x), date: moment().add(2, 'hours').startOf('hour').format('YYYY-MM-DD HH:mm')})

    const [error, setError] = useState()
    
    useEffect(() => {

        if(notifyChange instanceof Function) {

            const doc = {}
            switch(dlv(begin, 'value')) {
                case 'now':
                  doc.begin = moment().toDate()
                  break;
                case 'day':
                  if(!begin.date) return setError('Begin date is required')
                  doc.begin = moment(begin.date).startOf('day').toDate()
                  break;
                case 'custom':
                  if(!begin.date) return setError('Begin date is required')
                  doc.begin = moment(begin.date).toDate()
                  break;
                default:
                  return setError('Invalid Begin Date')
            }
        
            switch(dlv(end, 'value')) {
                case '1hour':
                  doc.end =  moment(doc.begin).add(1, 'hour').toDate()
                  break;
                case 'today':
                  doc.end = moment(doc.begin).endOf('day').toDate()
                  break;
                case 'day':
                  if(!end.date) return setError('End Date is required')
                  doc.end = moment(end.date).endOf('day').toDate()
                  break;
                case 'custom':
                  if(!end.date) return setError('End Date is required')
                  doc.end = moment(end.date).toDate()
                  break;
                default:
                  return setError('Invalid End Date')
                  
            }

            if(moment(doc.begin).isSameOrAfter(doc.end)) return setError('End must greater than Start')
            if(moment(doc.begin).add(10, 'minute').isSameOrAfter(doc.end)) return setError('At least 10 minutes Begin and End period')

            setError('')
            notifyChange({begin, end})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [begin, end])

    useEffect(() => {
        if(['day','custom'].includes(dlv(begin, 'value')) && ['day','custom'].includes(dlv(end, 'value'))) {
            const dateBegin = begin.value==='day' ? moment(begin.date).startOf('day') : moment(begin.date)
            const dateEnd = end.value==='day' ? moment(end.date).endOf('day') : moment(end.date)

            if(dateEnd.isSameOrBefore(dateBegin)) {
                setEnd(prev => { return {...prev, date: dateBegin.endOf('day').format('YYYY-MM-DD HH:mm')}})
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [begin])

    useEffect(() => {
        if(notifyChange instanceof Function) {
            notifyChange({begin, end, error})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, notifyChange])

    const headerWidth = 80
    const calendarOptions = { 
      altInput: true,
      enableTime: true,
      time_24hr: true, defaultHour: 8,
      minuteIncrement: 1,
      altFormat: "l j F Y H:i",
      dateFormat: "Y-m-d H:i",
      defaultDate: moment().format('YYYY-MM-DD HH:mm'),
      minDate: moment().format('YYYY-MM-DD HH:mm')
  };

  const calendarSelectDayOptions = { altInput: true, altFormat: "j F Y",  dateFormat: "Y-m-d", minDate: moment().startOf('day').format('YYYY-MM-DD')}

    return ( 
        <div className="card">
            <div className="card-header font-weight-bold">Create Plan Now</div>
            <div className="card-body">
                <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>Start</div>
                    <div className="col-auto mr-3">
                        <ButtonGroup
                            className="ml-4"
                            items={startList}
                            selectedItem={begin}
                            onItemSelect={item => {
                                
                                setBegin(prev => { return {...prev, ...item}})
                                if(item.value==='day') {
                                    setEnd(prev => { return {...prev, name: 'Select Day', value: 'day'}})
                                }
                            } }
                            valueProperty='value'
                            selectedItemClass="btn btn-dark"
                            defaultItemClass="btn btn-light btn-outline-dark"
                            />
                    </div>
                    {['day'].includes(begin.value) && 
                    <div className="col-auto mr-2 p-0">
                        <FontAwesome name="chevron-circle-left" size="2x" onClick={()=> setBegin(prev => {
                            const {date} = prev
                            const newDate=moment(date).add(-1, 'day')
                            if(newDate.isBefore(moment(), 'day')) {
                                return {...prev}
                            } else {
                                return {...prev, date: newDate.format('YYYY-MM-DD')}
                            }

                        })} />
                    </div>}
                    {begin.value==='day' && 
                    <div className="col-auto" style={{width: 180}}>
                        <Flatpickr
                            className="input input-sm"
                            id='filterDateBegin'
                            name='filterDateBegin'
                            value={begin.date}
                            onChange={(_,str) => {

                                setBegin(prev => { return {...prev, date: str} })
                                setEnd(prev => { return {...prev, date: moment(str).endOf('day').format('YYYY-MM-DD')}})
                            }  }
                            options={calendarSelectDayOptions}
                            />
                    </div>
                    }
                    {begin.value==='custom' && 
                    <div className="col-auto" style={{minWidth: 300}}>
                        <Flatpickr
                            id='filterDateBegin'
                            name='filterDateBegin'
                            value={begin.date}
                            onChange={(_,str) => {

                                setBegin(prev => { return {...prev, date: str} })
                                setEnd(prev => { return {...prev, date: moment(str).endOf('day').format('YYYY-MM-DD HH:mm')}})
                            }  }
                            options={calendarOptions}
                            />
                    </div>
                    }
                    {['day'].includes(begin.value) && <div className="col-auto ml-2 p-0">
                        <FontAwesome name="chevron-circle-right" size="2x" onClick={()=> setBegin(prev => {
                                const {date} = prev
                                const newDate=moment(date).add(1, 'day')
                                return {...prev, date: newDate.format('YYYY-MM-DD')}
                                
                            })} />
                    </div>}
                </div>
                <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>End</div>
                    <div className="col-auto mr-3">
                        <ButtonGroup
                            className="ml-4"
                            items={endList.filter(x=> !(x.value==='1hour' && begin.value==='day'))}
                            selectedItem={end}
                            onItemSelect={item => setEnd(prev => { return {...prev, ...item}})}
                            valueProperty='value'
                            selectedItemClass="btn btn-dark"
                            defaultItemClass="btn btn-light btn-outline-dark"
                            />
                    </div>
                    {['day'].includes(end.value) && 
                    <div className="col-auto mr-2 p-0">
                        <FontAwesome name="chevron-circle-left" size="2x" onClick={()=> setEnd(prev => {
                            const {date} = prev
                            const newDate=moment(date).add(-1, 'day')
                            if(newDate.isBefore(moment(), 'day')) {
                                return {...prev}
                            } else {
                                return {...prev, date: newDate.format('YYYY-MM-DD')}
                            }

                        })} />
                    </div>}
                    {end.value==='day' && 
                    <div className="col-auto" style={{width: 180}}>
                        <Flatpickr
                            className="input input-sm"
                            value={end.date}
                            onChange={(_,str) => setEnd(prev => { return {...prev, date: str }}) }
                            options={calendarSelectDayOptions}
                            />
                    </div>
                    }
                    {['day'].includes(end.value) && <div className="col-auto ml-2 p-0">
                        <FontAwesome name="chevron-circle-right" size="2x" onClick={()=> setEnd(prev => {
                            const {date} = prev
                            const newDate=moment(date).add(1, 'day')
                            return {...prev, date: newDate.format('YYYY-MM-DD')}
                            
                        })} />
                    </div>}
                    {end.value==='custom' && 
                        <div className="col-auto" style={{minWidth: 300}}>
                            <Flatpickr
                                id='filterDateEnd'
                                name='filterDateEnd'
                                value={end.date}
                                onChange={(_,str) => setEnd(prev => { return {...prev, date: str }}) }
                                options={calendarOptions}
                                />
                    </div>}
                </div>
                <h6>Start-End คือช่วงเวลาที่ผู้ปฏิบัติงานจะสามารถกดเริ่มงานได้ ไม่ได้บังคับเวลาสิ้นสุดงาน</h6>
                <h6>Staff must start work within Start-End period. This period is not enforce work finish time.</h6>
                {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
            </div>
        </div>
     );
}
 
export default PlanManual;