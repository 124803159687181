import React from 'react';
import DialogSearchPerson from './../../common/dialogSearchPerson/index';
import BadgeStaffCard from './../../common/badge/badgeStaffCard';

const approvalLevel = {
    10: 'TA',
    9: 'Director (กล่องฟ้า)',
    8: 'Director (กล่องเหลือง)',
    7: 'Dept Manager (จุดเขียว)',
    6: 'Section Manger (ขีดดำ)'
}

const headerSpace = 180

const NodeApproval = ({className, approval, setApproval, onWarning, readOnly=false}) => {

    const { level, person=[] } = approval

    const handleAddPerson = (item) => {
        setApproval(prev => {
            const {person} = prev
            if(!person.find(x=> x.userId===item.userId)) {
                person.push(item)
            } else {
                if(onWarning instanceof Function) {
                    onWarning('Approver is exist')
                }
            }

            return {...prev, person}
        })
    }

    const handleDeletePerson = (item) => {
        setApproval(prev => {
            const {person} = prev
            return {...prev, person: person.filter(x=> x.userId!==item)}
        })
    }

    return ( <div className={className}>
        <div className="row no-gutters align-items-center">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">ระดับผู้อนุมัติ</h6>
            </div>
            <div className="col-auto">
                <h5 className='d-inline'>{approvalLevel[level]}</h5>
            </div>
        </div>
        <div className="row no-gutters align-items-top mt-3">
            <div className="col-auto text-muted mt-1" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">ผู้อนุมัติ</h6>
            </div>
            <div className="col-auto">
                <div className="row no-gutters">
                    {person.map(item => 
                    <div key={item.userId} className="col-auto mb-2 mr-2">
                    <BadgeStaffCard userId={item.userId} buttonIcon={readOnly ? undefined : "times-circle-o"} buttonClass="text-danger"
                        styles={{ photoSize: 45, showInfo: ['name', 'sim']} } 
                        onClick={handleDeletePerson} />
                    </div>
                    )}
                </div>
            </div>
            {!readOnly && <div className="col-auto mt-2">
                <DialogSearchPerson cache={false} notifySelect={handleAddPerson} buttonText="Add" buttonClass="btn btn-sm btn-secondary" />
            </div>}
        </div>
    </div> );
}
 
export default NodeApproval;