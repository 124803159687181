import React from 'react';
import GraphDonutBasic from '../../../common/graph/basicDonut';
import ReportProjectList from './projectList';

import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import GraphBarBasic from '../../../common/graph/basicBar';

const ProjectHistory = ({report, filter}) => {

    const { projectTotal=0, workTotal=0, staffTotal=0, timeTotal=0, jvTotal=0 } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { groupByProject, projectList, groupDaily, groupByHour, timeByProject } = report

    const projectOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(projectList && projectList.find(x=> x.jvTotal>0)) projectOrderList.push({name: 'Job Value', value: 'jv'})

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-2">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Project</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{projectTotal}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{staffTotal}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Finish Task</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                                Total
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTime}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jvTotal>0 && 
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Job Value</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{jvTotal.toLocaleString()} &#3647;</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {groupByProject && <GraphDonutBasic className='mb-3' input={groupByProject} gHeight={projectTotal>5 ? 400 : 300} header="Total Work By Project" centerText="Total Work" />}
            {timeByProject && <GraphDonutBasic className='mb-3' input={timeByProject} gHeight={projectTotal>5 ? 400 : 300} header="Total Time By Project" centerText="Total Time" />}
            {groupByHour && <GraphBarBasic className='mb-3' data={groupByHour} header='Finish Hourly' yaxisText='Count Finish' />}
            {groupDaily && <GraphBarBasic className='mb-3' data={groupDaily} header='Finish Daily' yaxisText='Count Finish' />}
            {projectList && <ReportProjectList className="mt-4" data={projectList} filter={filter} style={{workVisible: ['finish']}} orderList={projectOrderList} />}
        </React.Fragment>
     );
}
 
export default ProjectHistory;