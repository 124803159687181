import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import ButtonGroup from '../../common/buttonGroup';
import { getDataField, saveDataField } from './../../../services/manage/data/field';
import ManageDataFieldNumber from './editFieldNumber';
import dlv from 'dlv'

const ManageDataField = ({history, match}) => {

  const [id,] = useState(match.params.id)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const fieldTypes = [{name: 'Number', value: 'number'} ];
  const [fieldType, setFieldType] = useState(undefined)
  const [number, setNumber] = useState(undefined)
  
  useEffect(() => {
    const loadData = async(dataId) => {

      if(dataId && dataId!=='new') {
  
        try {
          const {data: result } = await getDataField(dataId);
  
          if(result.status==='success') {
            const { doc } = result.data
  
            setName(doc.name)
            setDescription(doc.description)
            setNumber(doc.number)
            setFieldType(doc.type)
  
          } else {
            toast.error(result.message)
          }
        } catch(err) {
          toast.error('Failed to load data')
        }
      }
    }

    loadData(id)
  }, [id])
  

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    if(dlv(number, 'error')) return toast.error(dlv(number, 'error'), toastOptions)

    const doc = { name ,description, type: fieldType, number, _id: (id==='new') ? undefined : id  }

    try {
      const { data: result } = await saveDataField(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/manage/data"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }

  }

  const headerSpace = 180

    return (
      <React.Fragment>
        <div className="row align-items-center mb-4">
          <div className="col-auto" style={{width: headerSpace}}>
              <h4 className="font-weight-bold">Data Field</h4>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-auto" style={{width: headerSpace}}>
              <h5 className="font-weight-bold">Name</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-auto text-muted" style={{width: headerSpace}}>
              <h5 className="font-weight-bold">Description</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
          </div>
        </div>
        <div className="row align-items-center mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h5 className="d-inline font-weight-bold">Type</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
                <ButtonGroup
                    items={fieldTypes}
                    selectedItem={fieldTypes.find(x=> x.value===fieldType)}
                    onItemSelect={item => setFieldType(item.value)}
                    valueProperty='value'
                    selectedItemClass="btn btn-dark"
                    />
            </div>
        </div>
        {fieldType==='number' && <ManageDataFieldNumber number={number} setNumber={setNumber} />}
          
        <div className="row justify-content-center mt-4">
          <div className="col-auto text-center">
          <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save Data Field</button>
          </div>
          <div className="col-auto">
            <NavLink className="nav-item nav-link p-0" to={`/manage/data`} >
            <button type="button" className="btn btn-secondary text-white" >Cancel</button>
            </NavLink>
          </div>
        </div>
      </React.Fragment>
    );
  
}

export default ManageDataField;
