import React from 'react';
import { useState, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import DialogSearchListIndex from '../../../common/dialogSearchList';
import dlv from 'dlv'
import { toast } from 'react-toastify';
import { lineList } from './../../../../services/manage/line/lineService';
import FontAwesome from 'react-fontawesome';

const TaskLineNotify = ({className='', lineNotify={event: [], token: []}, setLineNotify=()=>{}}) => {

    const [show, setShow] = useState(false)

    const [line, setLine] = useState([])
    const eventList = [ {name: 'Finish', value: 'finish', check: false} ]

    useEffect(() => {

        const loadLineToken = async() => {
            try {
                const { data: result } = await lineList({enable: true, isDelete: false})
                if(result.status==='success') {
                    setLine(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch (error) {
                toast.error('Load Line Failed')
            }
        }

        loadLineToken()

    },[])


    const handleSelectEvent = ({event, check}) => {
        setLineNotify(prev => {
            const update = {...prev}

            const doc = {...event, check }

            if(dlv(prev, 'event', []).find(x=> x.value===event.value)) {
                update.event = update.event.map(item => {
                    if(item.value===event.value) {
                        item = doc
                    }
                    return item
                })
            } else {
                update.event.push(doc)
            }

            return update
        })
    }

    return ( 
        <div className={className}>
            <div className="card">
                <div className="card-header font-weight-bold" onClick={() => setShow(prev => !prev)} style={{cursor: 'pointer'}}>
                    <span>Line Notify</span>
                </div>
                <Collapse in={show}>
                    <div>
                        <div className="card-body">
                            <div className="row no-gutters">
                                <div className="col-auto mr-4"><span className="font-weight-bold text-primary">Notify on task event </span></div>
                                {eventList.map(event=> {
                                    const findEvent = dlv(lineNotify, 'event', []).find(x=>x.value===event.value)
                                    return (
                                        <div key={event.value} className="col-auto">
                                            <input type="checkbox" checked={findEvent ? findEvent.check : event.check} 
                                            onChange={e => handleSelectEvent({event, check: e.target.checked})} /><span> {event.name}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="card-body">
                            {dlv(lineNotify, 'token', []).length===0 && 
                            <div className="alert alert-warning" role="alert">No Record</div>
                            }
                            {dlv(lineNotify, 'token', []).map( (item, index) => {
                                const { _id } = item
                                const findLine = line.find(x=> x._id===_id)

                                return (<div key={_id} className="row">
                                    <div className="col">
                                        <div className="card mb-2">
                                            <div className="card-body p-2">
                                            <div className="row justify-content-start">
                                                <div className="col-auto p-0">
                                                <span className="ml-4"><FontAwesome name="comments-o" size="lg" /> <span className="text-primary font-weight-bold">{findLine ? findLine.name : 'Name Not Found'}</span></span>
                                                </div>
                                                <div className="col-auto ml-auto">
                                                <FontAwesome style={{cursor: 'pointer'}} name="trash" onClick={() => {
                                                    setLineNotify(prev => {
                                                        const update = {...prev}
                                                        update.token = update.token.filter(x=> x._id!== _id)
                                                        return update
                                                    })
                                                } } size="lg" />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                        <div className="card-footer p-2 text-right">
                            <DialogSearchListIndex header="Add Line" buttonAdd="Add" icon="comments-o"
                            list={line.filter(x=> !dlv(lineNotify, 'token',[]).find(token=> token._id===x._id))} 
                            notifySelect={item => {
                                setLineNotify(prev => {
                                    const update = {...prev}
                                    if(!dlv(update, 'token', []).find(x=> x._id===item._id)) {
                                        update.token.push({_id: item._id})
                                    }
                                    return update
                                })
                            }}
                            buttonStyle="btn btn-sm btn-dark"
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
     );
}
 
export default TaskLineNotify;