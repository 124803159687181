import React, { useState, useEffect } from 'react';
// import _ from 'lodash'
// import moment from 'moment'
// import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import dlv from 'dlv'
import NumericInput from 'react-numeric-input';
import DialogSearchListIndex from './../../common/dialogSearchList/index';
import ButtonGroup from './../../common/buttonGroup';
import PlanRecurrence from './options/planRecurrence';
import PlanManual from './options/planManual';
import PermissionComponent from '../common/permissonComponent';
import FontAwesome from 'react-fontawesome';
import { saveManagePlan, getManagePlan } from './../../../services/manage/plan/managePlanService';
import queryString from 'query-string';
// import moment from 'moment';
import { projectList as getProjectList } from './../../../services/manage/project/index';
import { templateTaskList } from './../../../services/manage/task/taskService';

const ManagePlanEdit = ({history, match, location}) => {

  const [templateTask, setTemplateTask] = useState([])
  const [projectList, setProjectList] = useState([])
  
  useEffect(() => {
    const loadTemplateTask = async() => {
      try {
          const {data: result} = await templateTaskList({enable: true, projectionReq: 'name'});
          
          if(result.status==='success') {
              setTemplateTask(result.data.list)
          } else {
              toast.error(result.message)
          }
      } catch(err) {
          toast.error(err.message)
      }
    }
  
    const loadProjectList = async() => {
      try {
          const {data: result} = await getProjectList({enable: true, projectionReq:'name,tasks', removeProjection: ''});
          if(result.status==='success') {
              setProjectList(result.data.list.filter(x=> dlv(x, 'tasks', []).length>0))
          } else {
              toast.error(result.message)
          }
      } catch(err) {
          toast.error(err.message)
      }
    }

    loadProjectList()
    loadTemplateTask()
      
  }, [])

  const [id,] = useState(match.params.id)
  const [doc, setDoc] = useState(undefined)

  const [task, setTask] = useState()
  const [project, setProject] = useState()
  // const [flow, setFlow] = useState()

  const [isRecurrence, setIsRecurrence] = useState(dlv(queryString.parse(location.search), 'isRecurrence')==='true' || false)
  const [recurrenceOptions, setRecurrenceOptions] = useState()

  const [count, setCount] = useState(1)
  const [countPerStaff, setCountPerStaff] = useState(false)

  const [begin, setBegin] = useState()
  const [end, setEnd] = useState()
  const [planManualError, setPlanManualError] = useState()
  const notifyPlanOneTimeChange = ({begin, end, error}) => {

    setBegin(begin)
    setEnd(end)
    setPlanManualError(error)
  }

  const [permission, setPermission] = useState([{role: {name: 'Create Task', value: 'create'}, mode: {name: 'All Staffs', value: 'all'}, person: []}])
  const recurrenceModeList = [{name: "Manual", value: false}, {name: "Auto", value: true}]

  useEffect(() => {
    const loadPlan = async(planId) => {

      if(planId && planId!=='new') {
        try {
          const {data: result } = await getManagePlan(planId);
  
          if( result.data && result.data.item ) {
  
            const { item } = result.data
            if(!item) toast.error('Plan data is not found')
  
            const { task, project, isRecurrence, count, permission, recurrenceOptions, countPerStaff=false } = item
  
            setTask(task)
            setProject(project)
            setIsRecurrence(isRecurrence)
            setCount(count)
            setPermission(permission)
            setCountPerStaff(countPerStaff)
            setRecurrenceOptions(recurrenceOptions)
  
            setDoc(item)
          }
        } catch(err) {
          toast.error('Failed to load form data')
        }
      }
    }

    if(id==='new') {
      setDoc({})
    } else {
      loadPlan(id)
    }
    
  }, [id])

  const doSubmit = async () => {

      if(!task) return toast.error('Item is required')
      if(dlv(permission.find(x=>x.role.value==='create'), 'mode.value')==='custom' 
        && dlv(permission.find(x=>x.role.value==='create'), 'person', []).length===0) {
          return toast.error('Person must not empty')
        } 

      if(!isRecurrence) {
        if(planManualError)  return toast.error(planManualError)

        if(!begin || !end) return toast.error('Begin and End are required')

      }

      if(isRecurrence) {
        if(recurrenceOptions.error) return toast.error(recurrenceOptions.error)
      }

      try {

        const parseCount = parseInt(count)
        if(parseCount>10) return toast.error("Count must not over 10")

        const doc = { _id: (id==='new') ? undefined : id, task, project, isRecurrence, count, permission, countPerStaff }
        if(isRecurrence) {
          doc.recurrenceOptions = recurrenceOptions
        } else {
          doc.begin = begin
          doc.end = end
        }

        const { data: result } = await saveManagePlan(doc);
        if ( result.status === 'success') {
          history.goBack()
        } else {
          toast.error(result.message)
        }
      } catch(err) {
        toast.error(err.message)
      }
    }

  const headerSpace = 120

  if(!doc) return (<div className="alert alert-warning" role="alert">Loading ...</div>)

  return ( 
  <div className="row">
  <div className="col-12 col-xl-10">
    <h4 className="mb-4">{isRecurrence ? 'Auto Plan' : 'Create Plan'}</h4>
    <div className="row align-items-center mb-3">
      <div className="col-auto" style={{width: headerSpace}}>
          <h5 className="font-weight-bold">Item</h5>
      </div>
      <div className="col-auto">
        <div className="row no-gutters align-items-center">
          {project && <div className="col-auto mr-2"><h5 className="text-info">{dlv(project, 'name', 'Name is not found')}  / </h5></div>}
          {task && <div className="col-auto mr-3"><h5 className="text-info">{dlv(task, 'name', 'Name is not found')}</h5></div>}

          {!task && !project && <div className="col-auto mr-3"><DialogSearchListIndex header="Select Project" buttonAdd="Select Project" 
          icon="file-code-o"
          list={projectList} 
          notifySelect={item =>  setProject(item) } 
          valueProperty="_id"
          buttonStyle="btn btn-info"
          /></div>}
          {!task && <div className="col-auto mr-3"><DialogSearchListIndex header="Select Task" buttonAdd="Select Task" 
          icon="file-code-o"
          list={(project) ? templateTask.filter(item=> dlv(project, 'tasks', []).some(x=> x._id===item._id && x.enable))  : templateTask.filter(x=> !x.permissionOverrideByProject)} 
          notifySelect={item => setTask(item) } 
          valueProperty="_id"
          buttonStyle="btn btn-info"
          /></div>}

          {(project || task) && <span className='text-danger'> <FontAwesome name="trash" size="2x" onClick={()=> {
            setProject(undefined)
            setTask(undefined)

          }} /></span>}
        </div>
      </div>
    </div>
    <div className="row align-items-center mb-4 d-none">
      <div className="col-auto" style={{width: headerSpace}}>
          <h5 className="font-weight-bold">Mode</h5>
      </div>
      <div className="col-12 col-md-8 col-lg-6">
        <ButtonGroup items={recurrenceModeList} 
          selectedItemClass="btn btn-info"
          selectedItem={recurrenceModeList.find(x=> x.value===isRecurrence)} 
          onItemSelect={item => setIsRecurrence(item.value)} valueProperty='value' />
      </div>
    </div>
    <div className="row mb-4">
      <div className="col">
        {!isRecurrence && <PlanManual notifyChange={notifyPlanOneTimeChange} />}
        {isRecurrence && <PlanRecurrence data={recurrenceOptions} notifyChange={setRecurrenceOptions} />}
      </div>
    </div>
    <div className="row mb-4">
      <div className="col">
          <PermissionComponent permission={permission} setPermission={setPermission} />
      </div>
    </div>
    <div className="row align-items-center mb-4">
      <div className="col-auto" style={{width: headerSpace}}>
          <h5 className="font-weight-bold">Number</h5>
      </div>
      {!countPerStaff && <div className="col-auto">
        <NumericInput size={3} min={1} max={10} 
          value={count} onChange={(value,str)=> (str==='') ? setCount(1) : setCount(value)} />
      </div>}
      <div className="col-aut">
        <input type="checkbox" checked={countPerStaff} 
            onChange={e => setCountPerStaff(e.target.checked)} /><strong> Create plan for all staffs in permission section</strong>
      </div>
    </div>
    <div className="row justify-content-center mt-4">
      <div className="col-auto text-center">
      <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>{isRecurrence ? 'Save Plan' : 'Create Now'}</button>
      </div>
      <div className="col-auto">
        {/* <NavLink className="nav-item nav-link p-0 d-none" to={`/manage/plan`} >
        <button type="button" className="btn btn-secondary text-white" >Cancel</button>
        </NavLink> */}
        <button type="button" className="btn btn-secondary text-white" onClick={() => {
          history.goBack();
        }} >Cancel</button>
      </div>
    </div>
    
  </div>
</div>
  );
}
 
export default ManagePlanEdit;