import React from "react";

const FormListDropdown = ({ label, list, notifySelect, enable=true, textProperty, valueProperty  }) => {

  const handleOnChange = (item) => {
    const { selectedIndex} = item.currentTarget

    const {_id, name} = (selectedIndex>0 && selectedIndex<=list.length) ? list[item.currentTarget.selectedIndex-1] : {_id:'', name: ''}

    if(typeof(notifySelect)==='function') {
      notifySelect([{_id, label, name}])
    }
  }

  // console.log(options);
  return (
    <div className="form-group">
      <label>{label}</label>
      <select className="form-control" onChange={handleOnChange} disabled={!enable}>
        <option value="">โปรดเลือก</option>
        {list && list.length>0 && list.map(option => (
          <option key={option[valueProperty]} value={option[valueProperty]}>
            {option[textProperty]}
          </option>
        ))}
      </select>
    </div>
  );
};

FormListDropdown.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default FormListDropdown;
