import React, { useState } from 'react';
import DialogSearchPersonMain from './dialog';
import PersonCard from './personCard';

const DialogSearchPerson = ({person: data, list=[], allowSearch, notifySelect, cache=true, buttonText="Add Person", buttonClass="btn btn-primary"}) => {

  const [enable, setEnable] = useState(false)
  const [person, setPerson] = useState(data || undefined)

  const confirmSelectPerson = (person) => {
    setEnable(false)
    if (cache) setPerson(person)

    if ( notifySelect instanceof Function) {
        notifySelect(person)
    }
    
  }

  const handleRemove = () => {
    setPerson(undefined)
    if ( notifySelect instanceof Function) {
      notifySelect(undefined)
    }
  }

  return ( 
  <React.Fragment>
      <DialogSearchPersonMain isOpen={enable} list={list} allowSearch={allowSearch} onCancel={() => setEnable(false)} onConfirm={confirmSelectPerson} />
      {person && <PersonCard data={person} onRemove={handleRemove} />}
      {!person && <button className={buttonClass} onClick={() => setEnable(true)}>{buttonText}</button>}
  </React.Fragment> );
    
}
 
export default DialogSearchPerson;