import React, {useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { saveData, getData } from '../../../services/manage/data/index';
import ButtonGroup from '../../common/buttonGroup';
import NumericInput from 'react-numeric-input';

const TemplateDataManage = ({history, match}) => {

  const [id,] = useState(match.params.id)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const dataTypes = [{name: 'API', value: 'api'}, {name: 'Manual', value: 'manual'}];
  //, {name: 'Manual', value: 'manual'}
  const [dataType, setDataType] = useState(dataTypes.find(x=>x))

  const [dataApi, setDataApi] = useState('')
  const [dataLevelName, setDataLevelName] = useState([{name: ''}])

  const onDataLevelNameChange = (index, input) => {
    const data = [...dataLevelName]
    data[index].name = input.value;

    setDataLevelName(data)
  }

  const handleManualLevelChange = (number) => {
    if(dataLevelName.length<number) {

      const newData = [...dataLevelName]
      const num = number-dataLevelName.length

      for(let i=0; i<num ;i++) {
        newData.push({name: ''})
      }
      // console.log('newData add', newData)
      setDataLevelName(newData)
    } else if (dataLevelName.length>number) {

      const newData = dataLevelName.slice(0, number)
      // console.log('newData reduce', newData)
      setDataLevelName(newData)

    }
  }

  const loadData = async(dataId) => {

    if(dataId && dataId!=='new') {

      try {
        const {data: result } = await getData(dataId);

        if(result.status==='success') {
          const { doc } = result.data

          setName(doc.name)
          setDescription(doc.description)

          if(doc.options) {
            const { dataType, api, manual } = doc.options

            if(dataType) {
              setDataType(dataType);

              if(dataType.value==='manual' && manual && manual.level && Array.isArray(manual.level)) {
                setDataLevelName(manual.level.map(x=> { return {name: x.name}}))
      
              }
            } 
            if(api) setDataApi(api.url || '')

          }

        } else {
          toast.error(result.message)
        }
      } catch(err) {
        toast.error('Failed to load data')
      }
    }
  }

  useEffect(() => {
    loadData(id)
  }, [id])
  

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)

    const doc = {name , description, options: { dataType }, _id: (id==='new') ? undefined : id  }

    if(dataType.value==='api') {
      if(!dataApi) return toast.error('Data API is required', toastOptions)

      doc.options.api = { url: dataApi}
    }

    if(dataType.value==='manual') {

      if(dataLevelName.length <= 0) return toast.error("Level number is invalid", toastOptions)
      if(dataLevelName.find(x=> x.name.trim()==='')) return toast.error("Level name can not empty", toastOptions)

      doc.options.manual = { level: dataLevelName }
    }

    try {
      const { data: result } = await saveData(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/manage/data"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }

  }

  const headerSpace = 180

    return (
      <React.Fragment>
          <div className="row align-items-center mb-4">
            <div className="col-auto" style={{width: headerSpace}}>
                <h4 className="font-weight-bold">Data List</h4>
            </div>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col-auto" style={{width: headerSpace}}>
                <h5 className="font-weight-bold">Name</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
          </div>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h5 className="font-weight-bold">Description</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
            </div>
          </div>
          <div className="row align-items-center mt-2">
              <div className="col-auto" style={{width: headerSpace}}>
                  <h6 className="font-weight-bold">Data Source</h6>
              </div>
              <div className="col-12 col-md-8 col-lg-6">
                  <ButtonGroup
                      items={dataTypes}
                      selectedItem={dataType}
                      onItemSelect={item => setDataType(item)}
                      valueProperty='value'
                      />
              </div>
          </div>
          {['api'].includes(dataType.value) &&
            <div className="row align-items-center mt-3">
                <div className="col-auto" style={{width: headerSpace}}>
                    <h6 className="font-weight-bold">API</h6>
                </div>
                <div className="col-12 col-md-8 col-lg-6">
                <input className="form-control" value={dataApi} onChange={e => setDataApi(e.target.value)} type="text" />
                </div>
            </div>
            }
          {['manual'].includes(dataType.value) &&
            <div className="row align-items-top mt-3">
                <div className="col-auto" style={{width: headerSpace}}>
                    <h6 className="font-weight-bold">Data Level</h6>
                </div>
                <div className="col-auto">
                    <NumericInput size="100px" min={1} max={5} value={dataLevelName.length} onChange={(value,str)=> (str==='') ? handleManualLevelChange(1) : handleManualLevelChange(value)} />
                </div>
            </div>
            }
            {['manual'].includes(dataType.value) &&
                dataLevelName.length>0 &&
                <div>
                  <div className="row align-items-top mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="pt-3 font-weight-bold">Level Name</h6>
                    </div>
                    <div className="col-auto">
                      {dataLevelName.map((x, index) => {
                          return (
                            <React.Fragment key={index}>
                                <div className="row align-items-center mt-2">
                                    <div className="col-auto pr-0">
                                    <h5><span className="badge badge-primary text-white">{index+1}</span></h5>
                                    </div>
                                    <div className="col-auto">
                                      <input value={x.name} className="form-control" onChange={e => onDataLevelNameChange(index, e.target)} type='text' />
                                      </div>
                                </div>
                            </React.Fragment>
                          )
                      })}
                    </div>
                  </div>
                </div>
                }
          
          <div className="row justify-content-center mt-4">
            <div className="col-auto text-center">
            <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save Data Template</button>
            </div>
            <div className="col-auto">
              <NavLink className="nav-item nav-link p-0" to={`/manage/data`} >
              <button type="button" className="btn btn-secondary text-white" >Cancel</button>
              </NavLink>
            </div>
          </div>
      </React.Fragment>
    );
  
}

export default TemplateDataManage;
