import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { NavLink } from 'react-router-dom';
import { saveProjectGroup } from '../../../../services/manage/project/group';


const ProjectGroupCard = ({ data={}, askGroup, setAskGroup=()=>{}, onRemove=()=>{} }) => {

    const {_id, name, projects } = data;

  const [enable, setEnable] = useState(data.enable || false)

  const toggleEnable = async(value) => {
    try {
      await saveProjectGroup({_id, enable: value })
      setEnable(value)

    } catch(err) {
        toast.error('Failed to update project')
    }
  }

  return ( 
    <React.Fragment>
    <div className={`card mb-2 ${askGroup===_id && 'border-warning'}`}>
        <div className="card-body p-3">
            <div className="row align-items-center justify-content-right">
                <div className="col mr-auto" style={{cursor: 'pointer'}} onClick={() => setAskGroup(prev => prev===_id ? undefined : _id)}>
                    <h4 className="d-inline mx-2">
                        <span className="badge badge-warning">GROUP</span>
                    </h4>
                    <h5 className="d-inline font-weight-bold"><span>{name}</span></h5>
                    <span className="badge badge-info ml-3">
                        <FontAwesome name="file-code-o" /> Project x {projects.length}
                    </span>
                </div>
                <div className="col-auto pr-4">
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto mr-2">
                            <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                        </div>
                        <div className="col-auto">
                            <NavLink className="nav-item nav-link text-dark" to={`/project/group/${_id}`} ><FontAwesome name="pencil" size="lg" />
                            </NavLink>
                        </div>
                        <div className="col-auto">
                            <FontAwesome style={{cursor: 'pointer'}} name="trash" size="lg" onClick={onRemove} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </React.Fragment>
    );
    
}
 
export default ProjectGroupCard;