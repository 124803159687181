import React from 'react';
import { useForm } from "react-hook-form";
import dlv from 'dlv'

import { Grid, TextField, Button } from "@material-ui/core";

const TaskForm = ({ forms=[], status='new', className='', notifySubmit=()=>{},  }) => {

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => { notifySubmit(data) }

  // console.log(watch("example")); // watch input value by passing the name of it

  const isButtonSave = false
  const isDisableEdit = ['pause'].includes(status)

  return (
    <form className={className}>
        <Grid container spacing={0}>
            <Grid item xs={6}>
              {forms.map(form => {
                const { _id, name, formType, typeText } = form

                const typeName = typeText || dlv(formType, 'name', 'Type not found')

                return (
                  <Grid key={_id} container spacing={1}>
                    {status==='new' && 
                      <Grid item xs={12}>
                        <h5 className="d-inline">{name}</h5>
                        <h5 className="d-inline text-info ml-2">{typeName}</h5>
                      </Grid>
                    }
                    {['active', 'pause'].includes(status) && <Grid item xs={12}>
                    <h5>{name}</h5>
                    <TextField name={_id} label={typeName} variant="outlined" fullWidth disabled={isDisableEdit} inputRef={register({required: true})} />
                    {errors[_id] && <span className="text-danger">This field is required</span>}
                    </Grid>}
                    {isButtonSave && <Grid item xs={12}>
                      <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>SAVE</Button>
                    </Grid>}
                  </Grid>
                )
              })}
              
            </Grid>
        </Grid>
    </form>
  );


}
 
export default TaskForm;