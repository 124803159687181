import React, {useState, useEffect, useContext} from 'react';
import { toast } from 'react-toastify';
import FilterDateCombo from '../../../common/filter/filterDateCombo';
import moment from 'moment'
import queryString from 'query-string'
import FontAwesome from 'react-fontawesome';
import ReportPerProjectToday from './perProjectToday';
import ReportPerProjectHistory from './perProjectHistory';
import { getReportProjectItem } from '../../../../services/report/reportProject';

import { DomainContext } from '../../../../context/domainContext';

import jsfiledownload from 'js-file-download'
import { downloadTask } from '../../../../services/download/dashboard';
import ButtonDownload from './../../../common/button/buttonDownload';

const ReportPerProject = ({match, location}) => {

    const {domain } = useContext(DomainContext)

    const [id,] = useState(match.params.id)
    const [filter, setDateFilter] = useState({filter: queryString.parse(location.search).filter || 'today', date: queryString.parse(location.search).date || moment().format('YYYY-MM-DD')})
    const [report, setReport] = useState({})

    const { name, description } = report
    
    useEffect(() => {
        const loadReport = async(id, input) => {
            if(!input || !id) return
    
            try {
                const { data: result } = await getReportProjectItem(id, input);
    
                setReport(result.data.report)
    
            } catch(err) {
                toast.error('Failed to load work data')
            }
        }

        if(id) {
            loadReport(id, {...filter})
        }
        
        // eslint-disable-next-line
    }, [id, filter])

    const handleReportDownload = () => {
        return new Promise(async (resolve, reject) => {
          try {
    
            const input = { date: filter.date }
    
            input.projectId = id
            if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
            if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
            
            const {data: result, headers } = await downloadTask(input)
    
            if( result.status==='error') {
                toast.error(result.message)
            } else {
                if ( headers['x-suggested-filename']==='nodata') {
                    toast.error("No Report")
                } else {
                    jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
                }
            }
    
            setTimeout(() => {resolve('ok')}, 300);
              
          } catch(err) { 
              toast.error("Error : ", err.message)
              resolve('error')
          }
        })
    
    }

    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row no-gutters align-items-center justify-content-left mt-2 mb-3">
            <div className="col-auto ml-2"><FontAwesome name='space-shuttle' size="2x" /></div>
            <div className="col-auto ml-4"><h3 className="d-inline text-primary">{name}</h3></div>
        </div>
        <h5 className="ml-4 text-muted">{description}</h5>
        <div className="row no-gutters justify-content-end">
            <div className="col-auto mr-auto mb-3">
                
            </div>
            <div className="col-auto mb-3">
                <ButtonDownload label='Download' onClick={handleReportDownload} />
            </div>
            <div className="col-auto ml-3 mb-3">
                <FilterDateCombo enableFilter={['today', 'day', 'week', 'month']}  notifyDateFilter={filter => setDateFilter(filter)} init={{...filter}} />
            </div>
        </div>
        {filter.filter==='today' && <ReportPerProjectToday report={report} filter={filter} />}
        {['day', 'week', 'month'].includes(filter.filter) && <ReportPerProjectHistory report={report} filter={filter} />}
    </React.Fragment> );
}
 
export default ReportPerProject;