import React, { useState, useEffect } from 'react';
import dlv from 'dlv'
import ButtonGroup from '../../../common/buttonGroup';
import { Collapse } from "react-bootstrap";

import Joi from 'joi';

const TaskLimit = ({className='', data, setData}) => {

    const modeList = [{name: 'Forever', value: 'forever'}, 
        {name: 'Hourly', value: 'hour'},
        {name: 'Daily', value: 'day'},
        {name: 'Weekly', value: 'week'},
        {name: 'Monthly', value: 'month'}
    ]

    const byList = [
        {name: 'Per Staff', value: 'staff'},
        {name: 'Per Person Group', value: 'group'},
        {name: 'Per Project', value: 'project'},
        {name: 'Per Domain', value: 'domain'}
    ]

    const [error, setError] = useState('')

    const schema = Joi.object({ value: Joi.number().integer().min(0) })

    // useEffect(() => {

    //     const {error: check } = schema.validate({value: dlv(data, 'value', 1)})
    //     if(check) return setError('Invalid Number Value')
    //     setError(undefined)

    //     if(setData instanceof Function) {
    //         setData(prev => { return {...prev, error: undefined } })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    //schema, mode, value, data, setData

    useEffect(() => {
        if(setData instanceof Function) {
            setData(prev => { return {...prev, error } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    return ( 
        <div className={className}>
            <div className="card">
                <div className="card-header font-weight-bold">Task Limit
                <input className="ml-4" type="checkbox" checked={dlv(data, 'enable', false)} 
                    onChange={e => {
                        const isCheck=e.target.checked
                        setData(prev => {
                            return {...prev, enable: isCheck}
                        })
                    }} /><span> Enable / เปิดใช้งาน</span>
                </div>
                
                <Collapse in={dlv(data, 'enable', false)}>
                    <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <ButtonGroup
                            items={modeList}
                            selectedItem={ modeList.find(x=> x.value===dlv(data, 'mode.value', 'forever'))}
                            onItemSelect={mode => setData(prev => {
                                return {...prev, mode}
                            })}
                            valueProperty='value'
                            />
                        </div>
                        <div className="col-auto p-0">
                            <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-default">Count</span>
                            </div>
                            <input type="text"
                                value={dlv(data, 'value', 1)} onChange={e => {
                                    const value = e.target.value
                                    const {error: check } = schema.validate({value})

                                    if(check) {
                                        setError('Invalid Number Value')
                                    } else {
                                        setError('')
                                    }

                                    setData(prev => {
                                        return {...prev, value}
                                    })

                                }} style={{maxWidth: 100}}
                                className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                            
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mt-3">
                        <div className="col-auto">
                            <ButtonGroup
                            items={byList}
                            selectedItem={byList.find(x=> x.value===dlv(data, 'by.value', 'staff'))}
                            onItemSelect={by => setData(prev => {
                                return {...prev, by}
                            })}
                            valueProperty='value'
                            />
                        </div>
                    </div>
                    {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
                    </div>
                </Collapse>
            </div>
        </div>
     );
}
 
export default TaskLimit;