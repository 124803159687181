import React, { useEffect, useContext } from 'react';
// import Input from '../common/input';
import ButtonGroup from '../../common/buttonGroup';
import _ from 'lodash'
import dlv from 'dlv'

import { dataList } from '../../../services/manage/data/index';
import SearchBox from '../../common/searchBox';
import { toast } from 'react-toastify';

import { formTypes } from './../../../assets/data/template/formTypes';
import { listTypes } from './../../../assets/data/template/listTypes';
import { scriptFormAllow, scriptFieldReserved } from './../../../assets/data/template/taskScript'
import { jvFormAllow } from './../../../assets/data/template/taskJV'


import TaskTemplateZoom from './taskItems/formTypeZoom';
import { PermissionContext } from '../../../context/permissionContext';
import { useState } from 'react';
import { DomainContext } from './../../../context/domainContext';
// import TaskTemplateFormDataField from './taskItems/formDataField';
import TaskTemplateFormTypeNumber from './taskItems/formTypeNumber';
import TaskTemplateFormTypePhoto from './taskItems/formTypePhoto';


const TemplateFormItemEdit = ({formData, notifySave, notifyCancel, scriptEnable=false, jvEnable=false, verifyScriptForm=()=>{}, verifyJVForm=()=>{} }) => {

    const {domain} = useContext(DomainContext)

    const {permission: appPermission=[] } = useContext(PermissionContext)

    const { options:formOptions={} } = formData

    const [options, setOptions] = useState(dlv(formData, 'options', {}))

    function useInput({ name='default', type, valueDefault='' }) {
        const [value, setValue] = useState(valueDefault);
        const input = <input className="form-control" name={name} value={value} onChange={e => setValue(e.target.value)} type={type} />;
        return [value, input, setValue];
    }

    const [error, setError] = useState('')
    const [name, nameInput, setName] = useInput({ type: "text", valueDefault: formData.name });
    const [required, setRequired] = useState(('required' in formData) ? formData.required : true)

    const [formType, setFormType] = useState(formData.formType || formTypes.find(x=>x))  //useState(formTypes.find(x=>x.value==='zoom'))  //

    const textOptions = [{name: 'Any', value: 'any'}, {name: 'Number', value: 'number'}]
    const [textOption, setTypeOption] = useState( textOptions.find(x=>x.value === dlv(formOptions, 'text.style', 'any')))

    const [listType, setListType] = useState(formOptions.listType || listTypes.find(x=>x))

    const listMethods = [{name: 'Template', value: 'template'}, {name: 'Manual', value: 'manual'}]
    const [listMethod, setListMethod] = useState(listMethods.find(x=>x.value===dlv(formOptions, 'list.source', 'template')))

    const [listManualData, setListManualData] = useState( dlv(formOptions, 'list.data', [{name: ''}]) )
    const [listDataTemplate, setListDataTemplate] = useState( dlv(formOptions, 'list.template'))
    const [dataSource, setDataSource] = useState([])
    const [searchListDataTemplate, setSearchListDataTemplate] = useState('')
    const dataSourceFilter = dataSource.filter(x=> {
        if(x.name.includes(searchListDataTemplate)) return true

        return false
    })

    const [linkField, linkFieldInput] = useInput({ type: "text", valueDefault: formData.linkField || '' });
    const [linkField2, linkFieldInput2] = useInput({ type: "text", valueDefault: formData.linkField2 || '' });
    const [jvField, jvFieldInput] = useInput({ type: "text", valueDefault: formData.jvField || '' });
    const [reportThis, setReportThis] = useState( dlv(formData, 'reportThis', false))

    const loadTemplateDataSource = async() => {
        try {
            const {data: result} = await dataList();
            if(result.status==='success') {
                setDataSource(result.data.list)
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => { loadTemplateDataSource()  }, [])
    
    const onListManualDataChange = (index, input) => {
        const data = [...listManualData]
        data[index][input.name] = input.value;

        setListManualData(data)
    }

    const onListManualDataAdd = () => {
        const data = [...listManualData]
        data.push({text: '', value: ''})
        setListManualData(data)
    }

    const listManualDataRemove = (index) => {
        const data = [...listManualData]
        data.splice(index, 1)
        setListManualData(data)

    }

    const verifyListManualData = () => {
        if(listManualData.find(x=> !x.name)) return 'List Data must none-empty'

        const dupText = _(listManualData).groupBy(x=>x.name).pickBy(x=>x.length>1).keys().value();
        if(dupText.length>0) return 'List Data "Text" is duplicated'

        return ''
    }

    const verifyScriptField = () => {
        if(!scriptEnable) return {}
        if(!scriptFormAllow.includes(formType.value)) return {}

        if(linkField) {
            if(!(/^[a-zA-Z0-9]+$/.test(linkField))) return { error: 'Invalid Link Field (a-z / 0-9)'}
            if(scriptFieldReserved.some(x=> x.value.toLowerCase()===linkField.toLowerCase()  )) return { error: 'Link field is reserved word'}
        }

        if(linkField2) {
            if(!(/^[a-zA-Z0-9]+$/.test(linkField2))) return { error: 'Invalid Link Field 2 (a-z / 0-9)'}
            if(scriptFieldReserved.some(x=> x.value.toLowerCase()===linkField2.toLowerCase()  )) return { error: 'Link field 2 is reserved word'}
        }

        if(linkField && linkField2 && linkField===linkField2) return { error: 'Script field and field2 are duplicated'}

        const {error: testScriptFormError } = verifyScriptForm({ linkField, linkField2 }) || {}
        if(testScriptFormError)  return { error: testScriptFormError }


        const output = { linkField }
        if(formType.value==='list' && linkField2) output.linkField2 = linkField2
        return output
    }

    const verifyJVField = () => {
        if(!jvEnable) return {}
        if(!jvFormAllow.includes(formType.value)) return {}

        if(jvField && !(/^[a-z]+$/.test(jvField))) return {error: 'Invalid JV Field (a-z only)' }

        const {error: testJVFormError } = verifyJVForm({ jvField }) || {}
        if(testJVFormError)  return { error: testJVFormError }

        return { jvField }
    }

    const onSave = () => {

        if(!name) return setError('Name is required')
        if (!(notifySave instanceof Function)) return;
        if(dlv(options, 'error')) {
            return setError(dlv(options, 'error', 'Form is error'))
        }

        const checkScript = verifyScriptField()
        if(checkScript.error) return setError(checkScript.error)
        
        const checkJV = verifyJVField()
        if(checkJV.error) return setError(checkJV.error)
        
        const doc = {name, formType, required }

        if(['text', 'list', 'number'].includes(formType.value) && reportThis) {
            doc.reportThis = reportThis
        }

        if(checkJV.jvField) { doc.jvField = checkJV.jvField  }

        if(checkScript.linkField) { doc.linkField = checkScript.linkField  }
        if(checkScript.linkField2) { doc.linkField2 = checkScript.linkField2 }

        switch(formType.value) {
            case 'text':
                const text = {style: textOption.value }
                if(textOption.value==='number') {
                    //support android version <= 19
                    // text.numberType = 'integer'
                    // text.numberMin = 0
                    text.numberMax = 9999999999999
                }

                notifySave({...doc, options: { text }})
                break;
            case 'number':
                notifySave({...doc, options })
                break;
            case 'list':
                if(listMethod.value==='manual') {
                    const verifyError = verifyListManualData()
                    if(verifyError) return setError(verifyError)

                    notifySave({...doc, options: { list: { style: listType.value, source: listMethod.value, data: listManualData } } })
                } else {
                    if(!listDataTemplate) return setError('Please select Data Source')

                    notifySave({...doc, options: { list: { style: listType.value, source: listMethod.value, template: listDataTemplate } }})
                }
                
                break;
            case 'photo':
                notifySave({...doc, options })
                break;
            case 'zoom':
                notifySave({...doc, options })
                break;
            default: break;
        }
    }

    const onCancel = () => {
        if (notifyCancel instanceof Function) {
            notifyCancel()
        }
    }

    const headerSpace = 180

    return ( <React.Fragment>
        <div className="card bg-secondary text-white my-3">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Required</h6>
                    </div>
                    <div className="col-auto">
                        <input type="checkbox" name="isRequired" value="required" checked={required} onChange={e => { setRequired(e.target.checked)}} />
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Name</h6>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6">
                        {nameInput}
                    </div>
                </div>
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Type</h6>
                    </div>
                    <div className="col-auto">
                        <ButtonGroup
                            items={formTypes}
                            selectedItem={formType}
                            onItemSelect={item => {
                                setFormType(item)
                                if(!name || formTypes.find(x=> x.name===name)) setName(item.name)
                            }}
                            valueProperty='value'
                            selectedItemClass="btn btn-light"
                            defaultItemClass="btn btn-dark btn-outline-secondary text-white"
                            />
                    </div>
                </div>
                {['text'].includes(formType.value) &&
                <React.Fragment>
                    <div className="row align-items-center mt-2">
                        <div className="col-auto" style={{width: headerSpace}}>
                            <h6 className="font-weight-bold">Text Type</h6>
                        </div>
                        <div className="col-auto">
                            <ButtonGroup
                                items={textOptions}
                                selectedItem={textOption}
                                onItemSelect={item => setTypeOption(item)}
                                valueProperty='value'
                                selectedItemClass="btn btn-light"
                                defaultItemClass="btn btn-dark btn-outline-secondary text-white"
                                />
                        </div>
                    </div>
                    {dlv(textOption, 'value')==='number' && <div className="row align-items-center mt-2">
                        <div className="col-auto" style={{width: headerSpace}}>
                        </div>
                        <div className="col-auto text-warning font-weight-bold">
                            Suit for ID or Key of subject or object
                        </div>
                    </div>}
                </React.Fragment>}
                {['number'].includes(formType.value) && 
                    <TaskTemplateFormTypeNumber options={options} setOptions={setOptions} /> }
                {['list'].includes(formType.value) && false &&
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">List Type</h6>
                    </div>
                    <div className="col-auto">
                        <ButtonGroup
                            items={listTypes}
                            selectedItem={listType}
                            onItemSelect={item => setListType(item)}
                            valueProperty='value'
                            selectedItemClass="btn btn-light"
                            defaultItemClass="btn btn-dark btn-outline-secondary text-white"
                            />
                    </div>
                </div>
                }
                {['list'].includes(formType.value) &&
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Data Method</h6>
                    </div>
                    <div className="col-auto">
                        <ButtonGroup
                            items={listMethods}
                            selectedItem={listMethod}
                            onItemSelect={item => setListMethod(item)}
                            valueProperty='value'
                            selectedItemClass="btn btn-sm btn-light"
                            defaultItemClass="btn btn-sm btn-dark btn-outline-secondary text-white"
                            />
                    </div>
                </div>
                }
                {['list'].includes(formType.value) &&
                ['template'].includes(listMethod.value) &&
                <div className="row align-items-top mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Data Source</h6>
                    </div>
                    <div className="col-auto">
                    {!listDataTemplate &&  <React.Fragment>
                        <div className="row">
                            <div className="col">
                                <SearchBox value={searchListDataTemplate} onChange={value => setSearchListDataTemplate(value)} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col mt-2">
                                {dataSourceFilter.length===0 && <span className="alert alert-warning">Not Found Data Source</span>}
                                <div className="row">
                                {dataSourceFilter.length>0 && dataSourceFilter.map( (item, index) => {
                                    const { name: sourceName } = item;

                                    return (
                                    <div key={index} className="col-12">
                                        <div className="card bg-dark mb-2 " style={{cursor:'pointer'}} 
                                            onClick={() => {
                                                setListDataTemplate(item)
                                                if(!name) setName(item.name)
                                            }}>
                                            <div className="card-body p-0">
                                                <div className="row align-items-center justify-content-between">
                                                <div className="col-auto ml-2">
                                                    <span className="ml-4" ><h6 className="d-inline font-weight-bold">{sourceName}</h6></span>
                                                </div>
                                                {/* <div className="col-auto pr-4">
                                                    <div className="row  align-items-center">
                                                        <div className="col-auto">
                                                        <button type="button" className="btn btn-dark text-white font-weight-bold" onClick={() => handleSelectTemplate(item)} >เลือก</button>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div></React.Fragment>}
                    {listDataTemplate && 
                        <div><span className="font-weight-bold">{listDataTemplate.name}</span>
                        <button type="button" className="btn btn-dark btn-sm text-white font-weight-bold ml-4" onClick={() => setListDataTemplate(undefined)} >เปลี่ยน</button>
                        </div>
                    }
                    </div>
                </div>
                }
                {['list'].includes(formType.value) &&
                ['manual'].includes(listMethod.value) &&
                <div>
                    <div className="row align-items-top mt-4">
                        <div className="col-auto" style={{width: headerSpace}}>
                            <h6 className="pt-3 font-weight-bold">Data</h6>
                        </div>
                        <div className="col-auto">
                            {listManualData.map((x, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className="row align-items-center mt-2">
                                            <div className="col-auto pr-0">Text</div>
                                            <div className="col-auto">
                                                <input name="name" value={x.name || ''} className="form-control" onChange={e => onListManualDataChange(index, e.target)} type='text' />
                                            </div>
                                            
                                            {index>0 && <div className="col-auto"><button type="button" className="btn btn-light text-dark" onClick={() => listManualDataRemove(index)}>Remove</button></div>}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                    <div className="row align-items-center mt-2">
                        <div className="col-auto" style={{width: headerSpace}}>
                        </div>
                        <div className="col-auto">
                            <button type="button" className="btn btn-light text-dark" onClick={onListManualDataAdd}>Add</button>
                        </div>
                    </div>
                </div>
                }
                {['photo'].includes(formType.value) && 
                    <TaskTemplateFormTypePhoto options={options} setOptions={setOptions} 
                    allowBrowse={appPermission.includes('admin') || 
                        dlv(domain, 'features',[]).find(x=>x.value==='task-photo-browse') }
                />}
                {['zoom'].includes(formType.value) && 
                    <TaskTemplateZoom options={options} setOptions={setOptions} />}
                
                {['text', 'list', 'number'].includes(formType.value) && 
                <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                    </div>
                    <div className="col-auto">
                        <input type="checkbox" name="reportThis" value="reportThis" checked={reportThis} onChange={e => { setReportThis(e.target.checked)}} />
                        <span className="ml-1">Report This</span>
                    </div>
                    <div className="col-auto">(Used in Report)</div>
                </div>}
                {scriptEnable && scriptFormAllow.includes(formType.value) && <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Script Field</h6>
                    </div>
                    {formType.value==='list' && <div className="col-auto text-warning">List Value</div>}
                    <div className="col-auto">
                        {linkFieldInput}
                    </div>
                    <div className="col-auto">(Used in script)</div>
                </div>}
                {scriptEnable && scriptFormAllow.includes(formType.value) && formType.value==='list' && <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">Script Field 2</h6>
                    </div>
                    <div className="col-auto text-warning">List Name</div>
                    <div className="col-auto">
                        {linkFieldInput2}
                    </div>
                    <div className="col-auto">(Used in script)</div>
                </div>}
                {jvEnable && jvFormAllow.includes(formType.value) && <div className="row align-items-center mt-2">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h6 className="font-weight-bold">JV Field</h6>
                    </div>
                    <div className="col-auto">
                        {jvFieldInput}
                    </div>
                    <div className="col-auto">(Used in job value)</div>
                </div>}
            </div>
            <div className="card-footer p-2">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        {error && <span className="text-warning font-weight-bold ml-3">{error}</span>}
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-light text-dark font-weight-bold mr-3" onClick={onCancel}>Cancel</button>
                        <button type="button" className="btn btn-light text-dark font-weight-bold" onClick={onSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default TemplateFormItemEdit;