import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import dlv from 'dlv'
import PermissionComponent from './../../common/permissonComponent';
import DialogSearchListIndex from './../../../common/dialogSearchList/index';

const headerSpace = 150
const defaultPermission = [{role: {name: 'Start Task', value: 'create'}, mode: {name: 'All Staffs', value: 'all'}, person: []}]

const TemplateFlowEditItemTask = ({work, setWork=()=>{}, projectList, taskList}) => {

    const [task, setTask] = useState(dlv(work, 'task'))
    const [project, setProject] = useState(dlv(work, 'project'))
    
    const [permission, setPermission] = useState(dlv(work, 'permission', defaultPermission))

    useEffect(()=> {
        setWork({task, project, permission})

        // eslint-disable-next-line
    }, [task, project, permission])

    return ( 
        <React.Fragment>
            <div className="row align-items-center mb-3">
                <div className="col-auto" style={{width: headerSpace}}>
                    <h5 className="font-weight-bold">Task</h5>
                </div>
                <div className="col-auto">
                    <div className="row no-gutters align-items-center">
                    {project && <div className="col-auto mr-2"><h5 className="d-inline text-primary">{dlv(project, 'name', 'Name is not found')}  / </h5></div>}
                    {task && <div className="col-auto mr-3"><h5 className="d-inline text-primary">{dlv(task, 'name', 'Name is not found')}</h5></div>}

                    {!task && !project && <div className="col-auto mr-3">
                        <DialogSearchListIndex header="Select Project" buttonAdd="Select Project" 
                    icon="file-code-o"
                    list={projectList.filter(project => project.tasks.some(task => task.enable && taskList.map(x=> x._id).includes(task._id)))} 
                    notifySelect={item => setProject(item) } 
                    valueProperty="_id"
                    buttonStyle="btn btn-outline-dark"
                    /></div>}
                    {!dlv(task, '_id') && <div className="col-auto mr-3">
                        <DialogSearchListIndex header="Select Task" buttonAdd="Select Task" 
                    icon="file-code-o"
                    list={(project) ? taskList.filter(item=> dlv(project, 'tasks', []).some(x=> x._id===item._id && x.enable))  : taskList.filter(x=> !x.permissionOverrideByProject)} 
                    notifySelect={item => setTask(item) } 
                    valueProperty="_id"
                    buttonStyle="btn btn-outline-dark"
                    /></div>}

                    {(task || project) && <span className='text-dark'><FontAwesome name="trash" size="2x" onClick={()=> {
                        setTask(undefined)
                        setProject(undefined)

                    }} /></span>}
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col">
                    <PermissionComponent permission={permission} setPermission={setPermission}  />
                </div>
            </div>
        </React.Fragment>
        
     );
}
 
export default TemplateFlowEditItemTask;