import React from 'react';
import PhotoGallery from './../../common/photo/photoGallery';
import ReportWorkZoom from './reportWorkZoom';

const ReportWorkTemplate = ({className, template, photoSize=250}) => {

    const {forms } = template
    if(!forms || forms.length===0) return (<div></div>)

    return ( 
    <div className={className}>
            {forms && forms.map( (item, index) => {
                
                const { _id, name, input, type, value } = item;

                return (
                    <div key={_id} className="row">
                        {['text', 'list'].includes(type) && <div className="col-12">
                            <h5>{name} <span className="text-primary">{value || "ไม่มีข้อมูล (No Data)"}</span></h5>
                        </div>}
                        {['photo'].includes(type) && <div className="col-12"><h5>{name}</h5></div>}
                        {type==='photo' && input && input.photo &&
                        <div className="col-auto mb-2">
                            <PhotoGallery photos={input.photo} thumbnailSize={photoSize} />
                        </div>
                        }
                        {type==='zoom' && input && input.zoom && <ReportWorkZoom className='col-12 mb-2' zoom={input.zoom} header={name} />}
                    </div>
                    )
            })}
    </div> );
}
 
export default ReportWorkTemplate;