import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { getZoomRoom, saveZoomRoom } from '../../../services/manage/zoom/zoomRoomService';
import dlv from 'dlv'

const DialogZoomRoomManage = ({id='new', isOpen, onSave, onCancel }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [roomId, setRoomId] = useState('')
    const [roomPassword, setRoomPassword] = useState('')
    const [joinUrl, setJoinUrl] = useState('')

    const [warning, setWarning] = useState(undefined)
    const [error, setError] = useState(undefined)

    useEffect(() => {
        setWarning(undefined)
        setError(undefined)
    }, [name, roomId])

    useEffect(() => {
        const loadData = async() => {

          if(!id || id==='new') {
              setName('')
              setDescription('')
              setRoomId('')
              setRoomPassword('')
          } else {
            try {
              const {data: result } = await getZoomRoom(id);
      
              const { doc } = result.data
              const { name='', description='', roomId='', roomPassword='', joinUrl='' } = doc

              setName(name)
              setDescription(description)
              setRoomId(roomId)
              setRoomPassword(roomPassword)
              setJoinUrl(joinUrl)
      
            } catch(err) {

            }
          }
        }

        loadData()

    }, [id])

    const handleSave = async () => {

        try {
      
            if(!name) return setWarning('Name is required'); else setWarning(undefined)
            if(!roomId) return setWarning('Room Id is required'); else setWarning(undefined)
      
            const doc = {name , description, roomId, roomPassword, joinUrl, _id: (id==='new') ? undefined : id  }
      
            const { data: result } = await saveZoomRoom(doc);
            if ( result.status === 'success') {
              if( typeof(onSave) === 'function') {
                  onSave(dlv(result, 'data.doc'))
              }
            } else {
              setError(result.message)
            }

        } catch(err) {
          setError(err.message)
        }
        
    }

    const handleCancel = () => {  
        setWarning(undefined)
        setError(undefined)
        if(typeof(onCancel)==='function') { onCancel() }    
    }

  const headerSpace = 130

  return ( 

    <div>
      <Modal isOpen={isOpen} toggle={handleCancel} size="lg" >
        <ModalHeader color="info">Add/Edit Zoom Room</ModalHeader>
        <ModalBody>
          <div className="row align-items-center">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Room Name</h6>
            </div>
            <div className="col p-2">
                <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Description</h6>
            </div>
            <div className="col p-2">
              <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
            </div>
          </div>
          <div className="row align-items-center mt-2">
              <div className="col-auto" style={{width: headerSpace}}>
                  <h6 className="font-weight-bold">Room Id</h6>
              </div>
              <div className="col p-2">
              <input className="form-control" value={roomId} onChange={e => setRoomId(e.target.value)} type="text" />
              </div>
          </div>
          <div className="row align-items-center mt-2">
              <div className="col-auto" style={{width: headerSpace}}>
                  <h6 className="font-weight-bold">Password</h6>
              </div>
              <div className="col p-2">
              <input className="form-control" value={roomPassword} onChange={e => setRoomPassword(e.target.value)} type="text" />
              </div>
          </div>
          <div className="row align-items-center mt-2">
              <div className="col-auto" style={{width: headerSpace}}>
                  <h6 className="font-weight-bold">Join URL</h6>
              </div>
              <div className="col p-2">
              <input className="form-control" value={joinUrl} onChange={e => setJoinUrl(e.target.value)} type="text" />
              </div>
          </div>
          {warning && <div className="alert alert-warning" role="alert">{warning}</div>}
          {error && <div className="alert alert-danger" role="alert">{error}</div>}
        </ModalBody>
        <ModalFooter>
            
            <Button color="primary mr-2" onClick={handleSave}>Save</Button>
            <Button color="secondary" onClick={handleCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>

   );
}
 
export default DialogZoomRoomManage;