import React, {useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { dataList as getDataList, deleteData } from '../../../services/manage/data/index';
import { toast } from 'react-toastify';
// import FontAwesome from 'react-fontawesome';
import ConfirmDelete from '../../common/confirmDelete';
import DataContent from './dataContent';

import { DomainContext } from '../../../context/domainContext';
import DataCard from './dataCard';
import { getDataFieldList, deleteDataField } from './../../../services/manage/data/field';
import DataFieldCard from './dataFieldCard';


const TemplateDatas = () => {
    const {domain } = useContext(DomainContext)

    const getList = async() => {
        try {
            const {data: result} = await getDataList();

            if(result.status==='success') {
                setDataList(result.data.list)
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    const getFieldList = async() => {
        try {
            const {data: result} = await getDataFieldList();

            if(result.status==='success') {
                setDataFields(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(domain) {
            getList()
            getFieldList()
        } 
        
    }, [domain])

    const [dataList, setDataList] = useState([])
    const [dataFields, setDataFields] = useState([])
    const [askDelete, setAskDelete] = useState(undefined)
    const [editContent, setEditContent] = useState(-1)

    const confirmDelete = async() => {
        try {
            const {dataType, _id} = askDelete
            if(dataType==='field') {
                await deleteDataField(_id)
                getFieldList()
            } else if(dataType==='list') {
                await deleteData(_id);
                getList()
            } else {
                return toast.error('Invalid Data Type')
            }
            
            setAskDelete(undefined)

        } catch(err) { toast.error('Failed to delete item')}
    }

    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete(undefined)} />
            <div className="row no-gutters justify-content-between align-items-center mb-3">
                <div className="col"><h4>Data</h4></div>
                <div className="col-auto">
                    <NavLink className="nav-item nav-link p-1" to={`/manage/data/new`} >
                        <button type="button" className="btn btn-primary text-white" >New List</button>
                    </NavLink>
                </div>
                <div className="col-auto ml-2">
                    <NavLink className="nav-item nav-link p-1" to={`/manage/data/field/new`} >
                        <button type="button" className="btn btn-primary text-white" >New Field</button>
                    </NavLink>
                </div>
            </div>
            {dataList.length===0 && dataFields.length===0 && <div className="alert alert-warning" role="alert">No Record</div>}
            {dataFields.length>0 &&
            <div className="row">
                {dataFields.map( item => 
                    <div key={item._id} className="col-12">
                        <DataFieldCard data={item} onRemove={()=> setAskDelete({dataType: 'field', _id: item._id})} />
                    </div>
                )}
            </div>}
            {dataList.length>0 &&
            <div className="row">
                {dataList.map( item => 
                    <div key={item._id} className="col-12">
                        <DataCard data={item} onRemove={()=> setAskDelete({dataType: 'list', _id: item._id })} setEditContent={setEditContent} />
                        {item._id===editContent && <DataContent id={item._id} notifyCancel={() => setEditContent(-1)} />}
                    </div>
                )}
            </div>}
        </React.Fragment>
     );
}
 
export default TemplateDatas;