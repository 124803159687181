import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { NavLink } from 'react-router-dom';

import PersonPhoto from '../../common/card/personPhoto';
import { saveTemplateFlow } from './../../../services/manage/flow/manageFlowService';

const RoundBorder = {
  borderRadius: 10,
  border: '1px solid #AAAAAA',
  padding: 1
}

const TemplateFlowCard = ({ data={}, onRemove=()=>{} }) => {

    const {_id, name, taskCount=0, permission=[] } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await saveTemplateFlow({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update task')
        }
    }


  return ( 
    <React.Fragment>
        <div className="card mb-2">
            <div className="card-body p-1">
                <div className="row no-gutters align-items-center justify-content-end">
                    <div className="col mr-auto">
                        <NavLink className="nav-item nav-link text-dark d-inline" to={`/manage/flow/${_id}`} >
                            <h5 className="d-inline font-weight-bold"><FontAwesome name="code-fork" size='lg' /><span className="ml-2">{name}</span></h5>
                        </NavLink>
                        <span className="badge badge-info mr-2"><FontAwesome name="file-code-o" /> Step x {taskCount}</span>
                    </div>
                    <div className="col-auto p-1">
                        <div className="row no-gutters align-items-center">
                            <div className="col-auto mr-2 pt-1">
                                <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                            </div>
                            <div className="col-auto">
                                <NavLink className="nav-item nav-link text-dark" to={`/manage/flow/${_id}/?clone=yes`} ><FontAwesome name="clone" size="lg" /></NavLink>
                            </div>
                            <div className="col-auto mr-1">
                            <NavLink className="nav-item nav-link text-dark" to={`/manage/flow/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                            </div>
                            <div className="col-auto mr-2">
                                <FontAwesome name="trash" size="lg" onClick={onRemove} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {permission.length>0 && 
            <div className="card-body py-1">
                <div className="row no-gutters align-items-center">
                    <div className="col-auto p-0">
                        {permission.map((item,index) => {
                            const {role, allowAll, person} = item;

                            return (
                            <div key={index} className="row no-gutters align-items-center" style={RoundBorder}>
                                <div className="col-auto mx-2">
                                    <h6 className="d-inline"><span className="badge badge-danger"><FontAwesome name="lock" /> {role}</span></h6>
                                </div>
                                {allowAll &&  <div className="col-auto mr-2"><strong className="text-success">Allow All Staffs</strong></div>}
                                {!allowAll && person.map( x=> {
                                    if(x.type==='staff') {
                                        return (
                                            <div key={x._id} className="col-auto mr-1">
                                                <PersonPhoto id={x._id} className='rounded-circle' size={35} />
                                            </div>
                                        )
                                    } else {

                                        const badge = x.enable ? 'badge badge-info' : 'badge badge-secondary'
                                        return (<div key={x._id} className="col-auto mr-1">
                                        <h6 className="d-inline"><span className={badge}><FontAwesome name="users" /> {x.name || x._id}</span></h6>
                                    </div>)
                                    }

                                })}
                            </div>
                            )
                        })}

                        
                    </div>
                </div>
            </div>
            }
        </div>
    </React.Fragment>
    );
    
}
 
export default TemplateFlowCard;