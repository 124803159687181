import React, {useState, useEffect} from 'react';
import { toast } from "react-toastify";
// import _ from "lodash";

import moment from 'moment';
import jsfiledownload from 'js-file-download'
import queryString from 'query-string'
import FilterDateCombo from '../../../common/filter/filterDateCombo';
import FontAwesome from 'react-fontawesome';
import { downloadTask } from '../../../../services/download/dashboard';
import { getReportSharedPerTask } from '../../../../services/report/reportShared';
import ReportSharedTaskHistory from './sharedTaskHistory';

const ReportSharedTask = ({match, location}) => {

  const [id,] = useState(match.params.id)
  const [filter, setDateFilter] = useState({filter: queryString.parse(location.search).filter || 'today', date: queryString.parse(location.search).date || moment().format('YYYY-MM-DD')})

  const [report, setReport] = useState({})

  const { name, description } = report

  const loadReport = async(id, input) => {
      if(!input || !id) return

      try {
          const { data: result } = await getReportSharedPerTask(id, input);

          setReport(result.data.report)

      } catch(err) {
          toast.error('Failed to load work data')
      }
  }

  // useEffect(() => {
  //     const query = queryString.parse(location.search)
  //     if(query.filter && query.date) setDateFilter({filter: query.filter, date: query.date})

  // }, [location])

  useEffect(() => {
      if(id) {
          loadReport(id, {...filter})
      }
      
  }, [id, filter])

  const handleReportDownload = async () => {
    // console.log("start download")
    try {
        const input = { date: filter.date }

        if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
        if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
        
        const {data: result, headers } = await downloadTask(input)

        if( result.status==='error') {
            // toast.error(result.message)
        } else {
            if ( headers['x-suggested-filename']==='nodata') {
                toast.error("No Report")
            } else {
                jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
            }
        }
        
    } catch(err) {
    }
  }

  if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
  if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

  return ( 
    <React.Fragment>
    <div className="row justify-content-end">
      <div className="col-auto mr-auto mb-2">
        <h4 className='d-inline'>Shared Report</h4>
      </div>
      <div className="col-auto p-0 d-none">
          <button type="button" onClick={handleReportDownload} className="btn btn-secondary">Download <FontAwesome name="download" /></button>
      </div>
      <div className="col-auto ml-3 mb-2">
        <FilterDateCombo enableFilter={['today', 'day', 'week', 'month']}  notifyDateFilter={filter => setDateFilter(filter)} />
        {/* <span className="text-danger">(ปิดการใช้ Weekly, Monthly ชั่วคราว)</span> */}
      </div>
    </div>
    <div className="row no-gutters align-items-center justify-content-left mt-2">
      <div className="col-auto ml-2"><FontAwesome name='file-code-o' size="2x" /></div>
      <div className="col-auto ml-4"><h3 className="d-inline">{name}</h3></div>
    </div>
    <h5 className="ml-4 mt-2 text-muted">{description}</h5>
    {/* {['today'].includes(filter.filter) && <ReportSharedToday report={report} filter={filter} />} */}
    {['today','day', 'week', 'month'].includes(filter.filter) && <ReportSharedTaskHistory report={report} filter={filter} />}
    </React.Fragment>
  );
}
 
export default ReportSharedTask;