import http from "../httpService";
import moment from 'moment'

const apiEndpoint = "/capture";

export function captureTaskLoad(id) {
  return http.get(apiEndpoint + '/task/' + id)
}

export function captureDailyStaff({id, date=moment().format('YYYY-MM-DD')}) {
  return http.get(apiEndpoint + '/daily/staff/' + id + '/?date=' + date )
}
