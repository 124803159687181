import React from 'react';
import FontAwesome from 'react-fontawesome';
import PersonPhoto from './personPhoto';

const PersonInfo = ({data}) => {
   
  const { userId, userName, company,empid,sim,email } = data;
    //    console.log('[PersonInfo], data',data)
  return (
    <React.Fragment>
      <div className="container"></div>
      <div className="row d-inline-block">
        <div className="col-auto ">
          <PersonPhoto id={userId} size={100} className="rounded-circle" />
        </div>
        <div className="col-auto mx-1 text-left" style={{}}>
          <strong className="card-title d-inline mr-2" style={{ fontSize: "14px",whiteSpace: 'nowrap'  }}>{userName}</strong>
          <span className="d-block" style={{fontSize: "12px"}}>{company}, ID : {empid}</span>
          <span className="d-block" style={{ fontSize: "12px" }}><FontAwesome name="phone" /> {sim}</span>
          <span className="d-block" style={{ fontSize: "12px",whiteSpace: 'nowrap' }}><FontAwesome name="envelope-o" /> {email}</span>
          
        </div>
      </div>
    </React.Fragment>
  );
}
 
export default PersonInfo;
// [PersonCardMini] userInfo 
// company: "AA_DAP"
// department: "IT Network & Telecommunication"
// email: "thunthita_k@doublea1991.com"
// empid: "542114"
// id: "542114"
// name: "ธัญธิตา แก้วฝั้น"
// position: "International Network Engineer"
// sim: "0858351563"
// status: 1
// userId: "542114"
// userName: "ธัญธิตา แก้วฝั้น"