import React, {useState, useEffect, useContext } from 'react';
import { lineList, deleteLine } from '../../../services/manage/line/lineService'
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';
// import DataContent from './dataContent';

import { DomainContext } from '../../../context/domainContext';
import DialogLineRoom from '../../common/dialogLineRoom/dialogLineRoom';
import LineRoomCard from './lineCard';


const LineRoom = () => {
    const {domain } = useContext(DomainContext)

    const getList = async() => {
        try {
            const {data: result} = await lineList();

            if(result.status==='success') {

                setList(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(domain) getList()
        
    }, [domain])

    const [list, setList] = useState([])

    const [askManage, setAskManage] = useState({isOpen: false, id: undefined})
    const [askDelete, setAskDelete] = useState('')


    const confirmSave = () => { 
        setAskManage({isOpen: false})
        getList()

    }

    const confirmDelete = async() => {
        try {
            await deleteLine(askDelete);

            setAskDelete('')
            getList()

        } catch(err) { toast.error('Failed to delete item')}
    }

    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <DialogLineRoom key={Date.now()} isOpen={askManage.isOpen} id={askManage.id} onCancel={() => setAskManage({isOpen: false})} onSave={confirmSave} />
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col"><h4>Line Room</h4></div>
                <div className="col-auto">
                    <button type="button" className="btn btn-primary" onClick={() => setAskManage({isOpen: true, id: 'new'})} >New</button>
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {list && list.map( (item, index) => {
                return (
                <div key={item._id || index} className="col-12">
                    <LineRoomCard data={item} onRemove={() => { setAskDelete(item._id); }} setAskManage={setAskManage} />
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default LineRoom;