import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { saveDomain } from './../../services/domain/domainService';
import { NavLink } from 'react-router-dom';

const DomainCard = ({ data={}, onRemove=()=>{} }) => {

  const { _id, name, features=[] } = data;

  const [enable, setEnable] = useState(data.enable || false)

  const toggleEnable = async(value) => {
    try {
      await saveDomain({_id, enable: value })
      setEnable(value)

    } catch(err) {
        toast.error('Failed to update person')
    }
  }


  return ( 
    <React.Fragment>
      <div className="card mb-2">
        <div className="card-body p-1">
            <div className="row align-items-center justify-content-between">
            <div className="col-auto ml-2">
                <NavLink className="d-inline nav-item nav-link text-dark" to={`/domain/${_id}`} >
                    <FontAwesome name="globe" size="lg" />
                    <h6 className="d-inline font-weight-bold ml-2">{name}</h6>
                </NavLink>
                {features.map(feature => {  return (<span key={feature.value} className="badge badge-primary mr-2">{feature.name}</span>)})}
            </div>
            <div className="col-auto pr-4">
                <div className="row align-items-center">
                    <div className="col-auto p-1">
                        <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                    </div>
                    <div className="col-auto p-1">
                      <NavLink className="nav-item nav-link text-dark" to={`/domain/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                    </div>
                    <div className="col-auto p-1 mr-2">
                        <FontAwesome name="trash" size="lg" onClick={()=>onRemove(_id)} />
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default DomainCard;