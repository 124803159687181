import React from 'react';
import ButtonGroup from '../../common/buttonGroup';
import { useState } from 'react';
import ReportAdminDomainCard from './domainCard';

const ReportDomainList = ({className, data, filter, style,
    orderList=[{name: 'Work Finish', value: 'finish'}, {name: 'Work Active', value: 'active'}, {name: 'Work Time', value: 'time'}, {name: 'Job Value', value: 'jv'}],
}) => {

    const [order, setOrder] = useState(orderList.find(x=>x))

    const domainList = data.sort((a,b) => {
        switch(order.value) {
        case 'finish':
            return b.workFinish-a.workFinish
        case 'active':
            return b.workActive-a.workActive
        case 'time':
            return b.timeTotal-a.timeTotal
        case 'jv':
            return b.jvTotal-a.jvTotal
        default: 
            return 0
        }

    })

    if(!data || !Array.isArray(data)) return (<div></div>)

    return ( 
        <div className={className}>
            {domainList.length>0 && 
                <div className="row mb-2">
                    <div className="col text-right">
                        <ButtonGroup
                        items={orderList}
                        selectedItem={order}
                        onItemSelect={filter => setOrder(filter)}
                        valueProperty='value'
                        selectedItemClass='btn btn-sm btn-primary'
                        defaultItemClass='btn btn-sm btn-outline-dark'
                        />
                    </div>
                </div>
                }
            {domainList.map( item => {
                return (
                    <div key={item._id} className="row">
                        <div className="col mb-2">
                            <ReportAdminDomainCard data={item} filter={filter} style={style} />
                        </div>
                    </div>
                )
            })}
        </div>

     );
}
 
export default ReportDomainList;