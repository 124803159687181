import React, {useState, useEffect} from 'react';

import { getDataFieldList } from './../../../../services/manage/data/field';
import { toast } from 'react-toastify';
import dlv from 'dlv'

const TaskTemplateFormDataField = ({useDataField, setUseDataField, dataField, setDataField}) => {

    const [list, setList] = useState([])

    useEffect(() => {
        const loadDataFields = async() => {
            try {
                const {data: result} = await getDataFieldList({enable: true, 
                    type: 'number',
                    projectionReq: 'name,number' })

                if(result.status==='success') {
                    setList(result.data.list)

                    if(dataField && dataField._id) {
                        const findDataField = result.data.list.find(x=> x._id===dataField._id)
                        if(findDataField) setDataField(findDataField)
                    }
                    

                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        loadDataFields()

        // eslint-disable-next-line
    }, []);

    const headerSpace = 180

    return ( 
    <React.Fragment>
        <div className="row no-gutters align-items-center mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Data Field</h6>
            </div>
            <div className="col-auto">
                <input type="checkbox" checked={useDataField} onChange={e => { setUseDataField(e.target.checked)}} />
            </div>
        </div>
        {useDataField && <div className="row align-items-top mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold mt-1">Select Data Field</h6>
            </div>
            <div className="col">
            {!dataField && list.length===0 && <span className="alert alert-sm alert-warning">No Data Available (Create in menu Manage Data)</span>}
            {!dataField && list.length>0 &&
            <React.Fragment>
                <div className="row">
                    {list.length>0 && list.map( item => 
                        <div key={item._id} className="col-12">
                            <div className="card bg-dark mb-2 " style={{cursor:'pointer', width: 500}} 
                                onClick={() => {
                                    setDataField(item)
                                }}>
                                <div className="card-body p-0">
                                    <div className="row align-items-center justify-content-between">
                                    <div className="col-auto ml-2">
                                        <h6 className="d-inline font-weight-bold">{item.name}</h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>}
            {dataField && 
                <div><span className="font-weight-bold">{dataField.name || dlv(list.find(x=> x._id===dataField._id), 'name', 'Unknown')}</span>
                <button type="button" className="btn btn-dark btn-sm text-white font-weight-bold ml-4" onClick={() => setDataField(undefined)} >Change</button>
                </div>
            }
            </div>
        </div>}
    </React.Fragment> );
}
 
export default TaskTemplateFormDataField;