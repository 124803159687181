import React from 'react';
import MeTaskCard from './taskCard';

const MeTaskList = ({list}) => {

    return ( 
        <React.Fragment>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">Empty</div>
            }
            {list && list.length>0 && 
            list.map( (item, index) => 
                <div key={item._id || index} className="row">
                <div className="col">
                <MeTaskCard data={item} />
                </div>
                </div>
            )}
        </React.Fragment>
     );
}
 
export default MeTaskList;