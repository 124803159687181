import React, { useState } from 'react';
import DialogSearchItem from './dialog';
import BadgeButton from '../badgeButton';

const DialogSearchListIndex = ({header, buttonAdd, list, selectData, selectValue='', notifySelect, invalidItem=false, cache=false, filter='name',  buttonStyle='btn-primary', icon="circle", valueProperty}) => {

  const [enable, setEnable] = useState(false)
  const [select, setSelect] = useState(selectData)

  const confirmSelectItem = (item) => {
    setEnable(false)
    if (cache) setSelect(item)

    if ( notifySelect instanceof Function) {
        notifySelect(item)

    }
  }

  const handleRemove = () => {
    setSelect(undefined)
    if ( notifySelect instanceof Function) {
      notifySelect(undefined)
    }
  }

  return ( 
  <React.Fragment>
    <DialogSearchItem icon={icon} header={header} data={list} isOpen={enable} valueProperty={valueProperty}
    onCancel={() => setEnable(false)} onConfirm={confirmSelectItem} filter={filter} />
    {select && !invalidItem && <BadgeButton item={select}
          itemClass="btn btn-sm btn-light"
          className="btn-group"
          buttonClass="btn btn-sm btn-outline-light"
          buttonText="Remove"
          onButtonClick={handleRemove} />}
    {invalidItem && <BadgeButton item={{name: selectValue + ' (Invalid)', _id: 'invalid'}}
          itemClass="btn btn-sm btn-warning"
          className="btn-group"
          buttonClass="btn btn-sm btn-outline-warning"
          buttonText="Remove"
          onButtonClick={handleRemove} />}
    {!select && !invalidItem && <button className={`btn ${buttonStyle}`} onClick={() => setEnable(true)}>{buttonAdd}</button>}
  </React.Fragment> );
    
}
 
export default DialogSearchListIndex;