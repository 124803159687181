import React, {useState, useEffect} from 'react';
import FontAwesome from 'react-fontawesome';
// import ToggleButton from 'react-toggle-button';
import Toggle from 'react-toggle'
import PersonPhoto from './personPhoto';
import { getEmployeeInfo } from '../../../services/hrms/employee';

const PersonCard = ({data, onRemove, enableToggle=false, enable, onToggle, onSetting}) => {

  const [userInfo, setUserInfo] = useState(data)
  const { _id, userId, type='person', userName, name: nameOld, company, sim, email, position } = userInfo;

  const name = userName || nameOld

  useEffect(() => {
    setUserInfo(data)
    const { userId, userName } = data
    
    const loadUserInfo = async() => {
        try {
          const userInfo = await getEmployeeInfo(userId)

          setUserInfo(userInfo)
        } catch(err) {}
    }

    if(!userName && userId) {
        loadUserInfo()
    }

  },[data])

  const toggleEnable = (value) => {
    if(onToggle instanceof Function) {
      onToggle({_id, id: userId, value})
    }
  }

  const clickRemove = () => {
    if(typeof(onRemove)==='function') {
      onRemove({_id, id: userId})
    }
  }

  return ( 
    <React.Fragment>
      
      <div className="card mb-2 text-dark p-0">
        <div className="row no-gutters align-items-center justify-content-left">
          {type==='person' && <div className="col-auto text-center mb-2 p-1 text-center">
            <PersonPhoto id={userId} size={80} />
          </div>}
          {type==='group' && <div className="col-auto text-center mb-2 p-1 text-center">
              <div style={{width: 80}}><FontAwesome name="users" size="3x" /></div>
            </div>}
          <div className="col-auto ml-2">
            <div className="card-body p-1">
              <div className="card-text">
                <h5 className="card-title d-inline mr-2">{name}</h5>
              </div>
              <div className="card-text">
                {position && <h6 className="card-text d-inline mr-2"><FontAwesome name="briefcase" /> {position}</h6>}
                {company && <h6 className="card-text d-inline"><FontAwesome name="building" /> {company}</h6>}
              </div>
              <div className="card-text">
                {sim && <span className="card-text"><FontAwesome name="phone-square" /> {sim}</span>}
                {email && <span className="card-text ml-2"><FontAwesome name="envelope" /> {email}</span>}
              </div>
            </div>
          </div>
          <div className="col-auto ml-auto mr-4">
            <div className="row no-gutters align-items-center">
              {typeof(onSetting)==='function' && 
              <div className="col-auto mr-3">
                <FontAwesome name="cog" size="2x" onClick={() => onSetting(_id)} style={{cursor: 'pointer'}} />
              </div>}
              {enableToggle && (enable!==undefined) &&
              <div className="col-auto pt-2 mr-3">
                <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
              </div>}
              <div className="col-auto">
                  <FontAwesome name="trash" size="2x" onClick={clickRemove} style={{cursor: 'pointer'}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default PersonCard;