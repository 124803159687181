import React from 'react';
import FontAwesome from 'react-fontawesome';


const PersonCard = ({data, onRemove}) => {

  const { id, name, userName, position, company, sim, email  } = data;

      return ( 
        <React.Fragment>
          <div className="card mb-2 text-dark">
            <div className="row no-gutters align-items-center justify-content-center">
              <div className="col-6 col-sm-5 col-md-4 col-lg-3 text-center mb-2 p-2 text-center">
                  <img className="rounded-circle ml-2" style={{width: "100%", maxHeight: 200, maxWidth: 200}} 
                  src={`https://api-idms.advanceagro.net/hrms/employee/${id}/photocard/?size=200`} alt="..." />
              </div>
              <div className="col-12 col-md-8 col-lg-9 mb-2">
                <div className="card-body">
                  <div className="card-text">
                    <h6 className="card-title d-inline mr-2">{name || userName}</h6>
                  </div>
                  <div className="card-text">
                    {position && <h6 className="card-text d-inline mr-2"><FontAwesome name="briefcase" /> {position}</h6>}
                    <h6 className="card-text d-inline"><FontAwesome name="building" /> {company}</h6>
                  </div>
                  <div className="card-text">
                    {sim && <span className="card-text"><FontAwesome name="phone-square" /> {sim}</span>}
                    {email && <span className="card-text ml-2"><FontAwesome name="envelope" /> {email}</span>}
                  </div>
                  <div className="d-inline">
                    <button className="btn btn-danger btn-sm mt-2" onClick={() => onRemove(id)}>Remove</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        );
    
}
 
export default PersonCard;