import React, {useState, useEffect} from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'
import PersonPhoto from '../../common/card/personPhoto';
import { getEmployeeInfo } from '../../../services/hrms/employee';
import { addPermission, removePermission, enablePerson, disablePerson } from '../../../services/domain/domainService';

const PermissionCard = ({domainId, data={}, onRemove, enableToggle=false, isAdmin=false, isOwner=false}) => {

  const [userInfo, setUserInfo] = useState({})
  const { userName, name: nameOld, company, sim, email, position } = userInfo;

  const [roles, setRoles] = useState(data.roles || [])
  const [enable, setEnable] = useState(data.enable || false)
  const { userId } = data

  const name = userName || nameOld

  useEffect(() => {
    let isSubscribed = true
    const loadUserInfo = async() => {
        try {
          const userInfo = await getEmployeeInfo(userId)
          if(isSubscribed) setUserInfo(userInfo)
        } catch(err) {}
    }

    if(userId) {
        loadUserInfo()
    }

    return () => isSubscribed = false

  },[userId])

  const handlePermissionChange = async({permissionName, boo}) => {

    try {
      if(boo) {
          await addPermission({domainId, userId, permissionName })
          setRoles(prev => [...prev, permissionName])

      } else {
          await removePermission({domainId, userId, permissionName })
          setRoles(prev => prev.filter(x=> x!==permissionName))

      }

    } catch(err) {
        toast.error('Failed to update permission')
    }
  }

  const toggleEnable = async(value) => {
    try {
      if(value) {
        await enablePerson({domainId, userId})
        setEnable(value)

      } else {
        await disablePerson({domainId, userId})
        setEnable(value)
      }

    } catch(err) {
        toast.error('Failed to update person')
    }
  }

  const clickRemove = () => {
    if(typeof(onRemove)==='function') {
      onRemove({id: userId})
    }
  }

  return ( 
    <React.Fragment>
      
      <div className="card mb-2 text-dark p-0">
        <div className="row no-gutters align-items-center justify-content-left">
          <div className="col-auto text-center mb-2 p-1 text-center">
            <PersonPhoto id={userId} size={80} />
          </div>
          <div className="col-auto ml-2">
            <div className="card-body p-1">
              <div className="card-text">
                <h5 className="card-title d-inline mr-2">{name}</h5>
              </div>
              <div className="card-text">
                {position && <h6 className="card-text d-inline mr-2"><FontAwesome name="briefcase" /> {position}</h6>}
                {company && <h6 className="card-text d-inline"><FontAwesome name="building" /> {company}</h6>}
              </div>
              <div className="card-text">
                {sim && <span className="card-text"><FontAwesome name="phone-square" /> {sim}</span>}
                {email && <span className="card-text ml-2"><FontAwesome name="envelope" /> {email}</span>}
              </div>
            </div>
          </div>
          <div className="col-auto ml-3">
            <div className="row no-gutters align-items-center">
              {(isAdmin || roles.includes('owner')) && <div className="col-auto px-2">
                <input type="checkbox" checked={roles.includes('owner')} disabled={!isAdmin}
                  onChange={e => handlePermissionChange({permissionName: 'owner', boo: e.target.checked})} /><span> Owner</span>
              </div>}
              <div className="col-auto px-2">
                <input type="checkbox" checked={roles.includes('owner') || roles.includes('report')} disabled={roles.includes('owner')}
                  onChange={e => handlePermissionChange({permissionName: 'report', boo: e.target.checked})} /><span> Report</span>
              </div>
              <div className="col-auto px-2">
                <input type="checkbox" checked={roles.includes('owner') || roles.includes('admin')} disabled={roles.includes('owner')}
                  onChange={e => handlePermissionChange({permissionName: 'admin', boo: e.target.checked})} /><span> Admin</span>
              </div>
            </div>
          </div>
          {(isAdmin || (isOwner && !roles.includes('owner'))) && <div className="col-auto ml-auto mr-4">
            <div className="row no-gutters align-items-center">
              {enableToggle && (enable!==undefined) &&
              <div className="col-auto pt-2 mr-3">
                  <Toggle
                    defaultChecked={enable}
                    onChange={value => toggleEnable(value.target.checked) } />
              </div>}
              <div className="col-auto">
                  <FontAwesome name="trash" size="2x" onClick={clickRemove} style={{cursor: 'pointer'}} />
              </div>
            </div>
          </div>}
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default PermissionCard;