import React from 'react';
// import FontAwesome from 'react-fontawesome';
import moment from 'moment'
// import dlv from 'dlv'

const TaskDataInfo = ({className, data: work}) => {

    const {  plan, project } = work

    return ( 
    <div className={className}>
        <div className="row justify-content-between align-item-bottom">
            <div className="col-auto">
                {project && project.name && <h4 className="text-primary mb-3">Project : {project.name}</h4>}
                {plan && <h6 className="mt-2">Plan Work
                    <span className="badge badge-info ml-2">Begin {moment(plan.begin).format('DD MMMM YYYY HH:mm')}</span>
                    <span className="badge badge-info ml-2">End {moment(plan.end).format('DD MMMM YYYY HH:mm')}</span>
                </h6>}
            </div>
        </div>
    </div> );
}
 
export default TaskDataInfo;