import React, { useState, useEffect } from 'react';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
// import FontAwesome from 'react-fontawesome';
import ReportStaffList from '../../staff/staffList';
import GraphBarBasic from '../../../common/graph/basicBar';
import GraphDonutBasic from '../../../common/graph/basicDonut';
import ReportTeamList from '../../team/summary/teamList';
import ButtonGroup from '../../../common/buttonGroup';

const ReportPerTaskToday = ({report={}, filter, setDownloadMode=()=> {}}) => {

    const listGroups = [{name: 'Staff', value: 'staff'}, {name: 'Team', value: 'team'} ]
    const [listGroup, setListGroup] = useState(listGroups.find(x=>x))

    useEffect(() => {
        if(listGroup.value==='team') {
            setDownloadMode('team')
        } else {
            setDownloadMode('normal')
        }
        // eslint-disable-next-line
    }, [listGroup]);

    const { staffList=[], teamList, reportThisSummary=[], reportThisGraph=[], customOrder=[] } = report
    const { groupByHour, groupDaily } = report

    const { workTotal=0, taskWait, taskActive, taskPause, taskFinish, taskDelete, timeTotal=0, jobValueTotal } = report
    const { staffTotal='-', staffActive } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(staffList.find(x=> x.jvTotal>0)) staffOrderList.push({name: 'Job Value', value: 'jv'})


    return ( <React.Fragment>
        <div className="row no-gutters justify-content-left mt-3">
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-light">{staffTotal}</span></h4>
                            Total
                            </div>
                            {staffActive!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{staffActive}</span></h4>
                            Active
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            {workTotal!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{workTotal}</span></h4>
                            Total
                            </div>}
                            {taskWait!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                            Plan
                            </div>}
                            {taskActive!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                            Active
                            </div>}
                            {taskPause!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                            Pause
                            </div>}
                            {taskFinish!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{taskFinish}</span></h4>
                            Finish
                            </div>}
                            {taskDelete!==undefined && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                            Delete
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work Time</strong>
                    </div>
                    <div className="card-body" style={{minHeight: 101}}>
                        <h4 className="card-title text-success">{workTime || <span className="text-muted">ไม่มีข้อมูล</span>}</h4>
                        <h6>Total</h6>
                    </div>
                </div>
            </div>
            {jobValueTotal>0 && <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                    <strong>Job Value</strong>
                    </div>
                    <div className="card-body" style={{minHeight: 101}}>
                        <h4 className="card-title text-primary">
                            <span>{jobValueTotal.toLocaleString()} &#3647;</span></h4>
                        <h6>Total</h6>
                    </div>
                </div>
            </div>}
            {reportThisSummary.map(item => {
                const {_id,label, sum, isDecimal } = item

                const total = isDecimal ? sum.toFixed(2) : sum

                return(<div key={_id} className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                    <strong>{label}</strong>
                    </div>
                    <div className="card-body" style={{minHeight: 101}}>
                        <div className="row no-gutters justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4 className="card-title text-primary">{total.toLocaleString()}</h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
            })}
            {reportThisGraph.map(item => {
                const {_id, label, graph} = item

                return(<div key={_id} className="col-12 mb-3 text-center"><GraphDonutBasic header={label} input={graph} /></div>)
            })}
        </div>
        {groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Finish Hourly' yaxisText='Count Finish' />}
        {groupDaily && <GraphBarBasic className='mb-3' data={groupDaily} header='Finish Daily' yaxisText='Count Finish' />}
        {teamList && staffList && 
        <React.Fragment>
            <div className="row mb-2">
                <div className="col text-center">
                    <ButtonGroup
                    items={listGroups}
                    selectedItem={listGroup}
                    onItemSelect={filter => setListGroup(filter)}
                    valueProperty='value'
                    selectedItemClass='btn btn-primary'
                    defaultItemClass='btn btn-outline-dark'
                    />
                </div>
            </div>
        </React.Fragment>}
        {teamList && listGroup.value==='team' && <ReportTeamList className="mt-4" data={teamList} filter={filter} />}
        {staffList && listGroup.value==='staff' && <ReportStaffList className="mt-4" orderList={staffOrderList} data={staffList} filter={filter} customOrder={customOrder} />}
        
    </React.Fragment> );
}
 
export default ReportPerTaskToday;