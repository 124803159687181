import React, {useState, useEffect} from 'react';

import dlv from 'dlv'
import ButtonGroup from '../../../common/buttonGroup';
import TaskTemplateFormDataField from './formDataField';

const TaskTemplateFormTypeNumber = ({options, setOptions}) => {

    const numberTypes = [{name: 'Integer', value: 'integer'}, {name: "Decimal", value: 'decimal'}]

    const [type, setType] = useState( dlv(options, 'number.type', 'integer') )
    const [min, setMin] = useState( dlv(options, 'number.min', 0))
    const [max, setMax] = useState( dlv(options, 'number.max', 999))
    const [init, setInit] = useState( dlv(options, 'number.init', ''))
    const [useInit, setUseInit] = useState( dlv(options, 'number.init')!==undefined ? true : false)

    const [useDataField, setUseDataField] = useState(dlv(options, 'number.dataField') ? true : false)
    const dataFieldId = dlv(options, 'number.dataField')
    const [dataField, setDataField] = useState(dataFieldId ? {_id: dataFieldId } : undefined)

    const sendError = (error) => { setOptions({ error })  }

    useEffect(() => {
        if(dataField) {
            if(dataField.number) {
                const {type, min, max, init} = dataField.number
                if(type && min && max) {
                    setType(type)
                    setMin(min)
                    setMax(max)
                    setInit(init)
                    setUseInit(init ? true : false)
                } else {
                    sendError('Invalid Data Field')
                }
            } else {
                sendError('Invalid Data Field')
            }
        }

        // eslint-disable-next-line
    }, [dataField]);

    useEffect(() => {
        if(setOptions instanceof Function) {

            if(!type) return sendError('Please select number type')
            if(useDataField && !dataField) return sendError('Select Data Field')
            
            if(type==='integer') {
                if(! /^[-]?\d+$/.test(min)) return sendError('Invalid min number')
                if(!  /^[-]?\d+$/.test(max)) return sendError('Invalid max number')
                if(parseInt(max)<parseInt(min)) return sendError('min is greater than max')
            } else if(type==='decimal') {
                if(! /^[-]?(?:\d*\.\d{1,2}|\d+)$/.test(min) || !  /^[-]?(?:\d*\.\d{1,2}|\d+)$/.test(max)) return sendError('min-max is invalid')
                if(parseFloat(max)<parseFloat(min)) return sendError('min is greater than max')
            } else {
                return sendError('Invalid Number Type')
            }

            const number = { type, min, max }

            if(useInit) {
                if(type==='integer') {
                    if(! /^[-]?\d+$/.test(init) ) return sendError('default is invalid')
                } else if(type==='decimal') {
                    if(! /^[-]?(?:\d*\.\d{1,2}|\d+)$/.test(init)) return sendError('default is invalid')
                }

                if(parseFloat(init) < parseFloat(min) || parseFloat(init)>parseFloat(max)) return sendError('default is out of range')

                number.init = init
            }

            if(useDataField) {
                number.dataField = dataField._id
            }

            setOptions({number})
        }

        // eslint-disable-next-line
    }, [type, min, max, init, useInit, useDataField, dataField])

    const headerSpace = 180

    return ( <React.Fragment>
        <TaskTemplateFormDataField useDataField={useDataField} setUseDataField={setUseDataField} dataField={dataField} setDataField={setDataField} />
        {(!useDataField || dataField) &&
        <div className="row no-gutters align-items-center mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Number</h6>
            </div>
            <div className="col-auto">
                <ButtonGroup
                    items={numberTypes}
                    selectedItem={numberTypes.find(x=> x.value===type)}
                    onItemSelect={item => setType(item.value)}
                    valueProperty='value'
                    selectedItemClass="btn btn-sm btn-light"
                    defaultItemClass="btn btn-sm btn-dark btn-outline-secondary text-white"
                    disabled={useDataField}
                    />
                
            </div>
            <div className="col-auto ml-2">
                <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Min</span>
                </div>
                <input type="text"
                    value={min} onChange={e => setMin(e.target.value)} style={{maxWidth: 90}}
                    className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" 
                    disabled={useDataField}
                    />
                
                </div>
            </div>
            <div className="col-auto ml-2">
                <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">Max</span>
                </div>
                <input type="text"
                    value={max} onChange={e => setMax(e.target.value)} style={{maxWidth: 90}}
                    className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" 
                    disabled={useDataField}
                    />
                </div>
            </div>
            <div className="col-auto ml-2">
                <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                        <input type="checkbox" checked={useInit} onChange={e => { setUseInit(e.target.checked)}}  disabled={useDataField} />
                        <span className="ml-2">Default</span>
                    </span>
                </div>
                <input type="text" disabled={!useInit || useDataField}
                    value={init} onChange={e => setInit(e.target.value)} style={{maxWidth: 90}}
                    className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                
                </div>
            </div>
        </div>}
        <div className="row no-gutters align-items-center mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
            </div>
            <div className="col-auto text-warning font-weight-bold">
                Suit for trend or statistic purpose
            </div>
        </div>
    </React.Fragment> );
}
 
export default TaskTemplateFormTypeNumber;