import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import ConfirmDelete from '../common/confirmDelete';
import { NavLink } from 'react-router-dom';
import SearchBox from './../common/searchBox';


import { domainListCount, domainList, deleteDomain } from './../../services/domain/domainService';
import DomainCard from './domainCard';

const Domains = () => {

    const [search, setSearch] = useState('')

    const [load, ] = useState(Date.now())
    const [loadList, setLoadList] = useState(undefined)

    //load Count
    useEffect(() => {
        const getListCount = async() => {
            try {
                const {data: result} = await domainListCount();
    
                if(result.status==='success') {
    
                    setCount(result.data.count)
                    setLoadList(Date.now())
        
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        getListCount()

    },[load])

    const limitRow = 20
    
    //load List
    useEffect(() => {

        const getList = async({skip, limit}) => {
            try {
                const {data: result} = await domainList({projectionReq: 'enable,name,features', sort: 'logs.0.date', skip, limit});
    
                if(result.status==='success') {
                    setList(prev => [...prev, ...result.data.list] )
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        const loadNum = Math.ceil(count/limitRow)
        for(let i=0; i<loadNum; i++) {
            getList({skip: i*limitRow, limit: limitRow})
        }
    
        // eslint-disable-next-line
    }, [loadList])

    const [count, setCount] = useState(0)
    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState('')

    const getDomainList = () => {
        return list.filter(x=> x.name.toLowerCase().includes(search.toLowerCase().trim())).sort((a,b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
    }

    const confirmDelete = async() => {
        try {
            await deleteDomain(askDelete);

            setList(prev => prev.filter(x=> x._id!==askDelete))
            setCount(prev => prev-1)
            setAskDelete(undefined)

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row justify-content-right align-items-center">
                <div className="col-auto mb-3"><h4>Domains</h4></div>
                <div className="col-auto mr-auto mb-3"><h5 className="d-inline text-primary">Total : {count}</h5></div>
                <div className="col-auto mb-3">
                    <SearchBox value={search} onChange={text => setSearch(text)} />
                </div>
                <div className="col-auto mb-3">
                    <NavLink className="nav-item nav-link p-0" to={`/domain/new`} >
                        <button type="button" className="btn btn-primary text-white" >New</button>
                    </NavLink>
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {getDomainList().map( (item, index) => {
                return (
                    <div key={item._id || index} className="col-12">
                        <DomainCard key={item._id || index} data={item} onRemove={setAskDelete} />
                    </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default Domains;