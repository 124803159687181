import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import PersonPhoto from '../../common/card/personPhoto';
import { savePerson } from '../../../services/manage/staff/teamService';
import { NavLink } from 'react-router-dom';

const TeamCard = ({ data={}, onRemove=()=>{} }) => {

    const {_id, name, count, list } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await savePerson({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update person group')
        }
    }

  return ( 
    <React.Fragment>
        <div className="card mb-2">
            <div className="card-body p-1">
                <div className="row no-gutters align-items-center justify-content-right">
                    <div className="col-auto ml-3">
                        <h4 className="d-inline badge badge-info"><FontAwesome name="users" /> TEAM</h4>
                    </div>
                    <div className="col-auto">
                        <NavLink className="nav-item nav-link text-dark text-bold font-weight-bold d-inline" to={`/manage/team/${_id}`} ><h4 className='d-inline'>{name}</h4></NavLink>
                    </div>
                    <div className="col-auto mr-auto"><span>จำนวน {count} คน</span></div>
                    <div className="col-auto">
                        <div className="row no-gutters align-items-center mr-3">
                            <div className="col-auto p-0 mr-1">
                                <NavLink className="nav-item nav-link text-dark" to={`/manage/team/${_id}`} ><FontAwesome name="pencil" size="2x" /></NavLink>
                            </div>
                            <div className="col-auto p-0 mr-3">
                                <Toggle className="mt-2" checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                            </div>
                            <div className="col-auto p-0">
                                <FontAwesome name="trash" size="2x" onClick={onRemove} />
                            </div>
                        </div>
                    </div>
                </div>
                {list && list.length>0 && <div className="row ml-2 mb-1">
                    <div className="col">
                        {list.map(x=> <PersonPhoto key={x} id={x} size={70} />) }
                    </div>
                </div>}
            </div>
        </div>
    </React.Fragment>
    );
    
}
 
export default TeamCard;