import React, { useState, useEffect } from 'react';
import ButtonGroup from '../../../common/buttonGroup';
import ReportTeamCard from './teamCard';

const ReportTeamList = ({className, data, filter, style, orderList=[] }) => {

    const [order, setOrder] = useState(orderList.find(x=>x))

    useEffect(() => {
        if(!order && orderList.length>0) {
            setOrder(orderList.find(x=>x))
        }
        // eslint-disable-next-line
    }, [orderList]);

    const getTaskList = data.sort((a,b) => {
        if(order && order.value) {
            return (b[order.value] - a[order.value])
        } else {
            return 0
        }
    })

    if(!data || !Array.isArray(data)) return (<div></div>)

    return ( 
        <div className={className}>
            {getTaskList.length>0 && 
                <div className="row mb-2">
                    <div className="col text-right">
                        <ButtonGroup
                        items={orderList}
                        selectedItem={order}
                        onItemSelect={filter => setOrder(filter)}
                        valueProperty='value'
                        selectedItemClass='btn btn-sm btn-primary'
                        defaultItemClass='btn btn-sm btn-outline-dark'
                        />
                    </div>
                </div>
                }
            {getTaskList.map( item => {
                return (
                    <div key={item._id} className="row">
                        <div className="col mb-2">
                            <ReportTeamCard data={item} filter={filter} style={style} />
                        </div>
                    </div>
                )
            })}
        </div>

     );
}
 
export default ReportTeamList;