import React, { useState, useEffect } from 'react';
import dlv from 'dlv'
import ButtonGroup from './../../../common/buttonGroup';
import { Collapse } from "react-bootstrap";
import Joi from 'joi'

import ButtonGroupMulti from '../../../common/buttonGroupMulti';
import moment from 'moment'
import {evaluate} from 'mathjs'

const TaskJobValueConponent = ({className='', jobValue, setJobValue=()=>{}, validField: initValidField}) => {

    const [validField, setValidField] = useState(initValidField)

    useEffect(() => {
        if(validField.every(x=> initValidField.includes(x)) && initValidField.every(x=> validField.includes(x))) {
            
        } else {
            setValidField(initValidField)
        }

        // eslint-disable-next-line
    }, [initValidField])

    const modeList = [{name: 'Per Task', value: 'task'}, {name: 'Per Hour', value: 'hour'}, {name: 'Formula', value: 'formula'}]
    const [mode, setMode] = useState( modeList.find(x=> x.value===dlv(jobValue, 'mode.value', 'task')))
    const [value, setValue] = useState(dlv(jobValue, 'value', 100))
    const [limit, setLimit] = useState(dlv(jobValue, 'limit', 0))

    const constrainsList = [{name: "ON", value: true}, {name: "OFF", value: false}]
    const [enableConstraints, setEnableConstraints] = useState(dlv(jobValue, 'constraint.enable', false))

    const ctDayModeList = [{name: 'Everyday', value: 'all'}, {name: 'Custom', value: 'custom'}]
    const [ctDayMode, setCTDayMode] = useState( ctDayModeList.find(mode=> mode.value===( dlv(jobValue, 'constraint.day',[]).length>0 ? 'custom' : 'all')) )
    const dayList = [{name: 'Monday', value: 'mon'}, 
        {name: 'Tuesday', value: 'tue'},
        {name: 'Wednesday', value: 'wed'},
        {name: 'Thursday', value: 'thu'},
        {name: 'Friday', value: 'fri'},
        {name: 'Saturday', value: 'sat'},
        {name: 'Sunday', value: 'sun'},
        ]
    const [constraintDay, setConstraintDay] = useState(dlv(jobValue, 'constraint.day', []))
    
    const ctTimeModeList = [{name: 'All-day', value: 'all'}, {name: 'Custom', value: 'custom'}]
    // console.log('test', test)
    const [ctTimeMode, setCTTimeMode] = useState( ctTimeModeList.find(mode=> mode.value===( dlv(jobValue, 'constraint.time',[]).length>0 ? 'custom' : 'all')) )
    const [constraintTime, setConstraintTime] = useState(dlv(jobValue, 'constraint.time', []).map(item => {
        return `${item.start}-${item.end}`
    }).join(', '))

    const [formula, setFormula] = useState(dlv(jobValue, 'formula', ''))

    const [showStaff, setShowStaff] = useState(dlv(jobValue, 'showStaff', false ))
    const [showReport, setShowReport] = useState(dlv(jobValue, 'showReport', false ))
    const [error, setError] = useState('')

    const schema = Joi.object({ value: Joi.number().integer().min(0), limit: Joi.number().integer().min(0) })

    useEffect(() => {
        const {error: check } = schema.validate({value, limit})
        if(check) return setError('Invalid Number Value')

        let constraint = { enable : false }
        if(enableConstraints) {
            if(ctDayMode.value==='custom' && constraintDay.length===0) {
                return setError('JV : Constraint Day is empty')
            }

            constraint = { enable : true, day: constraintDay, time: [] }

            const ctTime = []
            if( ctTimeMode.value==='custom' &&  constraintTime.length===0) return setError('JV : Constraint Time is empty')
            if( ctTimeMode.value==='custom' &&  constraintTime.length>0) {
                const ctTimeTrack = constraintTime.replace(/\s/g,'').split(',')
                let timeInvalid = false
                ctTimeTrack.forEach(item => {
                    const tracks = item.split('-')
                    if(tracks.length===2) {
                        if(!moment(tracks[0], "HH:mm", true).isValid()) timeInvalid = true
                        if(!moment(tracks[1], "HH:mm", true).isValid()) timeInvalid = true

                        if(moment(tracks[0], "HH:mm", true).isSameOrAfter(moment(tracks[1], "HH:mm", true))) timeInvalid = true

                        ctTime.push({start: tracks[0], end: tracks[1]})
                    } else {
                        timeInvalid = true
                    }
                    
                })

                //must not overlap
                if( ctTime.find((item, index, array) => {
                    const {start, end} = item

                    if(array.filter((x,i)=> i!==index).find(x => 
                        moment(x.start, 'HH:mm').isBefore(moment(end,'HH:mm')) 
                        && moment(x.end, 'HH:mm').isAfter(moment(start, 'HH:mm')) )) return true

                    return false
                } ) ) return setError('JV : Time must not overlap')

                if(timeInvalid) return setError('JV : Time is invalid')

                constraint.time = ctTime
            }
        }

        if(mode.value==='formula') {
            if(!formula) return setError('Formula is empty')

            try {

                const formulaReplace = formula.replace(
                        new RegExp("(" + validField.map(i=> {return i.replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&")}).join("|") + ")", "g"), 
                            1);
                // console.log('formulaReplace', formulaReplace)

                const result = evaluate(formulaReplace)

                if(!isFinite(result)) {
                    return setError('Invalid Formula')
                }

            } catch(err) {
                return setError('Invalid Formula')
            }
        }

        setError('')

        if(setJobValue instanceof Function) {
            setJobValue(prev => { return {...prev, mode, value, limit, constraint, formula } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, value, limit, enableConstraints, ctDayMode, constraintDay, ctTimeMode, constraintTime, formula, validField])

    useEffect(() => {
        if(setJobValue instanceof Function) {
            setJobValue(prev => { return {...prev, error } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, setJobValue])

    return ( 
        <div className={className}>
            <Collapse in={dlv(jobValue, 'enable')}>
                <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <ButtonGroup
                            items={modeList}
                            selectedItem={mode}
                            onItemSelect={item => setMode(item)}
                            valueProperty='value'
                            />
                        </div>
                        {['task', 'hour'].includes(mode.value) && <div className="col-auto p-0">
                            <div className="input-group">
                            <input type="text"
                                value={value} onChange={e => setValue(e.target.value)} style={{maxWidth: 100}}
                                className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                            <div className="input-group-append">
                                <span className="input-group-text" id="inputGroup-sizing-default">บาท (baht)</span>
                            </div>
                            </div>
                        </div>}
                        {['hour'].includes(mode.value) && <div className="col-auto ml-2 p-0">
                        (เศษของชั่วโมง คำนวณตามจริงเป็นนาที)
                        </div>}
                    </div>
                    <div className="row no-gutters mt-2 d-none">
                        <div className="col-auto">
                            <input type="checkbox" checked={showStaff} 
                            onChange={e => setShowStaff(e.target.checked)} /><span> แสดงข้อมูลให้ผู้ปฏิบัติงาน</span>
                        </div>
                    </div>
                    <div className="row no-gutters d-none">
                        <div className="col-auto">
                            <input type="checkbox" checked={showReport} 
                            onChange={e => setShowReport(e.target.checked)} /><span> แสดงข้อมูลในรายงาน</span>
                        </div>
                    </div>
                    {mode.value==='hour' && <div className="row align-items-center mt-3">
                        <div className="col-auto">
                            Limit
                        </div>
                        <div className="col-auto p-0">
                            <div className="input-group">
                            <input type="text"
                                value={limit} onChange={e => setLimit(e.target.value)} style={{maxWidth: 100}}
                                className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                            <div className="input-group-append">
                                <span className="input-group-text" id="inputGroup-sizing-default">บาท (baht)</span>
                            </div>
                            </div>
                        </div>
                    </div>}
                    {mode.value==='hour' && <div className="row align-items-center mt-3">
                        <div className="col-auto">
                            Constraints
                        </div>
                        <div className="col-auto p-0">
                            <ButtonGroup items={constrainsList} 
                                valueProperty='value' 
                                selectedItemClass='btn btn-info'
                                selectedItem={constrainsList.find(x=>x.value===enableConstraints)} 
                                onItemSelect={item => setEnableConstraints(item.value)} />
                            {/* <ButtonGroupMulti items={dayList} valueProperty='value' notifyChange={item => console.log('item', item)} /> */}
                        </div>
                    </div>}
                    
                    {mode.value==='hour' && enableConstraints && <div className="row align-items-center mt-3">
                        <div className="col-auto">
                            Day
                        </div>
                        <div className="col-auto p-0">
                            <ButtonGroup items={ctDayModeList} selectedItemClass='btn btn-info' selectedItem={ctDayMode} onItemSelect={item => {
                                if(item.value==='all') setConstraintDay([])
                                setCTDayMode(item)
                            } } />
                            {ctDayMode.value==='custom' && <ButtonGroupMulti 
                                className='btn-group p-0 m-0 ml-3'
                                items={dayList} 
                                initSelectItems={constraintDay} valueProperty='value' 
                                selectedItemClass='btn btn-info btn-outline-light' 
                                notifyChange={items => setConstraintDay(items)} />}
                        </div>
                    </div>}
                    {mode.value==='hour' && enableConstraints && <div className="row align-items-center mt-3">
                        <div className="col-auto">
                            Time
                        </div>
                        <div className="col-auto p-0">
                            <ButtonGroup items={ctTimeModeList} selectedItemClass='btn btn-info' selectedItem={ctTimeMode} onItemSelect={item => {
                                if(item.value==='all') setConstraintTime('')
                                setCTTimeMode(item)
                            } } />
                            
                        </div>
                        {ctTimeMode.value==='custom' && <div className="col-auto">
                            <input className="form-control font-weight-bold" type='text' 
                                value={constraintTime} onChange={e => setConstraintTime(e.target.value)} style={{width:350}} />
                        </div>}
                        {ctTimeMode.value==='custom' && <div className="col-auto ml-4">
                            <span className="text-muted">Format : HH:mm-HH:mm [,HH:mm-HH:mm] (Example: 08:00-12:00,13:00-17:00)</span>
                        </div>}
                    </div>}
                    {mode.value==='formula' && <div className="row align-items-center mt-3">
                        <div className="col-auto">
                            Formula
                        </div>
                        <div className="col-auto">
                            <input className="form-control font-weight-bold" type='text' 
                                value={formula} onChange={e => setFormula(e.target.value)} style={{width:500}} />
                        </div>
                        {validField.length>0 && <div className="col-auto ml-4">
                            <span className="text-muted">Valid Field : {validField.join(', ')}</span>
                        </div>}
                        {validField.length===0 && <span className="text-warning">Define jvField in task detail and use here</span>}
                    </div>}
                    {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
                </div>
            </Collapse>
        </div>
     );
}

const TaskJobValue = ({className='', jobValue, setJobValue, validField}) => {

    return ( 
        <div className={className}>
            <div className="card">
                <div className="card-header font-weight-bold">
                    Job Value
                    <input className="ml-4" type="checkbox" checked={jobValue.enable} 
                        onChange={e => {
                            const boo = e.target.checked
                            setJobValue(prev => { return {...prev, enable: boo}})
                        }} /><span> Enable / เปิดใช้งาน</span>
                </div>
                {jobValue.enable && <TaskJobValueConponent jobValue={jobValue} setJobValue={setJobValue} validField={validField} />}
            </div>
        </div>
     );
}
 
export default TaskJobValue;