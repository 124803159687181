import React, {useEffect, useState, useContext} from 'react';
import { staffList, saveStaff, deleteStaff } from '../../../services/manage/staff/staffService';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';

import { DomainContext } from '../../../context/domainContext';
import StaffManage from './staffManage';
import StaffCard from './staffCard';
import { personList, deletePerson } from './../../../services/manage/staff/teamService';
import TeamCard from './teamCard';
import { NavLink } from 'react-router-dom';

const Staffs = () => {

    const { domain } = useContext(DomainContext)
    const [refreshStaff, setRefreshStaff] = useState(Date.now())
    const [refreshTeam, setRefreshTeam] = useState(Date.now())

    //Load Staff List
    useEffect(() => {
        const getStaffList = async() => {
            try {
                const {data: result} = await staffList();
    
                if(result.status==='success') {
                    setList(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        if(domain) getStaffList()
    }, [domain, refreshStaff])

    //Load Team List
    useEffect(() => {
        const getTeamList = async() => {
            try {
                const {data: result} = await personList();
    
                if(result.status==='success') {
                    setTeams(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        if(domain) getTeamList()
    }, [domain, refreshTeam])

    const [list, setList] = useState([])
    const [teams, setTeams] = useState([])

    const [askEdit, setAskEdit] = useState(-1)
    const [askDelete, setAskDelete] = useState('')
    

    const confirmSelectPerson = async(item) => {
      try {
        await saveStaff(item)
        setRefreshStaff(Date.now())

      } catch(err) {   }
    }

    const confirmDelete = async() => {
        try {
            if(askDelete) {
                const {type, id} = askDelete

                if(type==='staff') {
                    await deleteStaff(id);

                    setRefreshStaff(Date.now())
                } else if (type==='team') {
                    await deletePerson(id);

                    setRefreshTeam(Date.now())
                } else {
                    toast.error('Invalid Delete Type')

                }

                setAskDelete(undefined)
            }
        } catch(err) { toast.error('Failed to delete item')}
    }


    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete(undefined)} />
            <div className="row justify-content-right align-items-center mb-3">
                <div className="col mr-auto"><h4>Staffs</h4></div>
                <div className="col-auto p-0">
                  <DialogSearchPerson cache={false} notifySelect={item => confirmSelectPerson(item) } buttonText="Add Staff" />
                </div>
                <div className="col-auto p-0">
                    <NavLink className="nav-item nav-link text-dark" to={`/manage/team/new`} >
                    <button type="button" className="btn btn-primary text-white" >Add Team</button>
                    </NavLink>
                </div>
            </div>
            {teams.length> 0 && <div className="row mb-4">
            {teams.map( (item, index) => {
                return (
                <div key={index._id || index} className="col-12">
                    <TeamCard data={item} onRemove={() => { setAskDelete({type: 'team', id: item._id}); }} />
                </div>
                )
            })}
            </div>}
            {list.length===0 && teams.length===0 &&
            <div className="alert alert-warning" role="alert">
                Empty
            </div>}
            <div className="row">
            {list && list.map( (item, index) => {
                return (
                <div key={item._id || index} className="col-12">
                  <StaffCard data={item} onRemove={()=> setAskDelete({type: 'staff', id: item._id}) } 
                    onEdit={() => (askEdit>=0 && askEdit===index) ? setAskEdit(-1) : setAskEdit(index)} />

                  {index===askEdit && <StaffManage id={item._id} notifyCancel={() => setAskEdit(-1)} staff={item} />}
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default Staffs;