import React from 'react';
// import moment from 'moment'
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import ReportStaffList from '../../staff/staffList';
import GraphBarBasic from '../../../common/graph/basicBar';
import GraphDonutBasic from '../../../common/graph/basicDonut';

const ReportPerTeamDetail = ({report={}, filter}) => {

    const { staffList=[], customOrder=[] } = report
    const { groupByHour, groupWeekly, groupMonthly } = report
    const { groupByProject, groupByTask } = report

    const { workFinish=0, staffTotal=0, staffActive=0, timeTotal=0, jvTotal } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(staffList.find(x=> x.jvTotal>0)) staffOrderList.push({name: 'Job Value', value: 'jv'})

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row no-gutters justify-content-left mt-2">
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                            Total
                            </div>
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{staffActive}</span></h4>
                            Active
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workFinish}</span></h4>
                            Finish
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work Time</strong>
                    </div>
                    <div className="card-body" style={{minHeight: 101}}>
                        <h4 className="card-title text-success">{workTime || <span className="text-muted">ไม่มีข้อมูล</span>}</h4>
                        <strong>Total</strong>
                    </div>
                </div>
            </div>
            {jvTotal>0 && <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                    <strong>Job Value</strong>
                    </div>
                    <div className="card-body" style={{minHeight: 101}}>
                        <h4 className="card-title text-primary">
                            <span>{jvTotal.toLocaleString()} &#3647;</span></h4>
                            <h6>Total</h6>
                    </div>
                </div>
            </div>}
        </div>
        <div className="row">
            {groupByProject && <GraphDonutBasic className="col-12 col-xl-6 mb-3" input={groupByProject} header="Group By Project" centerText="Total Work" />}
            {groupByTask && <GraphDonutBasic className="col-12 col-xl-6 mb-3" input={groupByTask} header="Group By Task" centerText="Total Work" />}
        </div>
        {groupByHour && <GraphBarBasic data={groupByHour} header='Finish Hourly' yaxisText='Count Finish' />}
        {groupWeekly && <GraphBarBasic data={groupWeekly} header='Finish Daily' yaxisText='Count Finish' />}
        {groupMonthly && <GraphBarBasic data={groupMonthly} header='Finish Daily' yaxisText='Count Finish' />}
        {staffList && <ReportStaffList className="mt-4" data={staffList} filter={filter} orderList={staffOrderList} customOrder={customOrder} style={{workVisible: ['finish']}} />}
        
    </React.Fragment> );
}
 
export default ReportPerTeamDetail;