import React, {useState, useEffect} from "react";
// import Joi from "joi-browser";
// import Form from "../common/form";
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import ConfirmDelete from './../common/confirmDelete';
import { saveDomain, getDomain } from '../../services/domain/domainService';

import { NavLink } from 'react-router-dom';
import ButtonGroupOnOff from "../common/buttonGroupOnOff";
import dlv from "dlv";
import Permissions from './../manage/permission/permissions';

const DomainManage = ({history, match}) => {

  const [id, setId] = useState(match.params.id)
  const [name, setName] = useState('')

  const loadForm = async(formId) => {

    if(formId && formId!=='new') {
      try {
        const {data: result } = await getDomain(formId);

        const { data } = result.data

        setName(data.name)
        setDescription(data.description)
        setFeatures(dlv(data, 'features', []))

      } catch(err) {
        toast.error('Failed to load form data')
      }
    }
  }

  useEffect(() => {
    loadForm(id)
  }, [id])
  
  const [description, setDescription] = useState('')

  const featureList = [{name: 'Browse Photo', value: 'task-photo-browse'}, 
    {name: 'Task Script', value: 'task-script'},
    {name: 'GPS Tracking', value: 'gps-track'}
  ]
  const [features, setFeatures] = useState([])

  const handleFeatureToggle = ({item, val}) => {
    if(val) {
      if(!features.find(x=> x.value===item.value)) {
       setFeatures(prev =>  [...prev, item])
      }
    } else {
       setFeatures(prev => {
         const update = [...prev]
         const findIndex = update.findIndex(x=> x.value===item.value)
         if(findIndex>-1) update.splice(findIndex,1)
         return update
       } )
    }
  }

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    // if(!personList || personList.length<1) return toast.error('Person is empty', toastOptions)

    const doc = { name, description, features, _id: (id==='new') ? undefined : id  }

    try {
      const { data: result } = await saveDomain(doc);
      if ( result.status === 'success') {
        const docId = dlv(result, 'data.docId')
        if(docId) {
          setId(docId)

          history.replace({
            pathname: "/domain/" + docId
          })
        }
        
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }

  }

    return (
      <React.Fragment>
          <div className="row align-items-center mb-4">
            <div className="col-auto" style={{width: 300}}>
                <h5 className="font-weight-bold">Domain Name</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            </div>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col-auto text-muted" style={{width: 300}}>
                <h5 className="font-weight-bold">Description</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
            </div>
          </div>
          <h5>Features</h5>
          <div className="row mb-4">
          {featureList.length>0 && 
            featureList.map(item => {
                  return (                  
                    <div key={item.value} className="col-auto">
                      <div className="card card-body p-3">
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto mr-2">
                            {item.name}
                          </div>
                          <div className="col-auto">
                            <ButtonGroupOnOff init={features.find(x=> x.value===item.value) ? true : false} notifyChange={val => handleFeatureToggle({item, val})}  />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              })
          }
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-auto text-center">
            <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save</button>
            
            </div>
            <div className="col-auto">
              <NavLink className="nav-item nav-link p-0" to={`/domain`} >
              <button type="button" className="btn btn-secondary text-white" >Cancel</button>
              </NavLink>
            </div>
          </div>
          {id!=='new' && <div className="mt-4"><Permissions className='mt-4' domainId={id} /></div>}
          
      </React.Fragment>
    );
  
}

export default DomainManage;
