import React, {useState, useEffect} from 'react';
import { getReportStaff } from '../../../../services/report/reportStaff';
import { toast } from 'react-toastify';
import FilterDateCombo from '../../../common/filter/filterDateCombo';
import moment from 'moment'
import PersonCardMini from '../../../common/card/personCardMini';
import queryString from 'query-string'
import PerStaffToday from './perStaffToday';
import PerStaffHistory from './perStaffHistory';
import ButtonDownload from './../../../common/button/buttonDownload';

import jsfiledownload from 'js-file-download'
import { downloadTask } from '../../../../services/download/dashboard';

const ReportStaffIndex = ({match, location}) => {

    const [id,] = useState(match.params.id)
    const [filter, setDateFilter] = useState({filter: queryString.parse(location.search).filter || 'today', date: queryString.parse(location.search).date || moment().format('YYYY-MM-DD')})
    const [report, setReport] = useState({})

    const loadStaff = async(input) => {
        if(!input) return

        try {
            const { data: result } = await getReportStaff(input);
            setReport(result.data.report)

        } catch(err) {
            toast.error('Failed to load work data')
        }
    }


    useEffect(() => {
        loadStaff({id, ...filter})
    }, [id, filter])

    const handleReportDownload = () => {
        return new Promise(async (resolve, reject) => {
          try {
    
            const input = { date: filter.date, }
    
            input.userId = id
            if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
            if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
            
            const {data: result, headers } = await downloadTask(input)
    
            if( result.status==='error') {
                toast.error(result.message)
            } else {
                if ( headers['x-suggested-filename']==='nodata') {
                    toast.error("No Report")
                } else {
                    jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
                }
            }
    
            setTimeout(() => {resolve('ok')}, 300);
              
          } catch(err) { 
              toast.error("Error : ", err.message)
              resolve('error')
          }
        })
    
    }

    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!report) return (<div className="alert alert-warning" role="alert">Report is not found</div>);

    return ( <React.Fragment>
        <div className="row justify-content-end">
            <div className="col-auto mr-auto mb-3">
                
            </div>
            <div className="col-auto p-0">
                {/* <h5 className="text-danger d-inline">ปิดใช้งานปุ่ม Download ชั่วคราว</h5> */}
                <ButtonDownload label='Download' onClick={handleReportDownload} />
            </div>
            <div className="col-auto ml-3 mb-3">
                <FilterDateCombo enableFilter={['today', 'day', 'week', 'month']}  notifyDateFilter={filter => setDateFilter(filter)} init={{...filter}} />
            </div>
        </div>
        
        {/* <div className="row"><div className="col text-right"><span className="text-danger">(ปิดการใช้ Weekly, Monthly ชั่วคราว)</span></div></div> */}
        <PersonCardMini data={report} styles={{photoSize:178, showInfo:['name', 'company', 'sim', 'position', 'department'], nameSize: 22, infoSize: 16, showBorder: false, marginLeft: 20}} />
        {['today'].includes(filter.filter) && <PerStaffToday className='mt-4' report={report} filter={filter} />}
        {['day', 'week', 'month'].includes(filter.filter) && <PerStaffHistory report={report} filter={filter} />}
    </React.Fragment> );
}
 
export default ReportStaffIndex;