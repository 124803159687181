import React from 'react';
import GraphDonutBasic from '../../../common/graph/basicDonut';
import ReportTaskList from '../../task/summary/taskList';

const ReportSharedHistory = ({report, filter}) => {

    const { taskTotal=0, workTotal=0, staffTotal=0, staffActive=0, timeTotal=0 } = report

    const { groupByTask, taskList, timeByTask } = report

    const taskOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-2">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Task</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{taskTotal}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{staffActive}</span></h4>
                                Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Finish Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                                Total
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{timeTotal} min</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {groupByTask && <GraphDonutBasic className="mb-3" input={groupByTask} gHeight={taskTotal>5 ? 400 : 300} header="Total Work By Task" centerText="Total Work" />}
            {timeByTask && <GraphDonutBasic className="mb-3" input={timeByTask} header="Total Time By Task" centerText="Total Minute" />}
            {taskList && <ReportTaskList className="mt-4" data={taskList} linkPrefix='/report/shared/task' filter={filter} style={{workVisible: ['finish', 'jv']}} orderList={taskOrderList} />}
        </React.Fragment>
     );
}
 
export default ReportSharedHistory;