import React from 'react';
import GraphDonutBasic from '../../../common/graph/basicDonut';
import ReportTeamList from './teamList';

const ReportTeamSummaryDetail = ({report, filter}) => {

    const { teamTotal=0, teamActive=0, workFinish=0, staffTotal=0, staffActive=0, timeTotal=0, jvTotal=0 } = report

    const { countByTeam, teamList=[], timeByTeam } = report

    const taskOrderList= []
    if(teamList.some(x=> x.workFinish>0)) {
        taskOrderList.push({name: 'Work Finish', value: 'workFinish'})
        taskOrderList.push({name: 'Work Time', value: 'timeTotal'})
    }
    if(teamList.some(x=> x.jvTotal>0)) {
        taskOrderList.push({name: 'Job Value', value: 'jvTotal'})
    }

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-3">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Team</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{teamTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{teamActive}</span></h4>
                                Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{staffActive}</span></h4>
                                Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workFinish}</span></h4>
                                Finish
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{timeTotal} min</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jvTotal>0 && 
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Job Value</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{jvTotal.toLocaleString()} &#3647;</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="row">
            {countByTeam && <GraphDonutBasic className="col-12 col-xl-6 mb-3" input={countByTeam} header="Work Number By Team" centerText="Total Work" />}
            {timeByTeam && <GraphDonutBasic className="col-12 col-xl-6 mb-3" input={timeByTeam} header="Work Time By Team" centerText="Total Minute" />}
            </div>
            
            {teamList && <ReportTeamList className="mt-4" data={teamList} 
                linkPrefix='/report/team' filter={filter} style={{workVisible: ['finish', 'jv']}} orderList={taskOrderList} />}
        </React.Fragment>
     );
}
 
export default ReportTeamSummaryDetail;