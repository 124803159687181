import React, { useEffect} from 'react';
import FontAwesome from 'react-fontawesome';
import PersonPhoto from './personPhoto';
import PersonInfo from './personInfo';
// import { NavLink } from 'react-router-dom';
import { getEmployeeInfo } from '../../../services/hrms/employee';
import { useState } from 'react';
import "./card.css"

const PersonCardMini = ({data, onClick, styles={ showBorder: true, photoSize: 50, showInfo: ['name', 'company'], nameSize: 16, infoSize: 12, marginLeft: 10, personBorder:'none'}}) => {
  
  const { showBorder=true, photoSize=50, showInfo=['name', 'company'], nameSize=16, infoSize=12, marginLeft=10 ,personBorder='none'} = styles

  const [userInfo, setUserInfo] = useState(data)
  const { userId, type='person', userName, company, sim , position, department } = userInfo;
  
  useEffect(() => {
      const { userId } = data
      let _isMounted = true
      const loadUserInfo = async() => {
          try {
            // console.log('load user info')
            const userInfo = await getEmployeeInfo(userId)
            if(_isMounted) setUserInfo(userInfo)
          } catch(err) {}
      }

    if (userId && !userInfo.userName && showInfo.length>0) { loadUserInfo() }

    return () => { _isMounted = false }
    
  },[data, userInfo, showInfo])

  const searchBoxStyle = (showBorder) ? { borderRadius: 10, border: '1px solid #BBBBBB66', padding: 2 } : {}
 
  // console.log('[PersonCardMini] userInfo',userInfo)
  return ( 
    <React.Fragment>
      
      <div className="row no-gutters align-items-center" style={searchBoxStyle}>
        <div className="col-auto p-0 ml-1" >

          {type === 'person' && <div className="personTooltip ">
            <PersonPhoto id={userId} size={photoSize} className="rounded-circle" border={personBorder} />
            {showInfo.length > 0 && showInfo.includes('photoInfo') && <div className="personTooltip-content"><PersonInfo data={userInfo} /></div>}               
          </div>}
       
          {type === 'group' && <div className="col-auto text-center mb-2 p-1 text-center"><div style={{ width: photoSize }}><FontAwesome name="users" size="3x" /></div></div>}
        </div>
        
        {showInfo.length > 0 && 
          <div className="col-auto p-0 mr-2 text-left" style={{ marginLeft: marginLeft }}>
          {showInfo.includes('name') && <strong className="card-title d-inline mr-2" style={{ fontSize: nameSize }}>{userName}</strong>}
          {showInfo.includes('company') && company &&  <span className="d-block" style={{fontSize: infoSize}}>{company}</span>}
          {showInfo.includes('department') && department && <span className="d-block" style={{fontSize: infoSize}}>{department.substring(0, 30)}</span>}
          {showInfo.includes('position') && position && <span className="d-block" style={{fontSize: infoSize}}>{position.substring(0, 30)}</span>}
          {showInfo.includes("sim") && sim && <span className="d-block" style={{fontSize: infoSize}}>{sim}</span>}
          </div>}
           
      </div>
    </React.Fragment>
  );
    
}
 
export default PersonCardMini;

