import React, {useState, useEffect} from 'react';

import DialogZoomListSelector from '../../../common/dialogZoomRoom/listSelector/index';
import BadgeButton from '../../../common/badgeButton';
import dlv from 'dlv'
import NumericInput from 'react-numeric-input';

const TaskTemplateZoom = ({options, setOptions}) => {

    const [min, setMin] = useState(dlv(options, 'zoom.min', 0))
    const [auto, setAuto] = useState(dlv(options, 'zoom.auto', false))
    const [manual, setManual] = useState(dlv(options, 'zoom.manual', false))
    const [presetRoom, setPresetRoom] = useState(dlv(options, 'zoom.room', []))

    useEffect(() => {

        if(setOptions instanceof Function) {
            setOptions({ zoom: { min, auto, manual, room: presetRoom}})
        }

    }, [min, auto, manual, presetRoom, setOptions])

    const headerSpace = 180

    return ( <React.Fragment>
        <div className="row align-items-top mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Min Time (Minute)</h6>
            </div>
            <div className="col-auto">
                <NumericInput size="100px" min={0} max={60} step={10} value={min} onChange={(value,str)=> (str==='') ? setMin(0) : setMin(value)} />
            </div>
        </div>
        <div className="row align-items-center mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Enable</h6>
            </div>
            <div className="col-auto">
                <input type="checkbox" name="auto" checked={auto} onChange={e => { setAuto(e.target.checked)}} />
                <span className="ml-1">Auto Room</span>
                <input className="ml-4" type="checkbox" name="manual" checked={manual} onChange={e => { setManual(e.target.checked)}} />
                <span className="ml-1">Manual Room</span>
            </div>
        </div>
        <div className="row align-items-top mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Preset Room</h6>
            </div>
            <div className="col-auto">
                {presetRoom && presetRoom.length>0 && 
                    <div>
                        {presetRoom.map(item => {
                        const { roomId } = item
                        return (
                            <BadgeButton key={roomId} item={item}
                            itemClass="btn btn-sm btn-light"
                            buttonClass="btn btn-sm btn-outline-light"
                            buttonIcon="times"
                            onButtonClick={item => setPresetRoom(prev => prev.filter(x=> x.roomId!==item.roomId))} /> )
                        })}
                    </div>
                    }
                <DialogZoomListSelector 
                    header="Add Zoom Room" 
                    buttonAdd="Add" 
                    icon="video-camera"
                    buttonStyle={'btn-sm btn-dark'}
                    selectList={presetRoom}
                    notifySelect={item => {
                        setPresetRoom(prev => [...prev, item])
                    } } />
                
            </div>
        </div>
    </React.Fragment> );
}
 
export default TaskTemplateZoom;