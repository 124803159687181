import React, { useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';

import Loader from 'react-loader-spinner'

const ButtonDownload = ({className='btn btn-secondary', label, onClick=()=>{}, enable=true}) => {
  const [isDownload, setIsDownload] = useState(false)

  const downloadReport = async () => {
    try {
      if(isDownload) return

      setIsDownload(true)
      await onClick()
      setIsDownload(false)
      
    } catch(err) {
      setIsDownload(false)
      toast.error("Error " + err.message)
    }
  }

  return ( 
    <React.Fragment>
        <button type="button" className={className} onClick={() => downloadReport()} disabled={!enable}>
          <FontAwesome name="download" /> {label} 
          {isDownload &&<Loader
            className="d-inline ml-2"
            type="Oval"
            color="#00BFFF"
            height={20}
            width={20}
          />}
          </button>
      </React.Fragment>
   );
}
 
export default ButtonDownload;