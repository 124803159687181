import React, {useState, useEffect} from 'react';

import dlv from 'dlv'
import FontAwesome from "react-fontawesome";
// import ToggleButton from 'react-toggle-button'
import DialogSearchListIndex from '../../../common/dialogSearchList/index';
import { Collapse } from "react-bootstrap";

const ProgressStage = ({no='', isUnstage=false, stage={}, setStage=()=>{}, taskList=[], askRemoveStage=()=>{}, percentage }) => {

    const [show, setShow] = useState(true)
    const [error, setError] = useState(undefined)

    const [name, setName] = useState(dlv(stage, 'name', ''))
    const [tasks, setTasks] = useState(dlv(stage, 'tasks', []))

    useEffect(() => {
        if(!name) return setError('Name is required')
        if(tasks.length===0) return setError('Task is empty')

        setError(undefined)
        setStage({name, tasks, error: undefined })

        // eslint-disable-next-line
    }, [name, tasks]);

    useEffect(() => {
        if(error) setStage({error})
        // eslint-disable-next-line
    }, [error]);


    const handleRemoveTask = (index) => {

        setTasks(prev => {
            if(index<prev.length) {
                const update = [...prev]
                update.splice(index, 1)
                return update
            }
        })
    }

    const headerWidth = 160
    return ( 
    <React.Fragment>
        <div className="card mb-2">
            <div className="card-header">
                <div className="row justify-content-end">
                    <div className="col-auto"><h5 className="d-inline">{isUnstage ? 'Unstage' : `Stage ${no}`}</h5></div>
                    <div className="col-auto mr-auto"><h5 className="d-inline text-primary">{name}</h5></div>
                    {percentage && <div className="col-auto p-0 mr-3 text-primary font-weight-bold">
                        {percentage}%
                    </div>}
                    {error && <div className="col-auto p-0 mr-3" data-toggle="tooltip" data-placement="bottom" title={error}>
                        <FontAwesome className='text-danger' style={{cursor: 'pointer'}} name='exclamation-circle' size="lg" onClick={()=>setShow(true)} />
                    </div>}
                    <div className="col-auto p-0">
                        <FontAwesome style={{cursor: 'pointer'}} name={show ? 'compress' : 'expand'} size="lg" onClick={() => setShow(prev => !prev)} />
                    </div>
                    <div className="col-auto">
                        <FontAwesome style={{cursor: 'pointer'}} name="trash" size="lg" onClick={askRemoveStage} />
                    </div>
                </div>
                
            </div>
            <Collapse in={show}>
                <div className="card-body">
                    <div className="row align-items-start mb-2">
                        <div className="col-auto" style={{width: headerWidth}}>
                            <h6 className='d-inline'>Name</h6>
                        </div>
                        <div className="col-auto">
                            <input className="form-control" value={name} onChange={e => setName(e.target.value) } type="text" />
                        </div>
                    </div>
                    {tasks.map( (item, index, array) => {
                        const { _id } = item
                        const findTask = taskList.find(x=> x._id===_id)

                        const invalid = !findTask || !findTask.enable

                        return (<div key={_id} className="row">
                            <div className="col">
                                <div className="card mb-2">
                                    <div className="card-body p-2">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                        <span className="mx-2"><FontAwesome name="file-code-o" size="lg" /></span>
                                        <span className="font-weight-bold">{findTask ? findTask.name : 'Name Not Found'}</span>
                                        {invalid && <span className="badge badge-danger ml-2"><FontAwesome name="explamation-triangle" /> INVALID (Task not found or not enable)</span>}
                                        </div>
                                        <div className="col-auto ml-auto mr-2">
                                        <div className="row no-gutters">
                                            <div className="col-auto mr-3 font-weight-bold">
                                                {Math.floor(percentage/array.length)}%
                                            </div>
                                            <div className="col-auto">
                                                <FontAwesome style={{cursor: 'pointer'}} name="trash" onClick={() => handleRemoveTask(index)} size="lg"  />
                                            </div>
                                        </div>
                                        </div>
                                        
                                    </div>

                                </div>
                                </div>
                            </div>
                        </div>)
                    })}
                    {tasks.length===0 && 
                    <div className="alert alert-secondary mt-4" role="alert">Task is empty / กดปุ่ม Add เพื่อเพิ่มใบงาน</div>
                    }
                    <div className="row align-items-center justify-content-end mt-3">
                        <div className="col-auto">
                            <DialogSearchListIndex header="Add Task" buttonAdd="Add Task" 
                                icon="file-code-o"
                                list={taskList} 
                                notifySelect={item => { 
                                    setTasks(prev => [...prev, {_id: item._id }])
                                }}
                                valueProperty="_id"
                                buttonStyle="btn btn-sm btn-secondary"
                            />
                        </div>
                    </div>
                    {error && 
                    <div className="alert alert-danger mt-4" role="alert">{error}</div>
                    }
                </div>
            </Collapse>
        </div>
        
    </React.Fragment> );
}
 
export default ProgressStage;