import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
import PersonCardMini from '../../../common/card/personCardMini';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
// import dlv from 'dlv'
// import { formatDateTime } from '../../../../utils/date';

const MeTaskCard = ({data, showOwner=true, styles}) => {

  const { _id, name, taskName, description, status, userId, count={}, jobValue, reportField=[], date } = data;
  const { period, trackTime  } = date

  const workTime = getWorkHourFromTotalMinutes(trackTime)
  const statusColor = (status==='finish') ? 'success' : (status==='wait') ? 'info' : (status==='active') ? 'warning' : 'secondary'

  const classTemplate = (count.template>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'
  const classMessage = (count.text>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'
  const classPhoto = (count.photo>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'
  const classZoom = (count.zoom>0) ? 'col-auto text-center' : 'col-auto text-center text-muted'

  return ( 
    <React.Fragment>
      <div className="card mb-2 text-dark p-0">
        <div className="card-body p-0">
        <NavLink className="stretched-link" to={`/me/task/${_id}`} ></NavLink>
        <div className="row no-gutters align-items-center justify-content-end">
          <div className={`d-none d-md-inline col-auto bg-${statusColor}`} style={{width: 10, height: 78}}></div>
          <div className="col-auto ml-2 mr-auto">
            <h5 className='m-0'>{name || taskName} 
              {status!=='delete' && <span className={`ml-2 text-${statusColor}`}><FontAwesome name="clock-o" /> {workTime}</span>}
              <span className={`ml-2 badge badge-${statusColor}`}>{status.toUpperCase()}</span>
            </h5>
            {!showOwner && <span className="text-muted">{description}</span>}
            {userId && showOwner && <React.Fragment>
              <PersonCardMini data={{userId}} styles={{...styles, photoSize: 40,showBorder: false}} />
          </React.Fragment>}
          </div>
          {reportField.length>0 && <React.Fragment>
            <div className="col-auto">
              <div className="row no-gutters">
                 {reportField.map((item,index) => {
                   const { name, value } = item;
                   return (<div key={index} className='col-auto pt-2 mr-3 text-center'>
                     <h6 className="m-0 p-0 text-primary font-weight-bold">{value}</h6>
                      {name.substring(0, 10)}
                   </div>)
                 })}
              </div>
            </div>
          </React.Fragment>}
          <div className="col-auto mr-2 p-1">
            <div className="row no-gutters">
              <div className='col-auto mr-4 text-center pt-2'>
                <h6 className="m-0 p-0 text-primary font-weight-bold">{jobValue>0 ? <span className="m-0 p-0">{jobValue.toLocaleString()}&#3647;</span> : <span>-</span>}</h6>
                <span className="font-weight-bold">JV</span>
              </div>
              <div className={classTemplate}>
                <div className="notify-item">
                    {count.template>0 && <span className="notify-badge">{count.template}</span>}
                    <FontAwesome name="file-code-o" size="2x" />
                </div>
                </div>
                <div className={classMessage}>
                <div className="notify-item">
                    {count.text>0 && <span className="notify-badge">{count.text}</span>}
                    <FontAwesome name="commenting-o" size="2x" />
                </div>
                </div>
                <div className={classPhoto}>
                    <div className="notify-item">
                        {count.photo>0 && <span className="notify-badge">{count.photo}</span>}
                        <FontAwesome name="picture-o" size="2x" />
                    </div>
                </div>
                <div className={classZoom}>
                    <div className="notify-item">
                        {count.zoom>0 && <span className="notify-badge">{count.zoom}</span>}
                        <FontAwesome name="video-camera" size="2x" />
                    </div>
                </div>
            </div>
          </div>
        </div>
        </div>
        <div className="card-footer text-right small p-1">
            <div className="row justify-content-end col-auto px-0">
            <div><FontAwesome name="clock-o" /> <span className="text-info font-weight-bold">{period}</span></div>
            </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default MeTaskCard;