import React, {useState} from 'react';

const FormApprove = ({notifySelect, data={}, enable=true}) => {

    const [approve, setApprove] = useState(data.approve || '')
    const [comment, setComment] = useState(data.comment || '')

    const handleApprove = (item) => {
        if(!enable) return

        setApprove(item)
        if(typeof(notifySelect)==='function') {
            notifySelect({approve: item, comment})
        }
    }

    const handleComment = (value) => {
        if(!enable) return

        setComment(value)
        if(typeof(notifySelect)==='function') {
            notifySelect({approve, comment})
        }
    }

    return (
        <div className="form-group">
            <label>{'โปรดพิจารณาอนุมัติ'}<span className='text-danger font-weight-bold'>*</span></label>
            <ul className="list-group list-group-horizontal">
                <li key={0} style={{cursor: 'pointer'}}
                    onClick={() => handleApprove(1)}
                    className={ (approve===1) ? "list-group-item active py-2" : "list-group-item py-2" }
                >Approve</li>
                <li key={1} style={{cursor: 'pointer'}}
                    onClick={() => handleApprove(0)}
                    className={ (approve===0) ? "list-group-item active py-2" : "list-group-item py-2" }
                >Disapprove</li>
            </ul>
            <label className="mt-2">{'ความเห็นเพิ่มเติม'}</label>
            <textarea className="form-control" value={comment} disabled={!enable} onChange={e => handleComment(e.target.value)} row={3} style={{maxWidth: 500, maxHeight: 100}} />
        </div> );
}
 
export default FormApprove;