import http from "../../httpService";

const apiEndpoint = "/manage/data/field";

export function getDataFieldList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getDataField(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveDataField(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deleteDataField(id) {
  return http.delete(apiEndpoint + "/" + id)
}