import React from 'react';
import Chart from 'react-apexcharts'

const CaptureDailyStaffTaskGroup = ({className='mt-2', input}) => {

    if(!input || !input.labels || !input.data) return (<div></div>)

    const { labels, data } = input;

    const optionsTag = {
        labels: labels,
        
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                  },
                  value: {
                  }
                }
              }
            }
          }
      }

    const seriesTag = data

    const showTag = (seriesTag.find(x=>x>0)) ? true : false;

    return ( 
        <div className={className}>
            <div className="row">
                <div className="col">
                    <div className="card" style={{width: '100%'}}>
                        <div className="card-body text-center">
                            {showTag && <Chart width={700} height={300} options={optionsTag} series={seriesTag} type="donut" />}
                            {!showTag && <span className="text-muted">ไม่มีข้อมูล</span>}
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        
    );

}
 
export default CaptureDailyStaffTaskGroup;