import http from "../httpService";

const apiEndpoint = "/report/shared";

export function getReportSharedSummary(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''
  
  return http.get(apiEndpoint + '/summary' + ((query) ? '/?' + query : ''))
}


export function getReportSharedPerTask(id, input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + '/task/' + id + ((query) ? '/?' + query : ''))
}


