import React, {useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';

import { DomainContext } from '../../../context/domainContext';
import PermissionCard from './permissionCard';
import { getPermissions, addPermission, deletePerson } from '../../../services/domain/domainService';
import { PermissionContext } from '../../../context/permissionContext';

const Permissions = ({history, domainId: initDomainId}) => {

    const {permission, } = useContext(PermissionContext)
    const { domain } = useContext(DomainContext)
    const [domainId, setDomainId] = useState(initDomainId || (domain ? domain._id : undefined))
    const [refresh, askRefresh] = useState(Date.now())

    const isAdmin = permission && Array.isArray(permission) && permission.includes('admin')

    useEffect(() => {
        let isSubscribed = true
        if(domainId) {
            getPermissions({domainId}).then(({data: result}) => {
                if(isSubscribed) {
                    setList(result.data.list)
                }
            })
        }

        return () => isSubscribed = false
    },[domainId, refresh])

    useEffect(() => {

        setDomainId(initDomainId || ((domain) ? domain._id : undefined))

        if(domain && !isAdmin && !domain.isOwner && history && history.replace instanceof Function) {
            history.replace({ pathname: '/dashboard' })
        }

    }, [initDomainId, domain, history, isAdmin])

    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState('')

    const confirmSelectPerson = async(item) => {
      try {
        if(domainId) {
            await addPermission({domainId, userId: item.userId, userName: item.userName})
            askRefresh(Date.now())
        }
      } catch(err) { }
    }

    const confirmDelete = async() => {
        try {
            await deletePerson({domainId, userId: askDelete});

            setAskDelete('')
            askRefresh(Date.now())

        } catch(err) { toast.error('Failed to delete item')}
    }

    if(!domainId) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col"><h4>Domain Permissions</h4></div>
                <div className="col-auto">
                  <DialogSearchPerson cache={false} notifySelect={item => confirmSelectPerson(item) } buttonText="Add" />
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {list && list.map( (item, index) => {
                return (
                <div key={item.userId || item._id || index} className="col-12">
                  <PermissionCard domainId={domainId} data={item} enableToggle={true} 
                    onRemove={({id}) => setAskDelete(id)} isAdmin={isAdmin} isOwner={(domain && domain.isOwner)} />
                </div>
                )
            })}
            </div>
            <p className="mt-4"><b className='text-danger'>Owner</b> = All Resource Access included permission modification / เจ้าของโดเมน สามารถแก้ไขข้อมูลทุกอย่าง รวมถึงแก้ไขตารางสิทธิ<br />
            การแก้สิทธิ Owner ดำเนินการโดยขออนุมัติผ่าน IT Manager เท่านั้น</p>
            <p><b>Report</b> = Allow Report Viewer Only / สามารถดูรายงานได้เท่านั้น</p>
            <p><b>Admin</b> = Allow all resource managed / สามารถแก้ไขข้อมูลต่าง ๆ ได้</p>
        </React.Fragment>
     );
}
 
export default Permissions;