import http from "../httpService";

const apiEndpoint = "/domain";

export function domainListCount(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + "/count" + ((query) ? '/?' + query : ''))
}

export function domainList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getDomain(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveDomain(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deleteDomain(id) {
  return http.delete(apiEndpoint + "/" + id)
}

export function getPermissions({domainId}) {
  return http.get(apiEndpoint + '/' + domainId + '/permission')
}

export function addPermission({domainId, userId, userName, permissionName}) {
  return http.put(apiEndpoint + '/' + domainId + '/permission/add', { permissionId:userId, perUserName: userName, permissionName })
}

export function removePermission({domainId, userId, permissionName}) {
  return http.put(apiEndpoint + '/' + domainId + '/permission/remove', { permissionId: userId, permissionName })
}

export function enablePerson({domainId, userId }) {
  return http.put(apiEndpoint + '/' + domainId + '/permission/enable', { permissionId: userId })
}

export function disablePerson({domainId, userId }) {
  return http.put(apiEndpoint + '/' + domainId + '/permission/disable', { permissionId: userId })
}

export function deletePerson({domainId, userId }) {
  return http.delete(apiEndpoint + '/' + domainId + '/permission/' + userId )
}