import React from 'react';

const DynamicView = ({work}) => {

    console.log('dynamicview', work)

    return ( <React.Fragment>
        <div className="row">
            {work && work.template && work.template.forms && work.template.forms.map( (form, index) => {
                const { formType, name, input } = form;
                const { value: type} = formType

                return (
                <div key={index} className="col-12">
                    {type==='approve' && input && 
                        <React.Fragment>
                            {input.approve===1 && <h5 className="d-inline"><span className="badge badge-info">อนุมัติ</span></h5>}
                            {input.approve===0 && <h5 className="d-inline"><span className="badge badge-danger">ไม่อนุมัติ</span></h5>}
                            {input.comment && <span>ความเห็นเพิ่มเติม <span className="font-weight-bold">{input.comment}</span></span>}
                        </React.Fragment>}
                    {/* {type==='list' && <div>
                            <SelectButton
                                name={_id}
                                value={formData[_id] || ''}
                                label={name}
                                options={list}
                                valueProperty="id"
                                onSelect={({item}) => updateFormList(_id, item)}
                                error={''}
                            />
                        </div>
                    } */}

                    {type==='list' && input && input.length>0 &&
                    input.map((x,i)=> {
                        const { label='item', name} = x

                        return (<div key={i}><h6 className="d-inline">{label} <span className="badge badge-info">{name}</span></h6></div>)


                    })}
                    {type==='text' && <h6 className="d-inline">{name} <span className="badge badge-info">{input.text}</span></h6>}
                    
                </div>
                )
            })}
        </div>
    </React.Fragment> );
}
 
export default DynamicView;