import React from 'react';
import ReportTeamList from './dataList';

const ReportDataSummaryDetail = ({report, filter}) => {

    const { dataTotal=0, dataActive=0, workTotal=0, staffTotal=0 } = report

    const { dataList=[] } = report

    const taskOrderList= []
    // if(dataList.some(x=> x.workTotal>0)) {
    //     taskOrderList.push({name: 'Work Total', value: 'workTotal'})
    // }

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-3">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Data</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-light">{dataTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{dataActive}</span></h4>
                                Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{workTotal}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                                Total
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {dataList && <ReportTeamList data={dataList}
             linkPrefix='/report/team' filter={filter} style={{workVisible: ['finish', 'jv']}} orderList={taskOrderList} />}
        </React.Fragment>
     );
}
 
export default ReportDataSummaryDetail;