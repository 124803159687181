import React from 'react';
import FontAwesome from "react-fontawesome";
import ToggleButton from 'react-toggle-button'
import DialogSearchListIndex from '../../common/dialogSearchList/index';

const ProjectManageTaskList = ({tasks=[], setTasks=()=>{}, taskList=[]}) => {


    return ( 
        <React.Fragment>
            <div className="row align-items-start mb-2">
            <div className="col-auto" style={{width: 180}}>
                <h5 className="font-weight-bold">Task List</h5>
            </div>
            </div>
            {tasks.length> 0 && tasks.map( (item, index) => {
                const { _id, enable } = item
                const findTask = taskList.find(x=> x._id===_id)

                const invalid = !findTask || !findTask.enable

                return (<div key={_id} className="row">
                    <div className="col">
                        <div className="card mb-2">
                            <div className="card-body p-2">
                            <div className="row justify-content-start">
                                <div className="col-auto">
                                <span className="mx-2"><FontAwesome name="file-code-o" size="lg" /></span>
                                <span className="font-weight-bold">{findTask ? findTask.name : 'Name Not Found'}</span>
                                {invalid && <span className="badge badge-danger ml-2"><FontAwesome name="explamation-triangle" /> INVALID (Task not found or not enable)</span>}
                                </div>
                                <div className="col-auto ml-auto mr-2">
                                <div className="row no-gutters">
                                    <div className="col-auto mr-2">
                                    <ToggleButton value={enable} onToggle={value => {
                                        setTasks(prev => {

                                        const update = prev.map(x=> {
                                            if(x._id===_id){ x.enable = !value}
                                            return x
                                        } );
                                        return update
                                        })
                                    }} />
                                    </div>
                                    <div className="col-auto">
                                    <FontAwesome style={{cursor: 'pointer'}} name="trash" onClick={() => {
                                        setTasks(prev => {
                                            const update = prev.filter(x=> x._id!==_id);
                                            return update
                                        })
                                    } } size="lg" />
                                    </div>
                                </div>
                                </div>
                                
                            </div>

                        </div>
                        </div>
                    </div>
                </div>)
            })}
            {tasks.length===0 && 
            <div className="alert alert-warning" role="alert">
            Task is empty / กดปุ่ม Add เพื่อเพิ่มใบงาน
            </div>
            }
            <div className="row align-items-center justify-content-end mt-3">
            <div className="col-auto">
                <DialogSearchListIndex header="Add Task" buttonAdd="Add Task" 
                    icon="file-code-o"
                    list={taskList.filter(x=> !(tasks.map(x=> x._id).includes(x._id)) )} 
                    notifySelect={item => { 
                    setTasks(prev => [...prev, {_id: item._id, enable: true}])
                        
                    } }
                    valueProperty="_id"
                    buttonStyle="btn btn-primary"
                />
            </div>
            </div>
        </React.Fragment>
     );
}
 
export default ProjectManageTaskList;