import React, {useState, useEffect} from 'react';
import { getReportTaskItem } from '../../../../services/report/reportTask';
import { toast } from 'react-toastify';
import FilterDateCombo from '../../../common/filter/filterDateCombo';
import moment from 'moment'
import queryString from 'query-string'
import FontAwesome from 'react-fontawesome';
import ReportPerTaskToday from './perTaskToday';
// import ReportPerTaskHistory from './perTaskHistory';
import jsfiledownload from 'js-file-download'
import { downloadTask, downloadTaskTeam } from '../../../../services/download/dashboard';
import ButtonDownload from './../../../common/button/buttonDownload';

const ReportPerTask = ({match, location}) => {

    const [id,] = useState(match.params.id)
    const [filter, setDateFilter] = useState({filter: queryString.parse(location.search).filter || 'today', date: queryString.parse(location.search).date || moment().format('YYYY-MM-DD')})

    const [downloadMode, setDownloadMode] = useState('normal')

    const [report, setReport] = useState({})

    const { name, description } = report

    const loadReport = async(id, input) => {
        if(!input || !id) return

        try {
            const { data: result } = await getReportTaskItem(id, input);

            setReport(result.data.report)

        } catch(err) {
            toast.error('Failed to load work data')
        }
    }

    // useEffect(() => {
    //     const query = queryString.parse(location.search)
    //     if(query.filter && query.date) setDateFilter({filter: query.filter, date: query.date})

    // }, [location])

    useEffect(() => {
        if(id) {
            loadReport(id, {...filter})
        }
        
    }, [id, filter])

    const handleReportDownload = () => {
        return new Promise(async (resolve, reject) => {
          try {
    
            if(downloadMode==='normal') {
                const input = { date: filter.date, taskDetail: true }
    
                input.templateId = id
                if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
                if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
                
                const {data: result, headers } = await downloadTask(input)
        
                if( result.status==='error') {
                    toast.error(result.message)
                } else {
                    if ( headers['x-suggested-filename']==='nodata') {
                        toast.error("No Report")
                    } else {
                        jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
                    }
                }
        
                setTimeout(() => {resolve('ok')}, 300);
            } else if(downloadMode==='team') {
                const input = { date: filter.date, taskDetail: true }
    
                input.templateId = id
                if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
                if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
                
                const {data: result, headers } = await downloadTaskTeam(input)
        
                if( result.status==='error') {
                    toast.error(result.message)
                } else {
                    if ( headers['x-suggested-filename']==='nodata') {
                        toast.error("No Report")
                    } else {
                        jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
                    }
                }
        
                setTimeout(() => {resolve('ok')}, 300);
            } else {
                toast.error("Invalid Download Mode")
            }
            
              
          } catch(err) { 
              toast.error("Error : ", err.message)
              resolve('error')
          }
        })
    
    }

    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row justify-content-end">
            <div className="col-auto mr-auto mb-3">
                
            </div>
            <div className="col-auto p-0">
                {/* <h5 className="text-danger d-inline">ปิดใช้งานปุ่ม Download ชั่วคราว</h5> */}
                <ButtonDownload label='Download' onClick={handleReportDownload} />
            </div>
            <div className="col-auto ml-3 mb-3">
                <FilterDateCombo enableFilter={['today', 'day', 'week', 'month', 'customoff']}  notifyDateFilter={filter => setDateFilter(filter)} init={{...filter}} />
            </div>
        </div>
        
        {/* <div className="row"><div className="col text-right"><span className="text-danger">(ปิดการใช้ Weekly, Monthly ชั่วคราว)</span></div></div> */}
        <div className="row no-gutters align-items-center justify-content-left mt-3">
            <div className="col-auto ml-2"><FontAwesome name='file-code-o' size="2x" /></div>
            <div className="col-auto ml-4"><h3 className="d-inline">{name}</h3></div>
        </div>
        <h5 className="ml-4 mt-2 text-muted">{description}</h5>
        {/* {filter.filter==='today' && <ReportPerTaskToday report={report} filter={filter} />} */}
        {['today', 'day', 'week', 'month'].includes(filter.filter) && <ReportPerTaskToday setDownloadMode={setDownloadMode} report={report} filter={filter} />}
        
    </React.Fragment> );
}
 
export default ReportPerTask;