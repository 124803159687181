import React, { useState } from 'react';
import DialogSearchItem from './dialog';

const DialogZoomListSelector = ({header, buttonAdd, selectList=[], notifySelect, buttonStyle='btn-primary', autoSelect=true }) => {

  const [enable, setEnable] = useState(false)

  const confirmSelectItem = (item) => {
    setEnable(false)

    if ( notifySelect instanceof Function) {
        notifySelect(item)
    }
  }

  return ( 
  <React.Fragment>
    <DialogSearchItem header={header} selectList={selectList} isOpen={enable} onCancel={() => setEnable(false)} onConfirm={confirmSelectItem} autoSelect={autoSelect} />
    <button className={`btn ${buttonStyle}`} onClick={() => setEnable(true)}>{buttonAdd}</button>
  </React.Fragment> );
    
}
 
export default DialogZoomListSelector;