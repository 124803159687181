import http from "../../httpService";

const apiEndpoint = "/manage/staff/team";

export function personList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getPerson(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function savePerson(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deletePerson(id) {
  return http.delete(apiEndpoint + "/" + id)
}