import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { DomainContext } from './context/domainContext'
import { PermissionContext } from './context/permissionContext';
import { SessionContext } from './context/sessionContext';

import auth from "./services/app/authService";

import NotFound from "./components/common/notFound";
import LoginForm from "./components/session/loginForm";
import Logout from "./components/session/logout";
import ProtectedRoute from "./components/common/protectedRoute";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Search from './components/search/search';

//ME
import MyWork from './components/me/task/index';
import TaskWorking from './components/me/task/working';
import MyFlows from './components/me/flow/index';
import FlowWorking from './components/me/flow/flowWorking';


//CAPTURE
import CaptureTask from './components/capture/task/index';
import CaptureReportDailyStaff from './components/capture/daily/staff/dailyStaff';

//REPORT
import Dashboard from './components/report/dashboard/index';
import ProjectReport from "./components/report/project/summary/index";
import ReportPerProject from './components/report/project/perProject/index';
import ReportTask from "./components/report/task/summary";
import ReportStaff from './components/report/staff/summary/index';
import ReportPerTask from "./components/report/task/perTask/index";
import ReportSharedSummary from './components/report/shared/summary/index';
import ReportSharedTask from './components/report/shared/task/index';
import ReportTeamSummary from './components/report/team/summary/index';
import ReportPerTeam from './components/report/team/perTeam/index';
import ReportPerData from './components/report/data/perData/index';
import ReportDataSummary from './components/report/data/summary/index';

import ProgressionReport from './components/report/progression/summary/index';

import ReportWork from './components/report/work/reportWork';
import ReportStaffIndex from './components/report/staff/perStaff/index';

import ReportDomainSummary from './components/report/domain/summary/index';

//RECORD
import RecordFlows from './components/record/flow';
import RecordFlowDetail from './components/record/flow/detail';
import RecordTasks from './components/record/task';
import PlanIndex from "./components/plan/planIndex";

//MANAGE
import DataManage from './components/manage/data/dataManage';
import Data from './components/manage/data/index';
import ProjectList from './components/manage/project/project';
import ProjectManage from './components/manage/project/projectManage';
import TemplateTasks from './components/manage/task/tasks';
import TemplateForm from './components/manage/task/taskManage';
import ManagePlanList from './components/manage/plan/managePlanList';
import ManagePlanEdit from './components/manage/plan/managePlanEdit';
import TemplateFlows from "./components/manage/flow/flows";
import TemplateFlowEdit from './components/manage/flow/flowEdit';
import Staff from './components/manage/staff/staffs';
import TeamManage from './components/manage/staff/teamManage';
import LineRoom from './components/manage/line/index';
import ZoomRooms from './components/manage/zoom/rooms';
import Permissions from './components/manage/permission/permissions';

//ADMIN
import Domains from './components/domain/domains';
import DomainManage from './components/domain/domainManage';
import OCIndex from './components/oc';

import ProjectGroupManage from './components/manage/project/group/projectGroupManage';
import ManageDataField from './components/manage/data/editField';
import AdminSetting from './components/admin/setting';
import ReportPerProgress from './components/report/progression/perProgress/index';
import AppPrivacy from "./components/common/privacy";
import MeTaskCreate from './components/me/task/create/index';
import AdminSystem from './components/admin/system';


const GlobalStateProvider = ({ children }) => {
  const [domain, setDomain] = React.useState(auth.getDomain());
  const [permission, setPermission] = React.useState(auth.getPermission())
  const [session, setSession] = React.useState(auth.getCurrentUser())
  const domainValue = React.useMemo(() => ({domain, setDomain}), [domain]);
  const permissionValue = React.useMemo(() => ({permission, setPermission}), [permission])
  const sessionValue = React.useMemo(() => ({session, setSession}), [session])

  return (
    <SessionContext.Provider value={sessionValue}>
    <DomainContext.Provider value={domainValue}>
      <PermissionContext.Provider value={permissionValue}>
        {children}
      </PermissionContext.Provider>
    </DomainContext.Provider>
    </SessionContext.Provider>
  );
};

const App = () => {

  const user = auth.getCurrentUser()

  return (
    <React.Fragment>
      <GlobalStateProvider>
        <ToastContainer />
        <Switch>
          <Route path="/capture/daily/staff/:id" component={CaptureReportDailyStaff} />
          <Route path="/capture/task/:id" component={CaptureTask} />
          
          <ProtectedRoute path="/search" component={Search} />
          <ProtectedRoute path="/oc" component={OCIndex} />

          <ProtectedRoute path="/dashboard" component={Dashboard} />
          <ProtectedRoute path="/report/project/:id" component={ReportPerProject} />
          <ProtectedRoute path="/report/project" component={ProjectReport} />
          <ProtectedRoute path="/report/progression/:id" component={ReportPerProgress} />
          <ProtectedRoute path="/report/progression" component={ProgressionReport} />
          <ProtectedRoute path="/report/task/:id" component={ReportPerTask} />
          <ProtectedRoute path="/report/task" component={ReportTask} />
          <ProtectedRoute path="/report/staff/:id" component={ReportStaffIndex} />
          <ProtectedRoute path="/report/staff" component={ReportStaff} />
          <ProtectedRoute path="/report/shared/task/:id" component={ReportSharedTask} />
          <ProtectedRoute path="/report/shared" component={ReportSharedSummary} />
          <ProtectedRoute path="/report/team/:id" component={ReportPerTeam} />
          <ProtectedRoute path="/report/team" component={ReportTeamSummary} />
          <ProtectedRoute path="/report/data/:id" component={ReportPerData} />
          <ProtectedRoute path="/report/data" component={ReportDataSummary} />

          <ProtectedRoute path="/report/work/:id" render={props => <ReportWork {...props} user={user} />} />

          <ProtectedRoute path="/record/flow/:id" render={props => <RecordFlowDetail {...props} user={user} />} />
          <ProtectedRoute path="/record/flow" component={RecordFlows} />
          <ProtectedRoute path="/record/task/:id" render={props => <ReportWork {...props} user={user} />} />
          <ProtectedRoute path="/record/task" component={RecordTasks} />

          <ProtectedRoute path="/report/admin" component={ReportDomainSummary} requestPermission={['admin']} />

          <ProtectedRoute path="/plan/:id" component={ManagePlanEdit} />
          <ProtectedRoute path="/plan" component={PlanIndex} />
          
          <ProtectedRoute path="/project/group/:id" component={ProjectGroupManage} />
          <ProtectedRoute path="/project/:id" component={ProjectManage} />
          <ProtectedRoute path="/project" component={ProjectList} />

          <ProtectedRoute path="/me/task/new" render={props => <MeTaskCreate {...props} user={user} />} />
          <ProtectedRoute path="/me/task/:id" exact  render={props => <TaskWorking {...props} user={user} />} />
          <ProtectedRoute path="/me/task" exact render={props => <MyWork {...props} user={user} />} />
          <ProtectedRoute path="/me/flow/:id" exact  render={props => <FlowWorking {...props} user={user} />} />
          <ProtectedRoute path="/me/flow" exact render={props => <MyFlows {...props} user={user} />} />
          

          <ProtectedRoute path="/manage/plan/:id" component={ManagePlanEdit} />
          <ProtectedRoute path="/manage/plan" component={ManagePlanList} />
          <ProtectedRoute path="/manage/flow/:id" exact component={TemplateFlowEdit} />
          <ProtectedRoute path="/manage/flow" exact component={TemplateFlows} />
          <ProtectedRoute path="/manage/task/:id" exact component={TemplateForm} />
          <ProtectedRoute path="/manage/task" exact component={TemplateTasks} />
          <ProtectedRoute path="/manage/data/:id" exact render={props => <DataManage {...props} user={user} />} />
          <ProtectedRoute path="/manage/data/field/:id" exact component={ManageDataField} />
          <ProtectedRoute path="/manage/data" exact render={props => <Data {...props} user={user} />} />
          <ProtectedRoute path="/manage/staff" exact render={props => <Staff {...props} user={user} />} />
          <ProtectedRoute path="/manage/team/:id" exact component={TeamManage} />
          <ProtectedRoute path="/manage/line" exact render={props => <LineRoom {...props} user={user} />} />
          <ProtectedRoute path="/manage/zoom" exact component={ZoomRooms} />
          <ProtectedRoute path="/manage/permission" exact render={props => <Permissions {...props} user={user} />} />

          <ProtectedRoute path="/domain/:id" exact component={DomainManage} requestPermission={['admin']} />
          <ProtectedRoute path="/domain" component={Domains} requestPermission={['admin']} />
          <ProtectedRoute path="/admin/setting" component={AdminSetting} requestPermission={['admin']} />
          <ProtectedRoute path="/admin/system" component={AdminSystem} requestPermission={['admin']} />

          <Route path="/login" component={LoginForm} />
          <ProtectedRoute path="/logout" component={Logout} />

          <Route path="/privacy" component={AppPrivacy} />
          <Route path="/privacyupdate" component={AppPrivacy} />
          
          <Route path="/not-found" component={NotFound} />
          <Redirect from="/" exact to="/dashboard" />
          <Redirect to="/" />
        </Switch>
      </GlobalStateProvider>
    </React.Fragment>
  );

}

export default App;
