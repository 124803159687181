import React from 'react';
import MyGoogleMap from './../../common/map/googleMap';

import dlv from 'dlv'

const ReportWorkMap = ({className, location}) => {

    const getPathLengthText = (input) => {

        if(input===0) return '0 meter'

        const kms = Math.floor(input/1000);
        const km_text = (kms>0) ? (kms + ((kms>1) ? ' kms': ' km')) : '';
        
        const meters = Math.floor((input%1000));
        const meter_text = (meters>0) ? (meters + ((meters>1) ? ' meters': ' meter')) : '';
        
        return (((km_text) ? km_text + ' ' : '') + ((meter_text) ? meter_text + ' ' : ''));
    }

    if( !dlv(location, 'enableAddress', false) && dlv(location, 'track',[] ).length===0) return (<div></div>)

    return ( 
    <div className={className}>
        <h5>Location <span className="text-primary">{dlv(location, 'latestAddress', 'Not Found')}</span></h5>
        {dlv(location, 'track',[] ).length>0 && <div>
        <h5>Total Distance <span className="text-primary">{getPathLengthText(dlv(location, 'latestDistance', 0))}</span></h5>
        <MyGoogleMap path={dlv(location, 'track',[]) } reportView={dlv(location, 'trackOptions.reportView')} />
        </div>}
    </div> );
}
 
export default ReportWorkMap;