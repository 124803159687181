import React from 'react';
import GraphBarBasic from './../../../common/graph/basicBar';
import ReportStaffList from './../../staff/staffList';

const ReportSharedTaskHistory = ({report, filter}) => {

    const { workTotal=0, staffTotal=0, staffActive=0, timeTotal=0 } = report
    const { staffList, groupDaily, groupByHour } = report
    const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-3">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{staffActive}</span></h4>
                                Active
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                                Total
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{timeTotal} min</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {groupDaily && <GraphBarBasic className='mb-3' data={groupDaily} header='Finish Daily' yaxisText='Count Finish' />}
            {groupByHour && <GraphBarBasic className='mb-3' data={groupByHour} header='Finish Hourly' yaxisText='Count Finish' />}
            {staffList && <ReportStaffList className="mt-4" data={staffList} filter={filter} 
                orderList={staffOrderList} style={{workVisible: ['finish']}} enableLink={false} />}

        </React.Fragment>
     );
}
 
export default ReportSharedTaskHistory;