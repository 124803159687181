import React, { useState, useRef, useContext } from "react";
import dlv from 'dlv'
// import { toast } from 'react-toastify';
import FontAwesome from 'react-fontawesome';
import PersonPhoto from './../../common/card/personPhoto';
import { ChartContext } from './../../../context/chartStateContext';
import PersonCardMini from "../../common/card/personCardMini";
import { SessionContext } from '../../../context/sessionContext';
import { PermissionContext } from './../../../context/permissionContext';
import { checkAppPermission } from './../../../services/app/permissionService';

const approvalLevel = {
    10: 'TA',
    9: 'กล่องฟ้า',
    8: 'กล่องเหลือง',
    7: 'จุดเขียว',
    6: 'ขีดดำ'
}

const OCNodeMaster = ({ nodeData={}, 
    askSwitchNode=()=>{}, askEditNode=()=>{}, 
    askDeleteNode=()=>{}, notifyChange=()=>{} }) => {

    const {permission: appPermission} = useContext(PermissionContext)
    const isAdmin = checkAppPermission({permission: appPermission, input: 'admin'})

    const {session} = useContext(SessionContext)
    const { chartState, setChartState } = useContext(ChartContext)

    const {id, name, type, enable, person, approval, canMoveLeft=false, canMoveRight=false, permission, parentEditChild=[], userCount  } = nodeData
    
    const isParentEditChild = parentEditChild.includes(dlv(session, 'userId'))
    const canEditNode = permission.some(x=> x.roles.includes('editNode') && x.userId===dlv(session, 'userId'))
    const viewOnly = !isParentEditChild && !canEditNode

    const title = approvalLevel[dlv(approval, 'level')] || ''

    const bgColor = enable ? (type==='root' ? '#2c3a99' : 
        type==='business' ? '#2c3a99' : 
        type==='group' ? '#30c2d9' : 
        type==='division' ? '#d99e30' :
        type==='department' ? '#30d995' :
        type==='section' ? '#d68baa' :
        type==='person' ? '#FFF' :
        '#EEE') : '#EEE'
    const textColor = type==='root' ? '#EEE' : type==='business' ? '#EEE' : '#333'

    const _delay_click = useRef(false)
    const [delayClick, setDelayClick] = useState(false)
    const handleClickNode = () => {

        if(delayClick) {
            clearTimeout(_delay_click.current)
            doubleClick()
        } else {
            setDelayClick(true)
      
            _delay_click.current = setTimeout(async () => {
              singleClick()
            }, 350)
        }
    }

    const singleClick = () => {
        setDelayClick(false)

        setChartState(prev => {
            if(dlv(prev, 'edit')===id) {
                return {...prev, edit: undefined}
            } else {
                return {...prev, edit: id}
            }
        })

        
    }

    const doubleClick = () => {
        setDelayClick(false)

        if(type==='person') {
            askEditNode({...nodeData })
        }else {
            setChartState(prev => {return {...prev, drill: id, edit: undefined} })
        }

    }

    const cardClassName = ( canEditNode || isParentEditChild ) ? 'card border-primary' : 'card'

    return (
    <div>
        {['root', 'business', 'group', 'division', 'department', 'section'].includes(type) &&
        <div className={cardClassName}>
            <div className="card-header py-0 px-2" onClick={handleClickNode} style={{backgroundColor: bgColor, color: textColor, fontWeight: 'bold'}}>{name}</div>
            {dlv(chartState,'viewMode.value')==='default' && <div className="card-body py-1 px-2" onClick={handleClickNode}>
                {dlv(approval, 'person',[]).map(person => <PersonPhoto key={person.userId} id={person.userId} size={35} className="rounded-circle" />) }
                <h6 className='d-inline'>{title}</h6>
            </div>}
            {dlv(chartState,'viewMode.value')==='permission' && 
            <div className="card-body py-1 px-2" onClick={handleClickNode}>
                {permission.length>0 && 
                <div className="row no-gutters">
                    <div className="col-12">Permission</div>
                    {permission.map(item => {
                    const { userId} = item

                    return (<div key={userId} className="col-auto"><PersonCardMini data={{userId}} styles={{showInfo: [], photoSize: 35, showBorder: false }} /></div>)
                })}
                </div>}
                {parentEditChild.length>0 && 
                <div className="row no-gutters">
                    <div className="col-12 text-secondary">Inherited Parent</div>
                    {parentEditChild.map(userId => {
                    return (<div key={userId} className="col-auto"><PersonCardMini data={{userId}} styles={{showInfo: [], photoSize: 35, showBorder: false }} /></div>)
                })}
                </div>}
                {permission.length===0 && parentEditChild.length===0 && <h6 className='mt-1 text-muted'>No Permission</h6>}
            </div>}
            {id===dlv(chartState, 'edit') && 
            <div className="card-footer small p-1">
                {isParentEditChild && canMoveLeft && <button type="button" className="btn btn-sm btn-secondary mx-1" 
                    onClick={() => askSwitchNode({...nodeData, move: 'left'})} >
                <FontAwesome name="arrow-left" size="lg" />
                </button>}
                {(isParentEditChild || canEditNode || isAdmin) && <button type="button" className="btn btn-sm btn-success mx-1" 
                    onClick={() => askEditNode({parent: {...nodeData} })} >
                <FontAwesome name="plus" size="lg" />
                </button>}
                {(isParentEditChild || canEditNode || isAdmin) && <button type="button" className="btn btn-sm btn-warning mx-1" onClick={() => askEditNode({...nodeData }) } >
                <FontAwesome name="pencil-square-o" size="lg" />
                </button>}
                {viewOnly &&<button type="button" className="btn btn-sm btn-info mx-1" onClick={() => askEditNode({...nodeData, readOnly: true }) } >
                <FontAwesome name="search" size="lg" />
                </button>}
                {(isParentEditChild || canEditNode || isAdmin) && <button type="button" className="btn btn-sm btn-danger mx-1" onClick={() => askDeleteNode(id)}  >
                <FontAwesome name="trash-o" size="lg" />
                </button>}
                {isParentEditChild && canMoveRight && <button type="button" className="btn btn-sm btn-secondary mx-1" 
                    onClick={() => askSwitchNode({...nodeData, move: 'right'})} >
                <FontAwesome name="arrow-right" size="lg" />
                </button>}
            </div>
            }
            {dlv(chartState, 'showUserNumber', false) && <div className="card-footer small p-1">
                <FontAwesome name="users" /> {userCount}
            </div>}
        </div>}
        {['person'].includes(type) && person && 
            <div onClick={handleClickNode}>
                <PersonCardMini data={person} styles={{ photoSize: 40, showInfo: [], showBorder: false}} />
            </div>}
    </div>);
};


export default OCNodeMaster;