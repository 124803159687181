import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
// import dlv from 'dlv'
// import moment from 'moment'

const ReportDataCard = ({data={}, filter={} }) => {

  const { _id: dataId, name } = data;

  const { workTotal, numberTotal=0, numberIsDecimal=false } = data

  const numberAVG = (workTotal>1) ? ( numberIsDecimal ? (numberTotal/workTotal).toFixed(2) : Math.round(numberTotal/workTotal) ) : undefined

  const statusColor = (workTotal>0) ? 'dark' : 'muted'


  return ( 
    <React.Fragment>
      <div className="card mb-2 text-dark p-0">
        <div className="card-body p-3">
        <NavLink className="stretched-link" to={`/report/data/${dataId}/?filter=${filter.filter}&date=${filter.date}`} ></NavLink>
        <div className="row no-gutters align-items-center justify-content-end">
          <div className="col-auto mr-auto">
            <h5 className={`text-${statusColor}`}><FontAwesome name='users' /> {name}</h5>
          </div>
          <div className="col-auto">
            <div className="row no-gutters justify-content-center align-items-center">
              {numberTotal!==undefined && <div className='col-auto mx-2 text-center'>
                <h4><span className="badge badge-pill badge-primary">{numberTotal.toLocaleString()}</span></h4>
                Total
              </div>}
              {numberAVG!==undefined && <div className='col-auto mx-2 text-center'>
                <h4><span className="badge badge-pill badge-primary">{numberAVG.toLocaleString()}</span></h4>
                AVG
              </div>}
              {workTotal!==undefined && <div className='col-auto mx-2 text-center'>
                <h4><span className="badge badge-pill badge-primary">{workTotal}</span></h4>
                Task
              </div>}
            </div>
          </div>
          {!workTotal && <h5 className="text-secondary mr-4">ไม่มีข้อมูลปฏิบัติงาน / No Task</h5>}
        </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default ReportDataCard;