import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { Button } from "@material-ui/core";

import { MeTaskToStart, MeTaskToStartDetail } from '../../../../services/me/task/meTaskService';
import MeTaskCreateList from './list';
import TaskData from './taskData';
import ConfirmSubmit from './../../../common/confirmSubmit';
import { createTask } from './../../../../services/work/workService';

import dlv from 'dlv'

const MeTaskCreate = ({history}) => {

    const [list, setList] = useState([])

    const [selectId, setSelectId] = useState(undefined)
    const [taskData, setTaskData] = useState(undefined)

    const [askConfirmStart, setAskConfirmStart] = useState(false)

    useEffect(() => {
        const loadTaskList = async() => {
            try {
                const {data: result} = await MeTaskToStart();
    
                if(result.status==='success') {
    
                    setList(result.data.list)
    
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }
        
        loadTaskList()

    }, []);

    useEffect(() => {
        const loadTaskData = async(id) => {
            try {
                const {data: result} = await MeTaskToStartDetail(id);
    
                if(result.status==='success') {
    
                    setTaskData(result.data.task)
    
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }
        
        if(selectId) loadTaskData(selectId)

    }, [selectId]);

    const startTask = async() => {
        
        setAskConfirmStart(false)

        try {

            const { data } = await createTask(taskData._id || selectId)

            const id = dlv(data, 'data.work._id')
            if(id) {
                history.replace({
                    pathname: "/me/task/" + id
                  })
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    const needLocation = dlv(taskData, 'location.enableAddress', false) || dlv(taskData, 'location.enableTrack', false)

    return ( 
    <React.Fragment>
        <ConfirmSubmit isOpen={askConfirmStart} message="To start working this task" onConfirm={startTask} onCancel={() => setAskConfirmStart(false)} />
        {!selectId && <MeTaskCreateList list={list} notifySelectId={setSelectId} />}
        {selectId && taskData && 
        <React.Fragment>
            <div className="row justify-content-start align-item-bottom">
                <div className="col-auto">
                    {/* {project && project.name && <h4 className="text-primary mb-3">Project : {project.name}</h4>} */}
                    <h4 className="d-inline"><span className="mr-2">{taskData.name || 'Name is undefined'}</span></h4>
                </div>
                <div className="col-auto">
                <button type="button" className="btn btn-secondary" onClick={() => {
                    setSelectId(undefined)
                    setTaskData(undefined)
                }} >Change</button>
                </div>
            </div>
            <div className="row">
                <div className="col">
                {taskData.description && <h6 className="text-muted my-3">{taskData.description}</h6>}
                </div>
            </div>
        </React.Fragment>
        }
        {selectId && taskData && <TaskData data={taskData} />}
        {taskData && needLocation && <h6 className='mt-4 text-danger'>This task need location address or tracking. Can not working on PC.</h6>}
        {selectId && taskData && !needLocation && <Button className="mt-4" variant="contained" color="primary" onClick={() => setAskConfirmStart(true)}>Start Task</Button>}
    </React.Fragment> );
}
 
export default MeTaskCreate;