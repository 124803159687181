import React from 'react';

const ReportWorkMessage = ({className, message: data}) => {

    if(data.length===0) return (<div></div>)

    return ( 
    <div className={className}>
        <h6>ข้อความเพิ่มเติม</h6>
        {data.map( item => {
            const {_id, text, date} = item

            return (
            <div key={_id} className="row">
                <div className="col-12">
                    <div className="card mb-2" style={{maxWidth: 800}}>
                        <div className="card-body py-3">
                            <p className="card-text my-0 font-weight-bold">{text}</p>
                        </div>
                        <div className="card-footer text-muted text-right py-1 small">
                            {date}
                        </div>
                    </div>
                </div>
            </div>)
        })}
    </div> );
}
 
export default ReportWorkMessage;