import React from 'react';

import dlv from 'dlv'

const headerSpace = 150

const TemplateFlowEditItemApprove = ({approve, setApprove}) => {


    return ( 
        <div className="row mb-3">
            <div className="col-auto" style={{width: headerSpace}}>
                <h5 className="font-weight-bold">Action</h5>
            </div>
            <div className="col-auto">
            <input type="checkbox" checked={dlv(approve, 'options.disapproveDeleteTask', false)} onChange={e => {
                const boo = e.target.checked
                setApprove(prev => {
                    return {...prev, options: { ...dlv(prev, 'options'), disapproveDeleteTask: boo}}
                })
            }} />
            <span> Delete Task when Disapprove</span>
            </div>
        </div> 
    );
}
 
export default TemplateFlowEditItemApprove;