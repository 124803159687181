import React from 'react';

import moment from 'moment'
import PlanDailyCard from './planDailyCard';

const PlanDaily = ({date, data=[]}) => {

    const list = [
        {start: moment(date + ' 00:00'), end: moment(date + ' 08:00')},
        {start: moment(date + ' 08:00'), end: moment(date + ' 13:00')},
        {start: moment(date + ' 13:00'), end: moment(date + ' 18:00')},
        {start: moment(date + ' 18:00'), end: moment(date, 'YYYY-MM-DD').add(1, 'day')}
    ]

    const allDay = data.filter(x=> moment(x.plan.begin).isSame(date) && moment(x.plan.end).isSame(moment(date).endOf('day'))) 
    const dataList = data.filter(x=> moment(x.plan.begin).isAfter(date) || moment(x.plan.end).isBefore(moment(date).endOf('day')))

    const isFuture = moment().isBefore(moment(date, 'YYYY-MM-DD'), 'day')

    const taskTotal = data.length
    const taskWait = data.filter(x=> x.status==='wait').length
    const taskActive = data.filter(x=> x.status==='active').length
    const taskFinish = data.filter(x=> x.status==='finish').length
    const taskPause = data.filter(x=> x.status==='pause').length
    const taskOverdue = data.filter(x=> x.status==='overdue').length
    const taskDelete = data.filter(x=> x.status==='delete').length

    return ( 
    <React.Fragment>
        <h5 className='mb-4 text-primary font-weight-bold'>{moment(date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')}</h5>
        <div className="row">
            <div className="col-auto mb-3 text-center">
                <div className="card">
                    <div className="card-body">
                        <div className="row no-gutters justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4 className="d-inline">Work</h4>
                            </div>
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{taskTotal}</span></h4>
                            Total
                            </div>
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                            Wating
                            </div>
                            {!isFuture && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                            Active
                            </div>}
                            {!isFuture && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{taskFinish}</span></h4>
                            Finish
                            </div>}
                            {!isFuture && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{taskOverdue}</span></h4>
                            Overdue
                            </div>}
                            {!isFuture && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                            Pause
                            </div>}
                            {!isFuture && <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                            Delete
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {allDay.length>0 && 
        <React.Fragment>
            <div className="card mb-2">
            <div className="card-header p-2">
                <span className="ml-2 font-weight-bold">All Day</span>
            </div>
            </div>
            {allDay.map((item, index) => {
                return <PlanDailyCard key={item._id || index} data={item} className="ml-3" />
            })}
            <div style={{height: 10}}></div>
        </React.Fragment>}
        {list.map(item => {

            const { start, end } = item
            const dataFilter = dataList.filter(x=> moment(x.plan.begin).isSameOrAfter(start) && moment(x.plan.begin).isBefore(end))
            if(dataFilter.length===0)  return(<div key={start}></div>)

            return (
                <React.Fragment key={start}>
                    <div className="card mb-2">
                    <div className="card-header p-2">
                        <span className="ml-2 font-weight-bold">{start.format('HH:mm')}</span>
                    </div>
                    </div>
                    {dataFilter.length===0 && <div className="ml-3 alert alert-secondary text-muted" role="alert">No Plan Record</div>}
                    {dataFilter.map((item,index) => {
                        return <PlanDailyCard key={item._id || index} data={item} className="ml-3" />
                    })}
                    {dataFilter.length>0 && <div style={{height: 10}}></div>}
                </React.Fragment>
            )
        })}


    </React.Fragment> );
}
 
export default PlanDaily;