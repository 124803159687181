// import Raven from "raven-js";

function init() {
  // Raven.config("https://8ee588a483c1400b820444cf43443794@sentry.io/1439544", {
  //   release: "1-0-0",
  //   environment: "development-test"
  // }).install();
}

function log(error) {
  console.log(error);
  // Raven.captureException(error);
}

export default {
  init,
  log
};
