import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { setZoomRoomEnable } from '../../../services/manage/zoom/zoomRoomService';

const ZoomRoomCard = ({ data={}, onRemove=()=>{}, setAskManage=()=>{} }) => {

    const {_id, name } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await setZoomRoomEnable({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update zoom room')
        }
    }

  return ( 
    <React.Fragment>
        <div className="card mb-2">
            <div className="card-body p-1">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto ml-3" style={{cursor: 'pointer'}}>
                        <h6 onClick={()=>setAskManage({isOpen: true, id: _id})} className="d-inline font-weight-bold">
                            <FontAwesome name="video-camera" size="lg" /><span className="ml-2">{name}</span>
                        </h6>
                    </div>
                    <div className="col-auto pr-4">
                        <div className="row  align-items-center">
                            <div className="col-auto p-1">
                                <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                            </div>
                            <div className="col-auto px-3">
                                <FontAwesome name="pencil" size="lg" onClick={() => setAskManage({isOpen: true, id: _id})} />
                            </div>
                            <div className="col-auto p-1 mr-2">
                                <FontAwesome name="trash" size="lg" onClick={onRemove} />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
    
}
 
export default ZoomRoomCard;