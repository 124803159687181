import React from 'react';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import GraphDonutBasic from './../../../common/graph/basicDonut';
import GraphBarBasic from './../../../common/graph/basicBar';
import ReportWorkCard from './../../work/workCard';
import dlv from 'dlv'

const PerStaffToday = ({className, report, filter}) => {

    const { taskTotal=0, timeTotal=0, jvTotal=0 } = report
    const { taskWait, taskActive, taskFinish, taskPause, taskDelete } = report

    const { tasks } = report

    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { staffList, groupByHour, groupByTask, groupByProject } = report

    const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(staffList && staffList.find(x=> x.jvTotal>0)) staffOrderList.push({name: 'Job Value', value: 'jv'})

    return ( 
        <div className={className}>
            <div className="row no-gutters justify-content-left">
                <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-2">
                    <div className="row no-gutters justify-content-center align-items-center">
                        <div className='col-auto mx-2 text-center'>
                        <h5 className="d-inline">Work</h5>
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-primary">{taskTotal}</span></h3>
                        Total
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-info">{taskWait}</span></h3>
                        Plan
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-warning">{taskActive}</span></h3>
                        Active
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-secondary">{taskPause}</span></h3>
                        Pause
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-success">{taskFinish}</span></h3>
                        Finish
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-secondary">{taskDelete}</span></h3>
                        Cancel
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-2">
                    <div className="row no-gutters justify-content-center align-items-center">
                    <div className='col-auto mx-1 text-center'>
                        <h5 className="d-inline">Work Time</h5>
                    </div>
                    <div className='col-auto mx-1 text-center'>
                        <h3><span className="badge badge-pill badge-success">{workTime}</span></h3>
                        Finish
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                {jvTotal>0 && <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-2">
                    <div className="row no-gutters justify-content-center align-items-center">
                    <div className='col-auto mx-1 text-center'>
                        <h5 className="d-inline">Job Value</h5>
                    </div>
                    <div className='col-auto mx-1 text-center'>
                        <h3><span className="badge badge-pill badge-success">
                            {jvTotal>0 ? <span>{jvTotal.toLocaleString()} &#3647;</span> : <span>-</span>}</span>
                        </h3>
                        Total
                    </div>
                    </div>
                    </div>
                </div>
                </div>}
            </div>
            {groupByProject && dlv(groupByProject,'data',[]).length>0 && <GraphDonutBasic className="mb-3" input={groupByProject} header="Group By Project" />}
            {groupByTask && <GraphDonutBasic className="mb-3" input={groupByTask} header="Group By Task" />}
            {groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Finish Work Hourly' yaxisText='Finish Work' />}
            { tasks && <div className="row mt-3">
            { tasks.map( (item, index) => {
                return (
                <div key={index} className="col-12">
                    <ReportWorkCard data={item} showOwner={false} />
                </div>
                )
            })}
            </div>}
            
        </div>
     );
}
 
export default PerStaffToday;