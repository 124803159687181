import React from 'react';
// import moment from 'moment'
import GraphDonutBasic from '../../../common/graph/basicDonut';
import ReportTaskList from './../../task/summary/taskList';

const ReportPerDataDetail = ({report={}, filter}) => {

    const { taskList=[] } = report
    const { groupByProject, groupByTask } = report

    const { numberTotal=0, taskTotal=0, projectTotal=0, staffTotal=0, workTotal=0, numberIsDecimal=false } = report

    const numberAVG = (workTotal>1) ? ( numberIsDecimal ? (numberTotal/workTotal).toFixed(2) : Math.round(numberTotal/workTotal) ) : undefined

    const taskOrderList= []

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row no-gutters justify-content-left mt-2">
        <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Number</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{numberTotal.toLocaleString()}</span></h4>
                            Total
                            </div>
                            {numberAVG && <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{numberAVG.toLocaleString()}</span></h4>
                            AVG
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Project</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{projectTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Task</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{taskTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{workTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="row">
            {groupByProject && <GraphDonutBasic className="col-12 col-xl-6 mb-3" input={groupByProject} header="Group By Project" centerText="Total Work" />}
            {groupByTask && <GraphDonutBasic className="col-12 col-xl-6 mb-3" input={groupByTask} header="Group By Task" centerText="Total Work" />}

        </div>
        {taskList && <ReportTaskList data={taskList} filter={filter} orderList={taskOrderList} style={{workVisible: ['finish']}} />}
    </React.Fragment> );
}
 
export default ReportPerDataDetail;