import 'flatpickr/dist/themes/material_blue.css'
// import "flatpickr/dist/l10n/th.js"

import React from 'react';
import Flatpickr from "react-flatpickr";

const MyCalendar = ({ id, name, label, error, onChange, ...rest }) => {
  return (
    <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Flatpickr className="form-control"
          name={name}
          onChange={(_,str) => onChange({ currentTarget: { id: id, name: name, value: str}})}
          {...rest}
        />
        {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default MyCalendar