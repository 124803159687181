import React from "react";
import SearchItem from "./searchItem";

const SearchTable = ({data, onConfirm, valueProperty, textProperty}) => {

  return ( <div className="row">
  {data && data.map( item => {
    return (
      <div key={item[valueProperty]} className="col-12">
        <SearchItem data={item} onConfirm={onConfirm} textProperty={textProperty} />
      </div>
    )
  })}
</div> );
}


SearchTable.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default SearchTable;
