import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getLine, saveLine } from './../../../services/manage/line/lineService';

import PersonCardMini from './../card/personCardMini';

const DialogLineRoom = ({id='new', staff: newStaff, isOpen, onSave, onCancel }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [lineToken, setLineToken] = useState('')
    const [staff, setStaff] = useState(newStaff)

    const [warning, setWarning] = useState(undefined)
    const [error, setError] = useState(undefined)

    useEffect(() => {
        setWarning(undefined)
        setError(undefined)
    }, [name, description, lineToken])

    useEffect(() => {
        const loadData = async() => {

          if(!id || id==='new') {
              setName('')
              setDescription('')
              setLineToken('')
              setStaff(newStaff)
          } else {
            try {
              const {data: result } = await getLine(id);
      
              const { doc } = result.data
              setName(doc.name)
              setDescription(doc.description)
              setLineToken(doc.lineToken)
              setStaff(doc.staff)
      
            } catch(err) {

            }
          }

            
        }

        loadData()

    }, [id, newStaff])

    const handleSave = async () => {

        try {
      
            if(!name) return setWarning('Name is required'); else setWarning(undefined)
            if(!lineToken) return setWarning('Token is required'); else setWarning(undefined)
      
            const doc = {name , description, lineToken, staff, _id: (id==='new') ? undefined : id  }
      
            const { data: result } = await saveLine(doc);
            if ( result.status === 'success') {
                if( typeof(onSave) === 'function') {
                    onSave({id})
                }
            } else {
              setError(result.message)
            }

        } catch(err) {
          setError(err.message)
        }
        
    }

    const handleCancel = () => {  
        setWarning(undefined)
        setError(undefined)
        if(typeof(onCancel)==='function') { onCancel() }    
    }

  const headerSpace = 130

  return ( 

    <div>
      <Modal isOpen={isOpen} toggle={handleCancel} size='lg' >
        <ModalHeader color="info">Add/Edit Line Room</ModalHeader>
        <ModalBody>
            {staff && 
            <div className="row align-items-center mb-2">
                <div className="col text-center">
                    <PersonCardMini data={staff} styles={{photoSize:50, showInfo:['name', 'company'], marginLeft: 10}} />
                </div>
            </div>}
          <div className="row align-items-center">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Room Name</h6>
            </div>
            <div className="col p-2">
                <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Description</h6>
            </div>
            <div className="col p-2">
              <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
            </div>
          </div>
          <div className="row align-items-center mt-2">
              <div className="col-auto" style={{width: headerSpace}}>
                  <h6 className="font-weight-bold">Token</h6>
              </div>
              <div className="col p-2">
              <input className="form-control" value={lineToken} onChange={e => setLineToken(e.target.value)} type="text" />
              </div>
          </div>
          {warning && <div className="alert alert-warning" role="alert">{warning}</div>}
          {error && <div className="alert alert-danger" role="alert">{error}</div>}
        </ModalBody>
        <ModalFooter>
            
            <Button color="primary mr-2" onClick={handleSave}>Save</Button>
            <Button color="secondary" onClick={handleCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>

   );
}
 
export default DialogLineRoom;