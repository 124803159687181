import http from "../httpService";
// import moment from 'moment';

const apiEndpoint = "/work";


export function getWork(id) { return http.get(apiEndpoint + "/" + id )}

export function createTask(id) {
  return http.post(apiEndpoint + "/fromtask/" + id )
}

export function updateWork(id, updateForms) {
  return http.put(apiEndpoint + "/" + id + "/update" , updateForms)
}

export function pauseWork(id) {
  return http.put(apiEndpoint + "/" + id + "/pause")
}

export function resumeWork(id) {
  return http.put(apiEndpoint + "/" + id + "/resume")
}

export function finishWork(id, updateForms) {
  return http.put(apiEndpoint + "/" + id + "/finish" , updateForms)
}

export function deleteWork(id) {
  return http.delete(apiEndpoint + "/" + id)
}