import http from "../httpService";

const apiEndpoint = "/oc";

export function getOcList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getOcItem(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveOc(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function switchOc({nodeA, nodeB}) {
  return http.put(apiEndpoint + "/switchposition", {nodeA, nodeB})
}

export function deleteOc(id) {
  return http.delete(apiEndpoint + "/" + id)
}