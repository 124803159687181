import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { getOcList, switchOc, saveOc, deleteOc } from './../../services/oc/ocService';
import dlv from 'dlv'
import moment from 'moment'
import _ from 'lodash'

import OrganizationChart from "./orgChartClass/ChartContainer"

import './org.css'
import OCNode from './nodeType/OCNodeMaster';
// import OCNode from './nodeType/OCNode';
import ButtonGroup from './../common/buttonGroup';
import { ChartContext } from './../../context/chartStateContext';
import DialogEditOCNode from './nodeManage/dialogOCNode';
import ConfirmSubmit from './../common/confirmSubmit';
import ConfirmDelete from './../common/confirmDelete';
import { SessionContext } from './../../context/sessionContext';

const viewModes = [{name: 'Approval', value: 'default'}, {name: 'Permission', value: 'permission'}]
const deepList = [{value: '3'}, {value: '4'},{value: '5'},{value: '6'},{value: '7'},]

const OCIndex = () => {

    const {session} = useContext(SessionContext)
    const [chartState, setChartState] = React.useState({viewMode: viewModes[0], deep: deepList[0]});
    const chartValue = React.useMemo(() => ({chartState, setChartState}), [chartState]);

    // console.log('chartState', chartState)

    const [load, setLoad] = useState(Date.now())
    const [build, setBuild] = useState(Date.now())

    const [data, setData] = useState([])
    const [OC, setOC] = useState({})
    const [root, setRoot] = useState()
    const [path, setPath] = useState([])
    const [editNode, setEditNode] = useState()
    const [moveNode, setMoveNode] = useState()
    const [askDelete, setAskDelete] = useState()

    //Load Data from API
    useEffect(() => {
        const loadOcList = async() => {
            try {
                const {data: result} = await getOcList();
    
                if(result.status==='success') {
                    const {list} = result.data

                    // setOCList(result.data.list)
                    if(Array.isArray(list) && list.length>0) {
                        setData(list)
                    }

                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        console.log('load OC Data')
        loadOcList()

        // eslint-disable-next-line
    }, [load])

    //Build OC Nodes
    useEffect(() => {
        console.log('Build OC Nodes')
        if(Array.isArray(data) && data.length>0) {

            const findRoot = root ? data.find(x=> x._id.toString()===root) : data.find(x=> x.type==='root');
            if(findRoot) {
                const { _id, name, type, permission, ...rest } = findRoot

                const deep = 1
                const {children, userCount} = getOCChilds({ deep, 
                    parent:  {id: _id, parentEditChild: permission.filter(x=> x.roles.includes('editChild')).map(x=> x.userId) }, data})

                setOC({id: _id, name, title: type==='root' ? 'TA' : type, type, deep, children, userCount, permission, ...rest })
                setPath(getPaths({child: _id}))
            }
        }

        // eslint-disable-next-line
    }, [root, data, build])

    const getOCChilds = ({parent, data, deep: previousDeep}) => {
        const { id: parentId, parentEditChild } = parent;
        const deep = previousDeep + 1
        const maxDeep = dlv(chartState, 'deep.value', 3)

        const children = data.filter(x=> x.parent===parentId).sort((a,b) => moment(a.order).diff(b.order, 'second')).map((item, index, array) => {
            
            const { _id, name, type, permission, ...rest } = item
            const canMoveLeft = index>0
            const canMoveRight = index<array.length-1

            // console.log(name, parentEditChild)
            
            const {children, userCount} = deep<maxDeep ? getOCChilds({
                parent: {id: _id, parentEditChild: _.uniq([...parentEditChild, ...permission.filter(x=> x.roles.includes('editChild')).map(x=>x.userId)]) }, 
                data: data,
                deep: deep
            }) : {children: [], userCount: 0}

            return {id: _id, name, title: type, type, deep, children, userCount, parent: parentId, canMoveLeft, canMoveRight, permission, parentEditChild,  ...rest }
        })

        const userCount = data.filter(x=> x.parent===parentId && x.type==='person').length

        return {children, userCount: userCount + children.map(x=> x.userCount).reduce((acc, x) => acc+x, 0)}
    }


    const getPaths = ({child}) => {
        const findNode = data.find(x=> x._id.toString()===child)
        if(findNode) {
            const findParent = findNode.parent ? data.find(x=> x._id.toString()===findNode.parent) : undefined
            if(findParent) {
                return [...getPaths({child: findParent._id}), findNode]
            } else {
                return [findNode]
            }
        } else {
            return []
        }
    }

    const getNodeName = (id) => {  return dlv(data.find(x=> x._id===id), 'name', 'Unknown')   }

    const checkPermission = (id) => {
        if(session) {
            return true
        } else {
            return false
        }
    }

    
    useEffect(() => {
        const { drill } = chartState
        if(drill!==root) {
            setRoot(drill)
        }

        // eslint-disable-next-line
    },[chartState])


    const handleSwitchNodePosition = async({id, parent, move='left'}) => {
        try {
            if(!parent) return
            if(!['left', 'right'].includes(move)) return toast.error('invalid action')

            const children = data.filter(x=> x.parent===parent).sort((a,b) => moment(a.order).diff(b.order, 'second')).map(x=> x._id)
            
            if(!children || children.length===0) return

            const nodeIndex = children.indexOf(id)

            if(nodeIndex<=0 && move==='left') return toast.info('Node is most left')
            if(nodeIndex===children.length-1 && move==='right') return toast.info('Node is most right')

            const nodeA = move==='left' ? children[nodeIndex-1] : id
            const nodeB = move==='left' ? id : children[nodeIndex+1]

            await switchOc({nodeA, nodeB})
            setLoad(Date.now())

        } catch(err) { toast.error('Failed to switch node');}
    }

    const confirmMoveHierarchy = async() => {
        try {
            const { id, parent } = moveNode

            if(id && parent) {
                await saveOc({_id: id, parent, order: Date.now()})
                setLoad(Date.now())
                setMoveNode(undefined)
            } else {
                toast.error('Invalid Paremeter')
            }

        } catch(err) { toast.error('Failed to move node');}
    }

    const askSwitchNode = (input) => {
        if(checkPermission()) handleSwitchNodePosition(input)
        else toast.error("Permission is denied")

    }

    const askMoveHierarchy = (input) => {
        if(checkPermission()) setMoveNode({...input, message: `Move node (${getNodeName(dlv(input,'id'))}) to new parent (${getNodeName(dlv(input, 'parent'))})` })
        else toast.error("Permission is denied")
    }

    const askEditNode = (input) => {
        if(checkPermission()) setEditNode(input)
        else toast.error("Permission is denied")
    }

    const askDeleteNode = (input) => {
        if(checkPermission()) setAskDelete(input)
        else toast.error("Permission is denied")
    }

    const confirmDeleteNode = async() => {
        try {
            await deleteOc(askDelete);
            setAskDelete(undefined)
            setEditNode(undefined)
            setLoad(Date.now())

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
    <React.Fragment>
        {moveNode && <ConfirmSubmit isOpen={moveNode ? true : false} message={moveNode.message} onConfirm={confirmMoveHierarchy} onCancel={() => setMoveNode(undefined)} />}
        <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDeleteNode} onCancel={()=> setAskDelete(undefined)} />
        {editNode && <DialogEditOCNode
        isOpen={true} 
        id={dlv(editNode, 'id')} 
        path={getPaths({child: dlv(editNode, 'parent.id') || dlv(editNode, 'parent')})}
        parent={dlv(editNode, 'parent')}
        onCancel={() => { setEditNode(undefined); setChartState(prev => {return {...prev, edit: undefined} }) }} 
        onSave={() => { setLoad(Date.now()); setEditNode(undefined); setChartState(prev => {return {...prev, edit: undefined} })}} 
        readOnly={dlv(editNode, 'readOnly', false)}
        askDeleteNode={askDeleteNode}
        />}
        <div className="row justify-content-right align-items-center mb-2">
            <div className="col mr-auto"><h4>OC Management</h4></div>
            <div className="col-auto p-0"><h5 className="d-inline">Deep</h5></div>
            <div className="col-auto">
                <ButtonGroup items={deepList} 
                    selectedItem={dlv(chartState, 'deep')} 
                    onItemSelect={item => setChartState(prev => { 
                        if(dlv(prev, 'deep.value')!==item.value) {
                            setBuild(Date.now())
                        }
                        return {...prev, deep: item}} )
                    } 
                    textProperty='value' valueProperty='value' />
            </div>
            <div className="col-auto p-0 ml-3"><h5 className="d-inline">Mode</h5></div>
            <div className="col-auto">
                <ButtonGroup items={viewModes} 
                    selectedItem={dlv(chartState, 'viewMode')} 
                    onItemSelect={item => setChartState(prev => { return {...prev, viewMode: item}} )} valueProperty='value' />
            </div>
            <div className="col-auto p-0 ml-3">
            <input type="checkbox" checked={dlv(chartState, 'showUserNumber', false)} 
                onChange={e => {
                    const boo = e.target.checked
                    setChartState(prev => {return {...prev, showUserNumber: boo } })
                }} style={{transform: 'scale(1.5)'}} />
            {/* <Toggle className="m-0 p-0"
                checked={dlv(chartState, 'showUserNumber', false)}
                onChange={value => setChartState(prev => {return {...prev, showUserNumber: value.target.checked } }) } /> */}
            </div>
            <div className="col-auto pl-0 ml-2"><h5 className="d-inline">Staff Number</h5></div>
        </div>
        <div className="row align-items-center justify-content-between mb-2" style={{minHeight: 33}}>
            <div className="col-auto">
                {Array.isArray(path) && path.map((item, index, array) => {
                    if(index===array.length-1) {
                        return (
                            <h5 key={item._id} className='d-inline text-dark'>{item.name}</h5>
                            )
                    } else {
                        return (
                        // eslint-disable-next-line
                        <h5 key={item._id} className='d-inline text-secondary'><a href="#" 
                            onClick={() => setChartState(prev => {return {...prev, drill: item._id, edit: undefined} })}>{item.name}</a> / </h5>
                        )
                    }
                })}
                
            </div>
        </div>
        
        {dlv(OC, 'id') && 
        <ChartContext.Provider value={chartValue}>
            <OrganizationChart 
                containerClass="custom" 
                chartClass="custom"
                pan={true}
                collapsible={false}
                draggable={true}
                datasource={OC}
                NodeTemplate={OCNode}
                // onClickNode={handleClickNode}
                onClickChart={() => setChartState(prev => {return {...prev, edit: undefined} })}
                notifyChange={() => {  setLoad(Date.now()) }}
                askSwitchNode={askSwitchNode}
                askEditNode={askEditNode}
                askMoveParent={askMoveHierarchy}
                askDeleteNode={askDeleteNode}
                />
        </ChartContext.Provider>
        }
    </React.Fragment> );
}
 
export default OCIndex;