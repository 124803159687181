import React, { useState, useEffect } from 'react';
import ReportStaffCard from './staffCard';
import ButtonGroup from '../../common/buttonGroup';
import dlv from 'dlv'
import InfiniteScroll from 'react-infinite-scroll-component';

const ReportStaffList = ({className, data, filter, 
        orderList=[{name: 'Work Finish', value: 'finish'}, {name: 'Work Active', value: 'active'}, {name: 'Work Time', value: 'time'}, {name: 'Job Value', value: 'jv'}],
        customOrder=[], style, enableLink }) => {

    const [order, setOrder] = useState(orderList.find(x=>x.value==='finish'))
    const getOrderList = [...customOrder, ...orderList]

    const scrollRow = 5
    const initRow = Math.max(Math.ceil((window.innerHeight-50)/100), 5)
    const [showRow, setShowRow] = useState(initRow)

    useEffect(() => {
        setShowRow(initRow)
    }, [initRow, data]);

    const filterList = data.sort((a,b) => {

        if (customOrder.find(x=> x.value===order.value)) {
            return dlv(dlv(b, 'reportThis', []).find(x=> x._id===order.value), 'sum', 0) - dlv(dlv(a, 'reportThis', []).find(x=> x._id===order.value), 'sum', 0)
        }
                
        switch(order.value) {
            case 'finish':
                return b.taskFinish-a.taskFinish
            case 'active':
                return b.taskActive-a.taskActive
            case 'time':
                return b.timeTotal-a.timeTotal
            case 'jv':
                return b.jvTotal-a.jvTotal
            
            default: 
                return 0
        }

    }).slice(0, showRow)

    const fetchMoreData = () => {  setShowRow(prev => prev+scrollRow)  };

    if(!data || !Array.isArray(data)) return (<div></div>)

    return ( 
        <div id='rootcontent' className={className}>
            {filterList.length>0 && getOrderList.length>0 &&
            <div className="row mb-2">
                <div className="col text-right">
                    <ButtonGroup
                    items={getOrderList}
                    selectedItem={order}
                    onItemSelect={filter => setOrder(filter)}
                    valueProperty='value'
                    selectedItemClass='btn btn-sm btn-primary'
                    defaultItemClass='btn btn-sm btn-outline-dark'
                    />
                </div>
            </div>}
            <InfiniteScroll
                dataLength={showRow} //This is important field to render the next data
                next={fetchMoreData}
                hasMore={showRow>=data.length ? false : true}
                loader={<h4>Loading...</h4>}
                style={{overflowX: 'hidden'}}
                scrollableTarget='rootcontent'
                scrollThreshold={0.9}
            >
                {filterList.map( item => {
                    return (
                        <div key={item.userId || item._id} className="row">
                            <div className="col mb-2">
                                <ReportStaffCard data={item} filter={filter} style={style} enableLink={enableLink} />
                            </div>
                        </div>
                    )
                })}
            </InfiniteScroll>
            
        </div>
     );
}
 
export default ReportStaffList;