import React from 'react';
// import GraphDonutBasic from '../../../common/graph/basicDonut';
import ProgressList from './progressList';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';

const ProgressionSummary = ({report}) => {

    const { progressTotal='-', progressActive='-', progressComplete='-', timeTotal=0, jvTotal=0, staffActive='-' } = report
    const { taskTotal='-', taskWait='-', taskActive='-', taskPause='-', taskFinish='-', taskDelete='-' } = report

    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { progressList } = report

    const progressOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(progressList && progressList.find(x=> x.jvTotal>0)) progressOrderList.push({name: 'Job Value', value: 'jv'})

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-2">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Progression</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold px-1">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-light">{progressTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-warning">{progressActive}</span></h4>
                                Active
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{progressComplete}</span></h4>
                                Complete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{staffActive}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold px-1">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-primary">{taskTotal}</span></h4>
                                Total
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                                Wait
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                                Active
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                                Pause
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{taskFinish}</span></h4>
                                Finish
                                </div>
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                                Delete
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTime}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jvTotal>0 && 
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Job Value</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{jvTotal.toLocaleString()} &#3647;</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {progressList && <ProgressList className="mt-4" data={progressList} orderList={progressOrderList} />}
        </React.Fragment>
     );
}
 
export default ProgressionSummary;