import axios from "axios";
import { toast } from "react-toastify";
import logger from "./app/logService";
import auth from "./app/authService";
import { detect } from 'detect-browser'
import publicIp from 'public-ip'
import { version } from './../../package.json'

const browser = detect();



const device = (browser) ? `[type=web][browser=${browser.name}][os=${browser.os}][v=${browser.version}]` : "[type=web]"

const getIp = async() => {
  const data = await publicIp.v4();
  axios.defaults.headers['ip'] = data;
}
getIp()

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers['locale'] = 'TH';
axios.defaults.headers['ip'] = "";
axios.defaults.headers['device'] = device;
axios.defaults.headers['x-web-client'] = version

// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   console.log(config)
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });

axios.interceptors.response.use(null, error => {

  if (
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  )
  {
    return new Promise((resolve, reject) => {
       let reader = new FileReader();
       reader.onload = () => {
          const returnJSON = JSON.parse(reader.result);
          if(returnJSON.message) {
            toast.error("Error : " + returnJSON.message);
          }
           error.response.data = returnJSON
           resolve(Promise.reject(error));
       };

       reader.onerror = () => {
           reject(error);
       };

       reader.readAsText(error.response.data);
    });
  };


  if ( error.response && error.response.data && error.response.data.message) {
    toast.error("Error : " + error.response.data.message);


    if(error.response.data.message==='Invalid Auth Token') {
      auth.logout()
    }

    if(error.response.data.message==='Please Refresh Website') {
      // console.log('Force Web Client Reload')
      toast.info("Update Now", { autoClose: 30000, onClick: x => window.location.reload() })
      //window.location.reload()
    }

  } else {
    const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

    
    if (!expectedError) {
      try {
        logger.log(error);
      } catch (ex) {
        console.log(ex);
      }

      console.log("INTERCEPTOR Logginge the error", error);
      toast.error("An unexpected error");
    }
  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers["x-auth-token"] = jwt;
}

function setDomain(domain) {
  if(domain && domain._id && domain.name) {
    axios.defaults.headers["domainid"] = domain._id
    axios.defaults.headers["domainname"] = domain.name
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  setDomain
};
