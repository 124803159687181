import React, {useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';

import { DomainContext } from '../../../context/domainContext';
import DialogZoomRoom from '../../common/dialogZoomRoom/manageForm';
import { getZoomRoomList, deleteZoomRoom } from '../../../services/manage/zoom/zoomRoomService';
import DialogCreateZoomRoom from '../../common/dialogZoomRoom/createZoomRoom';
import ZoomRoomCard from './zoomCard';

const ZoomRooms = () => {
    const {domain } = useContext(DomainContext)

    const getList = async() => {
        try {
            const {data: result} = await getZoomRoomList();

            if(result.status==='success') {

                setList(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(domain) getList()
        
    }, [domain])

    const [list, setList] = useState([])

    const [askManage, setAskManage] = useState({isOpen: false, id: undefined})
    const [askDelete, setAskDelete] = useState('')

    const confirmSave = () => { 
        setAskManage({isOpen: false})
        getList()

    }

    const notifyCreateZoom = () => {
        setAskManage({isOpen: false})
        getList()
    }

    const confirmDelete = async() => {
        try {
            await deleteZoomRoom(askDelete);

            setAskDelete('')
            getList()

        } catch(err) { toast.error('Failed to delete item')}
    }

    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <DialogZoomRoom key={Date.now()} isOpen={askManage.isOpen} id={askManage.id} onCancel={() => setAskManage({isOpen: false})} onSave={confirmSave} />
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col"><h4>Zoom Room</h4></div>
                <div className="col-auto">
                    <DialogCreateZoomRoom buttonAdd="New" buttonStyle="btn btn-primary" notifySave={notifyCreateZoom} />
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {list && list.map( (item, index) => {

                return (
                <div key={item._id || index} className="col-12">
                    <ZoomRoomCard data={item} onRemove={() => { setAskDelete(item._id); }} setAskManage={setAskManage} />
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default ZoomRooms;