import React from 'react';
import FontAwesome from 'react-fontawesome';

const ReportWorkZoom = ({className, zoom: data, header='Zoom เพิ่มเติม' }) => {

    if(data.length===0) return (<div></div>)

    return ( 
    <div className={className}>
        <h5>{header}</h5>
        <div className="row">
        {data.map( zoom=> {
            const { _id, mode, roomId, period, minute, records } = zoom

            return(
                <div key={_id} className="col-12 mb-2">
                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card border-secondary text-dark p-0">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-top justify-content-start">
                                    <div className="col-auto">
                                        <h5><FontAwesome name="video-camera" /> Zoom</h5>
                                    </div>
                                    <div className="col-auto text-primary ml-3">
                                        <h5>Meeting ID : {roomId} ({mode})</h5>
                                        <h5 className="text-dark">{period} <span>({minute}mins)</span></h5>
                                    </div>
                                    <div className="col-auto ml-auto"></div>
                                
                                </div>
                            </div>
                            {records && <div className="card-body p-2">
                                {records.map(record => {
                                    const { id, period, minute, play} = record

                                    return (<div key={id} className="row no-gutters align-items-center justify-content-start">
                                        <div className="col-auto">
                                            <h5><FontAwesome name="floppy-o" /> RECORD</h5>
                                        </div>
                                        <div className="col-auto text-primary ml-3">
                                            <h5>{period} <span>({minute}mins)</span></h5>
                                        </div>
                                        <div className="col-auto ml-3">
                                        <button type="button" className="btn btn-sm btn-dark" onClick={() => window.open(play, '_blank')}>Play</button>
                                        </div>
                                    </div>)
                                })}
                            </div>}
                        </div>
                    </div>
                </div>
                </div>
            )
        })}
        </div>
    </div> );
}
 
export default ReportWorkZoom;