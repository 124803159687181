import http from "../../httpService";

const apiEndpoint = "/manage/staff";

export function searchUser(input) {
  return http.get(apiEndpoint + `/search/${encodeURI(input)}`)
}

export function staffList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getStaff(id) {
  return http.get(apiEndpoint + "/" + id);
}

export function saveStaff(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint , doc )
}

export function deleteStaff(id) {
  return http.delete(apiEndpoint + "/" + id);
}