// import { get } from "lodash";
import http from "../httpService";

/* eslint-disable */

const apiEndpoint = "https://api-idms.advanceagro.net/hrms/employee";

export function searchEmployee(search, index=0, row=10) {
    return new Promise(async function(resolve, reject) {

        try {

            const encodedSearch = encodeURI(search);
            const {data: result} = await http.get(apiEndpoint + `/search/${encodedSearch}/?index=${index}&row=${row}`);
            
            if(result.status==='success') {
                const {search} = result.data

                if(search) {
                    const output = search.map(x=> {
                        const {empId, name, sim, email, company, status, position, department, } = x

                        return {userId: empId, userName: name, id: empId, empid: empId, name, sim, email, position, department, company, status}
                    })
                    
                    resolve(output)
                } else {
                    reject("Not Found")
                }
                    
            } else { reject("Not Found")}
                
        } catch(err) {
            reject(err)
        }
    })

}

export async function getEmployeeInfo(empid) {
    // console.log('[getEmployeeInfo]',empid)
    return new Promise(async function(resolve, reject) {

        try {
            const {data: result} = await http.get(apiEndpoint + `/${empid}`,{ timeout: 30000})
            if(result.status==='success') {
                const {employee} = result.data

                if(employee) {
                    const {ID_Emp: empid, EmpName: name, Sim_Number: sim, EMail: email, Position: position, Department: department, Company_Code: company, WorkStatusID } = employee

                    resolve({id: empid, userId: empid, userName: name, empid, name, sim, email, position, department, company, status: (WorkStatusID===3) ? 1 : 0})
                } else {
                    reject("Not Found")
                }
                    
            } else { reject("Not Found")}
                
        } catch(err) {
            reject(err)
        }
    })
}
