import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import _ from 'lodash';

// https://apexcharts.com/docs/options/plotoptions/pie/
// https://apexcharts.com/docs/chart-types/pie-donut/
// https://apexcharts.com/javascript-chart-demos/pie-charts/


const GraphDonutBasic = ({ input, className='mt-2', header='Basic Donut Graph', centerText='Total', height, customLegend=true, top=10}) => {

  const [showOther, setShowOther] = useState(false)
  const gHeight = height || (input.data.length>5 ? 400 : 300)

  if(!input || !input.labels || !input.data) return (<div></div>)
  if (input.labels.length !== input.data.length) return (<div></div>)
  
  const { labels, data } = input;
  // console.log('data', data) // [1,2,3,5,4,8,1,2,2,2,2444]
  // console.log('labels', labels) //["NaClO3 prepare and fill-up - 2", "Valve operation - 9", "Kappa online (PB) sample - 1", "High pressure online cleaning - 1", "Hydrochloric acid (HCL) prepare and fill-up - 1", "Pre-Coat Dreg filter - 3"]
  const dataSet = data.map((item, index) => { return { name: labels[index], value: item } }); 
  let dataOrderbyValue = _.orderBy(dataSet, ['value', 'name'], ['desc', 'asc'])
  // console.log('dataOrderbyValue 1', dataOrderbyValue)
  if (top && data.length > top && !showOther) {
    const dataExceptTop = _.slice(dataOrderbyValue, top - 1, data.length + 1);
    const totalOtherValue = _.reduce(dataExceptTop.map(x => x.value), function (result, item) { return result + item;}, 0);
    
    dataOrderbyValue = _.slice(dataOrderbyValue, 0, top - 1); 
    dataOrderbyValue.push( {name: "Other", value: totalOtherValue}) 
  }

  const palette = ['#008FFB', '#00E396', '#FEB019','#FF4560','#775DD0','#006E6D','#F9A3A4','#FAE03C','#615550','#2E294E']
  dataOrderbyValue = dataOrderbyValue.map((item, index) => { item.color = palette[index % 10]; return item; })
  
  let gLabels = dataOrderbyValue.map(x => x.name)
  let gSeries = dataOrderbyValue.map(x => x.value)
  let gColors = dataOrderbyValue.map(x => x.color)
  const showGraph = (gSeries.find(x => x > 0)) ? true : false;

  const optionsTag = {
    //ป้ายข้อมูล
    labels: gLabels,
    colors: gColors, 
    //คำอธิบายข้อมูล
    legend: { show: !customLegend, position: 'right', fontSize: '14pt', height: gHeight-(gHeight*0.2), width: '80%'},
    //ป้าย xx% ที่ขึ้นอยู่บนกราฟ  
    dataLabels: { enabled: true },
    plotOptions: { pie: {donut: {labels: {show: true, total:{show:true,fontWeight: 800,fontSize: '22px',color: '#000000', label: centerText}}}}
    },
    //----------------------------------------------------------------------------------------------------------
    responsive: [
      { breakpoint: 768, options: { chart: { height: '350' } } },
      { breakpoint: 680, options: { chart: { height: '290' }, legend: { show: !customLegend, position: 'bottom', width: '80%', height: 150, horizontalAlign: 'center' } } }, 
      { breakpoint: 576, options: { chart: { height: '298' } } },
    ]
  }
  // xs-0, sm-576, md-768, lg-992, xl-1200
  const styleGraph = (customLegend)? "col-12 col-md-7 p-0" : "col-12"
  const styleLegend = (customLegend) ? "col-12 col-md-5 p-1" : "col-12 d-none"
  
  return ( 
    <div className={className}>
      <div className="row">
      <div className="col-12">
        <div className="card" style={{ width: '100%', minWidth: '265px' }}>
          <div className="card-header text-center"><h5>{header}</h5></div>
        
          <div className="card-body">
            {showGraph && dataOrderbyValue && dataOrderbyValue.length > 0 && 
            <div className="row no-gutters">
              <div className={styleGraph} style={{ minWidth: '200px' }}>
                <Chart width='100%' height={gHeight} options={optionsTag} series={gSeries} type="donut" />
              </div>
            
              <div className={styleLegend}  >           
                <div className="row justify-content-center mt-3 "  >
                  <div className="col-12 ">                 
                    {dataOrderbyValue.map((item, index) => {                 
                      return <div key={index} className="text-left container  ">
                        
                        {/* xs-0, sm-576, md-768, lg-992, xl-1200 */}
                        <div className="badge badge-pill d-inline align-text-top " style={{ width: '30px', color:'#ffffff', backgroundColor: item.color }}>{item.value}</div>                 
                        
                        <div className="d-inline-block text-truncate pl-2 " style={{ maxWidth: '82%' }} title={item.name}>{item.name}</div>
                        {(item.name === 'Other') && <div className="text-left"><button className="btn btn-info btn-sm px-2 mt-2 " title="ดูข้อมูล" onClick={() => setShowOther(true)}>See more</button ></div>}
                        
                      </div> 
                      
                    })}
                    {showOther && <div className="container"><button className="btn btn-info btn-sm px-2 mt-2 " title="ย่อข้อมูล" onClick={() => setShowOther(false)}>See less</button ></div>}
                  </div>
                </div>
              </div>             
            
            </div> }
          
            {!showGraph && <span className="text-muted">ไม่มีข้อมูล</span>}

          </div>
      
        </div>   
      </div>
    </div>
    </div>  
  );

}
 
export default GraphDonutBasic;