import React, { useState, useEffect } from 'react';
import SortableTree, { changeNodeAtPath, addNodeUnderParent, removeNodeAtPath, getVisibleNodeCount, getTreeFromFlatData } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FontAwesome from 'react-fontawesome';

import { toast } from 'react-toastify';
import { getManualData, addManualData, editManualData, removeManualData } from '../../../services/manage/data/manual';
import ConfirmDelete from '../../common/confirmDelete';

const DataManual = ({id}) => {

  const loadManualData = async(id) => {
    if(!id) return;

    try {
      const {data: result } = await getManualData(id);

      setData(result.data.data)

      const {level} = result.data
      setLevel(level)

      const rootTag = (level && Object.keys(level).length>0) ? Object.keys(level)[0] : 'root'
      setRootName(level[rootTag].name)

    } catch(err) {
      toast.error('Failed to load data')
    }
  }

  useEffect(() => { loadManualData(id)}, [id])

  const [data, setData] = useState([])
  const [level, setLevel] = useState({})
  const [rootName, setRootName] = useState("root")

  const mapFlatData = (data) => {

    setTreeData(getTreeFromFlatData({
      flatData: data.map(node => ({ ...node })),
      getKey: node => node._id, // resolve a node's key
      getParentKey: node => node.parent, // resolve a node's parent's key
      rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
    }))
  } 

  useEffect(() => {
    mapFlatData(data)
  }, [data])

  const [treeData, setTreeData] = useState([])
  const [rootText, setRootText] = useState('')

  const getChildrenId = (node) => {
    if(node.children && node.children.length>0) {
      const output = node.children.map(x => getChildrenId(x)).flat();

      return [node._id, ...output]
    } else {
      return [node._id]
    }
    
  }

  const confirmNewRoot = async () => {
    try {
      if(!id) return toast.error("id is not found")
      if(!rootText) return toast.error("input can not empty")

      const tag = (level && Object.keys(level).length>0) ? Object.keys(level)[0] : 'root'
      const doc = {tag , name: rootText }

      const { data: result } = await addManualData(id, doc)

      if ( result.status === 'success') {
        const { item } = result.data

        setTreeData(addNodeUnderParent({
          treeData: treeData,
          getNodeKey,
          newNode: item
        }).treeData)

        setRootText('')

      } else {
        toast.error(result.message)
      }

    } catch(err) {
      toast.error(err.message);
    }
  }

  const askNewNode = ({node, path, getNodeKey}) => {
    // console.log(this.state.level)
    const label = (level) ? level[node.childTag].name : "Item";
    const newNode = {_id: 'new', tag: node.childTag, label, parent: node._id, name: ''}

    setTreeData(addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: newNode
    }).treeData)
    
  }

  const confirmNew = async ({node, path, getNodeKey}) => {
    try {
      if(!id) return toast.error("id is not found")

      if(!node.name) return toast.error("input is empty")

      const doc = {tag: node.tag, name: node.name, parent: node.parent}

      const { data: result } = await addManualData(id, doc)

      if ( result.status === 'success') {
        const { item } = result.data

        setTreeData(changeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey,
          newNode: item,
        }))

      } else {
        toast.error(result.message)
      }

    } catch(err) {
      toast.error(err.message);
    }
  }

  const [askEdit, setAskEdit] = useState(undefined)

  const confirmEdit = async ({node, path, getNodeKey}) => {
    try {

      if(!node.name) return toast.error("input is empty")

      const doc = {_id: node._id, tag: node.tag, name: node.name, parent: node.parent}

      const { data: result } = await editManualData(id, doc)

      if ( result.status === 'success') {
        const { item } = result.data;

        setTreeData(changeNodeAtPath({
          treeData: treeData,
          path,
          getNodeKey,
          newNode: item,
        }))

        setAskEdit(undefined)

      } else {
        toast.error(result.message)
      }

    } catch(err) {
      toast.error(err.message);
    }

  }

  const cancelEdit = ({node, path, getNodeKey}) => { 
    setTreeData(changeNodeAtPath({
      treeData: treeData,
      path,
      getNodeKey,
      newNode: {...askEdit},
    }))

    setAskEdit(undefined)
  }

  const [askDelete, setAskDelete] = useState(undefined)

  const confirmDelete = async () => {

    if(!askDelete) return

    try {
      if(!id) return toast.error("id is not found")

      const { node, path, key} = askDelete

      if ( node && path && key) {
        const doc = {_id: getChildrenId(node) }

        const { data: result } = await removeManualData(id, doc)

        if ( result.status === 'success') {

          setTreeData(removeNodeAtPath({
            treeData: treeData,
            path,
            getNodeKey: key
          }))

        } else {
          toast.error(result.message)
        }

      } else {
        toast.error("invalid ask delete info");
      }
    } catch(err) {
      toast.error(err.message);
    }

    
    setAskDelete(undefined)

  }

  const getNodeKey = ({ treeIndex }) => treeIndex;
  const count = getVisibleNodeCount({treeData})

  return ( 
    <React.Fragment>
        <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={() => setAskDelete(undefined)} />
        {treeData && 
        <div>
          <SortableTree
            style={{height: count * 62}} // height of each node
            treeData={treeData}
            onChange={treeData => setTreeData(treeData)}
            generateNodeProps={({ node, path }) => 
            {

              // console.log(askNew);
              // console.log(node)
              const isChildable = (node.childTag)
              const isNew = (node._id==='new') || false
              const isEdit = (askEdit && askEdit._id===node._id) || false

              const buttonAdd = (!isEdit && isChildable) ?'inline' : 'none'
              const buttonMainFlag = (isEdit || isNew) ? 'none' : 'inline'
              const buttonEditFlag = (isEdit) ? 'inline' : 'none'
              const buttonNewFlag = (isNew) ? 'inline' : 'none'
              // console.log(buttonEditFlag)


              const titleObj = 
                (isNew)
                ? <div>{node.label} <input style={{ fontSize: '1.1rem', backgroundColor: '#FFC300' }} value={node.name} 
                onChange={event => {
                    const name = event.target.value;

                    setTreeData(changeNodeAtPath({
                      treeData: treeData,
                      path,
                      getNodeKey,
                      newNode: { ...node, name },
                    }))

                  }} 
                  placeholder='เพิ่มรายการใหม่' autoFocus /></div>
                : (isEdit) 
                ? <div>{node.label} <input className="ml-2" style={{ fontSize: '1.1rem', backgroundColor: '#FFC300' }} value={node.name} 
                onChange={event => {
                    const name = event.target.value;

                    setTreeData(changeNodeAtPath({
                      treeData: treeData,
                      path,
                      getNodeKey,
                      newNode: { ...node, name },
                    }))

                  }} 
                /></div>
                : <span >{node.label} : {node.name}</span>
            
              return ({
                canDrag: false,
                title: (titleObj),
                buttons: [
                  <button
                    style={{display: buttonMainFlag}}
                    onClick={() => setAskEdit(node)}
                  ><FontAwesome name="wrench" /></button>,
                  <button
                    style={{display: buttonAdd}}
                    onClick={() => askNewNode({node, path, getNodeKey})}
                  ><FontAwesome name="plus-circle" /></button>,
                  <button
                    style={{display: buttonMainFlag}}
                    onClick={() => setAskDelete({node, path, key: getNodeKey})}
                  ><FontAwesome name="minus-circle" /></button>,
                  <button
                    style={{display: buttonEditFlag}}
                    onClick={() => confirmEdit({node, path, getNodeKey})}
                  ><FontAwesome name="check-circle-o" /></button>,
                  <button
                    style={{display: buttonEditFlag}}
                    onClick={() => cancelEdit({node, path, getNodeKey})}
                  ><FontAwesome name="ban" /></button>,
                  <button
                    style={{display: buttonNewFlag}}
                    onClick={() => confirmNew({node, path, getNodeKey})}
                  ><FontAwesome name="check-circle-o" /></button>,
                  <button
                    style={{display: buttonNewFlag}}
                    onClick={() =>
                      setTreeData(removeNodeAtPath({
                        treeData: treeData,
                        path,
                        getNodeKey,
                      })) }
                  ><FontAwesome name="ban" /></button>,
                ],
              })
            }
          }
            />
          <div style={{maxWidth: 300, marginTop: 16, marginLeft: 44, marginBottom: 20}}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">{rootName}</span>
              </div>
              <input type="text" value={rootText} className="form-control" placeholder="เพิ่มข้อมูล" onChange={e => setRootText(e.target.value)} />
              <div className="input-group-append">
                <button className="btn btn-outline-primary" onClick={() => confirmNewRoot()} ><FontAwesome name="plus-circle" /></button>
              </div>
            </div>
          </div>
        </div>}

      </React.Fragment>
   );
}
 
export default DataManual;