import React, {useState, useEffect} from 'react';
import SearchBox from "../../../common/searchBox";
import { toast } from "react-toastify";
// import _ from "lodash";
// import dlv from 'dlv'

import moment from 'moment';

import FilterDateCombo from '../../../common/filter/filterDateCombo';
// import FontAwesome from 'react-fontawesome';
import { getReportAdminSummary } from '../../../../services/report/reportAdmin';
// import ReportDomainToday from './domainToday';
import ReportDomainHistory from './domainHistory';

const ReportDomainSummary = () => {

  const [search, setSearch] = useState('')
  const [filter, setDateFilter] = useState({filter: 'today', date: moment().format('YYYY-MM-DD')})  //useState({filter: 'day', date: '2020-05-02'})
  const [report, setReport] = useState({})

  useEffect(() => {
    let isSubscribed = true
    const loadList = async() => {
      try {
          const { data: result } = await getReportAdminSummary(filter)
          if(result.status==='success') {

            if(isSubscribed) {
              setReport(result.data.report)
            }
          } else {
              toast.error(result.message)
          }
      } catch (error) {   }
    }

    loadList()

    return () => {  
      isSubscribed = false
    }

  }, [filter])


  return ( 
    <React.Fragment>
    <div className="row justify-content-end">
      <div className="col-auto mr-auto mb-3">
        <h4>Admin Report</h4>
      </div>
      <div className="col-auto mb-3 d-none">
        <SearchBox value={search} onChange={value => setSearch(value)} />
      </div>
      <div className="col-auto ml-3 mb-3">
        <FilterDateCombo enableFilter={['today', 'day', 'week', 'month']}  notifyDateFilter={filter => setDateFilter(filter)} />
      </div>
    </div>
    {/* {['today'].includes(filter.filter) && <ReportDomainToday report={report} filter={filter} />} */}
    {['today','day', 'week', 'month'].includes(filter.filter) && <ReportDomainHistory report={report} filter={filter} />}
    </React.Fragment>
  );
}
 
export default ReportDomainSummary;