import React from 'react';
// import FontAwesome from 'react-fontawesome';
import { useEffect, useState } from 'react';
import PersonPhoto from '../card/personPhoto';
import FontAwesome from 'react-fontawesome';
import { getPerson } from '../../../services/manage/staff/teamService';

const BadgePersonGroup = ({userId, buttonIcon, buttonClass, onClick, styles={ showBorder: true, photoSize: 35 }}) => {

//   const { userId, type='person', userName, company, sim , position, department } = data;

  const { showBorder=true, photoSize=35 } = styles

  const searchBoxStyle = (showBorder) ? {
    borderRadius: 10,
    border: '1px solid #BBBBBB66',
    padding: 2
  } : {}

  const [name, setName] = useState('')
  const [person, setPerson] = useState([])

  useEffect(() => {

    const loadPersonGroup = async() => {
        try {
            if(userId) {
                const {data: result } = await getPerson(userId)
                if(result.status==='success') {
                    setName(result.data.data.name)
                    setPerson(result.data.data.list)
                }
    
                
            }
            
        } catch (error) {
            
        }
    }

    loadPersonGroup()

  }, [userId])



    return ( 
        <React.Fragment>
            <div className="row no-gutters align-items-center m-0" style={searchBoxStyle}>
                <div className="col-auto">
                    <div className="row no-gutters align-items-ceneter">
                        <div className="col-auto px-2 py-1">
                            <h6 className="d-inline"><FontAwesome name="users" /> {name}</h6>
                        </div>
                        {person.length>0 && 
                            person.filter((x,i)=> i<5).map(item => {
                            return (<div key={item.userId || item._id} className="col-auto">
                                <PersonPhoto id={item.userId} size={photoSize} tooltip={item.userName} />
                            </div>)}
                            )
                        }
                        {person.length>5 && <div className="col-auto">
                            <h4 className="d-inline"><span className="badge badge-pill badge-light">+{person.length-5}</span></h4>
                        </div>}
                    </div>
                    
                    
                </div>
                {buttonIcon && (typeof(onClick)==='function') && <div className="col-auto p-1">
                    <FontAwesome style={{cursor: 'pointer'}} className={buttonClass} name={buttonIcon} onClick={() => onClick(userId)} size="2x" /></div>}
            </div>
        </React.Fragment>
        );
    
}
 
export default BadgePersonGroup;