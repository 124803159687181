import React, {useState, useEffect, useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';
import { templateFlowList, deleteTemplateFlow } from '../../../services/manage/flow/manageFlowService';
import { DomainContext } from './../../../context/domainContext';
import TemplateFlowCard from './flowCard';

const TemplateFlows = () => {

    const {domain } = useContext(DomainContext)

    useEffect(() => {
        const getList = async() => {
            try {
                const {data: result} = await templateFlowList();
    
                if(result.status==='success') {
                    setList(result.data.list)
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        if(domain) getList()
    }, [domain])

    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState('')


    const confirmDelete = async() => {
        try {
            await deleteTemplateFlow(askDelete);

            setList(prev => [...prev.filter(x=> x._id!==askDelete)])
            setAskDelete(undefined)

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col"><h4>Flows</h4></div>
                <div className="col-auto p-0">
                    <NavLink className="nav-item nav-link text-dark" to={`/manage/flow/new`} >
                    <button type="button" className="btn btn-primary text-white" >New</button>
                    </NavLink>
                    
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            {list.length>0 && <div className="row">
            {list.map(item => 
                <div key={item._id} className="col-12">
                    <TemplateFlowCard data={item} onRemove={() => setAskDelete(item._id)} />
                </div>
                )
            }
            </div>}
        </React.Fragment>
     );
}
 
export default TemplateFlows;