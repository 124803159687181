import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
import { getWorkHourFromTotalMinutes } from './../../../../utils/work';
import PhotoDisplay from '../../../common/card/viewPhoto';

const CaptureDailyTaskCard = ({data }) => {

  const { _id, name, status, date, photos } = data;
  const { start, update, finish, delete: dateDelete, trackTime  } = date || {}

  const workTime = getWorkHourFromTotalMinutes(trackTime)
  const statusColor = (status==='finish') ? 'success' : (status==='wait') ? 'info' : (status==='active') ? 'warning' : 'secondary'
  const isDelete = (status==='delete')
  const isPause = (status==='pause')

  return ( 
    <React.Fragment>
      <div className="card mb-3 text-dark p-0">
        <div className="card-body p-0">
        <NavLink className="stretched-link" to={`/report/work/${_id}`} ></NavLink>
        <div className="row no-gutters align-items-center justify-content-end">
          <div className={`col-auto bg-${statusColor}`} style={{width: 10, height: 80}}></div>
          <div className="col ml-2 p-2">
            <h5 className="font-weight-bold">{name}
              {status!=='delete' && <span className={`ml-2 text-${statusColor}`}><FontAwesome name="clock-o" /> {workTime}</span>}
              {isDelete && <span className="ml-2 badge badge-secondary">ยกเลิก</span>}
              {isPause && <span className="ml-2 badge badge-secondary">หยุด</span>}
            </h5>
            <div className="row justify-content-start col">
              <div><FontAwesome name="clock-o" /> เริ่มต้น <span className="text-dark">{start}</span></div>
              {(!finish && start!==update) && !dateDelete && <div className="ml-3"><FontAwesome name="clock-o" /> ล่าสุด <span className="text-dark">{update}</span></div>}
              {(finish) && !dateDelete && <div className="ml-3"><FontAwesome name="clock-o" /> เสร็จสิ้น <span className="text-dark">{finish}</span></div>}
              {dateDelete && <div className="ml-3"><FontAwesome name="clock-o" /> ลบ <span className="text-dark">{dateDelete}</span></div>}
            </div>
          </div>
          {photos && photos.length>0 && <div className="col-auto mr-2">
              {photos.map(photo => {
                return (
                  <div key={photo.url} className="d-inline ml-2"><PhotoDisplay url={photo.url} height={70} /></div>
                  
                )
              })}
              
          </div>}
        </div>
        
        </div>
      </div>
    </React.Fragment>
    );
}
 
export default CaptureDailyTaskCard;