import React from 'react';
import { useState } from 'react';
import ButtonGroup from './../../../common/buttonGroup';
import moment from 'moment'
import { useEffect } from 'react';
import dlv from 'dlv';
import Flatpickr from 'react-flatpickr';
import ButtonGroupMulti from './../../../common/buttonGroupMulti';
import _ from 'lodash'

const PlanRecurrence = ({data, notifyChange}) => {

    const modeList = [{name: 'Daily', value: 'daily'}, {name: 'Weekly', value: 'weekly'}, {name: 'Monthly', value: 'monthly'}]

    const [timeMode, setTimeMode] = useState(dlv(data, 'timeMode', 'all'))
    const [timeBegin, setTimeBegin] = useState(dlv(data, 'timeBegin', '08:00'))
    const [timeEnd, setTimeEnd] = useState(dlv(data, 'timeEnd', '17:00'))

    const dayList = [{name: 'Monday', value: 1}, 
        {name: 'Tuesday', value: 2},
        {name: 'Wednesday', value: 3},
        {name: 'Thursday', value: 4},
        {name: 'Friday', value: 5},
        {name: 'Saturday', value: 6},
        {name: 'Sunday', value: 0},
        ]
    
    const [dayMode, setDayMode] = useState(dlv(data, 'dayMode', 'all'))
    const [selectedDays, setSelectedDays] = useState(dlv(data, 'selectedDays', []))

    const [monthlyDate, setMonthlyDate] = useState(dlv(data, 'dayOfMonth',[]).join(','))
    const [monthlyExplain, setMonthlyExplain] = useState('')

    const [mode, setMode] = useState(modeList.find(x=> x.value===dlv(data, 'mode.value', 'daily')))

    const [error, setError] = useState()
    
    useEffect(() => {
        if(notifyChange instanceof Function) {

            if(mode.value==='weekly' && dayMode==='custom') {
                if(selectedDays.length===0) return setError('Selected Day is empty')
            } else if (mode.value==='monthly') {

                if( monthlyDate.replace(/\s/g,'').length===0) return setError('Date is empty')
                if(!monthlyDate.replace(/\s/g,'').match(/^([0-9]|End|,|(Mon|Tue|Wed|Thu|Fri|Sat|Sun)([1-4]|End))*$/g)) return setError('Invalid Day of Month')

                let monthlyExplain = ''
                const dateTrack = monthlyDate.replace(/\s/g,'').split(',')
                let dayInvalid = false
                dateTrack.forEach(item => {
                    if(item==='End') {
                        monthlyExplain += 'Last Date of Month\n'
                    } else if(item.length>=4 && ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].includes(item.substring(0, 3))) {
                        
                        const suffix = item.substring(3)
                        if(! ['1', '2', '3', '4', 'End'].includes(suffix)) dayInvalid =true

                        const dayLabel = moment(item.substring(0,3), 'ddd').format('dddd')

                        switch(suffix) {
                            case 'End': monthlyExplain += `Last ${dayLabel} of a month\n`; break;
                            case '1' : monthlyExplain += `First ${dayLabel} of a month\n`; break;
                            case '2' : monthlyExplain += `Second ${dayLabel} of a month\n`; break;
                            case '3' : monthlyExplain += `Third ${dayLabel} of a month\n`; break;
                            case '4' : monthlyExplain += `Forth ${dayLabel} of a month\n`; break;
                            default: break;
                        }
                        
                    } else {
                        const parseDay = parseInt(item)
                        if(isNaN(parseDay)) dayInvalid = true
                        if(parseDay<1 || parseDay>31) dayInvalid = true

                        monthlyExplain += `Date ${parseDay} of a month\n`
                    }
                })

                if(dayInvalid) return setError('Day of Month is invalid')

                setMonthlyExplain(monthlyExplain)
            }

            if(timeMode==='custom') {
                if(!moment(timeBegin, "HH:mm", true).isValid()) return setError('Begin time is invalid')
                if(!moment(timeEnd, "HH:mm", true).isValid()) return setError('End time is invalid')

                if(moment(timeBegin, 'HH:mm').isSameOrAfter(moment(timeEnd, 'HH:mm'))) return setError('End time must greater than Begin')
            }

            setError()

            const output = { mode }
            if(mode.value==='daily') {
                output.timeMode = timeMode
                if(timeMode==='custom') {
                    output.timeBegin = timeBegin
                    output.timeEnd = timeEnd
                }
            } else if(mode.value==='weekly') {
                output.dayMode = dayMode
                if(dayMode==='custom') {
                    output.selectedDays = selectedDays
                }

                output.timeMode = timeMode
                if(timeMode==='custom') {
                    output.timeBegin = timeBegin
                    output.timeEnd = timeEnd
                }
            } else if(mode.value==='monthly') {
                const days = _.uniq(monthlyDate.replace(/\s/g,'').split(','))
                output.dayOfMonth = days

                output.timeMode = timeMode
                if(timeMode==='custom') {
                    output.timeBegin = timeBegin
                    output.timeEnd = timeEnd
                }
            }

            notifyChange(output)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, dayMode, selectedDays, timeMode, timeBegin, timeEnd, monthlyDate])

    useEffect(() => {
        if(notifyChange instanceof Function) {
            notifyChange(prev => { return {...prev, error } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, notifyChange])

    const headerWidth = 80

    const calendarOptions = {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      };


    return ( 
        <div className="card">
            <div className="card-header font-weight-bold">Create Auto Plan</div>
            <div className="card-body">
                <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>Mode</div>
                    <div className="col-auto mr-3">
                        <ButtonGroup
                            items={modeList}
                            selectedItem={mode}
                            onItemSelect={item => setMode(item)}
                            selectedItemClass="btn btn-dark"
                            defaultItemClass="btn btn-light btn-outline-dark"
                            />
                    </div>
                </div>
                {['weekly'].includes(mode.value) && <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>Day</div>
                    <div className="col-auto">
                        <ButtonGroup 
                            items={[{name: 'Every Day', value: 'all'}, {name: 'Custom', value: 'custom'}]}
                            selectedValue={dayMode}
                            onItemSelect={item => setDayMode(item.value) }
                            selectedItemClass="btn btn-dark"
                            defaultItemClass="btn btn-light btn-outline-dark"
                        />
                        {dayMode==='custom' && <ButtonGroupMulti 
                            className='btn-group p-0 m-0 ml-3'
                            items={dayList} 
                            initSelectItems={selectedDays} valueProperty='value' 
                            selectedItemClass='btn btn-dark btn-outline-light' 
                            notifyChange={items => setSelectedDays(items)} />}
                    </div>
                </div>}
                {['monthly'].includes(mode.value) && 
                <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: 150}}>Day of Month</div>
                    <div className="col-auto mr-2">
                    <input className="form-control font-weight-bold" type='text' value={monthlyDate} onChange={e => setMonthlyDate(e.target.value)} style={{width:500}} />
                    </div>
                    <div className="col-12">
                        <span className="text-muted">Format : day [,day] (Example: 1,5,7,20,25,26)(Special: Mon1 = First Monday | Fri2 = Second Friday)</span>
                        <span className="text-muted"><br />(Special2: 'End' is last day of month or last day of week)(Example: FriEnd = Last Friday of Month)</span>
                    </div>
                    {monthlyExplain && !error && <div className="col-12 text-info mt-4">
                        {monthlyExplain.split ('\n').filter(x=>x).map ((item, i) => <p key={i} style={{lineHeight:'0.8'}}>{(i+1)}. {item}</p>)}
                    </div>}
                </div>}
                {['daily', 'weekly', 'monthly'].includes(mode.value) && <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>Time</div>
                    <div className="col-auto mr-3">
                        <ButtonGroup
                            items={[{name: 'All Day', value: 'all'}, {name: 'Custom', value: 'custom'}]}
                            selectedValue={timeMode}
                            onItemSelect={item => setTimeMode(item.value)}
                            selectedItemClass="btn btn-dark"
                            defaultItemClass="btn btn-light btn-outline-dark"
                            />
                    </div>
                </div>}
                {['daily', 'weekly', 'monthly'].includes(mode.value) && ['custom'].includes(timeMode) && <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>Begin</div>
                    <div className="col-auto" style={{width: 80}}>
                        <Flatpickr
                            className='form-control'
                            value={timeBegin}
                            onChange={(_,str) => setTimeBegin(str) }
                            options={calendarOptions}
                            />
                    </div>
                    
                </div>}
                {['daily', 'weekly', 'monthly'].includes(mode.value) && ['custom'].includes(timeMode) && <div className="row no-gutters align-items-center mb-3">
                    <div className="col-auto" style={{width: headerWidth}}>End</div>
                    <div className="col-auto" style={{width: 80}}>
                        <Flatpickr
                            className='form-control'
                            value={timeEnd}
                            onChange={(_,str) => setTimeEnd(str) }
                            options={calendarOptions}
                            />
                    </div>
                </div>}
                {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
            </div>
        </div>
     );
}
 
export default PlanRecurrence;