import React, {useState } from 'react';
import { toast } from 'react-toastify';

import { saveStaff } from '../../../services/manage/staff/staffService';
import PersonCard from './../../common/card/personCard';

const StaffCard = ({ data={}, onRemove=()=>{}, onEdit=()=>{} }) => {

    const {_id } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await saveStaff({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update staff')
        }
    }

  return ( 
    <React.Fragment>
        <PersonCard data={data} enableToggle={true} enable={enable}
            onSetting={onEdit}  onRemove={onRemove} onToggle={({value})=> toggleEnable(value)} />
    </React.Fragment>
    );
    
}
 
export default StaffCard;