import React from 'react';
import FontAwesome from 'react-fontawesome';

const SearchItem = ({data, onConfirm, textProperty='name', icon='video-camera'}) => {

  const selectItem = (item) => {
    if( typeof(onConfirm)==='function' ) {
      onConfirm(item)
    }
  }

  return ( 
    <React.Fragment>
          <div className="card mb-2 p-0" style={{width: '100%'}}>
              <div className="card-body p-2">
                  <div className="row align-items-center justify-content-between">
                      <div className="col-9">
                          <div className="container">
                            <FontAwesome className="mr-3" name={icon} />
                            <h5 className="card-title d-inline mr-2">{data[textProperty]}</h5>
                          </div>
                      </div>
                      <div className="col-3 text-right">
                        <button className="btn btn-primary p-1" onClick={() => selectItem(data)}>เลือก</button>
                      </div>
                  </div>
                  
              </div>
          </div>
        </React.Fragment>
   );
}
 
export default SearchItem;