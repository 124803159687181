import React, {useState, useContext, useEffect} from 'react';
import SearchBox from "../../common/searchBox";
import { toast } from "react-toastify";
// import _ from "lodash";

import moment from 'moment';
import jsfiledownload from 'js-file-download'

import { DomainContext } from '../../../context/domainContext';
import FilterDateCombo from '../../common/filter/filterDateCombo';
import DashboardToday from './dashboardToday';
import { getDashboardSummary } from '../../../services/report/reportDashboard';
import socketIOClient from '../../../services/app/socketioService';
// import FontAwesome from 'react-fontawesome';
import { downloadTask } from '../../../services/download/dashboard';
import DashboardHistory from './dashboardHistory';
import ButtonDownload from './../../common/button/buttonDownload';

const Dashboard = () => {

  const {domain } = useContext(DomainContext)
  const [search, setSearch] = useState('')
  const [filter, setDateFilter] = useState({filter: 'today', date: moment().format('YYYY-MM-DD')})  //useState({filter: 'day', date: '2020-05-02'})
  const [report, setReport] = useState({})

  useEffect(() => {
    const loadList = async() => {
      try {
          const { data: result } = await getDashboardSummary(filter)
          if(result.status==='success') {

            setReport(result.data.report)
  
          } else {
              toast.error(result.message)
          }
      } catch (error) {
          
      }
    }

    if(domain) loadList()


    socketIOClient.on('report-dashboard-main', (message) => {    
      // console.log('socketio', message)
      // console.log('domain', domain)
      // console.log('filter', filter)
      const { domainid, workDate } = message
      if(!domainid || !workDate ||!domain) return

      if(domainid===domain._id.toString() && filter.filter==='today' ) {
        loadList()
      }     
    })
    return () => { socketIOClient.off('report-dashboard-main')  }

  }, [filter, domain])

  const handleReportDownload = () => {
    return new Promise(async (resolve, reject) => {
      try {

        const input = { date: filter.date }

        if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
        if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
        if(filter.filter==='custom') input.dateEnd = filter.dateEnd
        
        const {data: result, headers } = await downloadTask(input)

        if( result.status==='error') {
          toast.error(result.message)
        } else {
            if ( headers['x-suggested-filename']==='nodata') {
                toast.error("No Report")
            } else {
                jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
            }
        }

        setTimeout(() => {resolve('ok')}, 300);
          
      } catch(err) { 
          toast.error("Error : ", err.message)
          resolve('error')
      }
    })

  }


  if(!domain) {
    return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
  }

  return ( 
    <React.Fragment>
    <div className="row justify-content-end">
      
      <div className="col-auto mr-auto mb-3">
        <h4>Dashboard</h4>
      </div>
      <div className="col-auto mb-3 d-none">
        <SearchBox value={search} onChange={value => setSearch(value)} />
      </div>
      <div className="col-auto p-0">
        {/* <h5 className="text-danger d-none">ปิดใช้งานปุ่ม Download ชั่วคราว</h5> */}
        <ButtonDownload label='Download' onClick={handleReportDownload} />
      </div>
      <div className="col-auto ml-3 mb-3">
        <FilterDateCombo enableFilter={['today', 'day', 'week', 'month', 'custom2']}  notifyDateFilter={filter => setDateFilter(filter)} />
        {/* <span className="text-danger">(ปิดการใช้ Weekly, Monthly ชั่วคราว)</span> */}
      </div>
    </div>

    {['today'].includes(filter.filter) && <DashboardToday report={report} filter={filter} />}
    {['day', 'week', 'month'].includes(filter.filter) && <DashboardHistory report={report} filter={filter} />}
    </React.Fragment>
  );
}
 
export default Dashboard;