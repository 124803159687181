import React from 'react';
import GraphBarBasic from './../../common/graph/basicBar';
import GraphDonutBasic from '../../common/graph/basicDonut';
// import ReportStaffList from './../staff/staffList';
import dlv from 'dlv'

const DashboardToday = ({report, filter}) => {

    const { staffTotal='-', staffActive='-', jobValueTotal='-' } = report
    const { taskTotal='-', taskWait='-', taskActive='-', taskPause='-', taskFinish='-', taskDelete='-' } = report
    const { groupByTask, groupByProject, groupByHour } = report

    const hourlyNumbers = (groupByHour || []).filter(x=>x.number>0).map(x=>x.number)
    const hourlyMaxMinDiff = hourlyNumbers.length>0 ? Math.max(...hourlyNumbers) - Math.min(...hourlyNumbers) : 0

    return ( 
    <React.Fragment>
        <div className="row justify-content-center mb-2">
            <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                <div className='col-auto mx-2 text-center'>
                    <h4 className="d-inline">Staff</h4>
                </div>
                <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-light">{staffTotal}</span></h3>
                    Total
                </div>
                <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-primary">{staffActive}</span></h3>
                    Active
                </div>
                </div>
                </div>
            </div>
            </div>
            {jobValueTotal>0 && <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                <div className='col-auto mx-2 text-center'>
                    <h3 className="d-inline">Job Value</h3>
                </div>
                <div className='col-auto mx-2 text-center'>
                    <h2><span className="badge badge-pill badge-primary">
                        {jobValueTotal>0 ? <span>{jobValueTotal.toLocaleString()} &#3647;</span> : <span>-</span>}</span>
                    </h2>
                    Total
                </div>
                </div>
                </div>
            </div>
            </div>}
            <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                    <div className='col-auto mx-2 text-center'>
                    <h4 className="d-inline">Work</h4>
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-primary">{taskTotal}</span></h3>
                    Total
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-info">{taskWait}</span></h3>
                    Plan
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-warning">{taskActive}</span></h3>
                    Active
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-secondary">{taskPause}</span></h3>
                    Pause
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-success">{taskFinish}</span></h3>
                    Finish
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-secondary">{taskDelete}</span></h3>
                    Cancel
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
          {dlv(groupByProject, 'data',[]).find(x=>x>0) && <GraphDonutBasic className='mb-3' input={groupByProject} header="Active/Finish Work Group by Project" />}
          {dlv(groupByTask, 'data',[]).find(x=>x>0) && <GraphDonutBasic className='mb-3' input={groupByTask} header="Active/Finish Work Group by Task" />}
          {taskFinish>0 && groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Finish by Hour' height={hourlyMaxMinDiff>10 ? 300 : 200} />}
          {/* {staffList && <ReportStaffList className="mt-4" data={staffList} filter={filter} />} */}
    </React.Fragment>
     );
}
 
export default DashboardToday;