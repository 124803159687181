import React from 'react';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import ReportStaffList from './../staffList';

const StaffToday = ({report, filter}) => {

    const { timeTotal=0, jvTotal=0, staffTotal=0, staffActive=0 } = report
    const { workTotal='-', taskWait='-', workActive='-', taskPause='-', taskFinish='-', taskDelete='-' } = report

    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { staffList } = report

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-center mt-2">
                <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-3">
                    <div className="row justify-content-center align-items-center">
                    <div className='col-auto mx-1 text-center'>
                        <h4 className="d-inline">Staff</h4>
                    </div>
                    <div className='col-auto mx-1 text-center'>
                        <h3><span className="badge badge-pill badge-light">{staffTotal}</span></h3>
                        Total
                    </div>
                    <div className='col-auto mx-1 text-center'>
                        <h3><span className="badge badge-pill badge-primary">{staffActive}</span></h3>
                        Active
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-3">
                    <div className="row justify-content-center align-items-center">
                    <div className='col-auto mx-1 text-center'>
                        <h4 className="d-inline">Work Time</h4>
                    </div>
                    <div className='col-auto mx-1 text-center'>
                        <h3><span className="badge badge-pill badge-success">{workTime}</span></h3>
                        Finish
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                {jvTotal>0 && <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-3">
                    <div className="row justify-content-center align-items-center">
                    <div className='col-auto mx-1 text-center'>
                        <h4 className="d-inline">Job Value</h4>
                    </div>
                    <div className='col-auto mx-1 text-center'>
                        <h3><span className="badge badge-pill badge-success">
                            {jvTotal>0 ? <span>{jvTotal.toLocaleString()} &#3647;</span> : <span>-</span>}</span>
                        </h3>
                        Total
                    </div>
                    </div>
                    </div>
                </div>
                </div>}
                <div className='col-auto mx-1 mb-3'>
                <div className="card">
                    <div className="card-body p-3">
                    <div className="row justify-content-center align-items-center">
                        <div className='col-auto mx-2 text-center'>
                        <h4 className="d-inline">Work</h4>
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-primary">{workTotal}</span></h3>
                        Total
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-info">{taskWait}</span></h3>
                        Plan
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-warning">{workActive}</span></h3>
                        Active
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-secondary">{taskPause}</span></h3>
                        Pause
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-success">{taskFinish}</span></h3>
                        Finish
                        </div>
                        <div className='col-auto mx-2 text-center'>
                        <h3><span className="badge badge-pill badge-secondary">{taskDelete}</span></h3>
                        Cancel
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {staffList && <ReportStaffList className="mt-3" data={staffList} filter={filter} />}
            
        </React.Fragment>
     );
}
 
export default StaffToday;