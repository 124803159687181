import React, {useState, useEffect} from "react";
// import Joi from "joi-browser";
// import Form from "../common/form";
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import ConfirmDelete from './../common/confirmDelete';
import { savePerson, getPerson } from '../../../services/manage/staff/teamService';
import { NavLink } from 'react-router-dom';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';
import PersonCard from '../../common/card/personCard';

const TeamManage = ({history, match}) => {

  const [id,] = useState(match.params.id)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [personList, setPersonList] = useState([])

  const loadForm = async(formId) => {

    if(formId && formId!=='new') {
      try {
        const {data: result } = await getPerson(formId);

        const { data } = result.data

        setName(data.name)
        setDescription(data.description)
        setPersonList(data.list)

      } catch(err) {
        toast.error('Failed to load form data')
      }
    }
  }

  useEffect(() => {
    loadForm(id)
  }, [id])

  const removePerson = (index) => {
    const newData = [...personList]
    newData.splice(index, 1)

    setPersonList(newData)
  }

  const confirmSelectPerson = (item) => {

    if(!personList.find(x=> x.empid===item.empid)) {
      setPersonList([...personList, item])
    }
  }


  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    if(!personList || personList.length<1) return toast.error('Person is empty', toastOptions)

    const doc = { name, description, list: personList, _id: (id==='new') ? undefined : id  }

    try {
      const { data: result } = await savePerson(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/manage/staff"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }

  }

    return (
      <React.Fragment>
          <div className="row align-items-center mb-4">
            <div className="col-auto" style={{width: 300}}>
                <h5 className="font-weight-bold">Staff Team</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            </div>
          </div>
          <div className="row align-items-center mb-4">
            <div className="col-auto text-muted" style={{width: 300}}>
                <h5 className="font-weight-bold">Description</h5>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
            </div>
          </div>
          {personList.length>0 && 
            <div className="row">
              <div className="col">
                  {personList &&
                      personList.map((x, index) => {
                          return (<PersonCard key={x.userId} className="mt-2" data={x} onRemove={() => removePerson(index)} />)
                      })
                  }
              </div>
            </div>
          }
          {personList.length===0 && 
          <div className="alert alert-warning" role="alert">
            Person is empty
          </div>
          }
          <div className="row mt-3">
            <div className="col text-right">
              <DialogSearchPerson cache={false} notifySelect={item => confirmSelectPerson(item) } />
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-auto text-center">
            <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save</button>
            
            </div>
            <div className="col-auto">
              <NavLink className="nav-item nav-link p-0" to={`/manage/staff`} >
              <button type="button" className="btn btn-secondary text-white" >Cancel</button>
              </NavLink>
            </div>
          </div>
      </React.Fragment>
    );
  
}

export default TeamManage;
