import React, { useState } from 'react';
import DialogZoomRoom from './manageForm';

const DialogCreateZoomRoom = ({ buttonAdd='New', notifySave, buttonStyle='btn-primary'}) => {

  const [askManage, setAskManage] = useState({isOpen: false, id: undefined})

  const confirmSave = (item) => {

    setAskManage({isOpen: false, id: undefined})
    if ( notifySave instanceof Function) {
        notifySave(item)
    }
    
  }


  return ( 
  <div className="d-inline">
    <DialogZoomRoom key={Date.now()} isOpen={askManage.isOpen} id={askManage.id} onCancel={() => setAskManage({isOpen: false})} onSave={confirmSave} />
    <button className={`btn ${buttonStyle}`} onClick={() => setAskManage({isOpen: true, id: 'new'})}>{buttonAdd}</button>
  </div> );
    
}
 
export default DialogCreateZoomRoom;