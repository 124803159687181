import React, {useState, useEffect} from 'react';
import FlowViewTableItemEdit from './tableItemEdit';
import FontAwesome from 'react-fontawesome';
import DynamicForm from '../../dynamic/form';
import Joi from "joi-browser";
import { toast } from 'react-toastify';
import { updateWork } from '../../../services/work/workService';

const WorkCard = ({data, index, step, notifyUpdate, user}) => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    const [edit, setEdit] = useState(false)
    const [schema, setSchema] = useState({})
    const [formData, setFormData] = useState()
    const [error, setError] = useState(undefined)

    const updateSchema = (data) => {
        if(!data) return

        if(data.template && data.template.forms) {
            const newSchema = {}
            data.template.forms.forEach((form, i) => {
                const { formType, required } = form;
                const { value:type } = formType
                const sche = {}

                if(type==='approve') {
                    sche['approve'] = Joi.number().required()
                    sche['comment'] = Joi.string().allow('')
                    newSchema[i] = sche

                } else if(type==='text') {
                    sche['text'] = (required) ? Joi.string().required() : Joi.string().allow('')
                    newSchema[i] = sche

                } else if(type==='list') {
                    newSchema[i] = Joi.array().items(Joi.object({_id: Joi.string().required(), label: Joi.string().required(), name: Joi.string().required()}))
                }

                // console.log(sche)
                
            })

            setSchema(newSchema)
        }
    }

    const validate = () => {
        const options = { abortEarly: false };
        const {error} = Joi.validate(formData, schema, options);
    
        if (!error) {
          return undefined
        } else {
          const errors = {};
          for (let item of error.details) {
            errors[item.path[0]] = item.message;
          }
      
          return errors;
        }
    }

    useEffect(() => {
        updateSchema(data)
    }, [data])


    const {template, person } = data;
    const stepColor = ((step-1)>index) ? "badge-success" : ((step-1)<index) ? "badge-secondary" : "badge-warning"

    const handleSaveChange = (item, index) => {
        
    }

    const handleWorkSave = async () => {
        console.log('schema', schema)
        console.log('formdata', formData)

        const checkError = validate()
        console.log(checkError)
        setError(checkError)
        if(checkError) return

        if(data.template && data.template.forms && data.template.forms.length>0) {

            const {template } = data
            const { forms } = data.template

            const mapForms = forms.map((form,i) => {
                form.input = formData[i]

                return form
            })

            try {
                template.forms = mapForms
                const doc ={ template, status: 'finish', _id: data.id, isFlow: true }

                const { data: result } = await updateWork(data.id, doc);
                if ( result.status === 'success') {
                    
                    if(typeof(notifyUpdate)==='function') { notifyUpdate() }

                } else {
                    toast.error(result.message, toastOptions)
                }
            } catch(err) {
                toast.error(err.message, toastOptions)
            }

        }

        
        

    }

    return ( 
        <div className="row">
        <div className="col-12">
            <div className="card mb-3">
                <div className="card-header font-weight-bold">
                    <div className="row justify-content-start">
                    <div className="col-auto">
                        <h5 className="d-inline"><span className={`badge ${stepColor}`}>{index+1}</span></h5>
                    </div>
                    <div className="col-auto p-0 text-primary">{template.name}</div>
                    <div className="col-auto p-0 ml-4">ผู้ปฏิบัติงาน <span className="text-primary">{(person.length>0) ? person.find(x=>x).name : "ไม่ระบุ"}</span></div>
                    <div className="col-auto p-0 ml-auto">
                        <FontAwesome className="mr-3" name="pencil" onClick={() => setEdit(!edit)} size="lg" />
                    </div>
                </div>
            </div>
            {!edit && <React.Fragment>
                {(step-1)<index && <div className="card-body"><div className="text-center text-muted">รอขั้นตอนก่อนหน้า</div></div>}

                {(step-1)===index && <div>
                    <div className="card-body">
                    {template.forms && <DynamicForm forms={template.forms} notifyFormDataChange={(data) => setFormData(data)} error={error} />}
                    </div>
                    <div className="card-footer p-2 text-right">
                        <button className="btn btn-primary" onClick={handleWorkSave}>บันทึกข้อมูล</button>
                    </div>
                </div>}
                
            </React.Fragment>}
            {edit && <div className="card-body p-0">
                <FlowViewTableItemEdit data={data} notifyCancel={() => setEdit(false)} notifySave={x => handleSaveChange(x, index)} />
            </div>}

            </div>
        </div>
    </div> );
}
 
export default WorkCard;