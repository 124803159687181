import React from 'react';
import ProjectProgressionStages from './progressStages';

const ProjectProgression = ({progress={}, setProgress=()=>{}, taskList=[]}) => {


    const headerWidth = 180
    return ( 
    <React.Fragment>
        <div className="row align-items-start mb-2">
          <div className="col-auto" style={{width: headerWidth}}>
              <h5 className="font-weight-bold">Progression</h5>
          </div>
          <div className="col-auto">
            <input type="checkbox" checked={progress.enable}
                onChange={e => { 
                    const boo = e.target.checked
                    setProgress(prev => { return {...prev, enable: boo }}) 
                    }} /> <span className='ml-2'>Enable</span>
          </div>
          {progress.enable && <div className="col-auto">
            <input type="checkbox" checked={progress.autoPercentage } disabled={true}
                onChange={e => { 
                    const boo = e.target.checked
                    setProgress(prev => { return {...prev, autoPercentage: boo }}) 
                    }} /> <span className='ml-2'>Auto Percentage</span>
          </div>}
          {progress.enable && <div className="col-auto">
            <input type="checkbox" checked={progress.enableUnstage}
                onChange={e => { 
                    const boo = e.target.checked
                    setProgress(prev => { return {...prev, enableUnstage: boo }}) 
                    }} /> <span className='ml-2'>Enable Unstage</span>
          </div>}
        </div>
        {progress.enable && <ProjectProgressionStages progress={progress} setProgress={setProgress} taskList={taskList} />}
    </React.Fragment> );
}
 
export default ProjectProgression;