import http from "../../httpService";

const apiEndpoint = "/manage/data";

export function getManualData(id) {
  return http.get(`${apiEndpoint}/${id}/manual`);
}

export function addManualData(id, data) {
  return http.put(`${apiEndpoint}/${id}/manual/add`, data)
}

export function editManualData(id, data) {
  return http.put(`${apiEndpoint}/${id}/manual/edit`, data)
}

export function removeManualData(id, data) {
  return http.put(`${apiEndpoint}/${id}/manual/remove`, data)
}