import React from 'react';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import ReportStaffList from './../staffList';
import GraphBarBasic from './../../../common/graph/basicBar';

const StaffHistory = ({report, filter}) => {

    const { workTotal=0, staffTotal=0, timeTotal=0, jvTotal=0 } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { staffList, groupByHour, groupDaily } = report

    const hourlyNumbers = (groupByHour || []).filter(x=>x.number>0).map(x=>x.number)
    const hourlyMaxMinDiff = hourlyNumbers.length>0 ? Math.max(...hourlyNumbers) - Math.min(...hourlyNumbers) : 0

    const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(staffList && staffList.find(x=> x.jvTotal>0)) staffOrderList.push({name: 'Job Value', value: 'jv'})

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-left mt-2">
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Staff</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{staffTotal}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Finish Work</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                                Total
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Work Time</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-1 text-center'>
                                <h4><span className="badge badge-pill badge-success">{workTime}</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jvTotal>0 && 
                <div className="col-auto mr-3 mb-3 text-center">
                    <div className="card" style={{minWidth: 100}}>
                        <div className="card-header p-2">
                            <strong>Job Value</strong>
                        </div>
                        <div className="card-body p-2">
                            <div className="row justify-content-center align-items-center font-weight-bold">
                                <div className='col-auto mx-2 text-center'>
                                <h4><span className="badge badge-pill badge-success">{jvTotal.toLocaleString()} &#3647;</span></h4>
                                Total
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Work Hourly' yaxisText='Finish Work' height={hourlyMaxMinDiff>10 ? 300 : 200} />}
            {groupDaily && <GraphBarBasic className='mb-30' data={groupDaily} header='Work Daily' yaxisText='Finish Work'  />}
            {staffList && <ReportStaffList className="mt-4" data={staffList} filter={filter} style={{workVisible: ['finish']}} orderList={staffOrderList} />}
        </React.Fragment>
     );
}
 
export default StaffHistory;