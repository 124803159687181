import React, {useState} from 'react';
import FlowViewTableItemEdit from './tableItemEdit';
import FontAwesome from 'react-fontawesome';
import DynamicView from '../../dynamic/view';

const FlowViewTableItem = ({data, index, step}) => {

    const [edit, setEdit] = useState(false)

    const {template, person, work } = data;
    const stepColor = ((step-1)>=index && work && work.status==='finish') ? "badge-success" : ((step-1)<index) ? "badge-secondary" : "badge-warning"

    const handleSaveChange = (item, index) => {
        
    }

    return ( 
        <div className="row">
        <div className="col-12">
            <div className="card mb-3">
                <div className="card-header font-weight-bold">
                    <div className="row justify-content-start">
                    <div className="col-auto">
                        <h5 className="d-inline"><span className={`badge ${stepColor}`}>{index+1}</span></h5>
                    </div>
                    <div className="col-auto p-0 text-primary">{template.name}</div>
                    <div className="col-auto p-0 ml-4">ผู้ปฏิบัติงาน <span className="text-primary">{(person.length>0) ? person.find(x=>x).name : "ไม่ระบุ"}</span></div>
                    <div className="col-auto p-0 ml-auto mr-3">
                        <div className="row">
                            {(step-1)===index && work && work.status==='active' && <div className="col-auto">
                                <h5 className="d-inline"><span className="badge badge-warning">กำลังปฏิบัติงาน</span></h5>
                            </div>}
                            {(step-1)>=index && work && work.status==='finish' && <div className="col-auto">
                                <h5 className="d-inline"><span className="badge badge-success">ปฏิบัติงานเสร็จเรียบร้อย</span></h5>
                            </div>}
                            {(step-1)<index && <div className="col-auto">
                                <FontAwesome className="mr-3" name="pencil" onClick={() => setEdit(!edit)} size="lg" />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {!edit && <React.Fragment>
                {(step-1)>=index && work && work.status==='finish' && <div className="card-body">
                    <DynamicView work={work} />
                </div>}
                {(step-1)<index && <div className="card-body"><div className="text-center text-muted">รอขั้นตอนก่อนหน้า</div></div>}

                {(step-1)===index && work && work.status==='wait' && <div className="card-body"><div className="text-center text-muted">รอปฏิบัติงาน</div></div>}
                {(step-1)===index && work && work.status==='active' && <div className="card-body"><div className="text-center text-primary font-weight-bold">กำลังปฏิบัติงาน</div></div>}
                
            </React.Fragment>}
            {edit && <div className="card-body p-0">
                <FlowViewTableItemEdit data={data} notifyCancel={() => setEdit(false)} notifySave={x => handleSaveChange(x, index)} />
            </div>}

            </div>
        </div>
    </div> );
}
 
export default FlowViewTableItem;