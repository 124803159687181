import http from "../httpService";

const apiEndpoint = "/download";

export function downloadTask(input) {
    const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''
  
    return http.get(apiEndpoint + '/task' + ((query) ? '/?' + query : ''),  {responseType: 'blob'})
}

export function downloadTaskTeam(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + '/task/team' + ((query) ? '/?' + query : ''),  {responseType: 'blob'})
}