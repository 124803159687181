import http from "../../httpService";

const apiEndpoint = "/manage/project/group";

export function getProjectGroupList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getProjectGroup(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveProjectGroup(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deleteProjectGroup(id) {
  return http.delete(apiEndpoint + "/" + id)
}