import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'
import dlv from 'dlv'

import { NavLink } from 'react-router-dom';

import PersonPhoto from '../../common/card/personPhoto';
import { saveTemplateTask } from './../../../services/manage/task/taskService';

const RoundBorder = {
  borderRadius: 10,
  border: '1px solid #AAAAAA',
  padding: 1
}

const TemplateTaskCard = ({ data={}, onRemove=()=>{} }) => {

    const {_id, name, oneActiveTask, jobValue } = data;
    const { script, enableLineNotify, publicThis, limit } = data;
    const { allowAll, permission=[], permissionOverrideByProject, permissionOverrideByFlow } = data
    const { location } = data

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await saveTemplateTask({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update task')
        }
    }


  return ( 
    <React.Fragment>
        <div className="card mb-2">
            <div className="card-body p-0">
                <div className="row no-gutters align-items-center justify-content-between">
                <div className="col-auto ml-1">
                    <NavLink className="nav-item nav-link text-dark d-inline" to={`/manage/task/${_id}`} >
                    <h5 className="d-inline font-weight-bold"><FontAwesome name="file-code-o" size='lg' /><span className="ml-2">{name}</span></h5>
                    </NavLink>
                    {publicThis && publicThis.enable && <span className="badge badge-primary mr-2"><FontAwesome name="globe" /> Public</span>}
                    {limit && limit.enable && <span className="badge badge-warning mr-2"><FontAwesome name="lock" /> Limit</span>}
                    {dlv(location, 'enableAddress', false) && <span className="badge badge-warning mr-2"><FontAwesome name="map-marker" /> Address</span>}
                    {dlv(location, 'enableTrack', false) && <span className="badge badge-warning mr-2"><FontAwesome name="map-o" /> Tracking</span>}
                    {jobValue && jobValue.enable && <span className="badge badge-info mr-2"><FontAwesome name="dollar" /> Job Value</span>}
                    {oneActiveTask && <span className="badge badge-warning mr-2"><FontAwesome name="rocket" /> One Active Task</span>}
                    {script && script.enable && <span className="badge badge-primary mr-2"><FontAwesome name="plug" /> Script</span>}
                    {enableLineNotify &&<span className="badge badge-success mr-2"><FontAwesome name="comments-o" /> Line</span>}
                    
                </div>
                <div className="col-auto p-1">
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto mr-2 pt-1">
                            <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                        </div>
                        <div className="col-auto">
                            <NavLink className="nav-item nav-link text-dark" to={`/manage/task/${_id}/?clone=yes`} ><FontAwesome name="clone" size="lg" /></NavLink>
                        </div>
                        <div className="col-auto mr-1">
                        <NavLink className="nav-item nav-link text-dark" to={`/manage/task/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                        </div>
                        <div className="col-auto mr-2">
                            <FontAwesome name="trash" size="lg" onClick={onRemove} />
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {( !allowAll || permissionOverrideByProject) && 
            <div className="card-body py-1">
                <div className="row no-gutters align-items-center">
                    <div className="col-auto p-0">
                        <div className="row no-gutters align-items-center" style={RoundBorder}>
                            <div className="col-auto ml-2 mr-1">
                                <h6 className="d-inline"><span className="badge badge-danger"><FontAwesome name="lock" /> Create</span></h6>
                            </div>
                            {permissionOverrideByFlow && <span className="text-secondary font-weight-bold mx-1"> Flow Overrided</span>}
                            {permissionOverrideByProject && 
                                <span className="text-secondary font-weight-bold mx-1"> Project Overrided</span>}
                            {!permissionOverrideByProject && permission.map( x=> {
                                if(x.type==='staff') {
                                    return (
                                        <div key={x._id} className="col-auto mr-1">
                                            <PersonPhoto id={x._id} className='rounded-circle' size={35} />
                                        </div>
                                    )
                                } else {

                                    const badge = x.enable ? 'badge badge-info' : 'badge badge-secondary'
                                    return (<div key={x._id} className="col-auto mr-1">
                                    <h6 className="d-inline"><span className={badge}><FontAwesome name="users" /> {x.name || x._id}</span></h6>
                                </div>)
                                }

                            })}
                        </div>
                    </div>
                </div>
                
            </div>
            }
        </div>
    </React.Fragment>
    );
    
}
 
export default TemplateTaskCard;