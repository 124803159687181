import React, {useState, useEffect, useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
// import moment from 'moment';
import dlv from 'dlv'
import FontAwesome from 'react-fontawesome';
import ToggleButton from 'react-toggle-button'
import ConfirmDelete from '../../common/confirmDelete';
import { getManagePlanList, deleteManagePlan, saveManagePlan } from '../../../services/manage/plan/managePlanService';
import BadgePersonGroup from './../../common/badge/badgePersonGroup';
import PersonPhoto from './../../common/card/personPhoto';

import { DomainContext } from '../../../context/domainContext';

const ManagePlanList = () => {

    const {domain } = useContext(DomainContext)

    const getList = async() => {
        try {
            const input = { mode: 'auto' }
            const {data: result} = await getManagePlanList({input});

            if(result.status==='success') {

                setList(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(domain) getList()
        
    }, [domain])

    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState('')


    const setEnable = async(_id, value) => {
        await saveManagePlan({_id, enable: !value })
        getList()
    }

    const confirmDelete = async() => {
        try {
            await deleteManagePlan(askDelete);

            setAskDelete('')
            getList()

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row justify-content-end align-items-center">
                <div className="col-auto mr-auto mb-3">
                    <h4>Auto Plans</h4>
                </div>
                <div className="col-auto p-0 mb-3">
                    <NavLink className="nav-item nav-link text-dark" to={`/manage/plan/new/?isRecurrence=true`} >
                        <button type="button" className="btn btn-success" >Create Auto Plan</button>
                    </NavLink>
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {list && list.map( (item, index) => {
                const {_id, enable, task, project, count, recurrenceOptions, permission=[], countPerStaff=false } = item;

                const { mode, dayMode, timeMode, selectedDays=[], timeBegin='', timeEnd='', dayOfMonth=[] } = recurrenceOptions

                const dayText = (dayMode==='all') ? 'Every Day' : selectedDays.map(x=> x.name).join(', ')
                const timeText = (timeMode==='all') ? 'All Day' : `${timeBegin}-${timeEnd}`

                const projectName = dlv(project, 'name', 'Unknown')
                const taskName = dlv(task, 'name', 'Unknown')

                const isAllowAll = permission.length===0 || permission.find(x=>x.role.value==='create' && x.mode.value==='all')
                const findCustomPerson = permission.find(x=>x.role.value==='create' && x.mode.value==='custom')
                const customPerson = (findCustomPerson) ? findCustomPerson.person : []

                // const statusColor = (status==='active') ? 'success' : 'secondary'

                return (
                <div key={index} className="col-12">
                    <div className="card mb-2">
                        <div className="card-body p-2">
                            <div className="row no-gutters justify-content-end">
                            <div className="col-auto ml-2 mr-auto">
                                <div className="row no-gutters align-items-center">
                                    {task && <div className="col-auto mr-3">
                                        <h5 className="d-inline font-weight-bold"><FontAwesome name="file-code-o" /> {taskName.substring(0,20) + ((taskName.length>20) ? '...' : '')}
                                        <span className='text-primary'> x {countPerStaff ? 'staffs' : count}</span></h5>
                                    </div>}
                                    {project && <div className="col-auto mr-3">
                                        <span className="badge badge-info mr-1"><FontAwesome name="space-shuttle" /> PROJECT</span>
                                        <h5 className="d-inline font-weight-bold">{projectName.substring(0,20) + ((projectName.length>20) ? '...' : '')}</h5>
                                    </div>}
                                    <div className="col-auto mr-2">
                                        <span className="badge badge-pill badge-warning">{mode.name}</span>
                                    </div>
                                    {['monthly'].includes(mode.value) && <div className="col-auto mr-2">
                                        <span className="badge badge-pill badge-secondary">Day of Month | {dayOfMonth.join(',')}</span>
                                    </div>}
                                    {['weekly'].includes(mode.value) && <div className="col-auto mr-2">
                                        <span className="badge badge-pill badge-secondary">Day | {dayText}</span>
                                    </div>}
                                    {['weekly', 'daily', 'monthly'].includes(mode.value)  && <div className="col-auto mr-2">
                                        <span className="badge badge-pill badge-secondary">Time | {timeText}</span>
                                    </div>}
                                </div>
                                <div className="row no-gutters mt-2">
                                    {isAllowAll && <div className="col-auto mr-2">
                                        <span className="badge badge-pill badge-success"><FontAwesome name="person" /> All Staffs</span>
                                    </div>}
                                    {!isAllowAll && customPerson.map( x=> {
                                        if(x.type==='staff') {
                                            return (<PersonPhoto key={x._id} id={x._id} size={35} />)
                                        } else {
                                            return (
                                            <div key={x._id} className="col-auto mr-1">
                                                <BadgePersonGroup userId={x._id} styles={{ photoSize: 35 }}  />
                                        </div>)
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="col-auto text-right">
                                <div className="row no-gutters align-items-center justify-content-end">
                                    <div className="col-auto p-1">
                                        <ToggleButton value={enable} onToggle={value => setEnable(_id, value)} />
                                    </div>
                                    <div className="col-auto p-1">
                                        <NavLink className="nav-item nav-link text-dark" to={`/manage/plan/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                                    </div>
                                    <div className="col-auto p-1 mr-2">
                                        <FontAwesome style={{cursor: 'pointer'}} name="trash" size="lg" onClick={()=>setAskDelete(_id)} />
                                    </div>
                                </div>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default ManagePlanList;