import React from 'react';
import moment from 'moment'

import _ from 'lodash'
import dlv from 'dlv'

moment.updateLocale('en', {week: { dow: 1 }})

const PlanMonthly = ({date, data}) => {

    const list = []

    for (var i=moment(date, 'YYYY-MM-DD').startOf('week'); i.isBefore(moment(date, 'YYYY-MM-DD').endOf('month')); i.add(1, 'week')) {
        const day = []
        for (var j=0; j<7; j++) {
            day.push({date: moment(i).add(j, 'day').format('YYYY-MM-DD')})
        }
        list.push({week: day})
    }

    const cellHeight = 120

    return ( 
    <React.Fragment>
        <h5 className='mb-3 text-primary font-weight-bold'>{moment(date, 'YYYY-MM-DD').format('MMMM YYYY')}</h5>

        <table className="table" style={{tableLayout: 'fixed', wordWrap: 'break-word', border: 'none'}}>
        <tbody>
        {list.map((item, weekIndex, weekArray) => {

            const { week } = item
            return (
                <React.Fragment key={weekIndex}>
                    {weekIndex===0 && 
                    
                    <tr>
                        {week.map(day => {
                        return (
                        <td key={day.date}>
                            <span className="font-weight-bold d-none d-md-inline">{moment(day.date, 'YYYY-MM-DD').format('dddd')}</span>
                            <span className="font-weight-bold d-none d-sm-inline d-md-none">{moment(day.date, 'YYYY-MM-DD').format('ddd')}</span>
                            <span className="font-weight-bold d-inline d-sm-none">{moment(day.date, 'YYYY-MM-DD').format('dd')}</span>
                        </td>)
                        })}
                    </tr>}
                    
                    <tr>
                    {week.map((day, dayIndex) => {

                        const isFuture = moment().isBefore(day, 'day')

                        const dayColor = (moment(day.date).isSame(moment(), 'day')) ? 'primary' : (moment(day.date).isSame(date, 'month')) ? 'dark' : 'muted'
                        const cellColor = (moment(day.date).isSame(date, 'month')) ? '#FFFFFF' : '#EEEEEE'

                        let columnBorder = 'border-top border-left border-secondary'

                        if(dayIndex===6) columnBorder += ' border-right'
                        if(weekIndex===weekArray.length-1) columnBorder += ' border-bottom'

                        const dataFilter = data.filter(x=> moment(x.plan.begin).isSame(day.date, 'day'))

                        const groupTasks = _.groupBy(dataFilter, x=> dlv(x, 'template._id'))
                        const taskList = Object.keys(groupTasks).map(key => {
                            const item = groupTasks[key]
                            const firstItem = item[0]
                            const { template, name } = firstItem
                            const workWait = item.length
                            const workActive = item.filter(x=> x.status==='active').length
                            const workFinish = item.filter(x=> x.status==='finish').length
                            const workOverdue = item.filter(x=> x.status==='overdue').length

                            return { _id: dlv(template, '_id',''), name, workWait, workActive, workFinish, workOverdue}

                        })

                        return (
                        <td key={day.date} className={columnBorder} 
                            style={{verticalAlign: 'top', width: '14.28%', height: cellHeight, 
                            lineHeight: 1.1, padding: 2, overflow: 'hidden', backgroundColor: cellColor
                            }}>
                            
                            <span className={`ml-1 text-${dayColor} font-weight-bold`}>{moment(day.date, 'YYYY-MM-DD').format('D')}</span>
                            <div style={{height: 5}}></div>
                            <div className='d-block d-md-none'>
                            <span style={{width: '100%'}} className='badge badge-pill badge-info text-left'>
                                {dataFilter.length}<span className="d-none d-sm-inline"> x Plans</span></span>
                            </div>
                            <div className='d-none d-md-block'>
                            {taskList.filter((_, index) => index<4).map(task => {
                                const {workWait, name, workActive, workFinish, workOverdue} = task
                                const badgeColor = (workFinish>=workWait) ? 'success' : (workFinish>0 || workActive>0) ? 'warning' : (workOverdue>=workWait) ? 'secondary' : 'info'

                                return (<span key={name} style={{width: '100%'}} className={`badge badge-pill badge-${badgeColor} text-left`}>
                                            {`${isFuture ? workFinish + '/' : ''}`}{workWait} : {name}
                                        </span>
                                )
                            })}
                            {taskList.length>4 && 
                            <span style={{width: '100%'}} className='badge badge-pill badge-info text-left'>
                                {taskList.filter((_, index) => index>=4).map(x=> x.workWait).reduce((a,x)=> a+x, 0)} x Others</span>}
                            </div>
                            
                        </td>)

                    })}
                    </tr>
                </React.Fragment>
                
            )
        })}
        </tbody>
        </table>

    </React.Fragment> );
}
 
export default PlanMonthly;