import http from "../../httpService";

const apiEndpoint = "/me/task";

export function GetMeTaskList(input) {

  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + "/list" + ((query) ? '/?' + query : ''))
}

export function MeTaskToStart() {

  return http.get(apiEndpoint + "/ToStart")
}

export function MeTaskToStartDetail(id) {

  return http.get(apiEndpoint + "/ToStart/" + id)
}