import React, {useState, useContext, useEffect} from 'react';
import SearchBox from "../../common/searchBox";
import { toast } from "react-toastify";
import InfiniteScroll from 'react-infinite-scroll-component';

import moment from 'moment';

import { DomainContext } from '../../../context/domainContext';
import FilterDateCombo from '../../common/filter/filterDateCombo';
import { getReportTasks } from '../../../services/report/reportTask';
import ReportWorkCard from '../../report/work/workCard';
import socketIOClient from '../../../services/app/socketioService';

const RecordTasks = () => {

  const {domain } = useContext(DomainContext)
  const [filter, setFilter] = useState({filter: "today", date: moment().format('YYYY-MM-DD')})
  const [search, setSearch] = useState('')
  const [list, setList] = useState([])

  useEffect(() => {
    const loadList = async() => {
      try {
          const { data: result } = await getReportTasks({date: filter.date})
          if(result.status==='success') {
            setList(result.data.list)
  
          } else {
              toast.error(result.message)
          }
      } catch (error) {
          
      }
    }

    loadList()

    socketIOClient.on('report-task-list', (message) => {    
      const { domainid, workDate } = message
      if(!domainid || !workDate) return

      if(domainid===domain._id.toString() && moment(workDate).format('YYYY-MM-DD')===filter.date ) {
        loadList()
      }     
    })
    return () => { socketIOClient.off('report-task-list')  }

  }, [filter, domain])

  const scrollRow = 5
  const initRow = Math.max(Math.ceil((window.innerHeight-50)/100), 5)
  const [showRow, setShowRow] = useState(initRow)

  useEffect(() => {
    setShowRow(initRow)
  }, [initRow, list]);

  const fetchMoreData = () => {  setShowRow(prev => prev+scrollRow)  };

  const filterList = list.slice(0, showRow)

  if(!domain) {
    return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
  }

  return ( 
    <React.Fragment>
      <div className="row justify-content-end align-items-center">
        <div className="col-auto mr-auto mb-3">
        <h4>Task Records</h4>
        </div>
        <div className="col-auto mb-3 d-none">
          <SearchBox value={search} onChange={value => setSearch(value)} />
        </div>
        <div className="col-auto mb-3">
          <FilterDateCombo enableFilter={['today', 'day']} notifyDateFilter={item => setFilter(item)} />
        </div>
      </div>
      {list.length===0 && 
      <div className="alert alert-warning" role="alert">
          Empty
      </div>
      }
      <InfiniteScroll
        dataLength={showRow}
        next={fetchMoreData}
        hasMore={showRow>=list.length ? false : true}
        loader={<h4>Loading...</h4>}
        style={{overflowX: 'hidden'}}
        scrollableTarget='rootcontent'
        scrollThreshold={0.9}
    >
        {filterList.map( item => {
          return (
            <div key={item._id} className="row">
                <div className="col">
                <ReportWorkCard data={item} />
                </div>
            </div>
          )
        })}
      </InfiniteScroll>
    </React.Fragment>
  );
}
 
export default RecordTasks;