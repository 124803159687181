import React from 'react'
import { GoogleMap, useLoadScript, Polyline, InfoBox } from '@react-google-maps/api'

import { getDistance, getBounds, getCenterOfBounds } from 'geolib'
// import _ from 'lodash'

// const options = {
//   zoomControlOptions: {
//     position: google.maps.ControlPosition.RIGHT_CENTER // ,
//     // ...otherOptions
//   }
// }

// const mql = window.matchMedia(`(min-width: 992px)`);
// console.log('mql', mql)

function MyGoogleMap({path, reportView='roadmap'}) {

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAoNyPaxoLzYwxVokxX98-bBuYIQDHw_Tw"
  })


  const isClient = typeof window === 'object';
  
  const mapWidth = isClient ? Math.min(800, window.innerWidth-80 - (window.innerWidth>992 ? 234 : 0)) : undefined
  const mapSize = 
  {
    width: mapWidth,
    height: isClient ? Math.min(window.innerHeight*3/4, (mapWidth || 1000)*3/4) : undefined
  }

  const getMapOptions = ({path, pixel}) => {
    const center = getCenterOfBounds(path);
    const mapSpaceMultiplier = 1.5

    const bounds = getBounds(path.map(x => { return {latitude: x.lat, longitude: x.lng }}));

    const pathWidth = getDistance({latitude: bounds.minLat, longitude: bounds.minLng},{latitude: bounds.maxLat, longitude: bounds.minLng })
    const pathHeight = getDistance({latitude: bounds.minLat, longitude: bounds.minLng},{latitude: bounds.minLat, longitude: bounds.maxLng })
    const mapMeters = Math.max(pathWidth, pathHeight) * mapSpaceMultiplier

    const findVal = 156543.03392 * Math.cos(13.9238513 * Math.PI / 180) / (mapMeters/pixel)

    const zoomLevel = Math.min( Math.round(Math.log(findVal)/Math.log(2)), 19) 
    
    return { center: {lat: center.latitude, lng: center.longitude}, zoomLevel }
    
  }

  const { center, zoomLevel } = getMapOptions({path, pixel: Math.min(mapSize.width, mapSize.height)});
  
  const mapContainerStyle = mapSize;
  
  const onLoad = polyline => {
    // console.log('polyline: ', polyline)
  };

  
  const options = {
    strokeColor: '#4d94ff',
    strokeWeight: 4,
    fillColor: '#4d94ff',
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: path,
    zIndex: 1
  };

  const infoBoxOptions = { closeBoxURL: '', enableEventPropagation: true };

  const renderMap = () => {

    if(!mapWidth) return <div></div>

    return  <React.Fragment>
        <GoogleMap
            id="circle-example"
            mapContainerStyle={mapContainerStyle}
            zoom={zoomLevel}
            center={center}
            options={{ mapTypeId: reportView}}
        >
            <Polyline
            onLoad={onLoad}
            path={path}
            options={options}
            />
            <InfoBox
              options={infoBoxOptions}
              position={path.find(x=>x)}
            >
              <div style={{ backgroundColor: 'yellow', opacity: 0.9, padding: 5 }}>
                <div style={{ fontSize: 14, color: `#08233B`, fontWeight: 'bold' }}>
                  Start
                </div>
              </div>
            </InfoBox>
            <InfoBox
              options={infoBoxOptions}
              position={path[path.length-1]}
            >
              <div style={{ backgroundColor: 'green', opacity: 0.9, padding: 5 }}>
                <div style={{ fontSize: 14, color: `#FFFFFF`, fontWeight: 'bold' }}>
                  Finish
                </div>
              </div>
            </InfoBox>
        </GoogleMap>
      </React.Fragment>
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>Loading..</div>
}


export default MyGoogleMap