import React, {useState, useEffect} from 'react';

import FontAwesome from 'react-fontawesome';
import TemplateJobManageItem from './flowDetailTableItem';
// import DialogSearchList from './../../common/dialogSearchList/dialog';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';

const FlowDetailTable = ({data, notifyDataChange}) => {

  const [list, setList] = useState(data)
  const [edit, setEdit] = useState(-1)

  useEffect(() =>{ setList(data) }, [data])

  const handleSaveChange = (item, index) => {
    const newData = [...list]
    if(index<newData.length) {
      newData[index] = item;

      if( notifyDataChange instanceof Function) {
        notifyDataChange(newData)
      }

      setEdit(-1)
    }
  }

  const updatePerson = (index, person) => {
    const newList = [...list];

    if(newList.length>index) {
      const item = newList[index];

      if(item) {
        item.person = [person]
        newList[index] = item;
        setList(newList)

        if(typeof(notifyDataChange)==='function') {
          notifyDataChange(newList)
        }
      }
    }
    
    
  }

  const notifyEditCancel = () => {
    setEdit(-1)
  }

    return (
      <React.Fragment>
        <h5>ลำดับการปฏิบัติงาน</h5>
        <div className="row">
          {list && list.map( (item, index) => {
            const {template, person, personAllowChange} = item;

            return (
              <div key={index} className="col-12">
                <div className="card mb-2">
                  <div className="card-body p-3">
                    <div className="row justify-content-start">
                      <div className="col-auto">
                        <h5 className="d-inline"><span className="badge badge-secondary">{index+1}</span></h5>
                        <span className="ml-4">Template <span className="text-primary font-weight-bold">{template.name}</span></span>
                        <span className="ml-3">Person <span className="text-primary font-weight-bold">{(person.length>0) ? person.find(x=>x).name : "ไม่ระบุ"}</span></span>
                        
                      </div>
                      {person.length===0 && <div>
                        <div className="col-auto"><DialogSearchPerson buttonText="เลือกบุคคล" cache={false} notifySelect={item => updatePerson(index, item)} /></div>
                        {/* <div className="col-auto px-0">
                            <DialogSearchList header="เลือกกลุ่มบุคคล" buttonAdd="เพิ่มกลุ่มบุคคล" list={personGroupList} notifySelect={item => setPerson([...person, {...item, type: 'group' }])} />
                        </div> */}
                        </div>}
                      {person.length>0 && personAllowChange && <div>
                        <div className="col-auto"><DialogSearchPerson buttonText="เปลี่ยนบุคคล" cache={false} notifySelect={item => updatePerson(index, item)} /></div>
                      </div>}
                      {person.length>0 && !personAllowChange && <div>
                        <div className="col-auto"><span className="text-muted">(ไม่สามารถเปลี่ยนแปลงได้)</span></div>
                      </div>}
                      <div className="col-auto ml-auto">
                        <FontAwesome className="mr-3" name="info-circle" onClick={() => setEdit((edit===index) ? -1 : index)} size="lg" />
                      </div>
                    </div>
                  </div>
                </div>
                {index===edit && <TemplateJobManageItem data={list[edit]} notifyCancel={notifyEditCancel} notifySave={x => handleSaveChange(x, index)} />}
              </div>
            )
          })}
        </div>
      </React.Fragment>
        
      );
}
 
export default FlowDetailTable;