import React from 'react';
// import moment from 'moment'
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import GraphBarBasic from '../../../common/graph/basicBar';
import GraphDonutBasic from '../../../common/graph/basicDonut';
import ReportTaskList from '../../task/summary/taskList';

const ReportPerProjectHistory = ({report={}, filter}) => {

    const { taskList=[] } = report
    const { groupByTask, groupByHour, groupDaily, timeByTask } = report

    const { taskTotal=0, workTotal=0, staffTotal=0, timeTotal=0, jvTotal, jvRemark } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row no-gutters justify-content-left mt-3">
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body p-2">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{staffTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Task</strong>
                    </div>
                    <div className="card-body p-2">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{taskTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Finish Work</strong>
                    </div>
                    <div className="card-body p-2">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work Time</strong>
                    </div>
                    <div className="card-body p-2">
                        <h4 className="card-title text-success">{workTime || <span className="text-muted">ไม่มีข้อมูล</span>}</h4>
                        <strong>Total</strong>
                    </div>
                </div>
            </div>
            {jvTotal>0 && <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                    <strong>Job Value</strong>
                    </div>
                    <div className="card-body p-2">
                        <h4 className="card-title text-primary">
                            {jvRemark && <span className="text-danger">*</span>}
                            <span>{jvTotal.toLocaleString()} &#3647;</span></h4>
                            <strong>Total</strong>
                    </div>
                </div>
            </div>}
            
        </div>
        {groupByTask && <GraphDonutBasic className='mb-3' input={groupByTask} header='Total Work By Task' centerText="Total Work" />}
        {timeByTask && <GraphDonutBasic className="mb-3" input={timeByTask} header="Total Time By Task" centerText="Total Minute" />}
        {groupByHour && <GraphBarBasic className='mb-3' data={groupByHour} header='Finish Hourly' yaxisText='Count Finish' />}
        {groupDaily && <GraphBarBasic className='mb-3' data={groupDaily} header='Finish Daily' yaxisText='Count Finish' />}
        {taskList && <ReportTaskList data={taskList} filter={filter} orderList={staffOrderList} style={{workVisible: ['finish', 'jv']}} />}
        
    </React.Fragment> );
}
 
export default ReportPerProjectHistory;