import React, {useState } from 'react';

import moment from 'moment';
import Flatpickr from "react-flatpickr";
import ButtonGroup from '../buttonGroup';
import FontAwesome from 'react-fontawesome';
import dlv from 'dlv'

moment.updateLocale('en', {week: { dow: 1 }})

const FilterDateCombo = ({notifyDateFilter, defaultValue='today', enableFilter=['day', 'week', 'month'], init={filter: 'today', date: moment().format('YYYY-MM-DD')}}) => {

  const masterFilterDates = [{name: 'Today', value: 'today'}, {name: 'Daily', value: 'day'}, {name: 'Weekly', value: 'week'}
    , {name: 'Monthly', value: 'month'}
    , {name: 'Custom', value: 'custom'}
  ];
  const filterDates = masterFilterDates.filter(x=> enableFilter.includes( x.value))

  const [selectFilter, setSelectFilter] = useState(filterDates.find(x=> x.value===dlv(init, 'filter', defaultValue)))
  const [filterDateStart, setFilterDateStart] = useState(init.date)
  const [filterDateEnd, setFilterDateEnd] = useState(init.date)

  const calendarOptions = { altInput: true, altFormat: "j F Y",  dateFormat: "Y-m-d"}

  const handleFilter = ({filter, date, dateEnd}) => {
    if(filter) {
        let newDate;
        switch(filter.value) {
            case "day":
            case "today":
            case "custom":
                newDate = moment().startOf('day').format("YYYY-MM-DD")
              break;
            case "week":
                newDate = moment().startOf('week').format("YYYY-MM-DD")
              break;
            case "month":
                newDate = moment().startOf('month').format("YYYY-MM-DD")
              break;
            default:
                newDate = moment().startOf('day').format("YYYY-MM-DD")
              break;
          }
          setFilterDateStart(newDate)
          setFilterDateEnd(newDate)
          setSelectFilter(filter)

          if(typeof notifyDateFilter === 'function') {
            notifyDateFilter({filter: filter.value, date: newDate, dateEnd: newDate})
          }
    } else if(date) {
        setFilterDateStart(date)

        if(typeof notifyDateFilter === 'function') {
            notifyDateFilter({filter: selectFilter.value, date: date, dateEnd: filterDateEnd})
          }
    } else if(dateEnd) {
      setFilterDateEnd(dateEnd)

      if(typeof notifyDateFilter === 'function') {
          notifyDateFilter({filter: selectFilter.value, date: filterDateStart, dateEnd: dateEnd})
        }
    }
      
  }

  const handleDateNext = () => { 
      const newDate = moment(filterDateStart, 'YYYY-MM-DD').add(1, selectFilter.value).format('YYYY-MM-DD')
      handleFilter({date: newDate})
    }
  const handleDatePrevious = () => { 
    const newDate = moment(filterDateStart, 'YYYY-MM-DD').add(-1, selectFilter.value).format('YYYY-MM-DD')
    handleFilter({date: newDate})
    }

  return ( 
    <div className='container-fluid'>
        <div className="row justify-content-end align-items-center">
        <div className="col-auto mb-3 px-0">
            {filterDates &&(<ButtonGroup
            items={filterDates}
            selectedItem={selectFilter}
            onItemSelect={filter => handleFilter({filter})}
            valueProperty='value'
            />)}
        </div>
        {['day', 'week', 'month'].includes(selectFilter.value) && <div className="col-auto mb-3 ml-3 p-0">
            <FontAwesome name="chevron-circle-left" size="2x" onClick={handleDatePrevious} />
        </div>}
        {['day', 'custom'].includes(selectFilter.value) && 
        <div className="col-auto mb-3">
            <Flatpickr
                id='filterDateStart'
                name='filterDateStart'
                value={filterDateStart}
                onChange={(_,str) => handleFilter({date: str})}
                options={calendarOptions}
                />
        </div>}
        {selectFilter.value==='custom' && 
        <React.Fragment>
          <div className="col-auto mb-3 p-0">to</div>
          <div className="col-auto mb-3">
              <Flatpickr
                  id='filterDateEnd'
                  name='filterDateEnd'
                  value={filterDateEnd}
                  onChange={(_,str) => handleFilter({dateEnd: str})}
                  options={calendarOptions}
                  />
          </div>
        </React.Fragment>}
        {selectFilter.value==='week' && 
        <div className="col-auto mb-3">
            <div className="d-inline-block">
                <div className="alert alert-secondary py-2 d-inline">
                {moment(filterDateStart, "YYYY-MM-DD").format("DD MMMM YYYY")} - {moment(filterDateStart, "YYYY-MM-DD").endOf('week').format("DD MMMM YYYY")}
                </div>
            </div>
        </div>}
        {selectFilter.value==='month' && 
        <div className="col-auto mb-3">
            <div className="d-inline-block">
                <div className="alert alert-secondary py-2 d-inline">
                {moment(filterDateStart, "YYYY-MM-DD").format("MMMM YYYY")}
                </div>
            </div>
        </div>}
        {['day', 'week', 'month'].includes(selectFilter.value) && <div className="col-auto mb-3 p-0">
            <FontAwesome name="chevron-circle-right" size="2x" onClick={handleDateNext} />
        </div>}
        </div>

    </div>
  );
}
 
export default FilterDateCombo;