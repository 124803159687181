import React from 'react';
import Img from 'react-image'

const PhotoDisplay = ({url, size=80, width, height, isRounded=false}) => {

    const maxWidth = (width) ? width : (height) ? '100%' : size
    const maxHeight = (height) ? height : (width) ? '100%' : size

    const classStyle = (isRounded) ? 'rounded-circle' : ''
    // console.log("PhotoDisplay width-height=", width, "-", height)
    // console.log("PhotoDisplay width-height=",maxWidth,"-",maxHeight)
    return ( 
        <Img className={classStyle} style={{ maxWidth: maxWidth, maxHeight: maxHeight}} 
            src={[url, 'https://storage.googleapis.com/jawis-core-photo/notfound.jpg' ]}
            loader={<img className={classStyle} style={{maxWidth: maxWidth, maxHeight: maxHeight}} 
            src={'https://storage.googleapis.com/jawis-core-photo/loading.jpg'} alt="..."/>}
        />
     );
}
 
export default PhotoDisplay;