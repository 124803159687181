import React, {useState, useEffect} from 'react';
// import SelectButton from './../SelectButton';
import FormApprove from './formApprove';
import FormListDropdown from './formListDropdown';
import FormText from './formText';

const DynamicForm = ({forms=[], notifyFormDataChange, formStatus=true, error={}}) => {

    const [enableForm, ] = useState(formStatus)
    const [formData, setFormData] = useState({})

    const updateFormData = (id, value) => {
        const data = {...formData}

        data[id] = value
        setFormData(data)
    }

    // const updateFormList = (id, item) => {
    //     if(!enableForm) return

    //     const data = {...formData}
    //     data[id] = [item]
    //     setFormData(data)
    // }

    useEffect(()=> {
        if (typeof(notifyFormDataChange)==='function') {

            notifyFormDataChange(formData)
        }
    }, [formData, notifyFormDataChange])

    const initFormData = (forms) => {
        if(!forms) return

        const newFormData = {}

        forms.forEach((form,i) => {
            newFormData[i] = ''
        })

        setFormData(newFormData)

    }
    useEffect(() => initFormData(forms), [forms])

    return ( 
    <React.Fragment>
        <div className="row">
            {forms.map( (form, index) => {
                const { formType, name, required, data } = form;
                const { value: type} = formType

                return (
                <div key={index} className="col-12">
                    <fieldset disabled={(enableForm) ? '' : "disabled"} >
                        {type==='approve' && <FormApprove notifySelect={(value) => updateFormData(index, value)} />}
                        {type==='list' && <FormListDropdown label={name} list={data} notifySelect={(value) => updateFormData(index, value)} />}
                        {/* {type==='list' && <div>
                                <SelectButton
                                    name={_id}
                                    value={formData[_id] || ''}
                                    label={name}
                                    options={list}
                                    valueProperty="id"
                                    onSelect={({item}) => updateFormList(_id, item)}
                                    error={''}
                                />
                            </div>
                        } */}

                        {type==='text' && <FormText label={name} notifyChange={(value) => updateFormData(index, value)} required={required} />}
                        
                    </fieldset>
                    {error[index] && <div className='alert alert-danger'>โปรดระบุข้อมูลให้ครบถ้วน</div>}
                    {/* {index===edit && <TemplateJobManageItem data={list[edit]} notifyCancel={notifyEditCancel} notifySave={x => handleSaveChange(x, index)} />} */}
                </div>
                )
            })}
        </div>

    </React.Fragment> );
}
 
export default DynamicForm;