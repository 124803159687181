import http from "../../httpService";

const apiEndpoint = "/manage/plan";

export function getManagePlanList({input}) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''
  
  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getManagePlan(id) {
  return http.get(apiEndpoint + "/" + id);
}

export function saveManagePlan(doc) {
  if (doc._id) {
    //Update
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  } else {
    //Create
    return http.post(apiEndpoint, doc);
  }
}

export function deleteManagePlan(id) {
  return http.delete(apiEndpoint + "/" + id);
}