import React from 'react';
import { version, description } from './../../../package.json'

const AdminSetting = () => {
    return ( 
        <React.Fragment>
            
            <h6>Version : {version}</h6>
            <h6>Description : {description}</h6>
            <h6>Base URL : {process.env.REACT_APP_API_BASE_URL}</h6>
            <h6>Socket IO : {process.env.REACT_APP_API_SOCKET_IO}</h6>
        </React.Fragment>
     );
}
 
export default AdminSetting;