import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import TemplateFormItemEdit from './taskManageItem';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { reorder, getItemStyle, getListStyle } from './../../common/dragdrop/dnd';

const TemplateFormItems = ({forms, setForms=()=>{}, scriptEnable=false, jvEnable}) => {

  const onDragEnd = (result)=> {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newData = reorder(forms, result.source.index, result.destination.index)

    setForms(newData)
  }

  const [edit, setEdit] = useState(undefined)


  const handleDelete = (index) => {

    const newData = [...forms]
    newData.splice(index, 1)

    setForms(newData)
  }

  const handleSaveChange = (item, index) => {
    
    const newData = [...forms]

    if(index>-1 && index<newData.length) {
      newData[index] = item
    } else {
      newData.push(item)
    }

    setForms(newData)
    setEdit(undefined)

  }

  const notifyEditCancel = () => { setEdit(undefined)  }

  const verifyScriptForm = ({ linkField, linkField2, index }) => {
    if(scriptEnable) {
      const allFields = forms.filter((x,i) => i!==index ).map( x=> { return [x.linkField, x.linkField2] }).reduce((a,b)=> a.concat(b), []).filter(x=>x)

      if(allFields.some( field => field===linkField )) return { error: 'Script Field are duplicated'}
      if(allFields.some( field => field===linkField2 )) return { error: 'Script Field are duplicated'}
    }

    return {}
  }

  const verifyJVForm = ({jvField, index}) => {
    if(jvEnable) {
      const allFields = forms.filter((x,i) => i!==index ).map( x=> { return x.jvField }).filter(x=>x)

      if(allFields.some( field => field===jvField)) return { error: 'JV Field are duplicated'}
    }

    return {}
  }

  return (
    <React.Fragment>
      <h6 className="text-muted">Task Detail / รายละเอียดงาน</h6>

      {forms.length===0 && 
        <div className="alert alert-warning" role="alert">
          Template is empty / กดปุ่ม Add เพื่อเพิ่มรายละเอียดงานที่ต้องการ
        </div>
        }
      {forms.length>0 && <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >

            {forms.map( (item, index, array) => {

              const {name, formType, jvField, linkField, linkField2 } = item;

                return (<Draggable key={index} draggableId={`id${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div className="row">
                        <div className="col">
                          <div className="card mb-2">
                            <div className="card-body p-3">
                              <div className="row justify-content-start">
                                <div className="col-auto">
                                  <h5 className="d-inline"><span className="badge badge-secondary">{index+1}</span></h5>
                                  <span className="ml-4">Name <span className="text-primary font-weight-bold">{name}</span></span>
                                  <span className="ml-3">Type <span className="text-primary font-weight-bold">{formType.name}</span></span>
                                  {jvEnable && jvField && <span className="ml-3">JV Field <span className="text-primary font-weight-bold">{jvField}</span></span>}
                                  {scriptEnable && linkField && <span className="ml-3">Script Field <span className="text-danger font-weight-bold">{linkField}</span></span>}
                                  {scriptEnable && linkField2 && <span className="ml-3">Script Field 2 <span className="text-danger font-weight-bold">{linkField2}</span></span>}
                                </div>
                                <div className="col-auto ml-auto">
                                  <FontAwesome className="mr-3" name="pencil" onClick={() => setEdit(index)} size="lg" />
                                  <FontAwesome name="trash" onClick={() => handleDelete(index)} size="lg" />
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                          {index===edit && <TemplateFormItemEdit formData={item} notifyCancel={notifyEditCancel} 
                            notifySave={x => handleSaveChange(x, index)} scriptEnable={scriptEnable} jvEnable={jvEnable} 
                            verifyScriptForm={({...args}) => verifyScriptForm({...args, index})}
                            verifyJVForm={({...args}) => verifyJVForm({...args, index})}
                             />}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>)

            })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>}
      {edit===(-1) && <TemplateFormItemEdit formData={{}} 
        notifySave={handleSaveChange} 
        notifyCancel={notifyEditCancel} 
        // verifyScriptForm={({...args}) => verifyScriptForm({...args, index: -1 })}
        // verifyJVForm={({...args}) => verifyJVForm({...args, index: -1 })}
        scriptEnable={scriptEnable} jvEnable={jvEnable} />}
      <div className="row mt-3">
          <div className="col-12 col-xl-12 text-right">
            <button type="button" className="btn btn-primary text-white" onClick={() => setEdit(-1) }>Add</button>
          </div>
        </div>
    </React.Fragment>
      
    );
}
 
export default TemplateFormItems;