import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import FontAwesome from 'react-fontawesome';
import ToggleButton from 'react-toggle-button'
import { NavLink } from 'react-router-dom';
import ConfirmDelete from '../../common/confirmDelete';
import { flowList, saveFlow, deleteFlow } from '../../../services/flow/index';
// import SearchBox from './../../common/searchBox';
import FilterDateCombo from './../../common/filter/filterDateCombo';

import moment from 'moment';

const RecordFlows = ({user}) => {

    const [, setFilter] = useState({filter: "today", date: moment().format('YYYY-MM-DD')})

    const getList = async() => {
        try {
            const {data: result} = await flowList();

            if(result.status==='success') {

                setList(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {   getList() }, [])

    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState('')
    const [show, setShow] = useState(-1)

    const setEnable = async(_id, value) => {
        await saveFlow({_id, enable: !value })
        getList()
    }

    const confirmDelete = async() => {
        try {
            await deleteFlow(askDelete);

            setAskDelete('')
            getList()

        } catch(err) { toast.error('Failed to delete item')}
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row justify-content-end align-items-center">
                <div className="col mr-auto mb-3"><h4>Flow Records</h4></div>
                <div className="col-auto mb-3 d-none">
                    {/* <SearchBox value={search} onChange={value => setSearch(value)} /> */}
                </div>
                <div className="col-auto mb-3">
                    <FilterDateCombo enableFilter={['today', 'day']} notifyDateFilter={item => setFilter(item)} />
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">Empty</div>
            }
            <div className="row">
            {list && list.map( (item, index) => {
                const {_id, name, enable, step, totalStep, status, statusText, flows } = item;

                // const currentFlow = flows[step-1];
                // const isMyTurn = (currentFlow.person.find(x=>x.id===user.userId)) ? true : false
                const currentPerson = flows[step-1].person.find(x=>x).name

                return (
                <div key={index} className="col-12">
                    <div className="card mb-2">
                        <div className="card-body p-2">
                            <div className="row align-items-center justify-content-start">
                                <div className="col-auto ml-2">
                                    <FontAwesome name="plug" className="mr-2" size="lg" />
                                    <span className="text-primary font-weight-bold" style={{cursor: 'pointer'}} onClick={() => setShow((show===index) ? -1 : index)}  >{name}</span>
                                </div>
                                <div className="col-auto p-0">
                                    ลำดับการทำงาน <span className="text-primary font-weight-bold">{`${step}/${totalStep}`}</span>
                                </div>
                                {status==='active' && <div className="col-auto ml-3 p-0">
                                    รอดำเนินการ <span className="text-primary font-weight-bold">{`${statusText}`}</span>
                                </div>}
                                {status==='active' && <div className="col-auto ml-3 p-0">
                                    ผู้ปฏิบัติงาน <span className="text-primary font-weight-bold">{currentPerson}</span>
                                </div>}
                                {status==='finish' && <div className="col-auto p-0 ml-3">
                                    <span className="text-success font-weight-bold">ปฏิบัติงานเสร็จสมบูรณ์</span>
                                </div>}
                                <div className="col-auto ml-auto">
                                    <div className="row align-items-center pl-2 pr-2">
                                        {<div className="col-auto pr-3">
                                        <NavLink className="nav-item nav-link p-0" to={`/flow/${_id}`} >
                                            <button className="btn btn-info btn-sm">รายละเอียด</button>
                                        </NavLink>
                                        </div>}
                                        <div className="col-auto p-1 d-none">
                                            <ToggleButton value={enable} onToggle={value => setEnable(_id, value)} />
                                        </div>
                                        <div className="col-auto p-1 d-none">
                                        <NavLink className="nav-item nav-link text-dark" to={`/mywork/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                                        </div>
                                        <div className="col-auto p-1 mr-2 d-none">
                                            <FontAwesome name="trash" size="lg" onClick={()=>setAskDelete(_id)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {show===index && flows && flows.length>0 && <div className="ml-4 pl-4 mb-3">
                        <h6 className="text-muted">ลำดับการปฏิบัติงาน</h6>
                        <div className="row">
                        {flows.map( (item, index) => {
                            const {template, person } = item;

                            return (
                            <div key={index} className="col-12">
                                <div className="card mb-2">
                                <div className="card-body p-1">
                                    <div className="row justify-content-start">
                                    <div className="col-auto">
                                        <h5 className="d-inline"><span className="badge badge-secondary">{index+1}</span></h5>
                                        <span className="ml-2">Template <span className="text-primary font-weight-bold">{template.name}</span></span>
                                        <span className="ml-3">Person <span className="text-primary font-weight-bold">{(person.length>0) ? person.find(x=>x).name : "ไม่ระบุ"}</span></span>
                                        
                                    </div>
                                    <div className="col-auto ml-auto">
                                        <FontAwesome className="mr-3" name="info-circle" size="lg" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* {index===edit && <TemplateJobManageItem data={list[edit]} notifyCancel={notifyEditCancel} notifySave={x => handleSaveChange(x, index)} />} */}
                            </div>
                            )
                        })}
                        </div>
                    </div>}
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default RecordFlows;