import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmLogout = ({isOpen, onCancel, onConfirm}) => {
    return ( 
        <div>
        <Modal isOpen={isOpen} toggle={onCancel} >
          <ModalHeader>Please Confirm</ModalHeader>
          <ModalBody>
             Do you want to logout ?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={onConfirm}>Confirm</Button>{' '}
            <Button color="secondary" onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
     );
}
 
export default ConfirmLogout;