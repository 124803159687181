
const scriptFormAllow = ['text', 'number', 'list', 'photo']

const scriptFieldReserved = [
    {name: 'User ID (system)', value: 'userid'},
    {name: 'User Name (system)', value: 'userName'},
    {name: 'User Phone (system)', value: 'userPhone'},
    {name: 'Task ID (system)', value: 'taskid'},
    {name: 'Task Name (system)', value: 'taskName'},
    {name: 'Project Name (system)', value: 'projectName'},
    {name: 'Task Start (system)', value: 'taskDateStart'},
    {name: 'Task Finish (system)', value: 'taskDateFinish'},
    {name: 'Task Time (system)', value: 'taskTime'},
]

const obj = {
    scriptFormAllow,
    scriptFieldReserved
}


module.exports = obj
