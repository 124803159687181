import React from 'react';
// import moment from 'moment'
import { getWorkHourFromTotalMinutes } from './../../../utils/work';
// import ReportStaffList from './../staff/staffList';
import GraphBarBasic from './../../common/graph/basicBar';
import GraphDonutBasic from '../../common/graph/basicDonut';

import dlv from 'dlv'

const DashboardHistory = ({report={}}) => {

    const { groupByTask, groupByProject, groupByHour, groupDaily } = report

    const { workTotal=0, staffTotal=0, timeTotal=0, jvTotal } = report
    const { workAVGStaff, timeAVGStaff, timeAVGTask } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    // const staffOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    // if(staffList.find(x=> x.jvTotal>0)) staffOrderList.push({name: 'Job Value', value: 'jv'})

    const hourlyNumbers = (groupByHour || []).filter(x=>x.number>0).map(x=>x.number)
    const hourlyMaxMinDiff = hourlyNumbers.length>0 ? Math.max(...hourlyNumbers) - Math.min(...hourlyNumbers) : 0

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row no-gutters justify-content-left mt-3">
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-2 text-center'>
                            <h4><span className="badge badge-pill badge-success">{staffTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Finish Work</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                            Total
                            </div>
                            {workAVGStaff && <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workAVGStaff}</span></h4>
                            AVG-Staff
                            </div>}
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card" style={{minWidth: 100}}>
                    <div className="card-header">
                        <strong>Work Time</strong>
                    </div>
                    <div className="card-body">
                        <div className="row no-gutters justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workTime}</span></h4>
                            Total
                            </div>
                            {timeAVGStaff && <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{getWorkHourFromTotalMinutes(timeAVGStaff)}</span></h4>
                            AVG-Staff
                            </div>}
                            {timeAVGTask && <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{getWorkHourFromTotalMinutes(timeAVGTask)}</span></h4>
                            AVG-Work
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            {jvTotal>0 && 
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card" style={{minWidth: 100}}>
                    <div className="card-header">
                        <strong>Job Value</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{jvTotal.toLocaleString()} &#3647;</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
        {dlv(groupByProject, 'data',[]).find(x=>x>0) && <GraphDonutBasic className="mb-3" input={groupByProject} header="Group by Project" />}
        {dlv(groupByTask, 'data',[]).find(x=>x>0) && <GraphDonutBasic className='mb-3' input={groupByTask} header="Group by Task" />}
        {groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Finish Hourly' yaxisText='Finish Work' height={hourlyMaxMinDiff>10 ? 300 : 200} />}
        {groupDaily && <GraphBarBasic data={groupDaily} header='Finish Daily' yaxisText='Finish Work'  />}
        
    </React.Fragment> );
}
 
export default DashboardHistory;