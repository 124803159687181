import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import SearchBox from "../searchBox";
import SearchTable from "./searchTable";

const DialogSearchList = ({header, data=[], isOpen, onCancel, onConfirm, textProperty='name', icon, valueProperty}) => {

  const [search, setSearch] = useState('')
  const filterData = data.filter(x => {
    
    if(x[textProperty] && x[textProperty].toLowerCase().includes(search.toLowerCase())) return true

    return false
  })

  const handleSelect = (id) => {
    if( typeof(onConfirm) === 'function') {
      
      onConfirm(id)
    }
  }

  return ( 

    <div>
      <Modal isOpen={isOpen} toggle={onCancel} >
        <ModalHeader color="info">{header}
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <div className="row justify-content-center align-items-center">
              <div className="col-auto mb-3">
                <h6 className="d-inline">ค้นหา</h6>
              </div>
              <div className="col-12 col-md-10 col-lg-8 mb-3">
                <SearchBox value={search} onChange={search => setSearch(search)} />
              </div>
            </div>
            {search && filterData.length===0 && <div className="alert alert-secondary mt-4" role="alert">
              Search is not found
            </div>}
            <SearchTable data={filterData} onConfirm={handleSelect} textProperty={textProperty} icon={icon} valueProperty={valueProperty} />
          </React.Fragment>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>
        </ModalFooter>
      </Modal>
    </div>

   );
}
 
export default DialogSearchList;