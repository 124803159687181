import React from 'react';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
// import FontAwesome from 'react-fontawesome';
import GraphBarBasic from '../../../common/graph/basicBar';
import ReportTaskList from '../../task/summary/taskList';
import GraphDonutBasic from '../../../common/graph/basicDonut';

const ReportPerProjectToday = ({className='', report={}, filter}) => {

    const { taskTotal='-', taskActive='-',  } = report
    const { taskList, groupByTask, groupByHour } = report

    const { workTotal=0, workWait='-', workActive='-', workPause='-', workFinish='-', workDelete='-', timeTotal=0, jobValueTotal, jvRemark } = report
    const { staffTotal='-' } = report
    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( 
    <div className={className}>
        <div className="row no-gutters justify-content-left mt-1">
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Task</strong>
                    </div>
                    <div className="card-body p-2">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-light">{taskTotal}</span></h4>
                            Total
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{taskActive}</span></h4>
                            Active
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body p-2">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work</strong>
                    </div>
                    <div className="card-body p-2">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{workTotal}</span></h4>
                            Total
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-info">{workWait}</span></h4>
                            Plan
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-warning">{workActive}</span></h4>
                            Active
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{workPause}</span></h4>
                            Pause
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workFinish}</span></h4>
                            Finish
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-secondary">{workDelete}</span></h4>
                            Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Work Time</strong>
                    </div>
                    <div className="card-body p-2">
                        <h4 className="card-title text-success">{workTime || <span className="text-muted">ไม่มีข้อมูล</span>}</h4>
                        <h6>Total</h6>
                    </div>
                </div>
            </div>
            {jobValueTotal>0 && <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                    <strong>Job Value</strong>
                    </div>
                    <div className="card-body p-2">
                        <h4 className="card-title text-primary">
                            {jvRemark && <span className="text-danger">*</span>}
                            <span>{jobValueTotal.toLocaleString()} &#3647;</span></h4>
                        <h6>Total</h6>
                    </div>
                </div>
            </div>}
        </div>
        {groupByTask && <GraphDonutBasic input={groupByTask} className='mb-3' header='Active/Finish Work Group by Task' />}
        {groupByHour && <GraphBarBasic data={groupByHour} header='Finish Hourly' yaxisText='Count Finish' />}
        {taskList && <ReportTaskList data={taskList} filter={filter} />}
        
    </div> );
}
 
export default ReportPerProjectToday;