import React, {useState, useEffect} from 'react';
import FlowTable from './flowTemplateSelect';
import FlowDetail from './flowDetail';
import { getTemplateFlow } from '../../../services/manage/flow/manageFlowService';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { getEmployeeInfo } from '../../../services/hrms/employee';
import { saveFlow } from '../../../services/flow/index';

const MyFlowManage = ({history, match, user}) => {

  const [id,] = useState(match.params.id)
  const [flowId, setFlowId] = useState(undefined)
  const [flowData, setFlowData] = useState(undefined)

  const loadFlowData = async(id, user) => {
    if(id && id!=='new') {
      try {
        const {data: result } = await getTemplateFlow(id);
        const { flows } = result.data.data

        const { empId } = user

        if(flows) {
          const newFlows = await Promise.all(flows.map(async x => {
            const {person, personAuto} = x
            
            if(person.length===0 && personAuto) {
              
              try {
                const employee = await getEmployeeInfo(empId)
                x.person = [employee]
              } catch(err) {}
              
            }

            return x;
          }))

          result.data.data.flows = newFlows
        }

        setFlowData(result.data.data)

      } catch(err) {
        toast.error('Failed to load form data')
      }
    }
  }

  useEffect(() => { loadFlowData(flowId, user)  }, [flowId, user])

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    const doc = { ...flowData }
    delete doc._id

    try {
      const { data: result } = await saveFlow(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/flow"
        })
      } else {
        toast.error(result.message, toastOptions)
      }
    } catch(err) {
      toast.error(err.message, toastOptions)
    }

  }

  return ( 
    <React.Fragment>
       {id==='new' && !flowId && <div>
          <FlowTable notifySelect={id=> setFlowId(id)} />
        </div>}
        {id==='new' && flowData && <div><FlowDetail data={flowData} user={user} /></div> }
        {id==='new' && flowData && <div className="row justify-content-center mt-4">
          <div className="col-auto text-center">
          <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Start Work Flow</button>
          </div>
          <div className="col-auto">
            <NavLink className="nav-item nav-link p-0" to={`/flow`} >
            <button type="button" className="btn btn-secondary text-white" >Cancel</button>
            </NavLink>
          </div>
        </div>}
    </React.Fragment>
   );
}
 
export default MyFlowManage;