import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { NavLink } from 'react-router-dom';
import { saveProject } from '../../../services/manage/project/index';

import PersonPhoto from '../../common/card/personPhoto';

const RoundBorder = {
  borderRadius: 10,
  border: '1px solid #AAAAAA',
  padding: 1
}

const ProjectCard = ({ data={}, onRemove=()=>{} }) => {

  const {_id, name, publicThis, lineEvent, lineToken } = data;
  const { taskCount, progress, overrideTaskPermission, denyAll, allowAll, permission=[] } = data;

  const progressEnable = progress!==undefined && progress.enable

  const [enable, setEnable] = useState(data.enable || false)

  const toggleEnable = async(value) => {
    try {
      await saveProject({_id, enable: value })
      setEnable(value)

    } catch(err) {
        toast.error('Failed to update project')
    }
  }


  return ( 
    <React.Fragment>
      <div className="card mb-2">
          <div className="card-body p-2">
              <div className="row align-items-center justify-content-between">
                  <div className="col-auto" style={{cursor: 'pointer'}}>
                      <NavLink className="nav-item nav-link text-dark d-inline" to={`/project/${_id}`} >
                      <h5 className="d-inline font-weight-bold"><FontAwesome name="space-shuttle" size="lg" /><span className="ml-2">{name}</span></h5>
                      </NavLink>
                      {!progressEnable && <span className="badge badge-info mr-2">
                          <FontAwesome name="file-code-o" /> Task x {taskCount}
                      </span>}
                      {progressEnable && <span className="badge badge-primary mr-2"><FontAwesome name="flag-checkered" /> Progression</span>}
                      {publicThis && publicThis.enable && <span className="badge badge-primary mr-2"><FontAwesome name="globe" /> Public</span>}
                      {lineEvent>0 && lineToken>0 &&
                      <span className="badge badge-success mr-2"><FontAwesome name="comments-o" /> Line</span>
                      }
                      {overrideTaskPermission && 
                          <span className="badge badge-secondary mr-2"><FontAwesome name="exclamation-triangle" /> Override Task Permission</span>}
                  </div>
                  <div className="col-auto pr-4">
                      <div className="row no-gutters align-items-center">
                          <div className="col-auto mr-2">
                            <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                          </div>
                          <div className="col-auto">
                              <NavLink className="nav-item nav-link text-dark" to={`/project/${_id}/?clone=yes`} ><FontAwesome name="clone" size="lg" /></NavLink>
                          </div>
                          <div className="col-auto">
                              <NavLink className="nav-item nav-link text-dark" to={`/project/${_id}`} ><FontAwesome name="pencil" size="lg" />
                              </NavLink>
                          </div>
                          <div className="col-auto">
                              <FontAwesome style={{cursor: 'pointer'}} name="trash" size="lg" onClick={()=>onRemove(_id)} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
            <div className="card-body py-0 mb-2">
              <div className="row no-gutters align-items-center">
                  <div className="col-auto p-0">
                      <div className="row no-gutters align-items-center" style={RoundBorder}>
                          <div className="col-auto ml-2 mr-1">
                              <h6 className="d-inline"><span className="badge badge-danger"><FontAwesome name="lock" /> Create</span></h6>
                          </div>
                          {denyAll && <span className="text-danger font-weight-bold mx-1">Default Denied</span>}
                          {denyAll===false && allowAll && <span className="text-success font-weight-bold mx-1">Allow All Staff</span>}
                          {denyAll===false && allowAll===false && permission.length===0 && <span className="text-danger font-weight-bold mx-1">Deny All Staff</span>}
                          {denyAll===false && allowAll===false && permission.length>0 && permission.map( x=> {
                              if(x.type==='staff') {
                                  return (
                                      <div key={x._id} className="col-auto mr-1">
                                          <PersonPhoto id={x._id} className='rounded-circle' size={35} />
                                      </div>
                                  )
                              } else {
                                  return (<div key={x._id} className="col-auto mr-1">
                                  <h6 className="d-inline"><span className="badge badge-info"><FontAwesome name="users" /> {x.name || x._id}</span></h6>
                              </div>)
                              }

                          })}
                      </div>
                  </div>
              </div>
              
          </div>
          
      </div>
    </React.Fragment>
    );
    
}
 
export default ProjectCard;