import React from "react";
import SearchItem from "./searchItem";

const SearchTable = ({data=[], onConfirm=()=>{}}) => {

  return ( <div className="row">
  {data.map( item => {
    return (
      <div key={item.id || item.userId} className="col-12">
        <SearchItem item={item} onConfirm={onConfirm} />
      </div>
    )
  })}
</div> );
}


export default SearchTable;
