import React, { useState, useEffect} from 'react';
import dlv from 'dlv'
import ButtonGroup from '../../common/buttonGroup';

const headerSpace = 180

const ManageDataFieldNumber = ({number, setNumber}) => {

    const numberTypes = [{name: 'Integer', value: 'integer'}, {name: "Decimal", value: 'decimal'}]

    const [type, setType] = useState( dlv(numberTypes.find(x=> x.value === dlv(number, 'type')), 'value', 'integer') )
    const [min, setMin] = useState( dlv(number, 'min', 0))
    const [max, setMax] = useState( dlv(number, 'max', 999))
    const [init, setInit] = useState( dlv(number, 'init', ''))
    const [useNumberDefault, setUseNumberDefault] = useState( dlv(number, 'init')!==undefined ? true : false)

    const sendError = (error) => { setNumber({ error })  }

    useEffect(() => {
        if(setNumber instanceof Function) {

            if(!type) return sendError('Please select number type')
            
            if(type==='integer') {
                if(! /^[-]?\d+$/.test(min)) return sendError('Invalid min number')
                if(!  /^[-]?\d+$/.test(max)) return sendError('Invalid max number')
                if(parseInt(max)<parseInt(min)) return sendError('min is greater than max')
            } else if(type==='decimal') {
                if(! /^[-]?(?:\d*\.\d{1,2}|\d+)$/.test(min) || !  /^[-]?(?:\d*\.\d{1,2}|\d+)$/.test(max)) return sendError('min-max is invalid')
                if(parseFloat(max)<parseFloat(min)) return sendError('min is greater than max')
            } else {
                return sendError('Invalid Number Type')
            }

            const update = { type, min, max }

            if(useNumberDefault) {
                if(type==='integer') {
                    if(! /^[-]?\d+$/.test(init) ) return sendError('default is invalid')
                } else if(type==='decimal') {
                    if(! /^[-]?(?:\d*\.\d{1,2}|\d+)$/.test(init)) return sendError('default is invalid')
                }

                if(parseFloat(init) < parseFloat(min) || parseFloat(init)>parseFloat(max)) return sendError('default is out of range')

                update.init = init
            }

            setNumber(update)
        }

        // eslint-disable-next-line
    }, [type, min, max, useNumberDefault, init]);

    return ( 
        <React.Fragment>
            <div className="row align-items-center mt-3">
                <div className="col-auto" style={{width: headerSpace}}>
                    <h5 className="font-weight-bold">Options</h5>
                </div>
                <div className="col-auto">
                    <ButtonGroup
                        items={numberTypes}
                        selectedItem={numberTypes.find(x=> x.value===type)}
                        onItemSelect={item => setType(item.value)}
                        valueProperty='value'
                        selectedItemClass="btn btn-dark"
                        />
                    
                </div>
                <div className="col-auto">
                    <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-default">Min</span>
                    </div>
                    <input type="text"
                        value={min} onChange={e => setMin(e.target.value)} style={{maxWidth: 100}}
                        className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                    
                    </div>
                </div>
                <div className="col-auto">
                    <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-default">Max</span>
                    </div>
                    <input type="text"
                        value={max} onChange={e => setMax(e.target.value)} style={{maxWidth: 100}}
                        className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                    
                    </div>
                </div>
                <div className="col-auto ml-2">
                    <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-default">
                            <input type="checkbox" checked={useNumberDefault} onChange={e => { setUseNumberDefault(e.target.checked)}} />
                            <span className="ml-2">Default</span>
                        </span>
                    </div>
                    <input type="text" disabled={!useNumberDefault}
                        value={init} onChange={e => setInit(e.target.value)} style={{maxWidth: 90}}
                        className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                    
                    </div>
                </div>
            </div>
        </React.Fragment>
     );
}
 
export default ManageDataFieldNumber;