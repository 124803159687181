import React from 'react';

import Chart from 'react-apexcharts'
import _ from 'lodash'

const GraphBarBasic = ({data, className = '', header = 'Bar Report', yaxisText = 'จำนวน', height, series=['staff']}) => {
  //data = [{label: "07", number: 1}]
  if(!data || data.isEmpty) return (<div></div>)

  if (!data || data.length < 1 ) return (<div></div>);


  const hourlyNumbers = (data || []).filter(x=>x.number>0).map(x=>x.number)
  const hourlyMaxMinDiff = hourlyNumbers.length>0 ? Math.max(...hourlyNumbers) - Math.min(...hourlyNumbers) : 0
  const gHeight = height || (hourlyMaxMinDiff>3 ? 300 : 200)
  //{label: "07", labelColor: "#000000", workCount: 1}
  //data = [{label: "07", number: 1}]
  let gSeries = [{ data: data.map(x => x.number), name: yaxisText }];
  let heightMultiply = 1
  let verticalHeight = data.length*20

  if (series.includes('staff')) {
    if(data.find(x=> x.staff)) {
      gSeries.push({ data: data.map(x => x.staff), name: 'Staff' })
      heightMultiply = 2
    }
  }
  

  const maxY = _.max(data.map(x => x.number))
  const gOptions = {   
    chart: { toolbar: { show: false }},
    plotOptions: { bar: { horizontal: false, dataLabels: { position: 'top' } } },
    // dataLabels: {offsetX: 0, offsetY: 0}, //, style: { fontSize: '10px', }
    xaxis: {      
      categories: data.map(x => x.label),      
      labels: { maxHeight: 160, rotateAlways: false, trim: true },
    },
    yaxis: {
      title: {text: yaxisText,},
      tickAmount: 4, min:0, max:maxY, forceNiceScale: true,
      labels: { formatter: (value) => { return Math.floor(value).toString() }, },
      
    },
    // xs-0, sm-576, md-768, lg-992, xl-1200--------------------------------------------------------------------
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: { height: (verticalHeight*heightMultiply)+100, width:'100%' ,},
          plotOptions: { bar: { horizontal: true, barHeight: '90%' } },
          // dataLabels: { offsetX: 0, offsetY: 0, },  
          grid:{xaxis: { lines: { show: true } },yaxis: { lines: { show: false } }},
          xaxis: {    
            title: { text: yaxisText,},
            min:0, max:maxY, forceNiceScale: true,
            labels: { minHeight: 30, trim: false, formatter: (value) => { return Math.floor(value).toString() },},
          },
          yaxis: {
            title: { text: '', }, axisBorder: { show: true },
            labels: {trim: true},
          },
        }
      },
    ]   
  }
  
  return ( 
    <div className={className}>
      <div className="card border-0" style={{ minWidth: '265px' }}>
          <div className="row" >
            <div className="col-12"><h6 className="m-0">{header}</h6></div>
            <div className="col-12">
            {data.length >= 1 && <Chart type="bar" series={gSeries} width="100%" height={gHeight} options={gOptions} />}
            {data.length < 1 && <div className="alert alert-secondary" role="alert">No Data</div>}       
            </div>
          </div>
      </div>
    </div>
  );


}
 
export default GraphBarBasic;
