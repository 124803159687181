import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { getOcItem, saveOc } from '../../../services/oc/ocService';
import ButtonGroup from '../../common/buttonGroup';
import dlv from 'dlv'
import Toggle from 'react-toggle';
import NodeApproval from './nodeApproval';
import NodePermission from './nodePermission';
import NodeJawis from './nodeJawis';
import { toast } from 'react-toastify';
import { domainList as getDomainList } from '../../../services/domain/domainService';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';
import PersonCardMini from '../../common/card/personCardMini';

const nodeType = [
    {value: 'root', name: 'Root', level: 1, approvalLevel: 10}, 
    {value: 'business', name: 'Business', level: 2, approvalLevel: 9}, 
    {value: 'group', name: 'Group', level: 3, approvalLevel: 9}, 
    {value: 'division', name: 'Division', level: 4, approvalLevel: 8}, 
    {value: 'department', name: 'Department', level: 5, approvalLevel: 7}, 
    {value: 'section', name: 'Section', level: 6, approvalLevel: 6}, 
    {value: 'person', name: 'Person', level: 7, approvalLevel: 5}, 
  ]

const DialogEditOCNode = ({id='new', path=[], parent, isOpen, onSave, onCancel, readOnly=false, askDeleteNode=()=>{} }) => {

  const [domainList, setDomainList] = useState([])

  useEffect(()=> {
    const loadDomainList = async() => {
      try {
        const {data: result} = await getDomainList({enable: true, projectionReq: 'name'})
        if(result.status==='success') {
           setDomainList(result.data.list)
        } else {
          toast.error(result.message)
        }
      } catch(err){ toast.error('Failed to load Domain List')}
    }

    loadDomainList()
  },[])

  const [enable, setEnable] = useState(true)
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [type, setType] = useState()
  const [approval, setApproval] = useState({person: []})
  const [permission, setPermission] = useState([])
  const [jawis, setJawis] = useState([])
  const [person, setPerson] = useState()

  const [warning, setWarning] = useState(undefined)
  const [error, setError] = useState(undefined)

  useEffect(() => {
      setWarning(undefined)
      setError(undefined)
  }, [name, description])

  //Load Node Data
  useEffect(() => {
      const loadData = async() => {

        if(!id || id==='new') {
            
        } else {
          try {
            const {data: result } = await getOcItem(id);

            console.log('result.data', result.data)
    
            const { doc } = result.data
            setEnable(doc.enable)
            setName(doc.name)
            setDescription(doc.description)
            setType(doc.type)
            
            setApproval({level: nodeType.find(x=> x.value===doc.type).approvalLevel, person: dlv(doc, 'approval.person', [])})
            setPermission(doc.permission || [])
            setJawis(doc.jawis || [])
            setPerson(doc.person)
    
          } catch(err) {

          }
        }
      }

      loadData()

  }, [id])

  const handleSave = async () => {

    try {
      if(!type) return setWarning('Type is required'); else setWarning(undefined)

      if(['business', 'group', 'division', 'department', 'section'].includes(type)) {
        if(!name) return setWarning('Name is required'); else setWarning(undefined)
      }

      if(type==='person' && !person) return setWarning('Person is required'); else setWarning(undefined)

      const doc = {_id: (id==='new') ? undefined : id, enable, name, description, type, permission , jawis, person }
      if(parent) {
        doc.parent = dlv(parent, 'id')
      }
      if(dlv(approval, 'level')) {
        doc.approval = approval
      }

      const { data: result } = await saveOc(doc);
      if ( result.status === 'success') {
          if( typeof(onSave) === 'function') {
              onSave({id})
          }
      } else {
        setError(result.message)
      }

    } catch(err) {
      setError(err.message)
    }
      
  }

  const handleCancel = () => {  
      setWarning(undefined)
      setError(undefined)
      if(typeof(onCancel)==='function') { onCancel() }    
  }

  const headerSpace = 180

  return ( 

    <div>
      <Modal size="xl" isOpen={isOpen} toggle={handleCancel} >
        <ModalHeader color="info">Add/Edit OC Node</ModalHeader>
        <ModalBody className="p-4">
          {type!=='person' && <div className="row align-items-center">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">Enable</h6>
            </div>
            <div className="col p-2">
              {readOnly && <h5 className='text-primary'>{enable ? 'Yes' : 'No'}</h5>}
              {!readOnly && <Toggle
                disabled={readOnly}
                checked={enable}
                onChange={value => setEnable(value.target.checked) } />}
            </div>
          </div>}
          <div className="row align-items-center">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">Path</h6>
            </div>
            <div className="col p-2">
              <h5 className='d-inline'>{path.map(x=> x.name).join(' / ')}</h5>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">Type</h6>
            </div>
            <div className="col p-2">
              {id==='new' && <ButtonGroup items={nodeType.filter(x=> x.level > dlv(nodeType.find(x=> x.value===dlv(parent, 'type')), 'level', 100))} 
              selectedItemClass='btn btn-sm btn-dark'
              defaultItemClass='btn btn-sm btn-outline-dark'
              selectedItem={nodeType.find(x=> x.value===type)} 
              onItemSelect={item => { 
                setType(item.value)
                setApproval(prev => { return {...prev, level: item.approvalLevel}})
              }} valueProperty='value' />}
              {id && id!=='new' && <h5 className='d-inline'>{dlv(nodeType.find(x=> x.value===type), 'name', 'Unknown')}</h5>}
            </div>
          </div>
          {['root', 'business', 'group', 'division', 'department', 'section'].includes(type) && <React.Fragment>
            <div className="row align-items-center">
              <div className="col-auto text-muted" style={{width: headerSpace}}>
                  <h6 className="d-inline font-weight-bold">Name</h6>
              </div>
              <div className="col p-2">
                {readOnly && <h5 className='text-primary'>{name}</h5>}
                {!readOnly && <input className="form-control font-weight-bold text-primary" value={name || ''} onChange={e => setName(e.target.value)} type="text" />}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-auto text-muted" style={{width: headerSpace}}>
                  <h6 className="d-inline font-weight-bold">Description</h6>
              </div>
              <div className="col p-2">
                {readOnly && <h6 className='text-muted'>{description || '-'}</h6>}
                {!readOnly && <textarea className="form-control" value={description || ''} onChange={e => setDescription(e.target.value)} row={3} />}
              </div>
            </div>
          
            {[10, 9,8,7,6].includes(dlv(approval, 'level')) && <NodeApproval className="mt-2" approval={approval} setApproval={setApproval} readOnly={readOnly} />}
            <NodePermission className="mt-3" permission={permission} setPermission={setPermission} readOnly={readOnly} />
            <NodeJawis className="mt-3" domainList={domainList} jawis={jawis} setJawis={setJawis} readOnly={readOnly} />
          </React.Fragment>}
          {['person'].includes(type) && 
          <div className="row align-items-center mt-3">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">Person</h6>
            </div>
            {!person && <div className="col">
              <DialogSearchPerson buttonText="Search Person" notifySelect={setPerson} cache={false} buttonClass="btn btn-sm btn-dark" />
            </div>}
            {person && <div>
              <PersonCardMini data={{userId: person.userId}} styles={{showInfo: ['name', 'company', 'sim', 'position'], photoSize: 90, showBorder: false }} />
            </div>}
          </div>
          }
          {warning && <div className="alert alert-warning" role="alert">{warning}</div>}
          {error && <div className="alert alert-danger" role="alert">{error}</div>}
        </ModalBody>
        <ModalFooter>
            {!readOnly && <Button color="primary mr-2" onClick={handleSave}>Save</Button>}
            {id!=='new' && type==='person' && <Button color="danger mr-2" onClick={() => askDeleteNode(id)}>Delete</Button>}
            <Button color="secondary" onClick={handleCancel}>{(readOnly) ? 'Close' : 'Cancel'}</Button>
        </ModalFooter>
      </Modal>
    </div>
   );
}
 
export default DialogEditOCNode;