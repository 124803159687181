import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import PersonPhoto from '../../common/card/personPhoto';
import { setLineEnable } from '../../../services/manage/line/lineService';

const LineRoomCard = ({ data={}, onRemove=()=>{}, setAskManage=()=>{} }) => {

    const {_id, name, staff } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await setLineEnable({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update line room')
        }
    }

  return ( 
    <React.Fragment>
        <div className="card mb-2">
            <div className="card-body p-1">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto ml-3" style={{cursor: 'pointer'}}>
                        <h6 className="d-inline font-weight-bold"><FontAwesome name="comments-o" size="lg" /><span className="ml-2">{name}</span></h6>
                        {staff && <PersonPhoto id={staff.userId} size={30} />}
                    </div>
                    <div className="col-auto pr-4">
                        <div className="row  align-items-center">
                            <div className="col-auto p-1">
                                <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                            </div>
                            <div className="col-auto px-3">
                                <FontAwesome name="pencil" size="lg" onClick={() => setAskManage({isOpen: true, id: _id})} />
                            </div>
                            <div className="col-auto p-1 mr-2">
                                <FontAwesome name="trash" size="lg" onClick={onRemove} />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
    
}
 
export default LineRoomCard;