import React, {useState, useEffect, useContext} from "react";
import { toast } from 'react-toastify';
// import ConfirmDelete from './../common/confirmDelete';
// import TemplateFormItemEdit from "./taskManageItem";
import TemplateFormItems from "./taskManageTable";
import { saveTemplateTask, getTemplateTask } from '../../../services/manage/task/taskService';
import { NavLink } from 'react-router-dom';
import { DomainContext } from '../../../context/domainContext';
import { Collapse } from "react-bootstrap";
import TaskJobValue from "./taskOptions/jobValue";
import TaskScript from './taskOptions/script';
import TaskPermission from "./taskOptions/permission";
import TaskLineNotify from './taskOptions/lineNotify';
import { PermissionContext } from '../../../context/permissionContext';
import TaskLimit from './taskOptions/limit';
import dlv from "dlv";
import LocationTracking from "./taskOptions/locationTrack";
import { scriptFieldReserved } from './../../../assets/data/template/taskScript'

import queryString from 'query-string'
import TaskControl from './taskOptions/taskControl';

const FormManage = ({history, match, location: propLocation }) => {

  const {domain} = useContext(DomainContext)

  const {permission: appPermission } = useContext(PermissionContext)

  const [id, setId] = useState(match.params.id)

  const [name, setName] = useState('')
  const [useAsTask, setUseAsTask] = useState(true)
  const [allowMessage, setAllowMessage] = useState(false)
  const [allowPhoto, setAllowPhoto] = useState(false)
  const [allowZoom, setAllowZoom] = useState(false)
  const [oneActiveTask, setOneActiveTask] = useState(false)
  const [control, setControl] = useState(undefined)

  const [jobValue, setJobValue] = useState({enable: false})
  const [script, setScript] = useState({enable: false, data: []})
  const [permission, setPermission] = useState([])
  const [lineNotify, setLineNotify] = useState({event: [], token: []})
  const [publicThis, setPublicThis] = useState({enable: false, sharedReport: false})
  const [limit, setLimit] = useState({enable: false})
  const [topList, setTopList] = useState(0)

  const [locationAddress, setLocationAddress] = useState(false)
  const [locationTrack, setLocationTrack] = useState({enable: false})

  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {

    const loadForm = async(formId) => {

      if(formId && formId!=='new') {
        try {
          const {data: result } = await getTemplateTask(formId);
  
          if( result.data && result.data.form ) {
            const { name, description, useAsTask=true, forms, allowMessage=false, allowPhoto=false, allowZoom=false, jobValue={enable: false}} = result.data.form
            const { oneActiveTask=false } = result.data.form
            const { script={enable: false}, publicThis={enable: false, sharedReport: false}, topList=0, permission=[], lineNotify={event: [], token: []}} = result.data.form
            const { limit={enable: false}, location } = result.data.form
            const { control } = result.data.form
  
            setName(name)
            setDescription(description)
            setUseAsTask(useAsTask)
            setForms(forms)
            setAllowMessage(allowMessage)
            setAllowPhoto(allowPhoto)
            setAllowZoom(allowZoom)
            setJobValue(jobValue)
            setOneActiveTask(oneActiveTask)
            setScript(script)
            setPermission(permission)
            setLineNotify(lineNotify)
            setPublicThis(publicThis)
            setLimit(limit)
            setTopList(topList)
            setLocationTrack({enable : dlv(location, 'enableTrack', false), trackOptions: dlv(location, 'trackOptions') })
            setLocationAddress(dlv(location, 'enableAddress', false))
            setControl(control)
  
            setShowOptions(allowMessage || allowPhoto || allowZoom || oneActiveTask )
  
            if(queryString.parse(propLocation.search).clone) {
              setId('new')
              setName('')
              setDescription('')
            }
          }
  
        } catch(err) {
          toast.error('Failed to load form data')
        }
      }
    }

    loadForm(id)
  }, [id, propLocation])
  
  const [description, setDescription] = useState('')
  const [forms, setForms] = useState([])


  const validScriptFields = [
    ...forms.map( x=> { return [x.linkField, x.linkField2] }).reduce((a,b)=> a.concat(b), []).filter(x=>x).map(x=> { return { name: x, value: x}})
    , ...scriptFieldReserved]

  const doSubmit = async () => {

    const toastOptions = { position: toast.POSITION.BOTTOM_RIGHT }

    if(!name) return toast.error('Name is required', toastOptions)
    if(!description) return toast.error("Description is required", toastOptions)
    if(!description.replace(/^\s*$(?:\r\n?|\n)/gm,'')) return toast.error("Description is required", toastOptions)
    if(!forms || forms.length<1) return toast.error('Template is empty', toastOptions)
    if(jobValue.error) return toast.error('Job Value is invalid')
    if(limit.error) return toast.error('Task Limit is invalid')
    if(dlv(control, 'error')) return toast.error(dlv(control, 'error'))

    if(script.enable ) {
      const allInput = script.data.map(x=> x.httpInput.map(input => input.linkField).filter(item=> item) ).reduce((a,b) => a.concat(b), [])

      if(! allInput.every( input => validScriptFields.some( field => field.value===input  ) )) {
        return toast.error('Some script inputs are invalid')
      }
    }


    const location = { 
      enableAddress: locationAddress,
      enableTrack: dlv(locationTrack, 'enable', false),
      trackOptions: dlv(locationTrack, 'trackOptions', undefined)
    }

    const doc = {name, description: description.replace(/^\s*$(?:\r\n?|\n)/gm,''), useAsTask, forms, _id: (id==='new') ? undefined : id, 
      allowMessage, allowPhoto, allowZoom,
      jobValue, oneActiveTask, script, permission, lineNotify, publicThis, 
      limit, topList, location, control }


    try {
      const { data: result } = await saveTemplateTask(doc);
      if ( result.status === 'success') {
        history.push({
          pathname: "/manage/task"
        })
      } else {
        toast.error(result.message)
      }
    } catch(err) {
      toast.error(err.message)
    }
  }

  if(id!=='new' && !name) return (<div className="alert alert-warning" role="alert">Loading task template...</div>)

  return (
    <div className="row">
      <div className="col-12 col-xl-10">
        <div className="row align-items-center mb-4">
          <div className="col-auto" style={{width: 180}}>
              <h5 className="font-weight-bold">Task Name</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <input className="form-control" value={name} onChange={e => setName(e.target.value)} type="text" />
            <span className="text-info">กำหนดชื่อ Task ให้กระชับ ช่วยให้รายงานต่าง ๆ ดูง่ายและสวยงาม</span>
            <p className="text-info">Keep task name short to support a neat report</p>
          </div>
        </div>
        <div className="row align-items-center mb-4">
          <div className="col-auto text-muted" style={{width: 180}}>
              <h5 className="font-weight-bold">Description</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <textarea className="form-control" value={description} onChange={e => setDescription(e.target.value)} row={3} />
          </div>
        </div>
        <div className="d-none row align-items-center mb-4">
          <div className="col-auto text-muted" style={{width: 180}}>
              <h5 className="font-weight-bold">Use as Task</h5>
          </div>
          <div className="col-12 col-md-8 col-lg-6">
            <input type="checkbox" checked={useAsTask} onChange={e => { setUseAsTask(e.target.checked)}} />
          </div>
        </div>
        <TemplateFormItems forms={forms} setForms={setForms} scriptEnable={script.enable} jvEnable={jobValue.enable} />

        {dlv(domain, 'features',[]).find(x=>x.value==='gps-track') && <LocationTracking className="mt-4" data={locationTrack} setData={setLocationTrack} />}
        <TaskLineNotify className="mt-4" lineNotify={lineNotify} setLineNotify={setLineNotify}  />
        <TaskJobValue className="mt-4" jobValue={jobValue} setJobValue={setJobValue}  validField={forms.filter(x=> x.jvField ).map(x=> x.jvField)}  />
        <TaskPermission className="mt-4" permission={permission} setPermission={setPermission}  />
        {false && <TaskControl className="mt-4" control={control} setControl={setControl}  />}
        <TaskLimit className="mt-4" data={limit} setData={setLimit}  />
        
        <div className="row mt-4">
          <div className="col">
            <div className="card">
              <div className="card-header font-weight-bold" onClick={() => setShowOptions(prev => !prev)} style={{cursor: 'pointer'}}>
                <span>More Options</span>
              </div>
              <Collapse in={showOptions}>
                <div>
                <div className="card-body">
                  <input type="checkbox" checked={allowMessage} onChange={e => { setAllowMessage(e.target.checked)}} /><span className="mr-4"> Custom Message / บันทึกข้อความเพิ่มเติมได้</span><br />
                  <input type="checkbox" checked={allowPhoto} onChange={e => { setAllowPhoto(e.target.checked)}} /><span className="mr-4"> Custom Photo / ถ่ายรูปภาพเพิ่มเติมได้</span><br />
                  <input type="checkbox" checked={allowZoom} onChange={e => { setAllowZoom(e.target.checked)}} /><span> Custom Zoom / ใช้ Zoom เพิ่มเติมได้</span>
                  <p><input type="checkbox" checked={locationAddress} onChange={e => { setLocationAddress(e.target.checked)}} />
                    <span className="font-weight-bold"> Working Location / สถานที่ปฏิบัติงาน</span></p>
                </div>
                <div className="card-body">
                <strong><input type="checkbox" checked={oneActiveTask} onChange={e => { setOneActiveTask(e.target.checked)}} />
                    <span className="mr-4"> One Task A Time / หยุดใบงานอัตโนมัติเมื่อปฏิบัติงานอื่น</span></strong><br />
                </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        
        {dlv(domain, 'features',[]).find(x=>x.value==='task-script') && <div className="row mt-4">
          <div className="col">
            <div className="card">
              <div className="card-header font-weight-bold">Script
              <input className="ml-4" type="checkbox" checked={script.enable} onChange={e => {
                  const isCheck = e.target.checked;
                  setScript(prev => 
                    { return {...prev, enable: isCheck}}
                  )

              }} /><span> Enable / เปิดใช้งาน</span><span> (ฟังก์ชันเฉพาะ โปรดสอบถามทีมงาน)</span>
              </div>
                {script.enable && <div className="card-body">
                <TaskScript script={script} notifyChange={setScript} linkFieldList={validScriptFields}  />
              </div>}
            </div>
          </div>
        </div>}
        {appPermission && appPermission.includes('admin') && <div className="row mt-4">
          <div className="col">
            <div className="card">
              <div className="card-header font-weight-bold">
                <div className="row no-gutters">
                  <div className="col-auto">Public Task</div>
                  <div className="col-auto">
                    <input className="ml-4" type="checkbox" checked={publicThis.enable} 
                      onChange={e => setPublicThis({enable: e.target.checked, sharedReport: false})} />
                    <span> Enable / เปิดใช้งาน</span>
                  </div>
                  {dlv(publicThis, 'enable') && <div className="col-auto">
                    <input className="ml-4" type="checkbox" checked={publicThis.sharedReport} onChange={e => setPublicThis({enable: true, sharedReport: e.target.checked})} />
                    <span> Shared Report</span>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>}
        {appPermission && appPermission.includes('admin') && <div className="row mt-4">
          <div className="col">
            <div className="input-group">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-default">Top List</span>
              </div>
              <input type="text"
                  value={topList} onChange={e => setTopList(e.target.value)} style={{maxWidth: 100}}
                  className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
              
            </div>
          </div>
        </div>}
        <div className="row justify-content-center mt-4">
          <div className="col-auto text-center">
          <button type="button" className="btn btn-primary text-white" onClick={doSubmit}>Save Template</button>
          </div>
          <div className="col-auto">
            <NavLink className="nav-item nav-link p-0" to={`/manage/task`} >
            <button type="button" className="btn btn-secondary text-white" >Cancel</button>
            </NavLink>
          </div>
        </div>
        
      </div>
    </div>
      
  );
  
}

export default FormManage;
