import React, { useState } from 'react';
import ProgressCard from './progressCard';
import ButtonGroup from '../../../common/buttonGroup';

const ProgressList = ({className, data, style,
    orderList=[{name: 'Work Finish', value: 'finish'}, 
        {name: 'Work Active', value: 'active'}, 
        {name: 'Work Time', value: 'time'}, 
        {name: 'Job Value', value: 'jv'}]  }) => {

    const [order, setOrder] = useState(orderList.find(x=>x))

    const getTaskList = data.sort((a,b) => {
        switch(order.value) {
        case 'finish':
            return b.taskFinish-a.taskFinish
        case 'active':
            return b.taskActive-a.taskActive
        case 'time':
            return b.timeTotal-a.timeTotal
        case 'jv':
            return b.jvTotal-a.jvTotal
        default: 
            return 0
        }

    })

    if(!data || !Array.isArray(data)) return (<div></div>)

    return ( 
        <div className={className}>
            {getTaskList.length>0 && 
                <div className="row mb-2 d-none">
                    <div className="col text-right">
                        <ButtonGroup
                        items={orderList}
                        selectedItem={order}
                        onItemSelect={filter => setOrder(filter)}
                        valueProperty='value'
                        selectedItemClass='btn btn-sm btn-primary'
                        defaultItemClass='btn btn-sm btn-outline-dark'
                        />
                    </div>
                </div>
                }
            {getTaskList.map( item => {
                return (
                    <div key={item._id} className="row">
                        <div className="col mb-2">
                            <ProgressCard data={item} style={style} />
                        </div>
                    </div>
                )
            })}
        </div>

     );
}
 
export default ProgressList;