import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
import PersonCardMini from '../../common/card/personCardMini';
import { getWorkHourFromTotalMinutes } from '../../../utils/work';
// import dlv from 'dlv'
// import moment from 'moment'

const ReportStaffCard = ({data={}, filter={}, style={workVisible: ['wait', 'active', 'finish', 'pause', 'overdue', 'delete']}, enableLink=true }) => {

  const { userId } = data;

  const { taskTotal, jvTotal, jvRemark, taskWait, taskActive, taskPause, taskFinish, taskDelete } = data
  const { timeTotal,reportThis=[] } = data

  const workTime = getWorkHourFromTotalMinutes(timeTotal)

  return ( 
    <React.Fragment>
      <div className="card mb-2 text-dark p-0">
        <div className="card-body p-2">
        {enableLink && <NavLink className="stretched-link" to={`/report/staff/${userId}/?filter=${filter.filter}&date=${filter.date}`} ></NavLink>}
        <div className="row no-gutters align-items-center justify-content-end">
          <div className="col-auto mr-auto">
            <PersonCardMini data={data} styles={{ photoSize: 80, showBorder: false, showInfo: ['name', 'sim', 'position']}} />
          </div>
          {(taskTotal>0 || taskFinish>0)  && <div className="col-auto mr-2">
            <div className="row no-gutters justify-content-center align-items-center">
                {reportThis.map(item => {
                    const {_id, label, sum, isDecimal} = item

                    const total = isDecimal ? sum.toFixed(2) : sum
                    const avg = (taskFinish<=1 || sum===0) ? undefined : ( isDecimal ? (sum/taskFinish).toFixed(2) : Math.round(sum/taskFinish) )

                    return(<div key={_id} className='col-auto mr-3 text-center'>
                      <h5>
                        <span className="text-primary">{total}<span style={{fontSize: 12}}>total</span></span>
                        {avg && 
                          <React.Fragment>
                            <span className="mx-1">/</span>
                            <span className="text-primary">{avg}<span style={{fontSize: 12}}>avg</span></span>
                          </React.Fragment>}
                      </h5>
                      {label.substring(0, 15)}
                    </div>)
                })}
                {jvTotal>0 && <div className='col-auto mr-3 text-center'>
                <h5><span className="text-primary">
                    {jvRemark && <span className="text-danger">*</span>}
                    {jvTotal.toLocaleString()} {jvTotal>0 && <span>&#3647;</span>}</span></h5>
                    Job Value
                </div>}
                {workTime && <div className='col-auto mr-3 text-center'>
                <h5>
                  <span className="text-primary"><FontAwesome name="clock-o" /> {workTime}</span>
                </h5>
                Work Time
                </div>}
                {style.workVisible.includes('wait') && taskWait!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                Plan
                </div>}
                {style.workVisible.includes('active') && taskActive!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                Active
                </div>}
                {style.workVisible.includes('finish') && taskFinish!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-success">{taskFinish}</span></h4>
                Finish
                </div>}
                {style.workVisible.includes('pause') && taskPause!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                Pause
                </div>}
                {style.workVisible.includes('delete') && taskDelete!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                Delete
                </div>}
            </div>
          </div>}
          {!taskTotal && !taskFinish && <h5 className="text-secondary mr-4">ไม่มีข้อมูลปฏิบัติงาน / No Task</h5>}
        </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default ReportStaffCard;