import React from 'react';
import FontAwesome from 'react-fontawesome';

const SearchItem = ({item={}, onConfirm=()=>{}}) => {

  const { id, name, userName, position, company  } = item;

  return ( 
    <React.Fragment>
          <div className="card mb-2 p-0" style={{width: '100%'}}>
              <div className="card-body p-2">
                  <div className="row align-items-center">
                      <div className="col-3 col-sm-2 text-center p-2">
                          <img className="rounded-circle ml-2" style={{width: "100%"}} src={`https://api-idms.advanceagro.net/hrms/employee/${id}/photocard/?size=100`} alt="..." />
                      </div>
                      <div className="col-9 col-sm-8 mb-2">
                          <div className="container">
                            <h5 className="card-title d-inline text-primary mr-2">{name || userName}</h5>
                            <h6 className="card-text d-inline"><FontAwesome name="building" /> {company}</h6>
                          </div>
                          <div className="container mt-2">
                            {position && <h6 className="card-text d-inline"><FontAwesome name="briefcase" /> {position}</h6>}
                            <div className="d-block d-sm-none mt-1">
                              <button className="btn btn-primary p-1" onClick={() => onConfirm(id)}>เลือก</button>
                            </div>
                          </div>
                      </div>
                      <div className="col-sm-2 d-none d-sm-inline">
                        <button className="btn btn-primary p-1" onClick={() => onConfirm(item)}>เลือก</button>
                      </div>
                  </div>
                  
              </div>
          </div>
        </React.Fragment>
   );
}
 
export default SearchItem;