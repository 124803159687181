import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { NavLink } from 'react-router-dom';
import { saveDataField } from '../../../services/manage/data/field';

const DataFieldCard = ({ data={}, onRemove=()=>{} }) => {

    const {_id, name } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await saveDataField({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update person group')
        }
    }

  return ( 
    <React.Fragment>
        
        <div className="card mb-2">
            <div className="card-body p-0">
                <div className="row align-items-center justify-content-between">
                <div className="col-auto" style={{cursor: 'pointer'}}>
                    
                    <NavLink className="nav-item nav-link text-dark" to={`/manage/data/field/${_id}`} >
                    <FontAwesome name='chain' size="lg" />
                        <h6 className="d-inline font-weight-bold ml-3">{name}</h6>
                    </NavLink>
                    
                </div>
                <div className="col-auto pr-4">
                    <div className="row  align-items-center">
                        <div className="col-auto p-1">
                            <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                        </div>
                        <div className="col-auto p-1">
                        <NavLink className="nav-item nav-link text-dark" to={`/manage/data/field/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                        </div>
                        <div className="col-auto p-1 mr-2">
                            <FontAwesome name="trash" size="lg" onClick={onRemove} />
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
    
}
 
export default DataFieldCard;