import React, { useState, useEffect } from 'react';
import dlv from 'dlv'
import { Collapse } from "react-bootstrap";


const FlowOptions = ({className='', data, setData}) => {
    const [show, setShow] = useState(true)
    const [startFromTask, setStartFromTask] = useState(dlv(data, 'options.startFromTask', false))

    useEffect(() => {
        if(setData instanceof Function) {
            setData(prev => { return {...prev, startFromTask } })
        }

    }, [startFromTask, setData])


    return ( 
        <div className={className}>
            <div className="card">
                <div className="card-header font-weight-bold" onClick={() => setShow(prev => !prev)} style={{cursor: 'pointer'}}>Options</div>
                <Collapse in={show}>
                    <div className="card-body">
                    <div className="row align-items-center">
                        <div className="col-auto">
                        <input type="checkbox" checked={startFromTask} onChange={e => setStartFromTask(e.target.checked)} />
                        <span> Allow create first task to start flow</span>
                        </div>
                    </div>
                    </div>
                </Collapse>
            </div>
        </div>
     );
}
 
export default FlowOptions;