import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
// import FontAwesome from 'react-fontawesome';
import { templateFlowList } from '../../../services/manage/flow/manageFlowService';

const FlowTable = ({notifySelect}) => {
    const getList = async() => {
        try {
            const {data: result} = await templateFlowList();

            if(result.status==='success') {

                setList(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        getList()
        
    }, [])

    const [list, setList] = useState([])

    const selectFlow = (id) => {
        if(typeof(notifySelect)==='function') {
            notifySelect(id)
        }
    }


    return ( 
        <React.Fragment>
            <div className="row justify-content-between mb-3">
                <div className="col"><h4>Select Work Flow</h4></div>
                <div className="col-auto p-0">
                    
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Work Flow is Empty
            </div>
            }
            <div className="row">
            {list && list.map( (item, index) => {
                const {_id, name } = item;

                return (
                <div key={index} className="col-12">
                    <div className="card mb-2">
                        <div className="card-body p-2">
                            <div className="row align-items-center justify-content-between">
                            <div className="col-auto ml-2">
                                {/* <h5 className="d-inline"><span className="badge badge-secondary">{index+1}</span></h5> */}
                                {/* <span className="ml-2"><h6 className="d-inline font-weight-bold">{name}</h6></span> */}
                                <NavLink className="nav-item nav-link text-dark text-bold font-weight-bold" to={`/manage/flow/${_id}`} >{name}</NavLink>
                            </div>
                            <div className="col-auto pr-4">
                                <div className="row  align-items-center">
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary text-white" onClick={() => selectFlow(_id)} >Select</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
            </div>
        </React.Fragment>
     );
}
 
export default FlowTable;