import http from "../../httpService";

const apiEndpoint = "/manage/flow";

export function templateFlowList() {
  return http.get(apiEndpoint)
}

export function getTemplateFlow(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveTemplateFlow(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deleteTemplateFlow(id) {
  return http.delete(apiEndpoint + "/" + id)
}