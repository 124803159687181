import React from 'react';
// import moment from 'moment'
import GraphBarBasic from '../../../common/graph/basicBar';
// import GraphDonutBasic from '../../common/graph/basicDonut';

// import dlv from 'dlv'
import ReportDomainList from '../domainList';

const ReportDomainHistory = ({report={}, filter}) => {

    const { domainTotal='-', domainActive='-', staffTotal='-', staffActive='-', jobValueTotal='-' } = report

    const {  groupByHour, groupDaily, domainList } = report

    const { workTotal=0 } = report

    const domainOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(domainList && domainList.some(x=> x.jvTotal>0)) domainOrderList.push({name: 'Job Value', value: 'jv'})

    const hourlyNumbers = (groupByHour || []).filter(x=>x.number>0).map(x=>x.number)
    const hourlyMaxMinDiff = hourlyNumbers.length>0 ? Math.max(...hourlyNumbers) - Math.min(...hourlyNumbers) : 0

    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    return ( <React.Fragment>
        <div className="row no-gutters justify-content-left mt-3">
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Domain</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-light">{domainTotal}</span></h4>
                            Total
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{domainActive}</span></h4>
                            Active
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Staff</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-light">{staffTotal}</span></h4>
                            Total
                            </div>
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-primary">{staffActive}</span></h4>
                            Active
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card">
                    <div className="card-header">
                        <strong>Finish Work</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{workTotal}</span></h4>
                            Total
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {jobValueTotal>0 && 
            <div className="col-auto mr-3 mb-3 text-center">
                <div className="card" style={{minWidth: 100}}>
                    <div className="card-header">
                        <strong>Job Value</strong>
                    </div>
                    <div className="card-body">
                        <div className="row justify-content-center align-items-center font-weight-bold">
                            <div className='col-auto mx-1 text-center'>
                            <h4><span className="badge badge-pill badge-success">{jobValueTotal.toLocaleString()} &#3647;</span></h4>
                            Total
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
        {groupDaily && <GraphBarBasic className='mb-3' data={groupDaily} header='Work Daily' yaxisText='Finish Work' series={[]}  />}
        {groupByHour && <GraphBarBasic className="mb-3" data={groupByHour} header='Finish by Hour' height={hourlyMaxMinDiff>10 ? 300 : 200} />}
        {domainList && <ReportDomainList className="mt-4" data={domainList} filter={filter} style={{workVisible: ['finish']}} orderList={domainOrderList} />}
        
    </React.Fragment> );
}
 
export default ReportDomainHistory;