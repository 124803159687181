import React, {useState, useEffect} from 'react';

import { toast } from 'react-toastify';
import ReportWorkTemplate from './reportWorkTemplate';
import ReportWorkMessage from './reportWorkMessage';
import ReportWorkPhoto from './reportWorkPhoto';
import ReportWorkInfo from './reportWorkInfo';
import socketIOClient from '../../../services/app/socketioService';
import ReportWorkZoom from './reportWorkZoom';
import { getReportWorkItem } from '../../../services/report/reportWork';
import ReportWorkMap from './reportWorkMap';

const ReportWork = ({match}) => {

    const [id,] = useState(match.params.id)
    const [work, setWork] = useState(undefined)

    const loadWork = async(workId) => {
        if(!workId) return

        try {
            const { data: result } = await getReportWorkItem(workId);
            setWork(result.data.work)

        } catch(err) {
            toast.error('Failed to load work data')
        }
    }

    useEffect(() => {
        loadWork(id)

        socketIOClient.on('work-update', (updateId) => { 
            if(updateId===id) loadWork(id) 
        })
        return () => { socketIOClient.off('work-update')   }
    }, [id])

    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!work) return (<div className="alert alert-warning" role="alert">work is not found</div>);

    return ( 
    <React.Fragment>
        {work && <ReportWorkInfo data={work} /> }
        {work.template && <ReportWorkTemplate className="mt-4" template={work.template} />}
        {work.location && <ReportWorkMap className="mt-4" location={work.location} />}

        {work.message && <ReportWorkMessage className="mt-4" message={work.message} />}
        {work.photo && <ReportWorkPhoto className="mt-4" photo={work.photo} />}
        {work.zoom && <ReportWorkZoom className="mt-4" zoom={work.zoom} />}
    </React.Fragment> );
}
 
export default ReportWork;