import React, {useState, useEffect} from 'react';

// import FontAwesome from 'react-fontawesome';
import TemplateFlowEditItem from './flowEditItem';
import { templateTaskList } from '../../../services/manage/task/taskService';
import { projectList as getProjectList } from '../../../services/manage/project/index';

import { toast } from 'react-toastify';
import dlv from 'dlv'
import TemplateFlowEditCard from './flowEditCard';

const TemplateFlowEditTable = ({tasks=[], setTasks}) => {

  const [edit, setEdit] = useState(-1)

  const [taskList, setTaskList] = useState([])
  const [projectList, setProjectList] = useState([])
  
  useEffect(() => {
    const loadTasks = async() => {
      try {
          const {data: result} = await templateTaskList({enable: true, projectionReq: 'name'});
          
          if(result.status==='success') {
            setTaskList(result.data.list)
          } else {
            toast.error(result.message)
          }
      } catch(err) {
          toast.error(err.message)
      }
    }
  
    const loadProjects = async() => {
      try {
          const {data: result} = await getProjectList({enable: true, projectionReq:'name,tasks', removeProjection:''});
          if(result.status==='success') {
              setProjectList(result.data.list.filter(x=> dlv(x, 'tasks', []).length>0))
          } else {
              toast.error(result.message)
          }
      } catch(err) {
          toast.error(err.message)
      }
    }

    loadTasks()
    loadProjects()
      
  }, [])

  const handleDelete = ({index}) => {
    const newData = [...tasks]
    newData.splice(index, 1)

    if( setTasks instanceof Function) { setTasks(newData)  }
  }

  const handleSaveChange = (item, index) => {
    const data = [...tasks];

    if(index>-1 && index<data.length) {
      data[index] = item
    } else {
      data.push(item)
    }

    if( setTasks instanceof Function) { setTasks(data)  }

    setEdit(undefined)
  }

  const askEditItem = (index) => {
      setEdit(index); 
  }

  const notifyEditCancel = () => { setEdit(-1)  }

  return (
    <React.Fragment>
      <h6 className="text-muted">Steps</h6>
      {tasks.length===0 && 
        <div className="alert alert-warning" role="alert">
          Flow is empty
        </div>
      }
      {tasks.length>0 && <div className="row">
        {tasks && tasks.map( (item, index) => {


          return (
            <div key={index} className="col-12">
              <TemplateFlowEditCard index={index} data={item} askEditItem={askEditItem} onRemove={handleDelete} />
              {index===edit && <TemplateFlowEditItem taskList={taskList} projectList={projectList} data={tasks[edit]} 
                notifyCancel={notifyEditCancel} 
                notifySave={x => handleSaveChange(x, index)} />}
            </div>
          )
        })}

      </div>}
      <div className="row mt-3">
        <div className="col text-right">
          <button type="button" className="btn btn-primary text-white" onClick={() => { setEdit('new')}}>Add</button>
        </div>
      </div>
      {edit==='new' && <TemplateFlowEditItem taskList={taskList} projectList={projectList} notifySave={x=> handleSaveChange(x, -1)} notifyCancel={() => setEdit(undefined)} />}

    </React.Fragment>
  );
}
 
export default TemplateFlowEditTable;