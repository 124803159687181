import React, { useState} from "react";

const ButtonGroupMulti = ({
  items,
  textProperty,
  valueProperty,
  notifyChange,
  initSelectItems=[],
  selectedItemClass='btn btn-primary btn-outline-light',
  defaultItemClass='btn btn-outline-dark',
  className='btn-group p-0 m-0'
}) => {

  const [selectedItems, setSelectedItems] = useState(initSelectItems)

  const handleItemClick = item => {
    const findExist = selectedItems.findIndex(x=> x[valueProperty]===item[valueProperty])

    let update = [...selectedItems]
    
    if(findExist>=0) {
      update.splice(findExist,1)

    } else {
      update.push(item)
    }

    setSelectedItems(update)

    if(notifyChange instanceof Function) {
      notifyChange(update)
    }
  }

  return (
    <ul className={className}>
      {items.map(item => {
        return (<button
          key={item[valueProperty]}
          type="button"
          onClick={() => handleItemClick(item)}
          className={
            selectedItems.find(x=> x[valueProperty]===item[valueProperty]) ? selectedItemClass : defaultItemClass
          }
        >
          {item[textProperty]}
        </button>)
      })}
    </ul>
  );
};

ButtonGroupMulti.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default ButtonGroupMulti;
