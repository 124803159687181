import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getData } from '../../../services/manage/data/index';
import http from '../../../services/httpService'
import ReactJson from 'react-json-view'
import DataManual from './dataManual';
import dlv from 'dlv'

const DataContent = ({id, notifySave, notifyCancel}) => {

    const [data, setData] = useState()
    const [dataType, setDataType] = useState({})
    const [dataApi, setDataApi] = useState('')
    const [content, setContent] = useState([])
    const [contentError, setContentError] = useState('')

    const loadDataDoc = async(id) => {

        try {
            const {data: result} = await getData(id);
            if(result.status==='success') {

                const { doc } = result.data

                setData(doc)
                setDataType(dlv(doc, 'options.dataType', {}))
                
                if( dlv(doc, 'options.dataType.value')==='api') {
                    setDataApi(dlv(doc, 'options.api.url'))
                }
                
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    const loadDataAPI = async(url) => {
        if(!url) return;
        try {
            const {data: result} = await http.get(url);
            if(result.status==='success') {


                setContent(result.data.list)
                
            } else {
                setContentError(result.message)
            }
        } catch(err) {
            setContentError(err.message)
        }
    }

    useEffect(() => {
        loadDataDoc(id)
        
    }, [id])

    useEffect(() => {
        console.log(dataApi)
        if(dataApi) {
            loadDataAPI(dataApi)
        } 
        

    }, [dataApi])



    const onCancel = () => {
        if (notifyCancel instanceof Function) {
            notifyCancel()
        }
    }


    return ( <React.Fragment>
        <div className="card bg-secondary text-white my-3">

            {data && <div className="card-body">
                Data Type <h5 className="d-inline ml-2"><span className="badge badge-light">{dataType.name}</span></h5>
                {dataType.value==='api' && 
                    <div className="row align-items-center m-4">
                        <div className="col bg-white p-2">
                            {contentError && <div className="alert alert-warning">{contentError}</div>}
                            {!contentError && <ReactJson src={content} name={false} theme="summerfruit:inverted" displayDataTypes={false} displayObjectSize={false} />}
                        </div>
                    </div>
                }
                {dataType.value==='manual' && 
                <div className="row align-items-center m-4">
                    <div className="col bg-white text-dark p-2">
                        <DataManual id={id} />
                    </div>
                </div>
                }
            </div>}
            
            <div className="card-footer p-2">
                <div className="row align-items-center justify-content-end">
                    <div className="col-auto">
                        <button type="button" className="btn btn-light btn-sm text-dark font-weight-bold mr-2" onClick={onCancel}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default DataContent;