import React, { useState} from 'react';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';

import NodePermissionCard from './nodePermissionCard';

const headerSpace = 180

const NodePermission = ({className, permission=[], setPermission, readOnly=false}) => {

    const [askDelete, setAskDelete] = useState('')

    const confirmSelectPerson = async(item) => {
        if(permission.some(x=> x.userId===item.userId)) {
            toast.info("User is exist in permission")
        } else {
            if(setPermission instanceof Function) {
                setPermission(prev => [...prev, {enable: true, roles: [], ...item}])
            }
        }
    }

    const onCardChange = ({userId,roles, enable}) => {
        if(setPermission instanceof Function) {
            setPermission(prev => {
                return prev.map(item => {
                    if(item.userId===userId) {
                        return {...item, roles, enable}
                    } else {
                        return item
                    }
                })
            })
        }
    }

    const confirmDelete = async() => {
        if(setPermission instanceof Function) {
            setPermission(prev => [...prev.filter(x=> x.userId!==askDelete)])
        }
        setAskDelete(undefined)
    }

    return ( 
    <div className={className}>
        <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
        <div className="row no-gutters align-items-top mt-3">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">Permissions</h6>
            </div>
            <div className="col">
                {permission.length===0 && 
                <div className="row no-gutters"><div className="col-12"><h6 className='text-primary d-inline'>Inherited from parents</h6></div></div>
                }
                <div className="row">
                {permission && permission.map( (item, index) => {
                    return (
                    <div key={item.userId || item._id || index} className="col-12">
                        <NodePermissionCard data={item} onChange={onCardChange} onRemove={() => setAskDelete(item.userId)} readOnly={readOnly}  />
                    </div>
                    )
                })}
                </div>
            </div>
            {!readOnly && <div className="col-auto ml-4">
                <DialogSearchPerson cache={false} notifySelect={item => confirmSelectPerson(item) } buttonText="Add" buttonClass="btn btn-sm btn-secondary" />
            </div>}
        </div>
        {permission.length>0 && 
        <div className="row no-gutters align-items-top mt-3">
        <div className="col-auto" style={{width: headerSpace}}>
            
        </div>
        <div className="col">
            <h6 className="text-muted">Edit Node : สามารถแก้ไขข้อมูล Node นี้ได้</h6>
            <h6 className="text-muted">Edit Childs : สามารถแก้ไขข้อมูลภายใต้ Node นี้ได้</h6>
        </div>
    </div>
        }
    </div>

     );
}
 
export default NodePermission;