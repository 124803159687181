import React, {useState, useEffect} from 'react';

import dlv from 'dlv'
import ProgressStage from './stage';
import ConfirmDelete from '../../../common/confirmDelete';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, getItemStyle, getListStyle } from '../../../common/dragdrop/dnd';

const ProgressStages = ({progress={}, setProgress=()=>{}, taskList=[]}) => {

    const [unstage, setUnstage] = useState(dlv(progress, 'unstage', {name: '', tasks: []}))

    const [stages, setStages] = useState(dlv(progress, 'stages', []))

    const enableUnstage = dlv(progress, 'enableUnstage', false)

    const [askRemoveState, setAskRemoveState] = useState(undefined)

    useEffect(() => {
        setProgress(prev => { return {...prev, stages, unstage }})
        
    }, [stages, unstage, setProgress]);

    const handleAddStage = () => {
        setStages(prev => [...prev, {no: prev.length+1 }])
    }

    const handleUpdateStage = (index, data) => {
        // console.log(index, data)

        setStages(prev=> [...prev.map((item,i) => {
            if(i===index) { return data;  }
            return item
        })])
    }

    const handleRemoveStage = () => {
        if(askRemoveState!==undefined) {
            setStages(prev => {
                const update = [...prev]
                update.splice(askRemoveState, 1)
                return update
            })
            setAskRemoveState(undefined)
        }
    }

    const onDragEnd = (result)=> {
        if (!result.destination) {  return;    }
    
        const newData = reorder(stages, result.source.index, result.destination.index)
        setStages(newData)
    }


    return ( 
    <React.Fragment>
        <ConfirmDelete isOpen={askRemoveState!==undefined} onConfirm={handleRemoveStage} onCancel={()=> setAskRemoveState(undefined)} />
        {enableUnstage && <ProgressStage isUnstage={true} stage={unstage} setStage={data => setUnstage(data) }
            taskList={taskList} percentage={enableUnstage ? Math.floor(100/Math.max(1, stages.length+ (enableUnstage ? 1 : 0))) : 0}
            />}
        {stages.length>0 && 
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
            >

            {stages.map( (stage, index, array) => {
                return (<Draggable key={index} draggableId={`id${index}`} index={index}>
                    {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                        )}  >

                        <ProgressStage key={index} no={index+1} stage={stage} setStage={data => handleUpdateStage(index, data) }
                            taskList={taskList} askRemoveStage={() => setAskRemoveState(index)} 
                            percentage={Math.floor(100/(array.length+ (enableUnstage ? 1 : 0)))}
                            />
                    </div>
                    )}
                </Draggable>)

            })}
                {provided.placeholder}
            </div>
            )}
            </Droppable>
        </DragDropContext>}
        {progress.enable && <React.Fragment>
            {dlv(progress, 'stages', []).length===0 && !dlv(progress, 'enableUnstage') && 
            <div className="alert alert-warning" role="alert">
                Stage is empty
            </div>
            }
            <div className="row align-items-center justify-content-end mt-3">
                <div className="col-auto">
                    <button type="button" className="btn btn-primary text-white" onClick={handleAddStage} >Add Stage</button>
                </div>
            </div>
        </React.Fragment>}
    </React.Fragment> );
}
 
export default ProgressStages;