import React, {useState, useEffect} from 'react';
import FontAwesome from 'react-fontawesome';
// import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

const featureList = [{name: 'OT', value: 'ot'}]

const NodeJawisCard = ({data={}, onChange, onRemove=()=>{}, readOnly=false}) => {

  const [features, setFeatures] = useState(data.feature || [])
  const [enable, setEnable] = useState(data.enable || false)

  useEffect(() => {
    if(onChange instanceof Function) {
      onChange({...data, features, enable})
    }

    // eslint-disable-next-line
  },[features, enable])

  const handleFeatureChange = async({feature, boo}) => {
    if(boo) {
      setFeatures(prev => [...prev, feature])
    } else {
      setFeatures(prev => prev.filter(x=> x!==feature))
    }
  }


  return ( 
    <React.Fragment>
      
      <div className="card mb-2 text-dark p-1">
        <div className="row no-gutters align-items-center justify-content-left">
          <div className="col-auto text-center p-1 ml-2 text-center">
            <h5 className="d-inline text-primary">{data.domain.name}</h5>
          </div>
          <div className="col-auto ml-3">
            <div className="row no-gutters align-items-center">
              {featureList.map(item => {
                const {name, value} = item

                return (
                  <div key={value} className="col-auto px-2">
                    <input type="checkbox" checked={features.includes(value)} disabled={readOnly}
                      onChange={e => handleFeatureChange({feature: value, boo: e.target.checked})} /><span> {name}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-auto ml-auto mr-4">
            <div className="row no-gutters align-items-center">
              {!readOnly &&
              <div className="col-auto pt-1 mr-3">
                  <Toggle defaultChecked={enable} onChange={value => setEnable(value.target.checked) } />
              </div>}
              {readOnly && !enable && <h6 className='d-inline mt-1 text-danger'>Disable</h6>}
              {!readOnly && <div className="col-auto">
                  <FontAwesome className="text-danger" name="times-circle-o" size="2x" onClick={onRemove} style={{cursor: 'pointer'}} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default NodeJawisCard;