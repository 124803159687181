import React from 'react';
import FontAwesome from 'react-fontawesome';
// import { NavLink } from 'react-router-dom';
// import dlv from 'dlv'
// import moment from 'moment'

const ReportAdminDomainCard = ({data={}, filter={}, style={workVisible: ['wait', 'active', 'finish', 'pause', 'delete']} }) => {

  const { name } = data;

  const { staffTotal, staffActive } = data
  const { jvTotal, workTotal, workWait, workActive, workPause, workFinish, workDelete, workOverdue } = data
  const { timeTotal } = data

  return ( 
    <React.Fragment>
      <div className="card mb-2 text-dark p-0">
        <div className="card-body p-2">
        {/* <NavLink className="stretched-link" to={`/report/domain/${domainId}/?filter=${filter.filter}&date=${filter.date}`} ></NavLink> */}
        <div className="row no-gutters align-items-center justify-content-end">
          <div className="col-auto ml-3 mr-auto" style={{maxWidth: 400}}>
            <h4>{name}</h4>
          </div>
          {(workTotal || workFinish) && <div className="col-auto">
            <div className="row no-gutters justify-content-center align-items-center">
                {staffTotal && <div className='col-auto mr-2 text-center'>
                <h4><span className="badge badge-pill badge-light">{staffTotal}</span></h4>
                  Staff Total
                </div>}
                {staffActive && <div className='col-auto mr-4 text-center'>
                <h4><span className="badge badge-pill badge-primary">{staffActive}</span></h4>
                Staff Active
                </div>}
                <div className='col-auto mr-3 text-center'>
                <h5><span className="text-primary">
                    {jvTotal.toLocaleString()} {jvTotal>0 && <span>&#3647;</span>}</span></h5>
                    Job Value
                </div>
                <div className='col-auto mr-3 text-center'>
                <h5>
                  <span className="text-primary"><FontAwesome name="clock-o" /> {timeTotal} min</span>
                </h5>
                Work Time
                </div>
                
                {workTotal && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-primary">{workTotal}</span></h4>
                Total Work
                </div>}

                {style.workVisible.includes('wait') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-info">{workWait}</span></h4>
                Plan
                </div>}
                {style.workVisible.includes('active') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-warning">{workActive}</span></h4>
                Active
                </div>}
                {style.workVisible.includes('finish') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-success">{workFinish}</span></h4>
                Finish
                </div>}
                {style.workVisible.includes('pause') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{workPause}</span></h4>
                Pause
                </div>}
                {style.workVisible.includes('overdue') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{workOverdue}</span></h4>
                Overdue
                </div>}
                {style.workVisible.includes('delete') && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{workDelete}</span></h4>
                Delete
                </div>}

            </div>
          </div>}
          {workTotal===0 && workFinish===0 && <h5 className="text-secondary mr-4">ไม่มีข้อมูล / No Data</h5>}
        </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default ReportAdminDomainCard;