import React from 'react';
import FlowViewTableItem from './tableItem';
import WorkCard from './workCard';

const FlowDetailTable = ({data, notifyUpdate, step=0, user}) => {

  return (
    <React.Fragment>
      <h5>ลำดับการปฏิบัติงาน</h5>
      {data && data.map( (item, index) => {

        const {work} = item

        console.log(work, user)

        if(work && (work.status==='wait' || work.status==='active') && work.person.find(x=>x.id===user.userId)) {

          return (<WorkCard key={index} data={work} index={index} step={step} user={user} notifyUpdate={notifyUpdate} />)
        } else {
          return (<FlowViewTableItem key={index} data={item} index={index} step={step} />)
        }
        
      })}
    </React.Fragment>
      
    );
}
 
export default FlowDetailTable;