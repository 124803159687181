import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import PersonPhoto from '../../../common/card/personPhoto';
// import dlv from 'dlv'
// import moment from 'moment'

const ReportTeamCard = ({data={}, filter={}, style={workVisible: ['wait', 'active', 'finish', 'pause', 'delete']}}) => {

  const { _id: teamId, name, personList=[] } = data;

  const { staffTotal, taskTotal, jvTotal, taskWait, taskActive, taskPause, workFinish, taskDelete } = data
  const { timeTotal, reportThis=[] } = data

  const workTime = getWorkHourFromTotalMinutes(timeTotal)
  const statusColor = (taskTotal>0 || workFinish>0) ? 'dark' : 'muted'

  const showPersonList = 10

  return ( 
    <React.Fragment>
      <div className="card mb-2 text-dark p-0">
        <div className="card-body p-3">
        <NavLink className="stretched-link" to={`/report/team/${teamId}/?filter=${filter.filter}&date=${filter.date}`} ></NavLink>
        <div className="row no-gutters align-items-center justify-content-end">
          <div className="col-auto mr-auto">
            <h5 className={`text-${statusColor}`}><FontAwesome name='users' /> {name}</h5>
            {personList.length>0 && <React.Fragment>
            <div className="col-auto p-0">
              <div className="row no-gutters align-items-center">
                 {personList.filter((_,index)=> index<showPersonList).map((item,index) => {
                    return (<div key={index} className='col-auto text-center'>
                    <PersonPhoto id={item} size={40} className='rounded-circle' />
                    </div>)
                 })}
                 {personList.length>showPersonList && <div className="col-auto">
                    <h4 className="d-inline"><span className="badge badge-pill badge-success">+{personList.length-showPersonList}</span></h4>
                    </div>}
              </div>
            </div>
          </React.Fragment>}
          </div>
          {(taskTotal>0 || workFinish>0) && <div className="col-auto">
            <div className="row no-gutters justify-content-center align-items-center">
                {reportThis.map(item => {
                    const {_id, label, sum, isDecimal} = item

                    const total = isDecimal ? sum.toFixed(2) : sum
                    const avg = (workFinish<=1 || sum===0) ? undefined : ( isDecimal ? (sum/workFinish).toFixed(2) : Math.round(sum/workFinish) )

                    return(<div key={_id} className='col-auto mr-3 text-center'>
                      <h5>
                        <span className="text-primary">{total}<span style={{fontSize: 12}}>total</span></span>
                        {avg && 
                          <React.Fragment>
                            <span className="mx-1">/</span>
                            <span className="text-primary">{avg}<span style={{fontSize: 12}}>avg</span></span>
                          </React.Fragment>}
                      </h5>
                      {label.substring(0, 15)}
                    </div>)
                })} 
                {jvTotal>0 && <div className='col-auto mr-3 text-center'>
                <h5><span className="text-primary">
                    {jvTotal.toLocaleString()}&#3647;</span></h5>
                    Job Value
                </div>}
                <div className='col-auto mr-3 text-center'>
                <h5>
                  <span className="text-primary"><FontAwesome name="clock-o" />{workTime}</span>
                </h5>
                Work Time
                </div>
                <div className='col-auto mx-3 text-center'>
                  <h4><span className="badge badge-pill badge-primary">{staffTotal}</span></h4>
                  Staff
                </div>

                {style.workVisible.includes('wait') && taskWait!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-info">{taskWait}</span></h4>
                Plan
                </div>}
                {style.workVisible.includes('active') && taskActive!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-warning">{taskActive}</span></h4>
                Active
                </div>}
                {style.workVisible.includes('finish') && workFinish!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-success">{workFinish}</span></h4>
                Finish
                </div>}
                {style.workVisible.includes('pause') && taskPause!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskPause}</span></h4>
                Pause
                </div>}
                {style.workVisible.includes('delete') && taskDelete!==undefined && <div className='col-auto mx-1 text-center'>
                <h4><span className="badge badge-pill badge-secondary">{taskDelete}</span></h4>
                Delete
                </div>}
            </div>
          </div>}
          {!taskTotal && !workFinish && <h5 className="text-secondary mr-4">ไม่มีข้อมูลปฏิบัติงาน / No Task</h5>}
        </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default ReportTeamCard;