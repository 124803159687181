import React from 'react';
import GraphDonutBasic from './../../../common/graph/basicDonut';
import ReportProjectList from './projectList';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';

const ProjectToday = ({report, filter}) => {

    const { projectTotal='-', projectActive='-', timeTotal=0, jvTotal=0, staffActive='-' } = report
    const { taskTotal='-', taskWait='-', taskActive='-', taskPause='-', taskFinish='-', taskDelete='-' } = report

    const workTime = getWorkHourFromTotalMinutes(timeTotal)

    const { groupByProject, projectList } = report

    const projectOrderList= [{name: 'Work Finish', value: 'finish'}, {name: 'Work Time', value: 'time'}]
    if(projectList && projectList.find(x=> x.jvTotal>0)) projectOrderList.push({name: 'Job Value', value: 'jv'})

    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-center mt-2">
            <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                <div className='col-auto mx-1 text-center'>
                    <h4 className="d-inline">Project</h4>
                </div>
                <div className='col-auto mx-1 text-center'>
                    <h3><span className="badge badge-pill badge-light">{projectTotal}</span></h3>
                    Total
                </div>
                <div className='col-auto mx-1 text-center'>
                    <h3><span className="badge badge-pill badge-primary">{projectActive}</span></h3>
                    Active
                </div>
                </div>
                </div>
            </div>
            </div>
            <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                <div className='col-auto mx-1 text-center'>
                    <h4 className="d-inline">Staff</h4>
                </div>
                <div className='col-auto mx-1 text-center'>
                    <h3><span className="badge badge-pill badge-primary">{staffActive}</span></h3>
                    Total
                </div>
                </div>
                </div>
            </div>
            </div>
            <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                <div className='col-auto mx-1 text-center'>
                    <h4 className="d-inline">Work Time</h4>
                </div>
                <div className='col-auto mx-1 text-center'>
                    <h3><span className="badge badge-pill badge-success">{workTime}</span></h3>
                    Finish
                </div>
                </div>
                </div>
            </div>
            </div>
            {jvTotal>0 && <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                <div className='col-auto mx-1 text-center'>
                    <h4 className="d-inline">Job Value</h4>
                </div>
                <div className='col-auto mx-1 text-center'>
                    <h3><span className="badge badge-pill badge-success">
                        {jvTotal>0 ? <span>{jvTotal.toLocaleString()} &#3647;</span> : <span>-</span>}</span>
                    </h3>
                    Total
                </div>
                </div>
                </div>
            </div>
            </div>}
            <div className='col-auto mx-1 mb-3'>
            <div className="card">
                <div className="card-body p-3">
                <div className="row justify-content-center align-items-center">
                    <div className='col-auto mx-2 text-center'>
                    <h4 className="d-inline">Work</h4>
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-primary">{taskTotal}</span></h3>
                    Total
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-info">{taskWait}</span></h3>
                    Plan
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-warning">{taskActive}</span></h3>
                    Active
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-secondary">{taskPause}</span></h3>
                    Pause
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-success">{taskFinish}</span></h3>
                    Finish
                    </div>
                    <div className='col-auto mx-2 text-center'>
                    <h3><span className="badge badge-pill badge-secondary">{taskDelete}</span></h3>
                    Cancel
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        {groupByProject && <GraphDonutBasic input={groupByProject} gHeight={projectActive>5 ? 400 : 300} header="Active/Finish Work by Project" />}
        {projectList && <ReportProjectList className="mt-4" data={projectList} filter={filter} orderList={projectOrderList} />}
        </React.Fragment>
     );
}
 
export default ProjectToday;