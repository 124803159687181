import React, {useState, useEffect} from 'react';

import dlv from 'dlv'
import NumericInput from 'react-numeric-input';

const TaskTemplateFormTypePhoto = ({options, setOptions, allowBrowse=false}) => {

    const [photoNum, setPhotoNum] = useState( dlv(options, 'photo.min', 1) )
    const [photoBrowse, setPhotoBrowse] = useState( dlv(options, 'photo.browse', false))

    const sendError = (error) => { setOptions({ error })  }

    useEffect(() => {
        if(setOptions instanceof Function) {
            if(!photoNum) return sendError('Invalid Number')

            const photo = { min: photoNum, browse: photoBrowse }

            setOptions({photo})
        }

        // eslint-disable-next-line
    }, [photoNum, photoBrowse])

    const headerSpace = 180

    return ( 
    <React.Fragment>
        <div className="row align-items-top mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                <h6 className="font-weight-bold">Number</h6>
            </div>
            <div className="col-auto">
                <NumericInput size="60px" min={1} max={10} value={photoNum} 
                onChange={(value,str)=> (str==='') ? setPhotoNum(1) : setPhotoNum(value)} />
            </div>
        </div>
        {allowBrowse &&
        <div className="row align-items-top mt-2">
            <div className="col-auto" style={{width: headerSpace}}>
                
            </div>
            <div className="col-auto">
                <input type="checkbox" name="photoBrowse" value="required" checked={photoBrowse} onChange={e => { setPhotoBrowse(e.target.checked)}} />
                <span className="ml-1">Allow Browse</span>
            </div>
        </div>
        }
    </React.Fragment> );
}
 
export default TaskTemplateFormTypePhoto;