import React, { useState} from 'react';
import { toast } from 'react-toastify';
import ConfirmDelete from '../../common/confirmDelete';

import NodeJawisCard from './nodeJawisCard';
import DialogSearchListIndex from '../../common/dialogSearchList/index';

import dlv from 'dlv'

const headerSpace = 180

const NodeJawis = ({className, domainList=[], jawis=[], setJawis, readOnly=false}) => {

    
    const [askDelete, setAskDelete] = useState('')

    const selectDomain = (item) => {
        if(jawis.some(x=> x.userId===item.userId)) {
            toast.info("Item is exist")
        } else {
            if(setJawis instanceof Function) {
                setJawis(prev => [...prev, {enable: true, domain: {id: item._id, name: item.name } }] )
            }
        }
    }

    const onCardChange = ({domain , enable, features}) => {
        if(setJawis instanceof Function) {
            setJawis(prev => {
                return prev.map(item => {
                    if(item.domain.id===domain.id) {
                        return {...item, enable, features}
                    } else {
                        return item
                    }
                })
            })
        }
    }

    const confirmDelete = async() => {
        if(setJawis instanceof Function) {
            setJawis(prev => [...prev.filter(x=> x.domain.id!==askDelete)])
        }
        setAskDelete(undefined)
    }

    return ( 
    <div className={className}>
        <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
        <div className="row no-gutters align-items-top mt-3">
            <div className="col-auto text-muted" style={{width: headerSpace}}>
                <h6 className="d-inline font-weight-bold">JAWIS Domain</h6>
            </div>
            <div className="col">
                {jawis.length===0 && 
                <div className="row no-gutters"><div className="col-12"><h6 className='text-muted d-inline'>-</h6></div></div>
                }
                <div className="row">
                {jawis && jawis.map( (item, index) => {
                    return (
                    <div key={item.domain.id || index} className="col-12">
                        <NodeJawisCard data={item} onChange={onCardChange} onRemove={() => setAskDelete(item.domain.id)} readOnly={readOnly}  />
                    </div>
                    )
                })}
                </div>
            </div>
            {!readOnly && <div className="col-auto ml-4">
                <DialogSearchListIndex header="Add" buttonAdd="Add"
                    list={domainList.filter(domain=> !jawis.some(x=> dlv(x, 'domain.id')===domain._id))} 
                    notifySelect={item => selectDomain(item)}
                    buttonStyle="btn btn-sm btn-secondary"
                    />
                
            </div>}
        </div>
    </div>

     );
}
 
export default NodeJawis;