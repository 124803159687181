import React from 'react';

import TaskDataInfo from './taskDataInfo';
import TaskForm from '../../../dynamic/taskForms';

const TaskData = ({data}) => {

    return ( 
    <React.Fragment>
        {data && <TaskDataInfo data={data} /> }
        {data.forms && <TaskForm forms={data.forms} status='new' />}
    </React.Fragment> );
}
 
export default TaskData;