import React, {useState} from 'react';

import dlv from 'dlv'
import moment from 'moment'
import FontAwesome from "react-fontawesome";
import { NavLink } from 'react-router-dom';
import { Collapse } from "react-bootstrap";
import PersonPhoto from './../../../common/card/personPhoto';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';
import { formatDateTimeFriendly } from './../../../../utils/date';

const ReportProgressStage = ({no='', stage={}, percentage, isCurrentStage, isUnstage }) => {

    const [show, setShow] = useState(true)
    const [error, ] = useState(undefined)

    const tasks = dlv(stage, 'tasks', [])
    const tasksWorks = tasks.filter(x=>x.workId).length

    const taskCompleted = tasks.filter(task => task.status==='finish').length
    const percentageColor = taskCompleted=== tasks.length ? 'success' : (taskCompleted>0) ? 'warning' : 'muted'

    const isStageCompleted = taskCompleted===tasks.length

    const totalTime = tasksWorks>0 ? getWorkHourFromTotalMinutes(tasks.map(task=> task.time || 0).reduce((acc,x)=> acc+x, 0)) : undefined
    const maxDate=new Date(Math.max.apply(null,tasks.map(task=> new Date(task.finish || moment()))));
    const minDate=new Date(Math.min.apply(null,tasks.map(task=> new Date(task.start))));

    const totalPeriod = tasksWorks>0 ? ((moment(minDate).isSame(moment(maxDate), 'day'))  ? 
                        formatDateTimeFriendly(minDate) + ' - ' + moment(maxDate).format("HH:mm")
                        : formatDateTimeFriendly(minDate) + ' - ' + formatDateTimeFriendly(maxDate)) : undefined

    return ( 
    <React.Fragment>
        <div className="card mb-3">
            <div className="card-header">
                <div className="row no-gutters justify-content-end">
                    <div className="col-auto mr-3"><h5 className="d-inline">{isUnstage ? 'Unstage' : `Stage ${no}`}</h5></div>
                    <div className="col-auto mr-auto"><h5 className="d-inline text-primary">{dlv(stage, 'name', '')}</h5></div>
                    {totalTime && <div className="col-auto p-0 mr-2"><h5 className={`badge badge-primary d-inline`}>{totalTime}</h5></div>}
                    {totalPeriod && <div className="col-auto p-0 mr-2"><h5 className={`badge badge-pill badge-secondary d-inline`}>{totalPeriod}</h5></div>}
                    {percentage && 
                    <div className="col-auto p-0 mr-2 text-primary font-weight-bold">
                        <span className={`text-${percentageColor}`}>{percentage.toFixed(2)}%</span>
                    </div>}
                    {isStageCompleted && <div className="col-auto p-0 mr-2"><h5 className={`badge badge-success d-inline`}>Stage Completed</h5></div>}
                    {isCurrentStage && !isStageCompleted && <div className="col-auto p-0 mr-2"><h5 className={`badge badge-warning d-inline`}>Stage Active</h5></div>}
                    {error && <div className="col-auto p-0 mr-3" data-toggle="tooltip" data-placement="bottom" title={error}>
                        <FontAwesome className='text-danger' style={{cursor: 'pointer'}} name='exclamation-circle' size="lg" onClick={()=>setShow(true)} />
                    </div>}
                    <div className="col-auto p-0 mr-2">
                        <FontAwesome style={{cursor: 'pointer'}} name={show ? 'compress' : 'expand'} size="lg" onClick={() => setShow(prev => !prev)} />
                    </div>
                </div>
                
            </div>
            <Collapse in={show}>
                <div className="card-body">
                    {tasks.map( (item, index, array) => {
                        const { _id, status, workId, name, userId, time, start, finish } = item

                        const workTime = getWorkHourFromTotalMinutes(time)

                        const period = status!=='wait' ? ((moment(start).isSame(moment(finish), 'day'))  ? 
                        formatDateTimeFriendly(start) + ' - ' + moment(finish).format("HH:mm")
                        : formatDateTimeFriendly(start) + ' - ' + formatDateTimeFriendly(finish)) : ''

                        const statusColor = status==='finish' ? 'success' : status==='active' ? 'warning' : 'secondary'

                        return (<div key={_id} className="row">
                            <div className="col">
                                <div className="card mb-2">
                                    <div className="card-body p-2">
                                    {workId && <NavLink className="stretched-link" target='_blank' to={`/report/work/${workId}`} ></NavLink>}
                                    <div className="row no-gutters justify-content-start align-items-center">
                                        <div className="col-auto">
                                            <span className="mx-2"><FontAwesome name="file-code-o" size="lg" /></span>
                                            <span className="font-weight-bold">{name || 'Name is not found'}</span>
                                        </div>
                                        <div className="col-auto ml-auto mr-2">
                                            <div className="row no-gutters align-items-center">
                                                {userId && <div className="col-auto mr-3">
                                                    <PersonPhoto id={userId} size={40} className='rounded-circle' />
                                                </div>}
                                                {workTime && <div className="col-auto mr-3">
                                                    <span className='text-primary font-weight-bold'>{workTime}</span>
                                                </div>}
                                                {period && <div className="col-auto mr-3">
                                                    <span className='badge badge-pill badge-secondary'>{period}</span>
                                                </div>}
                                                <div className="col-auto mr-2 font-weight-bold">
                                                    <span className={status==='finish' ? 'text-success' : 'text-muted'}>{Math.round(percentage/array.length)}%</span>
                                                </div>
                                                <div className="col-auto">
                                                    <h5 className={`badge badge-${statusColor} d-inline`}>{status}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>)
                    })}
                    {tasks.length===0 && 
                    <div className="alert alert-secondary mt-4" role="alert">Task is empty</div>
                    }
                    {error && 
                    <div className="alert alert-danger mt-4" role="alert">{error}</div>
                    }
                </div>
            </Collapse>
        </div>
        
    </React.Fragment> );
}
 
export default ReportProgressStage;