import http from "../httpService";

const apiEndpoint = "/flow";

export function flowList() {
  return http.get(apiEndpoint)
}

export function getFlow(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveFlow(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deleteFlow(id) {
  return http.delete(apiEndpoint + "/" + id)
}