import React, {useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

import ConfirmDelete from '../../common/confirmDelete';
// import dlv from 'dlv'

import { DomainContext } from '../../../context/domainContext';
import { projectList, deleteProject } from '../../../services/manage/project/index';

import { getProjectGroupList, deleteProjectGroup } from '../../../services/manage/project/group';
import ProjectListTable from './projectList';
import ProjectGroupCard from './group/projectGroupCard';
import SearchBox from '../../common/searchBox';

const ProjectList = () => {
    const {domain } = useContext(DomainContext)
    const [search, setSearch] = useState('')

    const getProjectGroups = async() => {
        try {
            const {data: result} = await getProjectGroupList();

            if(result.status==='success') {

                setGroups(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    const getList = async() => {
        try {
            const {data: result} = await projectList();

            if(result.status==='success') {

                setList(result.data.list)
    
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        if(domain) {
            getProjectGroups()
            getList()
        } 
        
    }, [domain])

    const [list, setList] = useState([])
    const [groups, setGroups] = useState([])

    const [askDelete, setAskDelete] = useState()
    const [askDeleteGroup, setAskDeleteGroup] = useState(false)

    const [askGroup, setAskGroup] = useState()

    const projectGroupFilter = () => {
        return groups.filter(x=> {
            // console.log('x', x.projects.map(project=> project._id))

            if (x.name.toLowerCase().includes(search.toLowerCase().trim())) return true
            if (list.filter(item=> 
                x.projects.map(project=> project._id).includes(item._id) 
                && item.name.toLowerCase().includes(search.toLowerCase().trim())).length>0) return true

            return false

        })
        .sort((a,b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
    }

    const projectListFilter = () => {

        return list
        .filter(item => !groups.map(x=> x.projects.map(project=> project._id)).reduce((a,b)=> a.concat(b), []).includes(item._id))
        .filter(x=> x.name.toLowerCase().includes(search.toLowerCase().trim())).sort((a,b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
    }



    const confirmDelete = async() => {
        try {
            if(askDeleteGroup) {
                await deleteProjectGroup(askDelete)
                setAskDelete(undefined)
                setAskDeleteGroup(undefined)
                getProjectGroups()
                getList()
            } else {
                await deleteProject(askDelete);

                setAskDelete(undefined)
                getList()
            }
            
        } catch(err) { toast.error('Failed to delete item')}
    }

    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row no-gutters justify-content-end align-items-center mb-4">
                <div className="col mr-auto"><h4>Project</h4></div>
                <div className="col-auto mr-4">
                    <SearchBox value={search} onChange={text => setSearch(text)} />
                </div>
                <div className="col-auto mr-3">
                    <NavLink className="nav-item nav-link p-0" to={`/project/new`} >
                    <button type="button" className="btn btn-success" >New Project</button>
                    </NavLink>
                </div>
                <div className="col-auto">
                    <NavLink className="nav-item nav-link p-0" to={`/project/group/new`} >
                    <button type="button" className="btn btn-secondary" >Create Project Group</button>
                    </NavLink>
                </div>
            </div>
            {groups.lenght===0 && list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            {groups.length>0 && <div className="row mb-4">
            {projectGroupFilter().map( (item, index) => {
                const {_id, projects } = item;

                return (
                <div key={_id || index} className="col-12">
                    <ProjectGroupCard data={item} askGroup={askGroup} setAskGroup={setAskGroup} onRemove={() => { setAskDelete(_id); setAskDeleteGroup(true); }} />
                    {askGroup===_id && 
                        <ProjectListTable className="ml-4" list={
                            list.filter(x=> projects.map(item=> item._id).includes(x._id))
                            .filter(x=> x.name.toLowerCase().includes(search.toLowerCase().trim())).sort((a,b) => {
                                if(a.name < b.name) { return -1; }
                                if(a.name > b.name) { return 1; }
                                return 0;
                            })

                        } setAskDelete={setAskDelete}  />
                    }
                </div>
                )
            })}
            </div>}
            <ProjectListTable
                list={projectListFilter()} 
                setAskDelete={setAskDelete}  />
        </React.Fragment>
     );
}
 
export default ProjectList;