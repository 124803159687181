import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { finishWork, getWork, updateWork, deleteWork, pauseWork, resumeWork } from '../../../../services/work/workService';
import ReportWorkInfo from '../../../report/work/reportWorkInfo';

import { Grid, TextField } from "@material-ui/core";

import { useForm } from "react-hook-form";

import dlv from 'dlv'
import ConfirmDelete from '../../../common/confirmDelete';

const TaskWorking = ({history, match}) => {

    const [id,] = useState(match.params.id)
    const [work, setWork] = useState(undefined)
    const [, setFormData] = useState({})
    const [askDelete, setAskDelete] = useState(false)


    const { register, handleSubmit, errors, watch } = useForm();

    //Load Work
    useEffect(()=> { 

        const loadWork = async(id) => {

            try {
                const {data: result} = await getWork(id);
    
                if(result.status==='success') {
    
                    setWork(result.data.work)
    
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
    
        }
        
        
        loadWork(id)


    }, [id])

    const updateFormTemplate = (work) => {
        if(!work) return

        const { template } = work;
        const newFormData = {}

        if(template && template.forms ) {
            template.forms.forEach(form => {
                newFormData[form._id] = ''
            })

            
        }

        setFormData(newFormData)

    }
    useEffect(() => updateFormTemplate(work), [work])

    //Save Work
    const askSaveWork = async() => {
        try {
            const updateForms = watch();
            if(!updateForms.some(x=> x.value))

            await updateWork(id, { updateForms });
            // console.log('output', output)

            toast.info("Save Work Completed", { autoClose: 1100})
        } catch(err) {
            toast.error(err.message)
        }
    }

    //Pause Work
    const askPauseWork = async() => {
        try {

            const {data: output} = await pauseWork(id );
            // console.log('output', output)

            if(output.data.work) setWork(output.data.work)

            toast.info("Pause Work Completed", { autoClose: 1500})
        } catch(err) {
            toast.error(err.message)
        }
    }

    //Resume Work
    const askResumeWork = async() => {
        try {

            const {data: output} = await resumeWork(id);
            // console.log('output', output)

            if(output.data.work) setWork(output.data.work)

            toast.info("Resume Work Completed", { autoClose: 1500})
        } catch(err) {
            toast.error(err.message)
        }
    }

    //Finish Work
    const askFinishWork = async() => {
        handleSubmit(async(data,e) => { 
            try {
    
                const {data: output} = await finishWork(id, { updateForms: data });
                // console.log('output', output)
    
                toast.success("Finish Work Completed", { autoClose: 1500})

                if(output.data.work) setWork(output.data.work)

            } catch(err) { }
        }
    
        , (error) => {
            toast.error('Task template is not completed')
        })()
    }

    //Delete Work
    const confirmDeleteWork = async() => {
        try {


            const {data: output} = await deleteWork(id);
            console.log('output', output)

            toast.info("Delete Work Completed", { autoClose: 1100})

            if(output.data.work) setWork(output.data.work) 
            else setWork(prev => { return {...prev, status: 'delete'}})

            setAskDelete(false)

        } catch(err) {
            toast.error(err.message)
        }
    }

    const { status } = work || {}
    const isDisableEdit = ['pause'].includes(status)

    if(!work) return (<div className="alert alert-warning" role="alert">Loading work ...</div>);

    return ( 
    <React.Fragment>
        <ConfirmDelete isOpen={askDelete} onConfirm={confirmDeleteWork} onCancel={()=> setAskDelete(false)} body="Do you want to delete this work?" />
        <ReportWorkInfo data={work} />
        {work.template && 
        <form className="mt-4">
            <Grid container spacing={0}>
                <Grid item xs={6}>
                {dlv(work, 'template.forms', []).map(form => {
                    const { _id, name, formType, typeText, value } = form

                    const typeName = typeText || dlv(formType, 'name', 'Type not found')

                    return (
                    <Grid key={_id} container spacing={1}>
                        {status==='new' && 
                        <Grid item xs={12}>
                            <h5 className="d-inline">{name}</h5>
                            <h5 className="d-inline text-info ml-2">{typeName}</h5>
                        </Grid>
                        }
                        {['active'].includes(status) && <Grid item xs={12}>
                        <h5>{name}</h5>
                        <TextField name={_id} label={typeName} variant="outlined" fullWidth disabled={isDisableEdit} inputRef={register({required: true})} />
                        {errors[_id] && <span className="text-danger">This field is required</span>}
                        </Grid>}
                        {['pause', 'finish', 'delete'].includes(status) && 
                        <Grid item xs={12}>
                            <h5 className="d-inline">{name}</h5>
                            <h5 className="d-inline text-info ml-2">{value || 'No data'}</h5>
                        </Grid>
                        }
                    </Grid>
                    )
                })}
                
                </Grid>
            </Grid>
        </form>}
        <div className="row mt-4">
            <div className="col text-left">
                {status==='active' && <button className="btn btn-warning mr-2" onClick={askSaveWork}>SAVE</button>}
                {['active', 'pause'].includes(status) && <button className="btn btn-success mr-2" onClick={askFinishWork}>FINISH</button>}
                {status==='wait' && <button className="btn btn-primary mr-2">START</button>}
                {status==='pause' && <button className="btn btn-primary mr-2" onClick={askResumeWork}>RESUME</button>}
                {status==='active' && <button className="btn btn-secondary mr-2" onClick={askPauseWork}>PAUSE</button>}
                {['wait', 'active', 'pause', 'finish'].includes(status) && <button className="btn btn-danger mr-2" onClick={()=> setAskDelete(true)}>DELETE</button>}
            </div>
        </div>
    </React.Fragment> );
}
 
export default TaskWorking;