import React from "react";

const ButtonGroup = ({
  items,
  textProperty,
  valueProperty,
  onItemSelect=()=>{},
  selectedItem,
  selectedValue,
  selectedItemClass='btn btn-primary',
  defaultItemClass='btn btn-outline-dark',
  className='',
  disabled=false
}) => {
  
  if(!items.find(x=> x._id) && items.find(x=> x.value)) {
    valueProperty= 'value'
  }

  return (
    <ul className={`btn-group p-0 m-0 ` + className}>
      {items.map(item => {
        return (<button
          key={item[valueProperty]}
          type="button"
          onClick={() => onItemSelect(item)}
          disabled={disabled}
          className={
            ((selectedValue && selectedValue===item[valueProperty]) || (selectedItem && item[valueProperty] === selectedItem[valueProperty])) ? selectedItemClass : defaultItemClass
          }
        >
          {item[textProperty]}
        </button>)
      })}
    </ul>
  );
};

ButtonGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default ButtonGroup;
