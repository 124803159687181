import React, {useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle'

import { NavLink } from 'react-router-dom';
import { saveData } from './../../../services/manage/data/index';

const DataCard = ({ data={}, onRemove=()=>{}, setEditContent=()=>{} }) => {

    const {_id, name } = data;

    const [enable, setEnable] = useState(data.enable || false)

    const toggleEnable = async(value) => {
        try {
        await saveData({_id, enable: value })
        setEnable(value)

        } catch(err) {
            toast.error('Failed to update person group')
        }
    }

  return ( 
    <React.Fragment>
        
        <div className="card mb-2">
            <div className="card-body p-0">
                <div className="row align-items-center justify-content-between">
                <div className="col-auto ml-3" style={{cursor: 'pointer'}}>
                    <FontAwesome name='list-ul' size="lg" />
                    <h6 className="d-inline font-weight-bold ml-3" 
                        onClick={() => setEditContent(prev => (prev===_id) ? -1 : _id) } >{name}</h6>
                </div>
                <div className="col-auto pr-4">
                    <div className="row  align-items-center">
                        <div className="col-auto p-1">
                            <Toggle checked={enable} onChange={value => toggleEnable(value.target.checked)} />
                        </div>
                        <div className="col-auto p-1">
                        <NavLink className="nav-item nav-link text-dark" to={`/manage/data/${_id}`} ><FontAwesome name="pencil" size="lg" /></NavLink>
                        </div>
                        <div className="col-auto p-1 mr-2">
                            <FontAwesome name="trash" size="lg" onClick={onRemove} />
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
    
}
 
export default DataCard;