import React, {useState, useContext, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import ConfirmLogout from './../common/confirmLogout';
import Clock from 'react-live-clock';
import { DomainContext } from './../../context/domainContext';
import DialogSelectDomain from '../common/dialogSearchList/dialog'
import { domainList } from '../../services/domain/domainService';
import { toast } from 'react-toastify';
import auth from '../../services/app/authService'
import socketIOClient from './../../services/app/socketioService'
import SearchBox from "../common/searchBox";
import Img from 'react-image'
import { PermissionContext } from "../../context/permissionContext";
import PersonPhoto from './../common/card/personPhoto';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const NavBar = ({onSidebarToggle, history, user}) => {

  const userId = (user) ? user.userId : undefined

  const {domain, setDomain} = useContext(DomainContext)
  const {permission } = useContext(PermissionContext)
  
  const [isModalLogoutOpen, setModalLogoutOpen] = useState(false)
  const [dialogSelectDomain, setDialogSelectDomain] = useState(false)

  const [search, setSearch] = useState('')
  
  const [domains, setDomains] = useState([])

  const isAdmin = permission && Array.isArray(permission) && permission.includes('admin')
  
  useEffect(() => {
    const getDomainList = async() => {
      if(!userId) return

      try {
          const {data: result} = await domainList({enable: true});
  
          if(result.status==='success') {
            const { list } = result.data;

            setDomains(list)

            // if(list.length===0) {
            //   if(!isAdmin) {
            //     if(history && typeof(history.replace)==='function') {
            //       console.log('force logout')
            //       // history.replace("/logout");
            //     }
            //   }
              
            // } else {
              
            // }
          } else {
              toast.error(result.message)
          }
      } catch(err) {
          toast.error(err.message)
      }
    }

    getDomainList()

    socketIOClient.on('domain-list-update', () => { getDomainList() })
    return () => { socketIOClient.off('domain-list-update')   }
    
  }, [userId, history])

  useEffect(() => {

    if(domains.length===1) {
      setDomain(domains[0])
      auth.setDomain(domains[0])
    } else if( domain && domains.length>0 ) {
      const findCurrent = domains.find(x=> x._id.toString()===domain._id.toString())

      if( findCurrent) {
        if(JSON.stringify(findCurrent)!==JSON.stringify(domain)) {
          setDomain(findCurrent)
          auth.setDomain(findCurrent)
        }
      } else {
        setDomain(undefined)
        auth.setDomain(undefined)
      }
    }

  }, [domains, domain, setDomain])

  const askSelectDomain = () => {
    if(domains && Array.isArray(domains) && domains.length>0) {
      // if(domains.length===1 && domains[0].domain===domain) return

      setDialogSelectDomain(true)
    }
  }

  const notifySelectDomain = (item) => {
    setDialogSelectDomain(false)

    auth.setDomain(item)
    setDomain(item)
  }

  const toggleNavbar =() => {
    if( typeof onSidebarToggle ==='function') {
      onSidebarToggle();
    }
  }

  const doLogout = () => { setModalLogoutOpen(true)  }
  const onLogoutCancel = () => { setModalLogoutOpen(false)  }

  const onLogoutConfirm = async () => {
    setModalLogoutOpen(false)

    if(history && typeof(history.replace)==='function') {
      history.replace("/logout");
    }
  }

  const searchBoxStyle = {
    borderRadius: 10,
    border: '1px solid #99999999',
    padding: 10,
    width: 178,
    height: 25,
  }

  return ( 
    <React.Fragment>
      <ConfirmLogout isOpen={isModalLogoutOpen} onConfirm={onLogoutConfirm} onCancel={onLogoutCancel} />
      <DialogSelectDomain header="Domain" data={domains} isOpen={dialogSelectDomain} 
        onCancel={() => setDialogSelectDomain(false)} 
        onConfirm={notifySelectDomain} 
        icon="globe"
      />
      <nav className="navbar navbar-dark fixed-top flex-md-nowrap p-0 shadow">
        <button className="navbar-toggler d-inline d-lg-none" type="button" onClick={toggleNavbar} ><span className="navbar-toggler-icon" /></button>
        <Link className="navbar-brand ml-2" to="/">{'JAWIS'}</Link>
        {user && <button type="button" className="btn btn-dark btn-sm py-0 px-2 mr-2 text-warning" onClick={askSelectDomain}><strong>{(domain) ? domain.name : 'Select Domain'}</strong></button>}
        {domain && domain.manager && <div className='d-none d-md-inline'>{domain.manager.map(x=> <PersonPhoto key={x} id={x} size={30} />) }</div>}
        {user && <div className="d-none d-lg-inline text-muted">
          <strong className="d-none d-xl-inline ml-2"><Clock format="ddd" ticking={true} interval={60000} /></strong>
          <strong className="d-none d-lg-inline ml-2">
            <Clock format="D MMMM YYYY" ticking={true} interval={60000} />
          </strong>
          <strong className="d-none d-lg-inline ml-2"><Clock format="HH:mm:ss" ticking={true} interval={1000} /></strong>
        </div>}
        {/* <div className="ml-auto p-2 text-warning">ปิดปรับปรุง JAWIS ทั้งระบบ วันอาทิตย์ที่ 18 ตุลาคมนี้ เวลา 12:00-14:00</div> */}
        {domain && isAdmin && 
          <div className="d-none d-lg-inline ml-auto p-2">
            <CopyToClipboard text={domain._id}>
              <button type="button" className="btn btn-sm btn-secondary">
                Domain Id : {domain._id}
              </button>
            </CopyToClipboard>
          </div>
        }
        {user &&  <div className="d-none d-sm-inline ml-auto p-2">
            {false && <SearchBox value={search} onChange={value => setSearch(value)} className='navbar' style={searchBoxStyle} />}
        </div>}
        {user && <span className="d-none d-md-inline text-white pr-2">
          <Img className="rounded-circle ml-2" style={{width: "100%", maxHeight: 30, maxWidth: 30}} 
              src={[
                `https://api-idms.advanceagro.net/hrms/employee/${user.userId}/photocard/?size=80`,
                'https://storage.googleapis.com/jawis-core-photo/person'
              ]}
              loader={<img className="rounded-circle ml-2" style={{width: "100%", maxHeight: 30, maxWidth: 30}} 
              src={'https://storage.googleapis.com/jawis-core-photo/person'} alt="..."/>}
            /> {user.name}
          </span>}
        {user && <span className="d-none d-lg-inline text-secondary pr-3 pb-1" style={{cursor: 'pointer'}} onClick={doLogout}><strong>ออกจากระบบ</strong></span>}
        {!user && <NavLink className="nav-item nav-link text-white" to="/login">Login</NavLink>}
      </nav>
    </React.Fragment>
   );
}

export default withRouter(NavBar);
