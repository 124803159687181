import React, {useState} from "react";
import TemplateJobManageTable from "./flowDetailTable";

const FlowDetail = ({data, user}) => {

  const [name, ] = useState(data.name)
  const [description, ] = useState(data.description)
  const [flows, setFlows] = useState(data.flows)

  const notifyFormItemChange = (data) => { setFlows(data) }

  return (
    <React.Fragment>
        <div className="row align-items-center">
          <div className="col-auto">
              <h5 className="font-weight-bold">Flow Name</h5>
          </div>
          <div className="col-auto">
            <h5 className="font-weight-bold text-primary">{name}</h5>
          </div>
        </div>
        {description && <div className="row align-items-center mt-2">
          <div className="col-auto">
              <h5>Description</h5>
          </div>
          <div className="col-auto">
            <h6 className="font-weight-bold">{description}</h6>
          </div>
        </div>}
        {flows.length>0 && 
        <div className="mt-4"><TemplateJobManageTable data={flows} notifyDataChange={notifyFormItemChange} user={user} /></div>
        }
        {flows.length===0 && 
        <div className="alert alert-warning" role="alert">
          Flow is empty
        </div>
        }
    </React.Fragment>
  );
  
}

export default FlowDetail;
