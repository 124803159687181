import React, { useState } from 'react';

import dlv from 'dlv'

import ButtonGroup from '../../common/buttonGroup';
import TemplateFlowEditItemApprove from './options/editItemApprove';
import TemplateFlowEditItemTask from './options/editItemTask';

const stepTypes = [{value: 'task', name: "Task"}, {value: 'approve', name: "Approve"}]

const TemplateFlowEditItem = ({ taskList=[], projectList=[], data={}, notifySave, notifyCancel=()=>{}}) => {

    const [type, setType] = useState(dlv(data, 'type', 'task'))
    const [work, setWork] = useState(dlv(data, 'work'))
    const [approve, setApprove] = useState(dlv(data, 'approve', {}))

    console.log('work', work)

    const [error, setError] = useState('')

    const onSave = () => {
        if(type.value==='task' && !work) return setError('Task is required')
        if(type.value==='approve' && !approve) return setError('Approval is required')
        // if(dlv(permission.find(x=>x.role.value==='create'), 'mode.value')==='custom' 
        // && dlv(permission.find(x=>x.role.value==='create'), 'person', []).length===0) {
        //   return setError('Person must not empty')
        // } 
            
        const doc = { type, work, approve }

        if (notifySave instanceof Function) notifySave(doc)

    }

    const headerSpace = 150

    return ( <React.Fragment>
        <div className="card my-3">
            <div className="card-body">
                <div className="row mb-3">
                    <div className="col-auto" style={{width: headerSpace}}>
                        <h5 className="font-weight-bold">Type</h5>
                    </div>
                    <div className="col-auto">
                        <ButtonGroup items={stepTypes} 
                            selectedItemClass="btn btn-dark"
                            selectedItem={stepTypes.find(x=> x.value===type) || stepTypes[0]} 
                            onItemSelect={item => setType(item.value)} valueProperty='value' />
                    </div>
                </div>
                {type==='task' && <TemplateFlowEditItemTask work={work} setWork={setWork} projectList={projectList} taskList={taskList} />}
                {type==='approve' && <TemplateFlowEditItemApprove approve={approve} setApprove={setApprove} />}
                
            </div>
            <div className="card-footer bg-secondary text-white p-2">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        {error && <span className="text-warning font-weight-bold ml-3">{error}</span>}
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-light text-dark font-weight-bold mr-3" onClick={notifyCancel}>Cancel</button>
                        <button type="button" className="btn btn-light text-dark font-weight-bold" onClick={onSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default TemplateFlowEditItem;