import React from 'react';
import Img from 'react-image'

const PersonPhoto = ({id, size=80, tooltip='', className='rounded-circle mr-1', border='none'}) => {
    return ( 
        <Img className={className} style={{width: "100%", maxHeight: size, maxWidth: size, border: border}} 
            data-toggle="tooltip" data-placement="bottom" title={tooltip}
            src={[
            `https://api-idms.advanceagro.net/hrms/employee/${id}/photocard/?size=${size}`,
            'https://storage.googleapis.com/jawis-core-photo/person'
            ]}
            loader={<img className={className} style={{width: "100%", maxHeight: size, maxWidth: size}} 
            src={'https://storage.googleapis.com/jawis-core-photo/person'} alt="..."/>}
        />
     );
}
 
export default PersonPhoto;