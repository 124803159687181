import React from 'react';

import moment from 'moment'
import PlanWeeklyCard from './planWeeklyCard';
import _ from 'lodash'
import dlv from 'dlv'

const PlanWeekly = ({date, data}) => {

    const list = [
        {day: moment(date) },
        {day: moment(date).add(1, 'day') },
        {day: moment(date).add(2, 'day') },
        {day: moment(date).add(3, 'day') },
        {day: moment(date).add(4, 'day') },
        {day: moment(date).add(5, 'day') },
        {day: moment(date).add(6, 'day') }
    ]

    return ( 
    <React.Fragment>
        <h5 className='mb-4 text-primary'>{moment(date).format('D MMMM YYYY')} - {moment(date).add(6, 'day').format('D MMMM YYYY')}</h5>
        {list.map(item => {

            const { day } = item
            const isFuture = moment().isBefore(day, 'day')

            const dataFilter = data.filter(x=> moment(x.plan.begin).isSame(day, 'day'))

            const groupTasks = _.groupBy(dataFilter, x=> dlv(x, 'template._id'))
            const taskList = Object.keys(groupTasks).map(key => {
                const item = groupTasks[key]
                const firstItem = item[0]
                const { template, name } = firstItem
                const workWait = item.length
                const workActive = item.filter(x=> x.status==='active').length
                const workFinish = item.filter(x=> x.status==='finish').length
                const workOverdue = item.filter(x=> x.status==='overdue').length
                const workPause = item.filter(x=> x.status==='pause').length
                const workDelete = item.filter(x=> x.status==='delete').length

                return { _id: dlv(template, '_id',''), name, workWait, workActive, workFinish, workOverdue, workPause, workDelete}

            })

            return (
                <React.Fragment key={day}>
                    <div className="card mb-2">
                    <div className="card-header p-2">
                        <span className="ml-2 font-weight-bold">{day.format('dddd, DD MMMM YYYY')}</span>
                    </div>
                    </div>
                    {taskList.length===0 && <div className="ml-3 alert alert-secondary" role="alert">No Plan Record</div>}
                    {taskList.map(item => {
                        return <PlanWeeklyCard key={item._id} data={item} isFuture={isFuture} className="ml-3" />
                    })}
                    {taskList.length>0 && <div style={{height: 10}}></div>}

                </React.Fragment>
                
            )
        })}


    </React.Fragment> );
}
 
export default PlanWeekly;