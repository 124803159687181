import http from "../httpService";

const apiEndpoint = "/report/task";

export function getReportTaskSummary(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''
  
  return http.get(apiEndpoint + '/summary' + ((query) ? '/?' + query : ''))
}

//search works
export function getReportTasks({date=''}) {
  return http.get(apiEndpoint + '/?date=' + date)
}

export function getReportTaskItem(id, input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + '/' + id + ((query) ? '/?' + query : ''))
}


