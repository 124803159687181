import React, {useState, useEffect} from 'react';

import { toast } from 'react-toastify';
import ReportWorkTemplate from '../../report/work/reportWorkTemplate';
import ReportWorkMessage from './../../report/work/reportWorkMessage';
import ReportWorkPhoto from './../../report/work/reportWorkPhoto';
import ReportWorkInfo from './../../report/work/reportWorkInfo';

import { captureTaskLoad } from '../../../services/capture/captureService';
import queryString from 'query-string';
import FontAwesome from 'react-fontawesome';
import ReportWorkZoom from '../../report/work/reportWorkZoom';
import ReportWorkMap from './../../report/work/reportWorkMap';
import dlv from 'dlv'

const CaptureTask = ({match, location}) => {

const [id,] = useState(match.params.id)
const [work, setWork] = useState(undefined)

const captureDomain = queryString.parse(location.search).domain
const domainName = dlv(work, 'domain.name', captureDomain)

    const loadWork = async(workId) => {
        if(!workId) return

        try {
            const { data: result } = await captureTaskLoad(workId);
            setWork(result.data.work)

        } catch(err) {
            toast.error('Failed to load work data')
        }
    }

    useEffect(() => {
        loadWork(id)

    }, [id])


    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!work) return (<div className="alert alert-warning" role="alert">Loading...</div>);

    return ( 
    <React.Fragment>
        <nav className="navbar navbar-dark fixed-top flex-md-nowrap p-0 shadow">
            <span className="navbar-brand ml-2">
                JAWIS {domainName ? <span className="text-warning" > {domainName}</span> : ''}
            </span>
            
            <span className="navbar-brand mr-2"><FontAwesome name="pie-chart" /> Auto Report</span>
        </nav>
        <main role="main" className="container-fluid" style={{padding: "33px", marginTop: "30px"}}>
            {work && <ReportWorkInfo data={work} /> }
            {work && work.template && <ReportWorkTemplate className="mt-4" template={work.template} photoSize={340} />}
            {work && work.location && <ReportWorkMap className="mt-4" location={work.location} />}
            {work && work.message && <ReportWorkMessage className="mt-4" message={work.message} />}
            {work && work.photo && <ReportWorkPhoto className="mt-4" photo={work.photo} photoSize={340} />}
            {work.zoom && <ReportWorkZoom className="mt-4" zoom={work.zoom} />}
        </main>
    </React.Fragment> );
}
 
export default CaptureTask;