import React, { useState, useEffect, useContext} from "react";
import { NavLink, withRouter } from "react-router-dom";
import FontAwesome from 'react-fontawesome';
import { loadPermission } from '../../services/app/authService'
import { PermissionContext } from '../../context/permissionContext';
import { DomainContext } from './../../context/domainContext';
import dlv from 'dlv'

const SideNavBar = ({onSidebarToggle, user, location}) => {

  const {permission, setPermission} = useContext(PermissionContext)
  const { domain } = useContext(DomainContext)
  const isAdmin = permission && Array.isArray(permission) && permission.includes('admin')
  const [submenu, setSubmenu] = useState({manage: true, report: dlv(location, 'pathname', '').toString().startsWith('/report/') })

  const [me, ] = useState({})
  
  const hide = () => {
    if( typeof(onSidebarToggle) ==='function') {
      onSidebarToggle()
    }
  } 

  useEffect(() => {
    const getPermission = async() => {
      const result = await loadPermission()
      if(result) {
        setPermission(result)
      }
    }
    
    getPermission()
  }, [setPermission])

  const verifyRoles = ({roles=['owner']}) => {
    return (isAdmin || dlv(domain, 'myRoles',[]).some(x=> roles.includes(x)))
  }

  const handleSubMenuClick = (item) => {
    const sub = {...submenu}
    const toggle = (sub[item]) ? !sub[item] : true;
    sub[item] = toggle

    setSubmenu(sub)
  }

  const { pathname } = location

  return ( 
    <React.Fragment>
      <div className="sidebar sidebar-sticky bg-dark text-white pl-1">
        {!domain && <h3 className="sidebar-heading px-3 mt-4 mb-2 text-warning">
        <FontAwesome name="exclamation-triangle" /> Select Domain
        </h3>}
    
        {domain && 
        <React.Fragment>
          <div className="mb-4 d-none">
            <span className={`nav-item nav-link ${(pathname.includes('/me')) ? 'active': ''}`} 
              style={{cursor: 'pointer'}} 
              onClick={() => handleSubMenuClick('me')}>
                <FontAwesome name="user" /> MY JAWIS
            </span>
            {submenu['me'] && 
            <ul className="nav flex-column pl-3">
              <div className="row align-items-center">
                <div className="col-auto"><NavLink className="nav-item nav-link" to="/me/task" onClick={hide}><FontAwesome name="file-text-o" /> Task</NavLink></div>
                {me.task && <div className="col-auto ml-auto mr-2"><h6 className="d-inline"><span className="badge badge-pill badge-warning">{me.task}</span></h6></div>}
              </div>
              <div className="row align-items-center">
                <div className="col-auto"><NavLink className="nav-item nav-link" to="/me/flow" onClick={hide}><FontAwesome className="ml-1" name="code-fork" /> Flow</NavLink></div>
                {me.flow && <div className="col-auto ml-auto mr-2"><h6 className="d-inline"><span className="badge badge-pill badge-warning">{me.flow}</span></h6></div>}
              </div>
              <div className="row align-items-center d-none">
                <div className="col-auto"><NavLink className="nav-item nav-link" to="/me/approve" onClick={hide}><FontAwesome name="check-square-o" /> Approve</NavLink></div>
                {me.approve && <div className="col-auto ml-auto mr-2"><h6 className="d-inline"><span className="badge badge-pill badge-warning">{me.approve}</span></h6></div>}
              </div>
            </ul>
            }
            <hr style={{backgroundColor: '#bbb', width: '90%' }} />
          </div>
          
          {verifyRoles({roles: ['owner','report']}) && 
          <ul className="nav flex-column mb-3">
            <NavLink className="nav-item nav-link" to="/dashboard" onClick={hide}><FontAwesome name="dashboard" /> DASHBOARD</NavLink>
            <h3 className="sidebar-heading px-3 mb-2 mt-2" onClick={() => handleSubMenuClick('report')} style={{cursor: 'pointer'}} >
              <FontAwesome name="pie-chart" /> Report
            </h3>
            {submenu['report'] && 
            <ul className="nav flex-column mb-2 pl-3">
              <NavLink className="nav-item nav-link" to="/report/project" onClick={hide}><FontAwesome name="pie-chart" /> Project Report</NavLink>
              <NavLink className="nav-item nav-link" to="/report/task" onClick={hide}><FontAwesome name="pie-chart" /> Task Report</NavLink>
              <NavLink className="nav-item nav-link" to="/report/team" onClick={hide}><FontAwesome name="pie-chart" /> Team Report</NavLink>
              <NavLink className="nav-item nav-link" to="/report/staff" onClick={hide}><FontAwesome name="pie-chart" /> Staff Report</NavLink>
              <NavLink className="nav-item nav-link" to="/report/data" onClick={hide}><FontAwesome name="pie-chart" /> Data Report</NavLink>
              <NavLink className="nav-item nav-link" to="/report/shared" onClick={hide}><FontAwesome name="pie-chart" /> Shared Report</NavLink>
              <NavLink className="nav-item nav-link" to="/report/progression" onClick={hide}><FontAwesome name="pie-chart" /> Progression</NavLink>
            </ul>
            }
          </ul>}
          {verifyRoles({roles: ['owner','report']}) && 
          <ul className="nav flex-column mb-3">
            <NavLink className="nav-item nav-link d-none" to="/record/flow" onClick={hide}><FontAwesome className="ml-1" name="code-fork" /> Flow</NavLink>
            <NavLink className="nav-item nav-link" to="/record/task" onClick={hide}><FontAwesome name="file-text-o" /> Task</NavLink>
            {verifyRoles({roles: ['owner','admin','report']}) && 
              <NavLink className="nav-item nav-link" to="/plan" onClick={hide}><FontAwesome name="calendar" /> Plan</NavLink>}
            {verifyRoles({roles: ['owner','admin']}) && 
              <NavLink className="nav-item nav-link" to="/manage/plan" onClick={hide}><FontAwesome name="calendar" /> Auto Plan</NavLink>}
          </ul>}
          {verifyRoles({roles: ['owner','admin']}) && 
          <React.Fragment>
            <h3 className="sidebar-heading px-3 mt-4 mb-2" onClick={() => handleSubMenuClick('manage')} style={{cursor: 'pointer'}} >
            <FontAwesome name="wrench" /> MANAGE
            </h3>
            {submenu['manage'] && 
            <ul className="nav flex-column mb-2 pl-3">
              <NavLink className="nav-item nav-link" to="/project" onClick={hide}><FontAwesome name="space-shuttle" /> Project</NavLink>
              {false && <NavLink className="nav-item nav-link" to="/manage/flow" onClick={hide}><FontAwesome className="ml-1" name="code-fork" /> Flow</NavLink>}
              <NavLink className="nav-item nav-link" to="/manage/task" onClick={hide}><FontAwesome name="file-code-o" /> Task</NavLink>
              <NavLink className="nav-item nav-link" to="/manage/data" onClick={hide}><FontAwesome name="database" /> Data</NavLink>
              <NavLink className="nav-item nav-link" to="/manage/staff" onClick={hide}><FontAwesome name="users" /> Staff</NavLink>
              <NavLink className="nav-item nav-link" to="/manage/zoom" onClick={hide}><FontAwesome name="video-camera" /> Zoom Room</NavLink>
              <NavLink className="nav-item nav-link" to="/manage/line" onClick={hide}><FontAwesome name="comments-o" /> Line Room</NavLink>
            </ul>
            }
          </React.Fragment>}
        </React.Fragment>}
        
        {domain && (domain.isOwner || isAdmin) && <NavLink className="nav-item nav-link" to="/manage/permission" onClick={hide}><FontAwesome name="users" /> Permission</NavLink>}
        {isAdmin && <h3 className="sidebar-heading mt-4 mb-1">
          <NavLink className="nav-item nav-link" to="/domain" onClick={hide}><FontAwesome name="globe" /> Domains</NavLink>
          <NavLink className="nav-item nav-link" to="/report/admin" onClick={hide}><FontAwesome name="globe" /> Domain Report</NavLink>
          <NavLink className="nav-item nav-link" to="/admin/setting" onClick={hide}><FontAwesome name="globe" /> Setting</NavLink>
          <NavLink className="nav-item nav-link" to="/admin/system" onClick={hide}><FontAwesome name="cog" /> SYSTEM</NavLink>
        </h3>}
        {(isAdmin || ['5f6d56b0befa1251e9950fae', '5f6db60ceea3164fd88b0954'].includes(dlv(domain, '_id'))) && 
        <ul className="nav flex-column mt-4">
          <NavLink className="nav-item nav-link" to="/oc" onClick={hide}><FontAwesome name="sitemap" /> Master OC</NavLink>
          
        </ul>}
        <h3 className="d-inline d-lg-none sidebar-heading mt-4 mb-1">
          <NavLink className="nav-item nav-link" to="/logout" onClick={hide}><FontAwesome name="plug" /> Logout</NavLink>
        </h3>
      </div>
    </React.Fragment>
   );
}

export default withRouter(SideNavBar);
