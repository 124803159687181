import http from "../httpService";
import moment from 'moment'

const apiEndpoint = "/report/staff";

export function getReportStaffSummary({filter='today', date=moment().format('YYYY-MM-DD')}) {
  return http.get(apiEndpoint + '/summary/?filter=' + filter + '&date=' + date)
}

export function getReportStaff({id, filter='today', date=moment().format('YYYY-MM-DD')}) {
    return http.get(apiEndpoint + '/' + id + '/?filter=' + filter + '&date=' + date)
  }