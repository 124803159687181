import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getQueueWorkReportLine } from './../../services/app/sysService';

const AdminSystem = () => {

    const [refresh, setRefresh] = useState(Date.now())
    const [queueWait, setQueueWait] = useState(undefined)
    const [queueActive, setQueueActive] = useState(undefined)
    const [maxCon, setMaxCon] = useState(undefined)

    useEffect(() => {
        const getQueueInfo = async() => {
            try {
                const {data: result} = await getQueueWorkReportLine();
    
                if(result.status==='success') {
    
                    setQueueActive(result.data.activeCount)
                    setQueueWait(result.data.waitingCount)
                    setMaxCon(result.data.maxConcurrence)
        
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        getQueueInfo()
    }, [refresh]);

    return ( 
        <React.Fragment>
            <h5>Queue Work Report Line</h5>
            <h6>Active : {queueActive}</h6>
            <h6>Waiting : {queueWait}</h6>
            <h6>Max Concurrence : {maxCon}</h6>
            <button className='btn btn-primary mt-2' onClick={()=> setRefresh(Date.now())}>Refresh</button>
        </React.Fragment>
     );
}
 
export default AdminSystem;