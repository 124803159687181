import React, {useState, useEffect} from "react";

const ButtonGroupOnOff = ({
  init,
  notifyChange=()=> {},
  selectedItemClass='btn btn-sm btn-primary',
  defaultOffItemClass='btn btn-sm btn-secondary',
  defaultItemClass='btn btn-sm btn-outline-dark',
  className='btn-group p-0 m-0'
}) => {

  useEffect(() => {
    setSelect(init)
  }, [init])

  const items = [{name: 'ON', value: true}, {name: 'OFF', value: false}]
  const [select, setSelect] = useState(init)

  const handleSelect = (item) => {
    if(item.value!==select) {

      setSelect(item.value)

      if(notifyChange instanceof Function) {
        notifyChange(item.value)
      }
    }
  }
  
  return (
    <ul className={className}>
      {items.map(item => {
        return (<button
          key={item.value}
          type="button"
          onClick={() => handleSelect(item)}
          className={
            item.value === select ? (item.value===true ? selectedItemClass : defaultOffItemClass) : defaultItemClass
          }
        >
          {item.name}
        </button>)
      })}
    </ul>
  );
};


export default ButtonGroupOnOff;
