import React from 'react';
import FontAwesome from 'react-fontawesome';
import dlv from 'dlv'

// import PersonPhoto from '../../common/card/personPhoto';

// const RoundBorder = {
//   borderRadius: 10,
//   border: '1px solid #AAAAAA',
//   padding: 1
// }

const TemplateFlowEditCard = ({ index, data={}, askEditItem=()=>{}, onRemove=()=>{} }) => {

    const {type, work } = data;

    const projectName = dlv(work, 'project.name')
    const taskName = dlv(work, 'task.name', 'Unknown')

    // const isAllowAll = permission.length===0 || permission.find(x=>x.role.value==='create' && x.mode.value==='all')
    // const findCustomPerson = permission.find(x=>x.role.value==='create' && x.mode.value==='custom')
    // const customPerson = (findCustomPerson) ? findCustomPerson.person : []



  return ( 
    <React.Fragment>
    <div className="card mb-2">
        <div className="card-body p-2">
            <div className="row no-gutters justify-content-end align-items-center">
            <div className="col-auto mx-2">
                <h4><span className="badge badge-secondary">{index+1}</span></h4>
            </div>
            <div className="col-auto ml-2 mr-auto">
                <div className="row no-gutters align-items-center">
                    {type==='approve' && 
                    <div className="col-auto mr-3">
                        <h5 className="d-inline font-weight-bold"><FontAwesome name="check-square-o" /> Approval</h5>
                    </div>}
                    {type==='task' && 
                    <React.Fragment>
                    {taskName && 
                    <div className="col-auto mr-3">
                        <h5 className="d-inline font-weight-bold">
                        <FontAwesome name="file-code-o" /> {taskName.substring(0,20) + ((taskName.length>20) ? '...' : '')}
                        </h5>
                    </div>}
                    {projectName && <div className="col-auto mr-3">
                        <span className="badge badge-info mr-1"><FontAwesome name="space-shuttle" /> PROJECT</span>
                        <h5 className="d-inline font-weight-bold">{projectName.substring(0,20) + ((projectName.length>20) ? '...' : '')}</h5>
                    </div>}
                        
                    </React.Fragment>}
                    
                    {/* {dlv(approval, 'no.deleteWork', false) && <span className="badge badge-warning"><FontAwesome name="explamation-triangle" /> Delete Work when Disapprove</span>}
                    {invalid && <span className="badge badge-danger"><FontAwesome name="explamation-triangle" /> INVALID (Project/Task not found or not enable)</span>} */}
                </div>
                {/* <div className="row no-gutters mt-2">
                    {isAllowAll && <div className="col-auto mr-2">
                        <span className="badge badge-pill badge-success"><FontAwesome name="person" /> All Staffs</span>
                    </div>}
                    {!isAllowAll && customPerson.map( x=> {
                        if(x.type==='staff') {
                            return (<PersonPhoto key={x._id} id={x._id} size={35} />)
                        } else {
                            return (
                            <div key={x._id} className="col-auto mr-1">
                                <BadgePersonGroup userId={x._id} styles={{ photoSize: 35 }}  />
                        </div>)
                        }
                    })}
                </div> */}
            </div>
            <div className="col-auto text-right">
                <FontAwesome className="mr-3" name="pencil" onClick={() => askEditItem(index)} size="lg" />
                <FontAwesome name="trash" onClick={() => onRemove({index})} size="lg" />
            </div>
            </div>
        </div>
    </div>
    </React.Fragment>
    );
    
}
 
export default TemplateFlowEditCard;