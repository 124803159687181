import http from "../../httpService";

const apiEndpoint = "/manage/data";

export function dataList() {
  return http.get(apiEndpoint)
}

export function getData(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveData(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function deleteData(id) {
  return http.delete(apiEndpoint + "/" + id)
}