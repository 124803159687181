import React, {useEffect, useState, useContext} from 'react';
import { templateTaskList, deleteTemplateTask } from '../../../services/manage/task/taskService';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import ConfirmDelete from '../../common/confirmDelete';

import { DomainContext } from './../../../context/domainContext';
import TemplateTaskCard from './taskCard';


const TemplateForms = () => {

    const {domain } = useContext(DomainContext)

    useEffect(() => {
        const getList = async() => {
            try {
                const {data: result} = await templateTaskList();
    
                if(result.status==='success') {
    
                    setList(result.data.list)
        
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }

        if(domain) getList()
        
    }, [domain])

    const [list, setList] = useState([])
    const [askDelete, setAskDelete] = useState('')

    const confirmDelete = async() => {
        try {
            await deleteTemplateTask(askDelete);

            setList(prev => [...prev.filter(x=> x._id!==askDelete)])
            setAskDelete(undefined)
            
        } catch(err) { toast.error('Failed to delete item')}
    }

    if(!domain) { return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)  }

    return ( 
        <React.Fragment>
            <ConfirmDelete isOpen={(askDelete) ? true : false} onConfirm={confirmDelete} onCancel={()=> setAskDelete('')} />
            <div className="row justify-content-between align-items-center mb-3">
                <div className="col"><h4>Tasks</h4></div>
                <div className="col-auto p-0">
                    <NavLink className="nav-item nav-link text-dark" to={`/manage/task/new`} >
                    <button type="button" className="btn btn-primary text-white" >New</button>
                    </NavLink>
                </div>
            </div>
            {list.length===0 && 
            <div className="alert alert-warning" role="alert">
                Empty
            </div>
            }
            <div className="row">
            {list && list.map(item => 
                <div key={item._id} className="col-12">
                    <TemplateTaskCard data={item} onRemove={() => { setAskDelete(item._id); }} />
                </div>
            )}
            </div>
        </React.Fragment>
     );
}
 
export default TemplateForms;