import React from 'react';
import {PhotoSwipeGallery} from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import './customPhoto.css'

import PhotoDisplay from '../card/viewPhoto';

const PhotoGallery = ({photos, thumbnailSize=250}) => {

  let photoItems = photos && photos.map((item) => {     
    
    const { url, desc="", width=1600, height=1200} = item;
           
    const photoItem = {}   
    photoItem.src = url.photo
    photoItem.thumbnail = url.thumbnail
    photoItem.w = width
    photoItem.h = height
    photoItem.title = desc
        
    return (photoItem)   
  })
  // const im =require(`./form.png`)
  // photoItems = [{
  //   src: "https://storage.googleapis.com/jawis-core-photo/2020/06/23/5ef1be63df62f40301bbf820/5ef4836bf209973861cf51b4/photo",
  //   thumbnail: "https://storage.googleapis.com/jawis-core-photo/2020/06/23/5ef1be63df62f40301bbf820/5ef4836bf209973861cf51b4/thumbnail",
  //   title: "25 มิถุนายน 2563 17:58",
  //   w: 1600,
  //   h: 1200
  // },{
  //   src: im ,
  //   thumbnail: im ,
  //   title: "25 มิถุนายน 2563 17:58",
  //   w: 1600,
  //   h: 1200
  // }]
  // const download = (item) => {
  //   return console.log('download Click', item)
  // }
  let options = {
    //http://photoswipe.com/documentation/options.html
    closeOnScroll: false,
    shareButtons: [{ id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }]
  //   getImageURLForShare: function (shareButtonData) {
  //     console.log('shareButtonData',shareButtonData[0])
  //     // `shareButtonData` - object from shareButtons array
  //     // 
  //     // `pswp` is the gallery instance object,
  //     // you should define it by yourself
  //     // 5eeadff4997af83a166b8c52.html
  //     return shareButtonData.src || '';
  
  //   },
  //   parseShareButtonOut: function(shareButtonData, shareButtonOut) {
  //     // `shareButtonData` - object from shareButtons array
  //     // `shareButtonOut` - raw string of share link element
  //     console.log('shareButtonData',shareButtonData)
  //     console.log('shareButtonOut', shareButtonOut)
  //     download(shareButtonOut)
  //     return shareButtonOut;
  // }
  };

  return ( 
    <div>
      <PhotoSwipeGallery items={photoItems} thumbnailContent={item => PhotoDisplay({url: item.thumbnail, size: thumbnailSize})} options={options} />
    </div>
  );
}

export default PhotoGallery;