import React from 'react';
import { useState } from 'react';

const FormText = ({label, value='', enable=true, notifyChange, required=false}) => {

    const [text, setText] = useState(value)

    const handleChange = (text) => {
        setText(text)

        if(typeof(notifyChange)==='function') {
            notifyChange({text})
        }
    }

    return ( <div className="form-group">
    <label>{label}{(required) ? <span className='text-danger font-weight-bold'>*</span> : ''}</label>
    <input className="form-control" value={text} onChange={e => handleChange(e.target.value)} type="text" disabled={!enable} />

</div> );
}
 
export default FormText;