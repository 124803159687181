import React from 'react';
import FontAwesome from 'react-fontawesome';


const MeTaskCreateList = ({list=[], notifySelectId=()=>{}}) => {
    return ( 
    <React.Fragment>
        <div className="row justify-content-between mb-3">
            <div className="col"><h4>Select Task To Start</h4></div>
            <div className="col-auto p-0">
            </div>
        </div>
        {list.length===0 && <div className="alert alert-warning" role="alert">Task is not available</div>}
        <div className="row">
        {list && list.map( (item, index) => {
            const {_id, name } = item;

            return (
            <div key={_id} className="col-12">
                <div className="card mb-2">
                    <div className="card-body p-2">
                        <div className="row align-items-center justify-content-between">
                        <div className="col-auto ml-2">
                            <h5 className="d-inline">
                                <FontAwesome name="file-code-o" size='lg' /><span className="ml-2">{name}</span>
                            </h5>
                        </div>
                        <div className="col-auto pr-4">
                            <div className="row  align-items-center">
                                <div className="col-auto">
                                    <button type="button" className="btn btn-primary text-white" onClick={() => notifySelectId(_id)} >Select</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        })}
        </div>
    </React.Fragment> );
}
 
export default MeTaskCreateList;