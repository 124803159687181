import React from "react";

const SearchBox = ({ value, onChange, placeholder='Search...', className='form-control', ...props }) => {
  return (
    <input
      type="text"
      name="query"
      className={className}
      placeholder={placeholder}
      {...props}
      value={value}
      onChange={e => { if(onChange instanceof Function) onChange(e.currentTarget.value)} }
    />
  );
};

export default SearchBox;
