import React, { useState, useEffect } from 'react';
// import Input from '../common/input';
// import ButtonGroup from '../../common/buttonGroup';
// import NumericInput from 'react-numeric-input';
// import _ from 'lodash'
import { toast } from 'react-toastify';
import DialogSearchPerson from '../../common/dialogSearchPerson/index';
import DialogSearchList from '../../common/dialogSearchList/index'
import { personList } from '../../../services/manage/staff/teamService';
import PersonCard from '../../common/card/personCard';

const FlowViewTableItemEdit = ({data={}, notifySave, notifyCancel}) => {

    const [personGroupList, setPersonGroupList] = useState([])
    
    const loadPersonGroupList = async() => {
        try {
            const {data: result} = await personList();
            if(result.status==='success') {
                setPersonGroupList(result.data.list.map(x=> { x.id=x._id; return x;}))
            } else {
                toast.error(result.message)
            }
        } catch(err) {
            toast.error(err.message)
        }
    }

    useEffect(() => {
        loadPersonGroupList()
        
    }, [])

    const [error, setError] = useState('')
    const [selectTemplate, ] = useState(data.template || undefined)
    const [person, setPerson] = useState((data.person) ? data.person : [])
    const removePerson = (index) => {
        const newData = [...person]
        newData.splice(index, 1)
    
        setPerson(newData)
      }

    const onSave = () => {
        if(!selectTemplate) return setError('Template is required')
            
        const doc = { template: selectTemplate, person}

        if (!(notifySave instanceof Function)) return;

        notifySave(doc)

    }

    const onCancel = () => {
        if (notifyCancel instanceof Function) {
            notifyCancel()
        }
    }

    const headerSpace = 200

    return ( <React.Fragment>
        <div className="card bg-secondary text-white">
            <div className="card-body">
                {!selectTemplate &&  <React.Fragment>
                    <div className="alert alert-warning" role="alert">
                        Template is invalid or not found
                    </div>
                </React.Fragment>}
                {selectTemplate && 
                    <div className="row align-items-center">
                        <div className="col-auto" style={{width: headerSpace}}>
                            <h6 className="font-weight-bold">Template</h6>
                        </div>
                        <div className="col-12 col-md-8">
                            <span className="font-weight-bold text-warning">{selectTemplate.name}</span>
                        </div>
                    </div>
                }
                {selectTemplate && 
                    <div className="row align-items-center mt-3">
                        <div className="col-auto" style={{width: headerSpace}}>
                            <h6 className="font-weight-bold">Detail</h6>
                        </div>
                        <div className="col-12 col-md-8 col-lg-6">
                            {selectTemplate.forms.length===0 && <span>Not Found</span>}
                            <div className="row">
                            {selectTemplate.forms.length>0 && selectTemplate.forms.map( (item, index) => {
                                const { name } = item;

                                return (
                                <div key={index} className="col-12">
                                    <div className="card mb-2">
                                        <div className="card-body bg-secondary p-0">
                                            <div className="row align-items-center justify-content-left">
                                            <div className="col-auto ml-2">
                                                <span>{index+1}.</span>
                                            </div>
                                            <div className="col-auto p-0">
                                                <span>{name}</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                }
                {selectTemplate && 
                    <div className="row align-items-top mt-3">
                        <div className="col-auto" style={{width: headerSpace}}>
                            <h6 className="font-weight-bold">ผู้ปฏิบัติ</h6>
                        </div>
                        
                        <div className="col">
                            
                            <div className="row">
                                <div className="col">
                                    {person &&
                                        person.map((x, index) => {
                                            return (<PersonCard key={x.id} className="mt-2" data={x} onRemove={() => removePerson(index)} />)
                                        })
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto"><DialogSearchPerson cache={false} notifySelect={item => setPerson([...person, {...item, personType: 'person'}])} /></div>
                                <div className="col-auto px-0">
                                    <DialogSearchList header="เลือกกลุ่มบุคคล" buttonAdd="เพิ่มกลุ่มบุคคล" list={personGroupList} notifySelect={item => setPerson([...person, {...item, personType: 'group' }])} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="card-footer p-2">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        {error && <span className="text-warning font-weight-bold ml-3">{error}</span>}
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-light text-dark font-weight-bold mr-3" onClick={onCancel}>Cancel</button>
                        <button type="button" className="btn btn-light text-dark font-weight-bold" onClick={onSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default FlowViewTableItemEdit;