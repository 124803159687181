import React from 'react';
// import FontAwesome from 'react-fontawesome';
import { useEffect, useState } from 'react';
import { getEmployeeInfo } from '../../../services/hrms/employee';
import PersonPhoto from '../card/personPhoto';
import FontAwesome from 'react-fontawesome';

const BadgeStaffCard = ({userId, buttonIcon, buttonClass, onClick, 
    styles={ showBorder: true, photoSize: 50,  showInfo: ['name', 'company'], nameSize: 16, infoSize: 12, marginLeft: 10 }}) => {

//   const { userId, type='person', userName, company, sim , position, department } = data;

  const { showBorder=true, photoSize=50, showInfo=['name', 'company'], nameSize=16, infoSize=12, marginLeft=10 } = styles

  const searchBoxStyle = (showBorder) ? {
    borderRadius: 10,
    border: '1px solid #BBBBBB66',
    padding: 2
  } : {}

  const [info, setInfo] = useState({})

  const { userName, company, department, position, sim } = info

  useEffect(() => {

    const loadInfo = async() => {
        try {
            if(userId) {
                const result = await getEmployeeInfo(userId)
    
                setInfo(result)
            }
            
        } catch (error) {
            
        }
    }

    loadInfo()

  }, [userId])



    return ( 
        <React.Fragment>
            <div className="row no-gutters align-items-center m-0" style={searchBoxStyle}>
                <div className="col-auto">
                    <PersonPhoto id={userId} size={photoSize} />
                </div>
                {showInfo.length>0 && 
                    <div className="col-auto p-0 mr-3" style={{marginLeft: marginLeft}}>
                    {showInfo.includes('name') && <strong className="card-title d-inline mr-2" style={{fontSize: nameSize}}>{userName}</strong>}
                    {showInfo.includes('company') && company &&  <span className="d-block" style={{fontSize: infoSize}}>{company}</span>}
                    {showInfo.includes('department') && department && <span className="d-block" style={{fontSize: infoSize}}>{department}</span>}
                    {showInfo.includes('position') && position && <span className="d-block" style={{fontSize: infoSize}}>{position}</span>}
                    {showInfo.includes("sim") && sim && <span className="d-block" style={{fontSize: infoSize}}>{sim}</span>}
                </div>}
                {buttonIcon && (typeof(onClick)==='function') && <div className="col-auto p-1">
                    <FontAwesome style={{cursor: 'pointer'}} className={buttonClass} name={buttonIcon} onClick={() => onClick(userId)} size="2x" /></div>}
            </div>
        </React.Fragment>
        );
    
}
 
export default BadgeStaffCard;