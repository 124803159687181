import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import FilterDateCombo from '../../../common/filter/filterDateCombo';
import moment from 'moment'
import FontAwesome from 'react-fontawesome';
import queryString from 'query-string'
import ButtonDownload from '../../../common/button/buttonDownload';

import jsfiledownload from 'js-file-download'
import { downloadTask } from '../../../../services/download/dashboard';
import ReportPerTeamDetail from './detail';
import { getReportPerData } from '../../../../services/report/reportData';

const ReportPerData = ({match, location}) => {

    const [id,] = useState(match.params.id)
    const [filter, setDateFilter] = useState({filter: queryString.parse(location.search).filter || 'today', date: queryString.parse(location.search).date || moment().format('YYYY-MM-DD')})
    const [report, setReport] = useState({})

    const { name } = report

    useEffect(() => {
        const loadReport = async(input) => {
            if(!input) return
    
            try {
                const { data: result } = await getReportPerData(id, input);
                setReport(result.data.report)
    
            } catch(err) {
                toast.error('Failed to load work data')
            }
        }

        loadReport({id, ...filter})
    }, [id, filter])

    const handleReportDownload = () => {
        return new Promise(async (resolve, reject) => {
          try {
    
            const input = { date: filter.date, }
    
            input.userId = id
            if(filter.filter==='week') input.dateEnd = moment(filter.date).add(6, 'day').format('YYYY-MM-DD') 
            if(filter.filter==='month') input.dateEnd = moment(filter.date).endOf('month').format('YYYY-MM-DD') 
            
            const {data: result, headers } = await downloadTask(input)
    
            if( result.status==='error') {
                toast.error(result.message)
            } else {
                if ( headers['x-suggested-filename']==='nodata') {
                    toast.error("No Report")
                } else {
                    jsfiledownload(result, headers['x-suggested-filename'] || `report.xlsx`)
                }
            }
    
            setTimeout(() => {resolve('ok')}, 300);
              
          } catch(err) { 
              toast.error("Error : ", err.message)
              resolve('error')
          }
        })
    
    }

    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!report) return (<div className="alert alert-warning" role="alert">Report is not found</div>);

    return ( <React.Fragment>
        <div className="row justify-content-end align-items-center">
            <div className="col-auto mr-auto mb-2">
                <div className="row no-gutters align-items-center justify-content-left">
                    <div className="col-auto ml-2"><FontAwesome name='users' size="2x" /></div>
                    <div className="col-auto ml-4"><h3 className="d-inline">{name}</h3></div>
                </div>
            </div>
            <div className="col-auto p-0 d-none">
                <ButtonDownload label='Download' onClick={handleReportDownload} />
            </div>
            <div className="col-auto ml-3 mb-2">
                <FilterDateCombo enableFilter={['today', 'day', 'week', 'month']}  notifyDateFilter={filter => setDateFilter(filter)} init={{...filter}} />
            </div>
        </div>
        <ReportPerTeamDetail report={report} filter={filter} />
    </React.Fragment> );
}
 
export default ReportPerData;