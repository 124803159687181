import React, { useState } from 'react';
import dlv from 'dlv'

import ScriptForm from './scriptForm';
import { useEffect } from 'react';
import FontAwesome from 'react-fontawesome';

const TaskScript = ({script: mydata, linkFieldList=[], notifyChange}) => {
    
    const [data, setData] = useState(dlv(mydata, 'data', []))
    const [askEdit, setAskEdit] = useState({enable: false, index: -1, data: undefined})

    const notifyFormSave = ({item, index=-1}) => {
        const newData = [...data];
    
        if(index>-1 && index<newData.length) {
          newData[index] = item
        } else {
          newData.push(item)
        }
    
        setData(newData)
        setAskEdit({enable: false})
    }

    useEffect(() => {

        if(notifyChange instanceof Function) {
            notifyChange({enable: true, data})
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    return ( 
        <div>
            {data.length===0 && 
                <div className="alert alert-warning" role="alert">
                No Script
                </div>
            }
            {data.length>0 && data.map( (item, index) => {
                const { event, httpUrl, httpMethod } = item
                return (<div key={index} className="row">
                    <div className="col">
                        <div className="card mb-2">
                            <div className="card-body">
                              <div className="row justify-content-start">
                                <div className="col-auto p-0">
                                  <span className="ml-4">Event <span className="text-primary font-weight-bold">{event.name}</span></span>
                                  <span className="ml-3">Method <span className="text-primary font-weight-bold">{httpMethod}</span></span>
                                  <span className="ml-3">URL <span className="text-primary font-weight-bold">{httpUrl}</span></span>
                                </div>
                                <div className="col-auto ml-auto">
                                  <FontAwesome className="mr-3" name="pencil" onClick={() => setAskEdit({enable: true, index, data: item })} size="lg" />
                                  <FontAwesome name="trash" onClick={() => setData(prev => prev.filter((x,i)=> i!==index)) } size="lg" />
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>)
            })}
            <div className="row">
                <div className="col text-right">
                    <button className="btn btn-sm btn-dark" onClick={() => setAskEdit({enable: true, index: -1})}>Add</button>
                </div>
            </div>
            {askEdit.enable && <ScriptForm index={dlv(askEdit, 'index', -1)} data={askEdit.data} linkFieldList={linkFieldList} notifySave={notifyFormSave} notifyCancel={() => setAskEdit({enable: false})} />}
            {/* {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>} */}
        </div>
        
     );
}
 
export default TaskScript;