import React, {useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { GetMeTaskList } from '../../../services/me/task/meTaskService';

import moment from 'moment';
import FilterDateCombo from './../../common/filter/filterDateCombo';
import MeTaskList from './list/taskList';
import ButtonGroup from '../../common/buttonGroup';

const workStatusOptions = [{name: 'All', value: 'all'},{name: 'Default', value: 'default'}, {name: 'Active', value: 'active'}]

const MeTasks = () => {

    const [filter, setFilter] = useState({status: 'default', filter: "today", date: moment().format('YYYY-MM-DD')})

    useEffect(() => {  
        const getList = async() => {
            try {
                const input = { date: filter.date }
                if(['custom'].includes(filter.filter)) input.dateEnd = filter.dateEnd

                switch(filter.status) {
                    case "all": {
                        input.status = ''
                        break;
                    }
                    case "default": {
                        input.status = 'active,pause'
                        break;
                    }
                    default: {
                        input.status = filter.status
                    }
                }

                const {data: result} = await GetMeTaskList(input);
    
                if(result.status==='success') {
    
                    setList(result.data.list)
        
                } else {
                    toast.error(result.message)
                }
            } catch(err) {
                toast.error(err.message)
            }
        }
        
        getList() 

    }, [filter])

    const [list, setList] = useState([])

    console.log('filter', filter)


    return ( 
        <React.Fragment>
            <div className="row no-gutters justify-content-end align-items-top">
                <div className="col mr-auto mb-3"><h4>My Task</h4></div>
                <div className="col-auto mr-3 mb-3">
                    <NavLink className="nav-item nav-link text-dark p-0" to={`/me/task/new`} >
                    <button type="button" className="btn btn-success text-white" >New Task</button>
                    </NavLink>
                </div>
                <div className="col-auto mr-3 mb-3">
                    <ButtonGroup
                        items={workStatusOptions}
                        selectedValue={filter.status}
                        onItemSelect={filter => setFilter(prev => { return {...prev, status: filter.value } })}
                        valueProperty='value'
                        />
                </div>
                <div className="col-auto mb-3">
                    <FilterDateCombo enableFilter={['today', 'day']} notifyDateFilter={item => setFilter(prev => {return {...prev, ...item}})} />
                </div>
            </div>
            <MeTaskList list={list} />
        </React.Fragment>
     );
}
 
export default MeTasks;