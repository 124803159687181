import React, {useState, useEffect} from 'react';
import FontAwesome from 'react-fontawesome';
// import { toast } from 'react-toastify';
import Toggle from 'react-toggle'
import PersonCardMini from './../../common/card/personCardMini';

const permissionList = [{name: 'Edit Node', value: 'editNode'}, {name: 'Edit Childs', value: 'editChild'}]

const NodePermissionCard = ({data={}, onChange, onRemove=()=>{}, readOnly=false}) => {

  const [roles, setRoles] = useState(data.roles || [])
  const [enable, setEnable] = useState(data.enable || false)

  useEffect(() => {
    if(onChange instanceof Function) {
      onChange({...data, roles, enable})
    }

    // eslint-disable-next-line
  },[roles, enable])

  const handlePermissionChange = async({permissionName, boo}) => {
    if(boo) {
      setRoles(prev => [...prev, permissionName])
    } else {
        setRoles(prev => prev.filter(x=> x!==permissionName))
    }
  }

  return ( 
    <React.Fragment>
      
      <div className="card mb-2 text-dark p-0">
        <div className="row no-gutters align-items-center justify-content-left">
          
          <div className="col-auto text-center p-1 text-center">
            <PersonCardMini data={data} styles={{ photoSize: 35, showInfo: ['name'], showBorder: false}} />
          </div>

          <div className="col-auto ml-3">
            <div className="row no-gutters align-items-center">
              {permissionList.map(item => {
                const {name, value} = item

                return (
                  <div key={value} className="col-auto px-2">
                    <input type="checkbox" checked={roles.includes(value)} disabled={readOnly}
                      onChange={e => handlePermissionChange({permissionName: value, boo: e.target.checked})} /><span> {name}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-auto ml-auto mr-4">
            <div className="row no-gutters align-items-center">
              {!readOnly &&
              <div className="col-auto pt-1 mr-3">
                  <Toggle defaultChecked={enable} onChange={value => setEnable(value.target.checked) } />
              </div>}
              {readOnly && !enable && <h6 className='d-inline mt-1 text-danger'>Disable</h6>}
              {!readOnly && <div className="col-auto">
                  <FontAwesome className="text-danger" name="times-circle-o" size="2x" onClick={onRemove} style={{cursor: 'pointer'}} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
    
}
 
export default NodePermissionCard;