import React, {useState, useEffect, useContext} from 'react';
import { toast } from 'react-toastify';
import FontAwesome from 'react-fontawesome';

import { DomainContext } from '../../../../context/domainContext';

import dlv from 'dlv'
import moment from 'moment'
import ReportProgression from './reportProgression';
import { getReportPerProgression } from '../../../../services/report/reportProgression';
import { formatDateTimeFriendly } from './../../../../utils/date';
import { getWorkHourFromTotalMinutes } from '../../../../utils/work';

const ReportPerProgress = ({match, location}) => {

    const {domain } = useContext(DomainContext)

    const [id,] = useState(match.params.id)
    const [report, setReport] = useState({})

    const { name, description, percentage=0 } = report
    const { progressReport } = report
    
    useEffect(() => {
        const loadReport = async(id) => {
            if(!id) return
    
            try {
                const { data: result } = await getReportPerProgression(id);
    
                setReport(result.data.report)
    
            } catch(err) {
                toast.error('Failed to load work data')
            }
        }

        if(id) {
            loadReport(id)
        }
        
        // eslint-disable-next-line
    }, [id])

    if(!domain) {
        return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
    }

    if(!match.params.id) return (<div className="alert alert-warning" role="alert">id is not found</div>);
    if(!report) return (<div className="alert alert-warning" role="alert">report is not found</div>);

    const statusColor = percentage >= 100 ? 'success' : 'warning'

    const allTasks = [...dlv(progressReport, 'stages', []).map(stage=> {
        return dlv(stage, 'tasks', [])
    }).reduce((acc,x) => acc.concat(x), []).filter(x=> x.workId)
        , ...dlv(progressReport, 'unstage.tasks', []).filter(x=> x.workId)]


    const totalTime = getWorkHourFromTotalMinutes(allTasks.map(task=> task.time || 0).reduce((acc,x)=> acc+x, 0))
    const maxDate=new Date(Math.max.apply(null,allTasks.map(task=> new Date(task.finish || moment()))));
    const minDate=new Date(Math.min.apply(null,allTasks.map(task=> new Date(task.start))));

    const totalPeriod = (moment(minDate).isSame(moment(maxDate), 'day'))  ? 
                        formatDateTimeFriendly(minDate) + ' - ' + moment(maxDate).format("HH:mm")
                        : formatDateTimeFriendly(minDate) + ' - ' + formatDateTimeFriendly(maxDate)

    return ( <React.Fragment>
        <div className="row no-gutters align-items-center justify-content-left mt-2 mb-3">
            <div className="col-auto ml-2"><FontAwesome name='flag-checkered' size="2x" /></div>
            <div className="col-auto ml-4"><h3 className="d-inline text-primary">{name}</h3></div>
            <div className="col-auto ml-3">
                <h3><span className={`badge badge-${statusColor} d-inline`}>{percentage}%</span></h3>
            </div>
            {totalTime && <div className="col-auto ml-3">
            <h5><span className={`badge badge-primary`}>Total Work Time {totalTime}</span></h5>
            </div>}
            {totalPeriod && <div className="col-auto ml-3">
            <h5><span className={`badge badge-pill badge-secondary`}>{totalPeriod}</span></h5>
            </div>}
        </div>
        <h5 className="ml-4 text-muted">{description}</h5>
        <ReportProgression data={progressReport} />
        
        
    </React.Fragment> );
}
 
export default ReportPerProgress;