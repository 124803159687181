import React from 'react';
import FontAwesome from 'react-fontawesome';
import PersonCardMini from './../../common/card/personCardMini';
import { getWorkHourFromTotalMinutes } from '../../../utils/work';
import moment from 'moment'
// import dlv from 'dlv'
import PersonPhoto from '../../common/card/personPhoto';
import BadgePersonGroup from '../../common/badge/badgePersonGroup';

const ReportWorkInfo = ({className, data: work}) => {

    const { name, date={}, plan, person, status, description, project, permission=[] } = work
    const { start, finish, delete: dateDelete, trackTime } = date

    // console.log('date', date )

    const findOwner = person.find(x=> x.role==='owner')
    const workTime = getWorkHourFromTotalMinutes(trackTime)
    const workStatus = (status==='finish') ? 
        <span className='badge badge-success'>เสร็จสิ้น</span> : 
        (status==='active') ? <span className='badge badge-warning'>Active / กำลังปฏิบัติ</span> : 
        (status==='wait') ? <span className='badge badge-info'>Waiting / รอปฏิบัติงาน</span> : 
        (status==='pause') ? <span className='badge badge-secondary'>Pause / หยุดชั่วคราว</span> : 
        (status==='delete') ? <span className='badge badge-secondary'>Delete / ยกเลิก</span> : 
        (status==='overdue') ? <span className='badge badge-secondary'>Overdue / เกินกำหนดเวลา</span> : <span className='badge badge-secondary'>Unknown</span>

    const bootColor = (status==='finish') ? 'success' : (status==='wait') ? 'info' : (status==='active') ? 'warning' : 'secondary'

    const isAllowAll = permission.length===0 || permission.find(x=>x.role.value==='create' && x.mode.value==='all')
    const findCustomPerson = permission.find(x=>x.role.value==='create' && x.mode.value==='custom')
    const customPerson = (findCustomPerson) ? findCustomPerson.person : []

    return ( 
    <div className={className}>
        <div className="row justify-content-between align-item-bottom">
            <div className="col-auto">
                {project && project.name && <h4 className="text-primary mb-3">Project : {project.name}</h4>}
                <h4 className="d-inline"><span className="mr-2">{name}</span>
                {workStatus} {['active', 'finish', 'pause'].includes(status) && <span className={`ml-2 text-${bootColor}`}><FontAwesome name="clock-o" /> {workTime}</span>}
                </h4>
                {description && <h6 className="text-muted my-3">{description}</h6>}
                {plan && <h6 className="mt-2">Plan Work
                    <span className="badge badge-info ml-2">Begin {moment(plan.begin).format('DD MMMM YYYY HH:mm')}</span>
                    <span className="badge badge-info ml-2">End {moment(plan.end).format('DD MMMM YYYY HH:mm')}</span>
                    </h6>}
                {['active','pause', 'finish', 'delete'].includes(status) && start && <h6 className="mt-2">Start <span className="badge badge-secondary ml-2">{start}</span></h6>}
                {finish && !dateDelete && <h6>Finish <span className="badge badge-secondary ml-2">{finish}</span></h6>}
                {dateDelete && <h6>Delete <span className="badge badge-secondary ml-2">{dateDelete}</span></h6>}
            </div>
            <div className="col-auto">
                {findOwner && <PersonCardMini data={findOwner} styles={{photoSize: 100, showInfo: ['name', 'company', 'sim', 'position', 'department'] }} />}
                
            </div>
            {['wait', 'overdue'].includes(status) && isAllowAll && <div className="col-12">
                <h6>Plan Staff <span className="ml-2 badge badge-pill badge-success"><FontAwesome name="person" /> All Staffs</span></h6>
            </div>}
            {['wait', 'overdue'].includes(status) && customPerson.length>0 && 
                <div className="col-12">
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto mr-3">Plan Staff </div>
                        {customPerson.map( x=> {
                            if(x.type==='staff') {
                                return (<PersonPhoto key={x._id} id={x._id} size={35} />)
                            } else {
                                return (
                                <div key={x._id} className="col-auto mr-1">
                                    <BadgePersonGroup userId={x._id} styles={{ photoSize: 35 }}  />
                            </div>)
                            }
                        })}
                    </div>
                </div>
                }
        </div>
    </div> );
}
 
export default ReportWorkInfo;