import http from "../httpService";

const apiEndpoint = "/report/progression";

export function getReportProgressionSummary(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''
  
  return http.get(apiEndpoint + '/summary' + ((query) ? '/?' + query : ''))
}

export function getReportPerProgression(id, input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + '/' + id + ((query) ? '/?' + query : ''))
}