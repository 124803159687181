import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavLink } from 'react-router-dom';
// import { getWorkHourFromTotalMinutes } from '../../../utils/work';
import PersonPhoto from '../../common/card/personPhoto';
import dlv from 'dlv'
import moment from 'moment'
import BadgePersonGroup from '../../common/badge/badgePersonGroup';
import PersonCardMini from '../../common/card/personCardMini';

const PlanDailyCard = ({data={}, className=''}) => {

  const {_id, status, name: taskName, project, plan, date={}, permission=[], person=[], isAuto } = data;
  const { begin, end } = plan
  const { start, update, finish, delete: dateDelete } = date

  const projectName = dlv(project, 'name', 'Unknown')

  const isAllowAll = permission.length===0 || permission.find(x=>x.role.value==='create' && x.mode.value==='all')
  const findCustomPerson = permission.find(x=>x.role.value==='create' && x.mode.value==='custom')
  const customPerson = (findCustomPerson) ? findCustomPerson.person : []

  const statusColor = (status==='wait') ? 'info' : (status==='active') ? 'warning' : (status==='finish') ? 'success' : 'secondary'

  return ( 
    <div className={className}>
      <div className="card border-secondary mb-2">
        <div className="card-body p-2">
          {!isAuto && <NavLink className="stretched-link" to={`/report/work/${_id}`} ></NavLink>}
          <div className="row no-gutters justify-content-end align-items-center">
            <div className="col-auto ml-2 mr-auto">
                <div className="row no-gutters align-items-center">
                  {!isAuto && <div className="col-auto mr-2">
                    <span className={`badge badge-pill badge-${statusColor}`}><h6 className='d-inline'>{status}</h6></span>
                  </div>}
                  {isAuto && <div className="col-auto mr-3">
                    <span className={`badge badge-pill badge-warning`}><h6 className='d-inline'>Auto Plan</h6></span>
                  </div>}
                  {['wait', 'overdue'].includes(status) && <div className="col-auto mr-3">
                    <span className='d-inline badge badge-pill badge-primary'>
                      <span>{moment(begin).format('HH:mm')} - {moment(end).format('HH:mm')}</span>
                    </span>
                  </div>}
                  {['active'].includes(status) && <div className="col-auto mr-3">
                    <span className='d-inline badge badge-pill badge-primary'>
                      <span>{moment(start).format('HH:mm')} - Now</span>
                    </span>
                  </div>}
                  {['finish', 'pause', 'delete'].includes(status) && <div className="col-auto mr-3">
                    <span className='d-inline badge badge-pill badge-primary'>
                      <span>{moment(start).format('HH:mm')} - {moment(dateDelete || finish || update).format('HH:mm')}</span>
                    </span>
                  </div>}
                  <div className="col-auto mr-3">
                    <FontAwesome name="file-code-o" />
                    <h6 className="ml-1 d-inline font-weight-bold">{taskName.substring(0,20) + ((taskName.length>20) ? '...' : '')}</h6>
                  </div>
                  {project && <div className="col-auto mr-3">
                      <span className="badge badge-info mr-1"><FontAwesome name="space-shuttle" /> PROJECT</span>
                      <h6 className="d-inline font-weight-bold">{projectName.substring(0,20) + ((projectName.length>20) ? '...' : '')}</h6>
                  </div>}
                  
                </div>
            </div>
            <div className="col-auto text-right">
                {['wait', 'overdue'].includes(status) && <div className="row no-gutters align-items-center justify-content-end">
                  {isAllowAll && <div className="col-auto mr-2">
                    <span className="badge badge-pill badge-success"><h6 className="d-inline"><FontAwesome name="person" /> All Staffs</h6></span>
                  </div>}
                  {!isAllowAll && customPerson.map( x=> {
                    if(x.type==='staff') {
                        return (<PersonPhoto key={x._id} id={x._id} size={35} />)
                    } else {
                        return (
                        <div key={x._id} className="col-auto mr-1">
                            <BadgePersonGroup userId={x._id} styles={{ photoSize: 35 }}  />
                    </div>)
                    }
                  })}
                </div>}
                {['active', 'finish', 'pause', 'delete'].includes(status) && <div className="row no-gutters align-items-center justify-content-end">
                  {person.map( item=> {
                    return (<PersonCardMini key={item.userId} data={item} styles={{ photoSize: 45, showBorder: false }} />)
                  })}
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    );
    
}
 
export default PlanDailyCard;