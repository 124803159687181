import http from "../../httpService";

const apiEndpoint = "/manage/line";

export function lineList(input) {
  const query = (input) ? Object.keys(input).map(key=> { return `${key}=${input[key]}`}).join('&') : ''

  return http.get(apiEndpoint + ((query) ? '/?' + query : ''))
}

export function getLine(id) {
  return http.get(apiEndpoint + "/" + id)
}

export function saveLine(doc) {
  if (doc._id) {
    const body = { ...doc };
    delete body._id;
    return http.put(apiEndpoint + "/" + doc._id, body);
  }

  return http.post(apiEndpoint, doc);
}

export function setLineEnable({_id, enable}) {
  return http.put(apiEndpoint + "/" + _id + "/enable", { enable })
}

export function deleteLine(id) {
  return http.delete(apiEndpoint + "/" + id)
}

const getHeader = (token) => {
  return {
      'Authorization': 'Bearer '+ token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin' : '*'
    }
}

export function verifyLineToken(token) {
  const headers = {headers: getHeader(token) };
  return http.get("https://notify-api.line.me/api/status" , headers)
}

