import React from 'react';
import StaffManageLine from './staffManageLine';

const StaffManage = ({notifyCancel, staff}) => {

    const onCancel = () => {
        if (notifyCancel instanceof Function) {
            notifyCancel()
        }
    }

    return ( <React.Fragment>
        <div className="card bg-secondary text-white my-3">
            <div className="card-body">
                <StaffManageLine staff={staff} />
            </div>
            <div className="card-footer p-2">
                <div className="row align-items-center justify-content-end">
                    <div className="col-auto">
                        <button type="button" className="btn btn-light btn-sm text-dark font-weight-bold mr-2" onClick={onCancel}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment> );
}
 
export default StaffManage;