import React from 'react';

import dlv from 'dlv'
import ReportProgressStage from './stage';

const ReportProgression = ({data={} }) => {

    const { stages=[], enableUnstage, unstage } = data

    return ( 
    <React.Fragment>
        <div className="row align-items-start mb-2">
          <div className="col-auto" style={{width: 500}}>
              <h5 className="font-weight-bold">Progression Report</h5>
          </div>
        </div>
        {enableUnstage && unstage!==undefined && 
            <ReportProgressStage isUnstage={true} stage={unstage} percentage={100/(stages.length+1)} />
        }
        {stages.map((stage, index, array) => {
            const tasksPrevious = index>0 ? dlv(array[index-1], 'tasks', []) : []

            const previousCompleted = tasksPrevious.filter(task => task.status==='finish').length
            const isCurrentStage = previousCompleted===tasksPrevious.length

            const percentage= 100/ (enableUnstage ? array.length+1 : array.length)

            return (<ReportProgressStage key={stage._id || index} no={index+1} stage={stage} percentage={percentage} isCurrentStage={isCurrentStage} />)
        }
        )}

    </React.Fragment> );
}
 
export default ReportProgression;