import axios from "axios";


export async function testApi({url, method, input=[], check200, checkStatus}) {

    return new Promise((resolve, reject) => {
        try {
          const data = input.filter(x=> x.req==='body').reduce((obj, item) => ({...obj, [item.name]: item.value }) , {})
          // console.log('body', data)

          const query = input.filter(x=> x.req==='query').reduce((obj, item) => ({...obj, [item.name]: item.value })  , {})
          // console.log('query', query)

          axios({
            method,
            url,
            data,
            params: query

          }).then(({data: result}) => {
            // console.log('result data', result)

            if(!check200 || !checkStatus) resolve({status: true, message: '', data: { result }})
            
            resolve({status: true, message: '', data: { result }})
          }).catch(err=> {
            // console.log('err', err)
            resolve({status: false, message: 'error : ' + err.message, data: undefined})
          })
      
        } catch(err) {
          console.log('throw', err)
          resolve({status: false, message: 'error : ' + err.message, data: undefined})
        }
    })
  }