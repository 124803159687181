import React, {useState, useContext, useEffect} from 'react';
import SearchBox from "../../../common/searchBox";
import { toast } from "react-toastify";
// import _ from "lodash";

import { DomainContext } from '../../../../context/domainContext';
import ProgressionSummaryDetail from './detail';
import { getReportProgressionSummary } from '../../../../services/report/reportProgression';

const ProgressionReport = () => {

  const {domain } = useContext(DomainContext)
  const [search, setSearch] = useState('')
  const [report, setReport] = useState({})

  useEffect(() => {
    const loadList = async() => {
      try {
          const { data: result } = await getReportProgressionSummary()
          if(result.status==='success') {
  
            setReport(result.data.report)
  
          } else {
              toast.error(result.message)
          }
      } catch (error) { }
    }

    if(domain) loadList()

  }, [domain])


  if(!domain) {
    return (<div className="alert alert-warning" role="alert">Please Select Domain</div>)
  }

  return ( 
    <React.Fragment>
    <div className="row justify-content-end">
      <div className="col-auto mr-auto mb-3">
        <h4>Progression Report</h4>
      </div>
      <div className="col-auto mb-3 d-none">
        <SearchBox value={search} onChange={value => setSearch(value)} />
      </div>
    </div>
    <ProgressionSummaryDetail report={report} />
    </React.Fragment>
  );
}
 
export default ProgressionReport;