import React, { Component } from "react";

// import _ from "lodash";

import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import { searchEmployee } from "../../services/hrms/employee"
import SearchTable from "./searchTable";


class Search extends Component {
  _isMounted = false;
  _delay_seach = null;

  state = {
    data: [],
    search: "",
    index: 0,
    row: 10,
    isLoading: false
  };

  async componentDidMount() {
    this._isMounted = true;

    await this.loadSearch();
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadSearch() {
    const { search, index, row } = this.state;

    if(search && search.length>2) {

      clearTimeout(this._delay_seach)
      this.setState({isLoading: true})

      this._delay_seach = setTimeout(async () => {
        const { data: result } = await searchEmployee(search, index, row);
        this.setState({isLoading: false})
        if ( result.status === 'success') {
          if ( this._isMounted) this.setState({ data: result.data.search });
        } else {
            toast.error("Error : " + result.message);
        }
      }, 1000)
 
      
    } else {
      clearTimeout(this._delay_seach)
      this.setState({data : [], isLoading: false})
    }
  }



  getPagedData = () => {
    const { data } = this.state;

    data.map(x=> x.id=x.empId)

    return { data: data };
  };

  handleSearch = async search => {
    await this.setState({ search });

    this.loadSearch()

  };



  render() {
    // const { length: count } = this.state.movies;
    const { search, isLoading } = this.state;
    // const { user } = this.props;

    const { data } = this.getPagedData();

    const empty = (data.length===0);

    return (
      <React.Fragment>
        {empty && <div className="d-none d-lg-block" style={{height:234}}></div>}
        <div className="row justify-content-center">
          <div className="col-auto mb-3">
            <h4>ค้นหา</h4>
          </div>
          <div className="col-12 col-md-10 col-lg-8 mb-3">
            <SearchBox value={search} onChange={this.handleSearch} />
          </div>
        </div>
        {search && search.length>2 && data.length===0 && !isLoading && <div className="alert alert-secondary mt-4" role="alert">
          Search is not found
        </div>}
        {isLoading && <div className="alert alert-secondary mt-4" role="alert">
          Loading...
        </div>}
        <SearchTable data={data} />
      </React.Fragment>
      
    );
  }
}

export default Search;
